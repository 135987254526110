import React from "react";
import moment from "moment";
import uuidv4 from "uuid/v4";
import _ from "lodash";
import { connect } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
// import CardHeader from '@material-ui/core/CardHeader';
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import Rating from '@material-ui/lab/Rating';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import Alert from "Components/Common/Alert/Alert";
import ActionChip from "Components/Common/Chips/ActionChip";
import ActionMenu from "Components/Common/Activity/ActionMenu";
import ActivityLog from "Components/Common/Activity/ActivityLog";
import AdjustmentForm from "Components/Stock/Misc/AdjustmentForm";
import AddNoteForm from "Components/Common/Activity/AddNoteForm";
import API from "API";
import DataTable from "Components/Common/DataTables/DataTable";
import DocumentForm from "Components/Common/Forms/DocumentForm";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import Heading from "Components/Common/Heading/Heading";
import ImageWithError from "Components/Common/ImageWithError/ImageWithError";
import InsightChip from "Components/Common/Chips/InsightChip";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import Logo from "Assets/Images/no-img.jpg";
import PaddedError from "Components/Common/ErrorHandling/PaddedError";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import ProductStockChip from "Components/Common/Chips/ProductStockChip";
import SearchActivity from "Components/Stock/Misc/SearchActivity";
import TaskForm from "Components/Common/Activity/TaskForm";
import Tile from "Components/Common/Tiles/Tile";
import ViewActions from "Components/Common/Activity/ViewActions";
import ViewDocuments from "Components/Common/Activity/ViewDocuments";
import ViewNotes from "Components/Common/Activity/ViewNotes";
import ViewPage from "Components/Common/Styles/ViewPage";
import { CLOUDFRONT_URL, WEBSITE_BASE_URL, VIEW_PAGE_APP_BAR_HEIGHT, VIEW_PAGE_OFFSET } from "Constants";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";
import { getPresignedUrl } from "Functions/S3Functions";
import { closeDialog, deployDialog } from "Redux/Actions/Dialog/Dialog";
import { handleTabChange, momentFormatDate, TabPanel } from "Functions/MiscFunctions";
import CommodityCodeInput from "Components/Common/Inputs/CommodityCodeInput";
import BooleanTile from "Components/Common/Tiles/BooleanTile";
import { Hidden, Paper } from "@material-ui/core";
import ProductChart from "Components/Products/ViewProduct/ProductChart";
import ProductHistory from "Components/Reporting/Products/ProductHistory/ProductHistory";

import { setPersistence } from "Redux/Actions/StatePersistence/StatePersistence";

import { getInitialState, hasPageState, savePageState } from "Functions/StatePersistence/StatePersistenceFunctions";
import StockDueIn from "./StockDueIn";
import StockAllocation from "./StockAllocation";

const initialState = () => ({
    access: {
        addNote: false,
        setDefaultImage: false,
        updateProduct: false,
        stockAdjustment: false,
        stock: false,
        productStock: false,
        uploadDoc: false,
        modifyDoc: false,
        deleteDoc: false,
        deleteProduct: false,
    },
    currentTab: 0,
    dialogImagePath: "",
    isLoading: true,
    key: uuidv4(),
    product: {},
    productCodes: [],
    productDefaultCode: "",
    productId: "",
    viewing: {
        list: [],
        update: false,
    },
});

class ViewProduct extends React.Component {
    constructor(props) {
        super(props);
        this.mainContentArea = React.createRef();
        this.persistenceId = `viewProduct:${(this.props.id && this.props.id) || this.props.match.params.id}`;
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.handleTabChange = handleTabChange.bind(this);
        this.poll = false;
    }

    componentDidMount() {
        if (!this.hasPageState()) {
            API.multiAccess([
                "view-product:set-default-image",
                "view-quote:add-note",
                "view-product:update-product",
                "stock-adjustment",
                "stock",
                "product-stock",
                "view-product:add-document",
                "view-product:modify-document",
                "view-product:delete-document",
                "prd-delete"
            ]).then(([setDefaultImage, addNote, updateProduct, stockAdjustment, stock, productStock, uploadDoc, modifyDoc, deleteDoc, deleteProduct]) => {
                const productId = (this.props.id && this.props.id) || this.props.match.params.id;
                this.setState(
                    {
                        access: {
                            addNote,
                            setDefaultImage,
                            updateProduct,
                            stockAdjustment,
                            stock,
                            productStock,
                            uploadDoc,
                            modifyDoc,
                            deleteDoc,
                            deleteProduct
                        },
                        productId,
                    },
                    () => {
                        this.poll = setInterval(this.handlePoll, 5000);
                        this.loadComponentData();
                    }
                );
            });
        } else {
            if (this.props.pageTitle) {
                let code = _.find(this.state.product.codes, (el) => el.code_default === 1);
                if (code) {
                    code = `${code.code_value} - ${this.state.product.product_name}`;
                } else {
                    code = this.state.product.product_name;
                }

                this.props.pageTitle([1, "Products", code]);

                this.loadComponentData();
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match?.params?.id !== this.props.match?.params?.id) {
            const productId = (this.props.id && this.props.id) || this.props.match.params.id;
            this.persistenceId = `viewProduct:${productId}`;
            if (this.hasPageState()) {
                this.setState(
                    {
                        ...this.getInitialState(initialState()),
                    },
                    () => {
                        if (this.props.pageTitle) {
                            let code = _.find(this.state.product.codes, (el) => el.code_default === 1);
                            if (code) {
                                code = `${code.code_value} - ${this.state.product.product_name}`;
                            } else {
                                code = this.state.product.product_name;
                            }

                            this.props.pageTitle([1, "Products", code]);
                        }

                        this.loadComponentData();
                    }
                );
            } else {
                this.setState(
                    {
                        currentTab: 0,
                        isLoading: true,
                        productId,
                    },
                    () => {
                        this.loadComponentData();
                    }
                );
            }
        }
    }

    componentWillUnmount = () => {
        if (this.poll) {
            clearInterval(this.poll);
        }
    };

    handlePoll = () => {
        if (!document.hidden) {
            API.post(
                `/staff/my/view`,
                {
                    product: this.state.productId,
                    type: "View",
                },
                {
                    props: {
                        ignoreErrors: true,
                        noLoading: true,
                    },
                }
            ).then((res) => {
                const { viewing } = this.state;
                if (res?.data) {
                    const list = res.data;
                    if (JSON.stringify(list) !== JSON.stringify(viewing.list)) {
                        const update = _.some(list, (el) => el.u === 1);
                        this.setState(
                            {
                                viewing: {
                                    list,
                                    update,
                                },
                            },
                            () => {
                                if (viewing.update && !update) {
                                    const view = _.find(viewing.list, (el) => el.u === 1);
                                    if (this.props.staff[view.i]) {
                                        this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished updating this product`);
                                    }
                                    this.loadComponentData();
                                }
                            }
                        );
                    }
                }
            });
        }
    };

    loadComponentData = () => {
        API.get(`/products/${this.state.productId}`, {
            params: {
                forView: true,
            },
        }).then((result) => {
            if (result && result.data.errors) {
                if (this.props.history) {
                    this.props.history.push("/products");
                }
            } else if (result && result.data) {
                let product = result.data,
                    productCodes = [],
                    productDefaultCode = "",
                    isLoading = false;

                _.each(product.codes, (code) => {
                    if (code.code_default === 1) {
                        productDefaultCode = code.code_value;
                        productCodes.unshift({
                            heading: `${code.code_type}`,
                            default: true,
                            value: code.code_value,
                        });
                    } else {
                        productCodes.push({
                            heading: code.code_type,
                            value: code.code_value,
                        });
                    }
                });

                // remove trailing 0 from product.product_weight
                if (product.product_weight) {
                    product.product_weight = parseFloat(product.product_weight);
                }

                this.setState(
                    {
                        product,
                        productCodes,
                        productDefaultCode,
                        isLoading,
                    },
                    () => {
                        if (this.props.pageTitle) {
                            let code = _.find(product.codes, (el) => el.code_default === 1);
                            if (code) {
                                code = `${code.code_value} - ${product.product_name}`;
                            } else {
                                code = product.product_name;
                            }

                            this.props.pageTitle([1, "Products", code]);
                        }

                        this.savePageState();
                    }
                );
            }
        });
    };

    handleCallback = () => {
        this.setState(
            {
                key: uuidv4(),
            },
            () => this.loadComponentData()
        );
    };

    handleDeleteProduct = () => {
        API.put(`/products/${this.state.productId}/delete`)
        .then(res => {
            if (res?.data) {
                this.props.deploySnackBar("success", "The product has been successfully deleted");
                this.props.history.push('/products');
            }
        });
    }

    setDefaultImage = (id) => {
        API.put(`/products/${this.state.productId}/defaultImage`, { imageId: id }).then((result) => {
            if (result.data) {
                this.props.deploySnackBar("success", "The default image has been updated");
                this.loadComponentData();
            }
        });
    };

    handleDeployAddNote = () =>
        this.props.deployDialog(
            <AddNoteForm
                id={this.state.productId}
                relation="product"
                toggleDialog={() => {
                    this.loadComponentData();
                    this.props.closeDialog();
                }}
                standardOnly
            />,
            true,
            "",
            "standard",
            "sm",
            false,
            true
        );

    handleDeployAddTask = () =>
        this.props.deployDialog(
            <TaskForm
                id={this.state.productId}
                relation="product"
                toggleDialog={() => {
                    this.loadComponentData();
                    this.props.closeDialog();
                }}
            />,
            true,
            "",
            "standard",
            "sm",
            false,
            true
        );

    handleDeployStockAdj = () =>
        this.props.deployDialog(
            <AdjustmentForm productId={this.state.productId} callback={this.handleCallback} closeDialog={this.props.closeDialog} />,
            false,
            "Stock Adjustment",
            "standard",
            "xs",
            false,
            true
        );

    handleDeployFileUpload = () =>
        this.props.deployDialog(
            <DocumentForm id={this.state.productId} type="product" category="Products" callback={this.loadComponentData} cancel={this.props.closeDialog} privateFileText="Hide from website" allAllPrivate={true} />,
            false,
            "File Upload",
            "standard",
            "xs",
            false,
            true
        );

    handleDeployImage = (file) => this.props.deployDialog(<ImageWithError src={file} alt="Part Image" style={{ maxWidth: 500 }} />, false, "", "standard", "sm");

    render() {
        const { classes, inDialog, staff, ui } = this.props;
        const { access, currentTab, isLoading, key, product, productCodes, productDefaultCode, viewing } = this.state;
        const height = window.matchMedia("(display-mode: standalone)").matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight = window.matchMedia("(display-mode: standalone)").matches
            ? `calc(env(safe-area-inset-bottom) + ${ui.device.height - (inDialog ? 64 : VIEW_PAGE_OFFSET)}px)`
            : height - (inDialog ? 64 : VIEW_PAGE_OFFSET);
        return (
            <>
                <Grid container style={{ margin: -24, width: "initial" }}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{ backgroundColor: "#fff", borderBottom: "1px solid #ddd", height: VIEW_PAGE_APP_BAR_HEIGHT }} position="static" elevation={0}>
                                    <Box pt={inDialog ? 2 : 1} pr={1} pb={1} pl={3}>
                                        <Grid container spacing={3} alignItems="center">
                                            <Grid item>
                                                <Avatar
                                                    style={{ width: 60, height: 60 }}
                                                    variant="square"
                                                    w
                                                    alt={product.product_name}
                                                    src={product.default_image ? `${CLOUDFRONT_URL}${product.default_image.img_filekey}` : Logo}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <Grid container alignItems="center">
                                                    <Grid item xs>
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <Typography variant="h4" className="textDefault" style={{ textDecoration: product.product_deleted === 1 ? "line-through" : "none" }}>
                                                                    {productDefaultCode}
                                                                    {!ui?.device?.isPortrait
                                                                        ? ` - ${product.product_name.substring(0, ui?.device?.width < 1200 ? 25 : 50)}${
                                                                              product.product_name.length > (ui?.device?.width < 1200 ? 25 : 50) ? "..." : ""
                                                                          }`
                                                                        : ``}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {!inDialog && (
                                                        <Grid item>
                                                            <Tooltip title="Add Action" placement="top">
                                                                <IconButton onClick={() => this.handleDeployAddTask()}>
                                                                    <FAIcon type="light" icon="layer-plus" size={17.5} button noMargin />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    )}
                                                    {!inDialog && access.addNote && (
                                                        <Grid item>
                                                            <Tooltip title="Add Note" placement="top">
                                                                <IconButton onClick={() => this.handleDeployAddNote()}>
                                                                    <FAIcon type="light" icon="comment-alt-plus" size={17.5} button noMargin />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    )}
                                                    {!inDialog && access.uploadDoc && (
                                                        <Grid item>
                                                            <Tooltip title="Upload File" placement="top">
                                                                <IconButton onClick={() => this.handleDeployFileUpload()}>
                                                                    <FAIcon type="light" icon="file-plus" button noMargin size={17.5} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    )}
                                                    {!inDialog && access.updateProduct && product.product_deleted === 0 && (
                                                        <Grid item>
                                                            <Tooltip title="Modify Product" placement="top">
                                                                <IconButton disabled={viewing.update} onClick={() => this.props.history.push(`/products/update/${product.product_id}`)}>
                                                                    <FAIcon type="light" icon="pencil" size={17.5} disabled={viewing.update} button noMargin />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    )}
                                                    {!inDialog && access.stockAdjustment && product.product_service === 0 && product.product_deleted === 0 && (
                                                        <Grid item>
                                                            <Tooltip title="New Stock Adjustment" placement="top">
                                                                <IconButton onClick={() => this.handleDeployStockAdj()}>
                                                                    <FAIcon type="light" icon="exchange" size={17.5} button noMargin />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    )}
                                                    {!inDialog && (
                                                        <Grid item>
                                                            <ActionMenu
                                                                rows={[
                                                                    {
                                                                        icon: "layer-plus",
                                                                        label: "Add Action",
                                                                        onClick: () => this.handleDeployAddTask(),
                                                                        display: true,
                                                                    },
                                                                    {
                                                                        icon: "comment-alt-plus",
                                                                        label: "Add Note",
                                                                        onClick: () => this.handleDeployAddNote(),
                                                                        display: access.addNote,
                                                                    },
                                                                    {
                                                                        icon: "exchange",
                                                                        label: "New Stock Adjustment",
                                                                        onClick: () => this.handleDeployStockAdj(),
                                                                        display: access.stockAdjustment && product.product_service === 0,
                                                                        disabled: product.product_deleted === 1
                                                                    },
                                                                    {
                                                                        icon: "file-plus",
                                                                        label: "Upload File",
                                                                        onClick: () => this.handleDeployFileUpload(),
                                                                        display: access.uploadDoc,
                                                                        divider: true
                                                                    },
                                                                    {
                                                                        icon: "pencil",
                                                                        label: "Modify Product",
                                                                        onClick: () => this.props.history.push(`/products/update/${product.product_id}`),
                                                                        display: access.updateProduct,
                                                                        disabled: viewing.update || product.product_deleted === 1,
                                                                    },
                                                                    {
                                                                        icon: "trash-alt",
                                                                        label: "Delete Product",
                                                                        onClick: () => this.props.deployConfirmation(`Are you sure you want to delete this product?`, this.handleDeleteProduct),
                                                                        display: access.deleteProduct,
                                                                        disabled: product.product_deleted === 1
                                                                    },
                                                                ]}
                                                            />
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={12}>
                                                        <Grid container justify="space-between" alignItems="center">
                                                            <Grid item xs={10} md={9}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item>
                                                                        {product.product_value_vault ? (
                                                                            <InsightChip 
                                                                                background={`#ef3340`}
                                                                                color='#fff'
                                                                                icon={'star'}
                                                                                label={`Value Vault `}
                                                                                marginRight={4}
                                                                                paddingLeft={6}
                                                                            />
                                                                        ) : null}
                                                                    </Grid>
                                                                    {product.product_service === 0 && (
                                                                        <Grid item>
                                                                            <ProductStockChip
                                                                                deleted={product.product_deleted}
                                                                                rep={product.product_rep_product_id}
                                                                                status={product.product_status}
                                                                                stocked={product.product_stock_item === 1}
                                                                                stockAvailable={product.stock_available_count}
                                                                                stockOnOrderAvailable={product.stock_on_order_count - product.stock_on_order_allocated_count}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    {product.product_check_price === 1 && (
                                                                        <Grid item>
                                                                            <InsightChip marginRight={8} paddingLeft={8} icon="exclamation-triangle" label="Check Price and Availability" />
                                                                        </Grid>
                                                                    )}
                                                                    {/* <Grid item>
                                                                        {(product.product_service === 1 && <InsightChip icon="check" label={`Managed Service`} paddingLeft={8} />) || (
                                                                            <InsightChip
                                                                                icon="check"
                                                                                label={`${
                                                                                    product.product_consignment_stock ? "Consignment" : product.product_stock_item ? "Stocked" : "Non-stocked"
                                                                                } Product`}
                                                                                paddingLeft={8}
                                                                            />
                                                                        )}
                                                                    </Grid> */}
                                                                    {!ui?.device?.isTablet && (
                                                                        <>
                                                                            <Grid item>
                                                                                <InsightChip
                                                                                    icon="chevron-right"
                                                                                    type="light"
                                                                                    label={`${product.product_service === 1 ? "" : ""} ${
                                                                                        product.supplier?.supp_company_name ?? "Advanced Access Internal"
                                                                                    }`}
                                                                                />
                                                                            </Grid>
                                                                            {product.brand && (
                                                                                <Grid item>
                                                                                    <InsightChip icon="chevron-right" type="light" label={`${product.brand.brand_name}`} />
                                                                                </Grid>
                                                                            )}
                                                                            {product.range && (
                                                                                <Grid item>
                                                                                    <InsightChip icon="chevron-right" type="light" label={`${product.range.range_name}`} />
                                                                                </Grid>
                                                                            )}
                                                                            {product.category && (
                                                                                <Grid item>
                                                                                    <InsightChip icon="chevron-right" type="light" label={`${product.category.parent ? `${product.category.parent.category_name} - ` : ``}${product.category.category_name}`} />
                                                                                </Grid>
                                                                            )}
                                                                            {/* {product.category &&
                                                                                _.map(product.category, (cat, idx) => (
                                                                                    <Grid item>
                                                                                        <InsightChip icon="arrow-right" label={cat.category_name} paddingLeft={8} />
                                                                                    </Grid>
                                                                                ))} */}
                                                                        </>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                            <Hidden smDown>
                                                                <Grid item xs={6} md={3}>
                                                                    <Grid container spacing={2} justify="flex-end" style={{overflow: 'hidden', whiteSpace: 'nowrap'}}>
                                                                        <Grid item>
                                                                            <ActionChip action={{ u: 0, p: 0 }} staff={staff[this.props.loggedInStaff.id]} page="product" />
                                                                        </Grid>
                                                                        {_.map(viewing.list, (view, idx) => {
                                                                            if (staff[view.i]) {
                                                                                return (
                                                                                    <Grid item key={idx}>
                                                                                        <ActionChip action={view} staff={staff[view.i]} page="product" />
                                                                                    </Grid>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </Grid>
                                                                </Grid>
                                                            </Hidden>
                                                            <Hidden mdUp>
                                                                <Grid item xs={2} align="right">
                                                                    <ActionChip staff={{ name: `${_.size(viewing.list) + 1} viewing` }} page="product" />
                                                                </Grid>
                                                            </Hidden>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid>
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{ height: contentHeight - VIEW_PAGE_APP_BAR_HEIGHT }}>
                                <Grid container>
                                    {(product.product_deleted === 1 && (
                                        <Grid item xs={12}>
                                            <Box borderBottom="1px solid #ddd">
                                                <Alert severity="error" variant="standard" elevation={0}>
                                                    <strong>Product Deleted</strong> - Please contact Sales Admin to re-instate this product...
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )) || ((product.product_bulky === 1 || product.product_heavy === 1) && (
                                        <Grid item xs={12}>
                                            <Box borderBottom="1px solid #ddd">
                                                <Alert severity={"warning"}>
                                                    <strong>Warning -</strong> Please handle with care, this product is{" "}
                                                    {product.product_bulky === 1
                                                        ? `large (${product.product_length ?? 0}mm x ${product.product_width ?? 0}mm x ${product.product_height ?? 0}mm)${
                                                              product.product_heavy === 1 ? ` and ` : ``
                                                          }`
                                                        : ``}
                                                    {product.product_heavy === 1 ? `heavy (${product.product_weight}kg)` : ``}
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    ))}
                                    {product.newPrice && (
                                        <Grid item xs={12}>
                                            <Box borderBottom="1px solid #ddd">
                                                <Paper elevation={0}>
                                                    <Box p={1.75}>
                                                        {product.newPrice && (
                                                            <Grid item xs={12}>
                                                                <Typography variant="body2" className="fw-400">
                                                                    <FAIcon icon="exclamation-triangle" type="solid" className="textError mr-1" size={15} />
                                                                    The trade price of this product is going to {product.newPriceTitle ?? "change"} to &pound;{product.newPrice} on{" "}
                                                                    {product.newPriceDate}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                    </Box>
                                                </Paper>
                                            </Box>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Box p={3}>
                                            <TabPanel value={currentTab} index={0}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={6} xl={8}>
                                                        <Grid container spacing={3}>
                                                            {product.product_deleted !== 1 && (
                                                                <>
                                                                    {(product.replacement && (
                                                                        <Grid item xs={12}>
                                                                            <Paper>
                                                                                <Box borderBottom="1px solid #eee">
                                                                                    <Alert
                                                                                        severity={product.product_status === "Active" ? "warning" : "error"}
                                                                                        style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                                                                                    >
                                                                                        <span className="fw-400">
                                                                                            {(product.product_status === "Active" && `This product has been temporarily replaced by -`) ||
                                                                                                `This product has been replaced by -`}
                                                                                        </span>
                                                                                    </Alert>
                                                                                </Box>
                                                                                <Box
                                                                                    mt={1}
                                                                                    // p={3}
                                                                                    pt={2}
                                                                                    width="100%"
                                                                                    style={{ cursor: "pointer", border: 0 }}
                                                                                    onClick={() => this.props.history.push(`/products/${product.replacement?.product_id ?? 0}`)}
                                                                                >
                                                                                    <Grid container spacing={1} alignItems="center">
                                                                                        <Grid item>
                                                                                            <ImageWithError
                                                                                                width={125}
                                                                                                height={125}
                                                                                                src={
                                                                                                    product?.replacement?.default_image && `${CLOUDFRONT_URL}${product?.replacement?.default_image.img_filekey}`
                                                                                                }
                                                                                                alt={product?.replacement?.product_name}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs>
                                                                                            <Typography variant="body1" className="fw-400 textDefault">
                                                                                                {product.replacement?.default_code?.code_value ?? ""}
                                                                                            </Typography>
                                                                                            <Typography variant="body2" className="textDefault">
                                                                                                {product.replacement?.product_name}
                                                                                            </Typography>
                                                                                            <Typography variant="caption" component="div" className="textDefault">
                                                                                                {`${product.replacement?.stock_count ?? 0} In Stock | ${
                                                                                                    product.replacement?.stock_allocated_count ?? 0
                                                                                                } Allocated | ${product.replacement?.stock_available_count ?? 0} Available | ${
                                                                                                    product.replacement?.stock_on_order_count ?? 0
                                                                                                } On Order | ${product.replacement?.stock_on_order_allocated_count ?? 0} Pre-ordered`}
                                                                                            </Typography>
                                                                                            <Box pt={1}>
                                                                                                <ProductStockChip
                                                                                                    rep={product.replacement?.product_rep_product_id}
                                                                                                    status={product.replacement?.product_status}
                                                                                                    stocked={product.replacement?.product_stock_item}
                                                                                                    stockAvailable={product.replacement?.stock_available_count}
                                                                                                    stockOnOrderAvailable={
                                                                                                        product.replacement?.stock_on_order_count - product.replacement?.stock_on_order_allocated_count
                                                                                                    }
                                                                                                />
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                            </Paper>
                                                                        </Grid>
                                                                    )) ||
                                                                        (!product.replacement && product.product_service === 0 && (
                                                                            <Grid item xs={12}>
                                                                                <Paper>
                                                                                    <Box borderBottom="1px solid #eee">
                                                                                        <Alert
                                                                                            severity={
                                                                                                product.product_status === "Active"
                                                                                                    ? product.product_stock_item === 0
                                                                                                        ? `success`
                                                                                                        : product.stock_available_count > 0
                                                                                                        ? `success`
                                                                                                        : product.stock_on_order_count > 0
                                                                                                        ? `warning`
                                                                                                        : `error`
                                                                                                    : "error"
                                                                                            }
                                                                                            style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                                                                                        >
                                                                                            <span className="fw-400">
                                                                                                {(product.product_status === "Active" &&
                                                                                                    `This product ${
                                                                                                        product.product_stock_item === 0
                                                                                                            ? `is available subject to supplier availability`
                                                                                                            : product.stock_available_count > 0
                                                                                                            ? `is in-stock and available today`
                                                                                                            : product.stock_on_order_count > 0
                                                                                                            ? `is currently available to pre-order`
                                                                                                            : `is currently out of stock`
                                                                                                    }`) ||
                                                                                                    `This product may no longer be available`}
                                                                                            </span>
                                                                                        </Alert>
                                                                                    </Box>
                                                                                    <Box pl={1} p={1} width="100%">
                                                                                        <Grid container spacing={3} alignItems="center">
                                                                                            <Grid item xs>
                                                                                                <Grid item xs={12}>
                                                                                                    <Box p={1.5} pt={1.5}>
                                                                                                        <Box>
                                                                                                            <Typography variant="body2" component="span" gutterBottom>
                                                                                                                <em>
                                                                                                                    Pack Size: {product.product_pack_size} | Min Order Quantity: {product.product_min_order_qty} | Order In Multiples Of: {product.product_order_multiples_of}
                                                                                                                </em>
                                                                                                            </Typography>
                                                                                                            {product.product_length > 0 && product.product_width > 0 && product.product_height > 0 && (
                                                                                                                <Typography variant="body2" component="span" gutterBottom>
                                                                                                                    <em>
                                                                                                                        {' | '}Dimensions: {product.product_length}mm (L) x {product.product_width}mm (W) x{" "}
                                                                                                                        {product.product_height}mm (H)
                                                                                                                    </em>
                                                                                                                </Typography>
                                                                                                            )}
                                                                                                        </Box>
                                                                                                        <Box my={1}>
                                                                                                            <Divider />
                                                                                                        </Box>
                                                                                                        {(!_.isEmpty(product.product_full_description) && (
                                                                                                            <Typography
                                                                                                                variant="body2"
                                                                                                                dangerouslySetInnerHTML={{ __html: product.product_full_description }}
                                                                                                            />
                                                                                                        )) || <Typography variant="body2">No product information is available for this product</Typography>}
                                                                                                    </Box>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Box>
                                                                                </Paper>
                                                                            </Grid>
                                                                        ))}
                                                                    </>
                                                                )}
                                                            <Grid item xs={12} lg={6}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Grid container alignItems="center">
                                                                                <Grid item xs={12}>
                                                                                    <Heading text="Codes" />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Grid container spacing={2}>
                                                                                        {_.map(productCodes, (code) => (
                                                                                            <Grid item xs={12}>
                                                                                                <Tile
                                                                                                    noPaper
                                                                                                    icon={ui?.device?.isTablet ? false : code.default ? "star" : "check"}
                                                                                                    iconType={code.default ? "solid" : "light"}
                                                                                                    color={code.default ? "#FFA000" : "#000"}
                                                                                                    title={code.heading}
                                                                                                    content={code.value}
                                                                                                />
                                                                                            </Grid>
                                                                                        ))}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Box pb={0.4}>
                                                                                <Heading text="Import / Export" />
                                                                            </Box>
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={12}>
                                                                                    <Tile
                                                                                        // color="#BA68C8"
                                                                                        noPaper
                                                                                        icon="globe"
                                                                                        title="Country Of Origin"
                                                                                        content={
                                                                                            product.origin
                                                                                                ? `${product.origin?.country_code_alpha2 ? `${product.origin?.country_code_alpha2} - ` : ``}${
                                                                                                    product.origin?.country_name
                                                                                                }`
                                                                                                : "Unknown"
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} style={{ marginTop: -3 }}>
                                                                                    <Tile
                                                                                        // color="#EC407A"
                                                                                        noPaper
                                                                                        icon="arrow-from-bottom"
                                                                                        title="Export Commodity Code"
                                                                                        content={
                                                                                            <CommodityCodeInput
                                                                                                forExport
                                                                                                disabled
                                                                                                noLabel
                                                                                                value={_.isEmpty(product.product_cc_export) ? `--------` : product.product_cc_export}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} style={{ marginTop: -3 }}>
                                                                                    <Tile
                                                                                        // color="#00ACC1"
                                                                                        noPaper
                                                                                        icon="arrow-to-bottom"
                                                                                        title="Import Commodity Code"
                                                                                        content={
                                                                                            <CommodityCodeInput
                                                                                                disabled
                                                                                                noLabel
                                                                                                value={_.isEmpty(product.product_cc_import) ? `----------` : product.product_cc_import}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="Product Details" />
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        title="Check Price & Availability"
                                                                                        content={product.product_check_price ? "Yes" : "No"}
                                                                                        isTrue={!product.product_check_price}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        title="Check Weight"
                                                                                        content={product.product_check_weight ? product.product_check_weight_qty_allowed > 0 ? `Yes (> ${product.product_check_weight_qty_allowed} Qty)` : "Yes" : "No"}
                                                                                        isTrue={!product.product_check_weight}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        title="Discountable"
                                                                                        content={product.product_no_discount === 0 ? "Yes" : "No"}
                                                                                        isTrue={product.product_no_discount === 0 ? true : false}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        isTrue
                                                                                        noPaper
                                                                                        icon="coins"
                                                                                        title="Nominal Code"
                                                                                        content={
                                                                                            product.nominal_code ? `${product.nominal_code?.nominal_code} - ${product.nominal_code?.nominal_name}` : "Not Set"
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Divider />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        title="Bespoke Product"
                                                                                        content={product.product_bespoke ? "Yes" : "No"}
                                                                                        isTrue={!product.product_bespoke}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        title="Card Order Required"
                                                                                        content={product.product_carddb ? "Yes" : "No"}
                                                                                        isTrue={!product.product_carddb}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        title="Customer Specific"
                                                                                        content={product.customer?.cust_account_number ?? "No"}
                                                                                        isTrue={!product.customer ? true : false}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        title="In-house Programming"
                                                                                        content={product.product_programmable ? "Yes" : "No"}
                                                                                        isTrue={!product.product_programmable}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        title="Exclude From Website"
                                                                                        content={product.product_exclude_web ? `Yes` : `No`}
                                                                                        isTrue={!product.product_exclude_web}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        title="Paxton Hardware"
                                                                                        content={product.product_pax_hw ? "Yes" : "No"}
                                                                                        isTrue={!product.product_pax_hw ? true : false}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        title="Published On Website"
                                                                                        content={!product.product_exclude_web && product.product_ecommerce && product.product_status === 'Active' ? "Yes" : "No"}
                                                                                        isTrue={product.product_exclude_web || (!product.product_exclude_web && product.product_ecommerce && product.product_status === 'Active') ? true : false}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <BooleanTile
                                                                                        noPaper
                                                                                        title="Requires Technical"
                                                                                        content={product.product_req_tech ? "Yes" : "No"}
                                                                                        isTrue={!product.product_req_tech ? true : false}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6} xl={4}>
                                                        <Grid container spacing={3}>
                                                            {product.product_service === 0 && (
                                                                <Grid item xs={12} lg={6}>
                                                                    <PaddedPaper>
                                                                        <Heading text="Availability" />
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={12}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon={!product.product_stock_item || (product.product_stock_item && product.stock_count > 0) ? "check-circle" : "exclamation-circle"}
                                                                                    color={!product.product_stock_item || (product.product_stock_item && product.stock_count > 0) ? "#4CAF50" : "#f44336"}
                                                                                    title="In Stock"
                                                                                    content={product.stock_count}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} className="pt-1 pb-1">
                                                                                <Divider />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon={!product.product_stock_item || (product.product_stock_item && product.stock_available_count > 0) ? "check-circle" : "exclamation-circle"}
                                                                                    color={!product.product_stock_item || (product.product_stock_item && product.stock_available_count > 0) ? "#4CAF50" : "#f44336"}
                                                                                    title="Available"
                                                                                    content={product.stock_available_count}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon={product.stock_allocated_count === 0 ? "check-circle" : "exclamation-circle"}
                                                                                    color={product.stock_allocated_count === 0 ? "#4CAF50" : "#f44336"}
                                                                                    title="Allocated"
                                                                                    content={product.stock_allocated_count}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon={product.stock_on_order_count === 0 ? "check-circle" : "exclamation-circle"}
                                                                                    color={product.stock_on_order_count === 0 ? "#4CAF50" : "#f44336"}
                                                                                    title="On Order"
                                                                                    content={product.stock_on_order_count}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon={product.stock_on_order_allocated_count === 0 ? "check-circle" : "exclamation-circle"}
                                                                                    color={product.stock_on_order_allocated_count === 0 ? "#4CAF50" : "#f44336"}
                                                                                    title="Pre-ordered"
                                                                                    content={product.stock_on_order_allocated_count}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} className="pt-1 pb-1">
                                                                                <Divider />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="calendar-day"
                                                                                    // color="#9C27B0"
                                                                                    title="Last Ordered"
                                                                                    content={product.last_ordered}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </PaddedPaper>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12} lg={product.product_service === 0 ? 6 : 12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Pricing" />
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={12}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="book-open"
                                                                                // color="#4CAF50"
                                                                                title="Trade Price"
                                                                                content={`£${parseFloat(product.default_rate.rate_price).toFixed(2)}`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="percent"
                                                                                // color="#2196F3"
                                                                                title="Margin"
                                                                                content={`${parseFloat(product.default_rate.rate_margin).toFixed(2)}%`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} className="pt-1 pb-1">
                                                                            <Divider />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="tag"
                                                                                // color="#FF9800"
                                                                                title="List Price"
                                                                                content={`£${parseFloat(product.default_rate.rate_rrp).toFixed(2)}`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="badge-percent"
                                                                                // color="#3F51B5"
                                                                                title="Supplier Discount"
                                                                                content={
                                                                                    product.default_rate?.rate_discount !== 0 ? `${parseFloat(product.default_rate.rate_discount).toFixed(2)}%` : "None"
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="money-check"
                                                                                // color="#f44336"
                                                                                title="Buy Price"
                                                                                content={`£${parseFloat(product.default_rate.rate_cost).toFixed(2)}`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} className="pt-1 pb-1">
                                                                            <Divider />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="arrows-v"
                                                                                // color="#9C27B0"
                                                                                title="Last Price Change"
                                                                                content={
                                                                                    product.default_rate.rate_valid_from === "0000-00-00"
                                                                                        ? "-"
                                                                                        : moment(product.default_rate.rate_valid_from).format("DD/MM/YYYY")
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid>
                                                            
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Fulfilment" />
                                                                    <Grid container spacing={2}>
                                                                        {(product.product_service === 1 && (
                                                                            <>
                                                                                <Grid item xs={12}>
                                                                                    <Alert severity="success" variant="outlined">
                                                                                        This product is a service and no fulfilment is required
                                                                                    </Alert>
                                                                                </Grid>
                                                                                <Grid item xs={12} style={{ marginTop: -2 }}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="cogs"
                                                                                        // color="#558B2F"
                                                                                        title="Service Managed By"
                                                                                        content={"Advanced Access Internal"}
                                                                                    />
                                                                                </Grid>
                                                                            </>
                                                                        )) || (
                                                                            <>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="inventory"
                                                                                        // color="#0097A7"
                                                                                        title="Bin Location"
                                                                                        content={`${product?.warehouse_bin?.wh_bin_location ?? 'Not Set (Direct)'}`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="balance-scale"
                                                                                        // color="#F57C00"
                                                                                        title="Weight"
                                                                                        content={`${product.product_weight}kg`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="arrow-down"
                                                                                        // color="#c62828"
                                                                                        title="Min Stock Level"
                                                                                        content={product.product_min_stock_level}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="arrow-up"
                                                                                        // color="#558B2F"
                                                                                        title="Max Stock Level"
                                                                                        content={product.product_max_stock_level}
                                                                                    />
                                                                                </Grid>
                                                                            </>
                                                                        )}
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid>
                                                            {product.product_carddb === 1 && (
                                                                <Grid item xs={12}>
                                                                    <PaddedPaper>
                                                                        <Heading text="Card DB" />
                                                                        <Grid container spacing={3}>
                                                                            <Grid item xs={12}>
                                                                                <Tile
                                                                                    // color="#7CB342"
                                                                                    noPaper
                                                                                    icon="credit-card-blank"
                                                                                    title="Card Format"
                                                                                    content={product.card_db_format ? `${product.card_db_format.format_name}` : "Not Set"}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Tile
                                                                                    // color="#7CB342"
                                                                                    noPaper
                                                                                    icon="id-card"
                                                                                    title="Card Type"
                                                                                    content={product.card_db_type ? `${product.card_db_type.type_name}` : "Not Set"}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Tile
                                                                                    // color="#7CB342"
                                                                                    noPaper
                                                                                    icon="laptop-code"
                                                                                    title="Programmer Type"
                                                                                    content={product.card_db_programmer ? `${product.card_db_programmer.programmer_name}` : "Not Set"}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </PaddedPaper>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={8}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={8}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Description" />
                                                                    <Tile
                                                                        noPaper
                                                                        icon="search"
                                                                        color="#000"
                                                                        content={!_.isEmpty(product.product_description) ? product.product_description : "Not Set"}
                                                                        small
                                                                    />
                                                                </PaddedPaper>
                                                            </Grid>
                                                            {!_.isEmpty(product.key_features) && (
                                                                <Grid item xs={12}>
                                                                    <PaddedPaper>
                                                                        <Grid container spacing={1} alignItems="center" justify="flex-end">
                                                                            <Grid item xs={12}>
                                                                                <Grid container spacing={1} alignItems="center">
                                                                                    <Grid item>
                                                                                        <Typography variant="h6">Key Features</Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <List disablePadding>
                                                                                    {(!_.isEmpty(product.key_features) &&
                                                                                        _.map(product.key_features, (f, idx) => (
                                                                                            <ListItem key={idx} dense disableGutters>
                                                                                                <ListItemIcon>
                                                                                                    <FAIcon icon="check" />
                                                                                                </ListItemIcon>
                                                                                                <ListItemText
                                                                                                    primary={<Typography variant="body2" dangerouslySetInnerHTML={{ __html: f }} />}
                                                                                                    disableTypography
                                                                                                />
                                                                                            </ListItem>
                                                                                        ))) || <Alert severity="warning">There are no key features set for this product</Alert>}
                                                                                </List>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </PaddedPaper>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Grid container spacing={1} alignItems="center" justify="flex-end">
                                                                        <Grid item xs={12}>
                                                                            <Grid container spacing={1} alignItems="center">
                                                                                <Grid item>
                                                                                    <Typography variant="h6">Product Information</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        {product.product_length > 0 && product.product_width > 0 && product.product_height > 0 && (
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="caption">
                                                                                    <em>
                                                                                        Dimensions: {product.product_length}mm (L) x {product.product_width}mm (W) x {product.product_height}mm (H)
                                                                                    </em>
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}
                                                                        <Grid item xs={12}>
                                                                            {(product.product_full_description && (
                                                                                <Typography variant="body2" dangerouslySetInnerHTML={{ __html: product.product_full_description }} />
                                                                            )) || (
                                                                                <Alert severity="warning" variant="outlined">
                                                                                    No product information is available for this product
                                                                                </Alert>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} lg={4}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Published" />
                                                                    <BooleanTile
                                                                        isTrue={product.product_ecommerce === 1 && product.product_status === 'Active'}
                                                                        noPaper
                                                                        content={
                                                                            <Grid container alignItems="center">
                                                                                <Grid item>{product.product_ecommerce === 1 && product.product_status === 'Active' ? "Yes" : "No"}</Grid>
                                                                                <Grid item xs className="pl-1">
                                                                                    <Tooltip title="View">
                                                                                        <IconButton
                                                                                            disabled={product.product_ecommerce === 0}
                                                                                            onClick={() => window.open(`${WEBSITE_BASE_URL}shop/product/${product.product_slug}`)}
                                                                                        >
                                                                                            <FAIcon icon="external-link" noMargin button size={15} />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Grid>
                                                                            </Grid>
                                                                        }
                                                                        small
                                                                    />
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Categories" />
                                                                    <Tile
                                                                        noPaper
                                                                        icon="arrow-right"
                                                                        color="#000"
                                                                        content={
                                                                            !_.isEmpty(product.ecommerce_category?.category_name) ? `${product.ecommerce_category?.category_name} (Primary)` : "Not Set"
                                                                        }
                                                                        small
                                                                    />
                                                                    {_.map(product.ecommerce_categories, (cat, idx) => (
                                                                        <Tile noPaper icon="arrow-right" color="#000" key={idx} content={cat.category_name} small />
                                                                    ))}
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Supplier" />
                                                                    <Tile noPaper icon="truck" color="#000" content={product.ecommerce_supplier?.supp_company_name ?? "Not Set"} small />
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Product Filters" />
                                                                    {_.isEmpty(product.filters) ? (
                                                                        <Tile noPaper icon="exclamation-circle" color="#000" content={`No filters set for this product`} small />
                                                                    ) : (
                                                                        <Grid container>
                                                                            {_.map(product.filters, (filter, idx) => (
                                                                                <Grid item xs={12} key={idx}>
                                                                                    <Tile noPaper icon={`caret-right`} color="#000" content={<><strong>{filter.name}</strong>: {filter.value ?? <span className="textError fw-400">Not set</span>}</>} small />
                                                                                </Grid>
                                                                            ))}
                                                                        </Grid>
                                                                    )}
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Website URL Code" />
                                                                    <Tile noPaper icon="directions" color="#000" content={product.product_slug ?? "Not Set"} small />
                                                                </PaddedPaper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={8}>
                                                        <Paper>
                                                            <Box p={3}>
                                                                <Heading text="Sales History" />
                                                                <ProductHistory inline productId={product.product_id} />
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <Paper>
                                                                    <Box p={3}>
                                                                        <Heading text="Sales | Last 12 months" />
                                                                        <ProductChart productId={product.product_id} />   
                                                                    </Box>
                                                                </Paper>                                                             
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Paper>
                                                                    <Box p={3}>
                                                                        <Heading text="Price History" />
                                                                        <DataTable
                                                                            config={{
                                                                                key: "rate_id",
                                                                                inline: true,
                                                                            }}
                                                                            columns={[
                                                                                {
                                                                                    heading: "Trade Price",
                                                                                    field: (rowData) => rowData.rate_price,
                                                                                    fieldFormat: "currency",
                                                                                    sizeToContent: true,
                                                                                    cellProps: (rowData) => ({
                                                                                        className: rowData.rate_valid_to === "0000-00-00" ? "fw-400" : undefined,
                                                                                    }),
                                                                                },
                                                                                {
                                                                                    heading: "Buy Price",
                                                                                    field: (rowData) => rowData.rate_cost,
                                                                                    fieldFormat: "currency",
                                                                                    sizeToContent: true,
                                                                                    cellProps: (rowData) => ({
                                                                                        className: rowData.rate_valid_to === "0000-00-00" ? "fw-400" : undefined,
                                                                                    }),
                                                                                },
                                                                                {
                                                                                    heading: "Margin",
                                                                                    field: (rowData) => `${rowData.rate_margin}%`,
                                                                                    fieldFormat: "percentage:2",
                                                                                    alignment: "right",
                                                                                    sizeToContent: true,
                                                                                    cellProps: (rowData) => ({
                                                                                        className: rowData.rate_valid_to === "0000-00-00" ? "fw-400" : undefined,
                                                                                    }),
                                                                                },
                                                                                {
                                                                                    heading: "Period",
                                                                                    field: (rowData) =>
                                                                                        `${momentFormatDate(rowData.rate_valid_from)} - ${
                                                                                            rowData.rate_valid_to === "0000-00-00" ? `Now` : momentFormatDate(rowData.rate_valid_to)
                                                                                        }`,
                                                                                    sizeToContent: true,
                                                                                    cellProps: (rowData) => ({
                                                                                        className: rowData.rate_valid_to === "0000-00-00" ? "fw-400" : undefined,
                                                                                    }),
                                                                                },
                                                                            ]}
                                                                            rows={_.orderBy(product.rates, ["rate_id"], ["desc"])}
                                                                        />
                                                                    </Box>
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            {(access.stock || access.productStock) && (
                                                <TabPanel value={currentTab} index={2}>
                                                    <SearchActivity key={key} noPersistence productId={product.product_id} inDialog={inDialog} />
                                                </TabPanel>
                                            )}
                                            <TabPanel value={currentTab} index={3}>
                                                <ViewNotes notes={product.notes} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={4}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <ViewDocuments access={inDialog ? {} : access} callback={this.loadComponentData} documents={product.documents} category="Products" privateFileText="Hide from website" privateFileChip="Hidden" allAllPrivate={true} />
                                                    </Grid>
                                                    {/* {access.uploadDoc && (
                                                        <Grid item xs={12} lg={5}>
                                                            <PaddedPaper>
                                                                <Typography variant="h6" paragraph>
                                                                    Upload File
                                                                </Typography>
                                                                <DocumentForm
                                                                    id={product.product_id}
                                                                    type="product"
                                                                    category="Products"
                                                                    callback={this.loadComponentData}
                                                                />
                                                            </PaddedPaper>
                                                        </Grid>
                                                    )} */}
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={5}>
                                                <Grid container spacing={3}>
                                                    {(_.isEmpty(product.images) && (
                                                        <Grid item xs={12}>
                                                            <PaddedError>There are no images stored for this product</PaddedError>
                                                        </Grid>
                                                    )) || (
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={3}>
                                                                {product.images.map((image, idx) => {
                                                                    let defaultImage = (product.default_image && product.default_image.img_id === image.img_id && true) || false;
                                                                    let setDefaultImg = defaultImage ? false : access.setDefaultImage ? true : false;
                                                                    return (
                                                                        <Grid item xs={6} lg={3} key={idx}>
                                                                            <Card style={{ position: "relative" }}>
                                                                                {defaultImage && (
                                                                                    <div className={classes.overlay}>
                                                                                        <FAIcon type="light" icon="star" noMargin size={18} style={{ color: "#FFA000" }} />
                                                                                        <Typography variant="body2" component="span" className="fw-400 pl-1">
                                                                                            Default
                                                                                        </Typography>
                                                                                    </div>
                                                                                )}
                                                                                <CardContent style={{ textAlign: "center", paddingTop: 48, paddingBottom: 0 }}>
                                                                                    <ImageWithError
                                                                                        src={`${CLOUDFRONT_URL}${image.img_filekey}`}
                                                                                        style={{ height: 200, filter: image.deleteFlag ? "grayscale(100%)" : "initial" }}
                                                                                        alt={image.img_filename}
                                                                                    />
                                                                                </CardContent>
                                                                                <CardContent style={{ textAlign: "center", paddingTop: 0, paddingBottom: 28, position: "relative" }}>
                                                                                    <Tooltip title="Download">
                                                                                        <IconButton onClick={() => getPresignedUrl(image.img_filekey)}>
                                                                                            <FAIcon type="light" icon="arrow-to-bottom" noMargin fixedHeight button />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    {setDefaultImg && (
                                                                                        <Tooltip title="Set Default Image">
                                                                                            <IconButton onClick={() => this.setDefaultImage(image.img_id)}>
                                                                                                <FAIcon type="light" icon="check" noMargin fixedHeight button />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    )}
                                                                                </CardContent>
                                                                            </Card>
                                                                        </Grid>
                                                                    );
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={6}>
                                                {/* <Grid container spacing={3}>
                                                    {(_.isEmpty(product.reviews) && ( 
                                                        <Grid item xs={12} lg={8}>
                                                            <PaddedError>
                                                                There are no customer reviews for this product
                                                            </PaddedError>               
                                                        </Grid>
                                                    )) || (
                                                        <Grid item xs={12} lg={8}>
                                                            {_.map(product.reviews, (review, rIdx) => (
                                                                <Grid item xs={12} key={rIdx}>
                                                                    <Card>
                                                                        <CardHeader
                                                                            avatar={
                                                                                <Avatar alt={`${review.customer.cust_company_name}`}>
                                                                                    {review.customer.cust_company_name.charAt(0)}
                                                                                </Avatar>
                                                                            }        
                                                                            title={`${review.customer.cust_company_name}`}
                                                                            subheader={<Rating readOnly max={5} value={review.review_rating} size="small" precision={0.5} />}
                                                                        />
                                                                        <CardContent class={classes.cardContent}>
                                                                            <Typography variant="caption" component="p">
                                                                                {review.review_content}
                                                                            </Typography>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={12} lg={4}>
                                                        <PaddedPaper>
                                                            <Typography variant="h6" paragraph>
                                                                Approve Reviews
                                                            </Typography>
                                                            approve customer reviews form placeholder
                                                        </PaddedPaper>
                                                    </Grid>
                                                </Grid> */}
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={7}>
                                                {!_.isEmpty(product.activity) && <ActivityLog activity={product.activity} fullPage />}
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={9}>
                                                <StockDueIn productId={product.product_id} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={11}>
                                                <StockAllocation productId={product.product_id} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={10}>
                                                <ViewActions
                                                    key={key}
                                                    filter="product"
                                                    filterId={product.product_id}
                                                    staff={staff}
                                                    deployDialog={deployDialog}
                                                    closeDialog={closeDialog}
                                                    callback={this.handleCallback}
                                                />
                                            </TabPanel>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.side} style={{ height: contentHeight - VIEW_PAGE_APP_BAR_HEIGHT }}>
                                <Tabs
                                    color="primary"
                                    onChange={this.handleTabChange}
                                    orientation="vertical"
                                    TabIndicatorProps={{
                                        style: {
                                            display: "none",
                                        },
                                    }}
                                    value={currentTab}
                                    variant="scrollable"
                                >
                                    <Tab classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }} value={0} label="Overview" />
                                    <Tab classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }} value={8} label="E-Commerce" disabled={product.product_service === 1} />
                                    <Tab classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }} value={5} label="Images" disabled={_.isEmpty(product.images)} />
                                    <Divider />
                                    <Tab classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }} value={11} label="Allocations" disabled={product.product_service === 1} />
                                    <Tab classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }} value={9} label="Availability" disabled={product.product_service === 1} />
                                    <Tab classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }} value={1} label="Sales History" />
                                    {(access.stock || access.productStock) && (
                                        <Tab classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }} value={2} label="Stock Movement" disabled={product.product_service === 1} />
                                    )}
                                    <Divider />
                                    <Tab classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }} value={10} label="Actions" />
                                    <Tab classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }} disabled={_.isEmpty(product.activity)} value={7} label="Timeline" />
                                    <Tab
                                        classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                        value={4}
                                        label={
                                            <Badge color="primary" className="badge" badgeContent={product.all_files_require_action_count ?? product.files_require_action_count ?? 0} showZero={false}>
                                                Files
                                            </Badge>
                                        }
                                        disabled={_.isEmpty(product.documents)}
                                    />
                                    <Tab classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }} value={3} label="Notes" />
                                    {/* <Tab classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} value={6} label="Reviews" disabled/> */}
                                </Tabs>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    staff: state.notifications.status,
    loggedInStaff: state.staffAuth.staff,
    statePersistence: state.statePersistence,
    ui: state.ui,
});

const mapDispatchToProps = (dispatch) => ({
    deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
    closeDialog: () => dispatch(closeDialog()),
    deployDialog: (content, disableDialogContent = false, title = "", variant = "standard", size = "md", fs = false, de = false) =>
        dispatch(deployDialog(content, disableDialogContent, title, variant, size, fs, de)),
    deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
    setPersistence: (key, state) => dispatch(setPersistence(key, state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(ViewProduct));
