import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

class CopyToClipboardDatagrid extends Component{
    constructor(props) {
        super(props);
        this.state = {
            copyIconColor: '',
            tooltip: false,
            title: 'Copy To Clipboard',
            clicked: false
        }
    }

    componentDidMount = () => {
        if(this.props.disabled) {
            this.setState({
                copyIconColor: 'rgba(0, 0, 0, 0.26)'
            })
        }
    }

    copyToClipboard = text => {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand("copy");  
        document.body.removeChild(textArea);
   
        this.setState({
            copyIconColor: '#0282C6',
            tooltip: true,
            title: 'Copied!',
            clicked: true
        }, 
            () => setTimeout(() => this.hideIconColorChange(), 1500)
        );
    }

    hideIconColorChange = () => {
        this.setState({
            copyIconColor: '',
            tooltip: false,
            clicked: false,
            title: 'Copy To Clipboard'
        })
    }

    mouse = () => {
        if(this.state.clicked === false){
            this.setState({
                copyIconColor: this.props.disabled ? 'rgba(0, 0, 0, 0.26)' : '',
                tooltip: this.props.disabled ? false : true,
                title: 'Copy To Clipboard'
            })
        }
    }

    mouseOut = () => {
        if(this.state.clicked === false){
            this.setState({
                tooltip: false,
            })
        }
    }

    render(){
        const { text, iconSize } = this.props;
        const { tooltip, copyIconColor, title } = this.state;
        return (
            <div style={{display:'inline-block'}} onMouseOver={this.mouse} onMouseOut={this.mouseOut}>
                <Tooltip title={title} placement="bottom" open={tooltip}>
                    <span>
                        <IconButton onClick={() => this.copyToClipboard(text)} disabled={this.props.disabled ?? false}>
                            <FAIcon type="light" icon="copy" size={iconSize ?? 20} style={{color: copyIconColor}}  button noMargin />
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        );
    }
}

export default (CopyToClipboardDatagrid);
