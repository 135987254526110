import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import uuidv4 from "uuid/v4";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import API from "API";
import Alert from "Components/Common/Alert/Alert";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import ImageWithError from "Components/Common/ImageWithError/ImageWithError";
import CommodityCodeInput from "Components/Common/Inputs/CommodityCodeInput";
import DragFileInput from "Components/Common/Inputs/DragFileInput";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import AutoCompleteMultiSelect from "Components/Common/Selects/AutoCompleteMultiSelect";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import Form from "Components/Common/Styles/Form";
import WYSIWYG from "Components/Common/WYSIWYG/WYSIWYG";
import CustomerSearchSelector from "Components/Customers/Misc/CustomerSearchSelector";
import ProductSearchSelector from "Components/Products/Misc/ProductSearchSelector";
import { CLOUDFRONT_URL } from "Constants";
import { getFormData, handleChange, handleCheckedChange, handleMultiSelectChange, handleNumberChange } from "Functions/FormFunctions";
import { handleTabChange, isNumeric, TabPanel } from "Functions/MiscFunctions";
import { getPresignedUrl } from "Functions/S3Functions";
import { formatValidationErrors } from "Helpers/ErrorHelper";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { closeDialog, deployDialog } from "Redux/Actions/Dialog/Dialog";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";

const initialState = () => ({
    cardDb: {
        formats: [],
        programmers: [],
        types: [],
    },
    codeExists: {},
    cooList: [],
    currentTab: 0,
    discountExclusions: {
        category: [],
        supplier: null
    },
    discountOptions: [],
    eCommerceCategoriesList: [],
    eCommerceCategoryFilters: [],
    formData: {
        supplier: '',
        productName: '',
        productCategory: '',
        productDept: '',
        productBrand: '',
        productRanges: '',
        productCodes: [
            { code: '', type: 'Internal', defaultFlag: true },
            { code: '', type: 'Supplier', defaultFlag: false },
        ],
        shortDescription: '',
        fullDescription: '',
        rrp: '',
        supplierDiscount: '',
        discount: 0,
        cost: parseFloat(0).toFixed(2),
        margin: parseFloat(25).toFixed(4),
        price: parseFloat(0).toFixed(2),
        markup: parseFloat(0).toFixed(4),
        openingStockQty: '0',
        minStockLevel: '0',
        maxStockLevel: '0',
        consignmentStockItem: 0,
        stockItem: 0,
        warehouseBin: 0,
        productWeight: '0.00000',
        imgUploads: '',
        images: '',
        defaultImage: 0,
        checkPrice: 0,
        checkWeight: 0,
        checkWeightQtyAllowed: 0,
        minOrderQty: 1,
        orderMultiplesOf: 1,
        packSize: 1,
        excludeWeb: 0,
        noDiscount: 0,
        noDiscountOverride: 0,
        ignDiscEx: 0,
        paxHw: 0,
        productStatus: 'Active',
        replacement: '',
        ecomm: 0,
        valueVault: 0,
        configNew: 0,
        isNew: 0,
        ecommCat: [],
        ecommDefCat: '',
        ecommSupp: '',
        dimL: '',
        dimW: '',
        dimH: '',
        ccImport: '',
        ccExport: '',
        coo: '',
        nc: '',
        cardDb: 0,
        progId: '',
        formatId: '',
        typeId: '',
        prog: 0,
        reqTech: 0,
        service: 0,
        customer: '',
        customerName: '',
        keyFeatures: [],
        filters: {},
    },
    formErrors: [],
    id: false,
    isLoading: true,
    initialFormData: {
        supplier: '',
        productName: '',
        productCategory: '',
        productDept: '',
        productBrand: '',
        productRanges: '',
        productCodes: [
            { code: '', type: 'Internal', defaultFlag: true },
            { code: '', type: 'Supplier', defaultFlag: false },
        ],
        shortDescription: '',
        fullDescription: '',
        rrp: '',
        supplierDiscount: '',
        discount: 0,
        cost: parseFloat(0).toFixed(2),
        margin: parseFloat(25).toFixed(4),
        price: parseFloat(0).toFixed(2),
        markup: parseFloat(0).toFixed(4),
        openingStockQty: '0',
        minStockLevel: '0',
        maxStockLevel: '0',
        consignmentStockItem: 0,
        stockItem: 0,
        warehouseBin: 0,
        productWeight: '0.00000',
        imgUploads: '',
        images: '',
        defaultImage: 0,
        checkPrice: 0,
        checkWeight: 0,
        checkWeightQtyAllowed: 0,
        minOrderQty: 1,
        orderMultiplesOf: 1,
        packSize: 1,
        excludeWeb: 0,
        noDiscount: 0,
        noDiscountOverride: 0,
        ignDiscEx: 0,
        paxHw: 0,
        productStatus: 'Active',
        replacement: '',
        ecomm: 0,
        valueVault: 0,
        configNew: 0,
        isNew: 0,
        ecommCat: [],
        ecommDefCat: '',
        ecommSupp: '',
        dimL: '',
        dimW: '',
        dimH: '',
        ccImport: '',
        ccExport: '',
        coo: '',
        nc: '',
        cardDb: 0,
        progId: '',
        formatId: '',
        typeId: '',
        prog: 0,
        reqTech: 0,
        service: 0,
        customer: '',
        customerName: '',
        keyFeatures: [],
        filters: {},
    },
    key: uuidv4(),
    nominalCodesList: [],
    product: {},
    productDescriptionEditor: EditorState.createEmpty(),
    productCategoriesList: [],
    productDepartmentsList: [],
    productBrandsList: [],
    productRangesList: [],
    suppliersList: [],
    warehouseBinsList: [],
    codeTypesList: [
        { value: 'Internal', label: 'Internal' },
        { value: 'Supplier', label: 'Supplier' },
        { value: 'Previous', label: 'Previous' },
    ],
    allCategories: [],
    allSuppliers: [],
});

class ProductForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.mainContentArea = React.createRef();
        this.poll = false;
        this.getFormData = getFormData.bind(this);
        this.handleChange = handleChange.bind(this);
        this.handleNumberChange = handleNumberChange.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
        this.handleMultiSelectChange = handleMultiSelectChange.bind(this);
        this.handleTabChange = handleTabChange.bind(this);
    }

    componentDidMount() {
        this.loadComponentData();
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({
                ...initialState()
            }, () => {
                this.loadComponentData();
            })
        }
        if (JSON.stringify(this.state.formData) !== JSON.stringify(this.state.initialFormData)) {
            if (!window.onbeforeunload) {
                window.onbeforeunload = () => "";
            }
        } else {
            if (window.onbeforeunload) {
                window.onbeforeunload = undefined;
            }
        }
    };

    componentWillUnmount = () => {
        if (window.onbeforeunload) {
            window.onbeforeunload = undefined;
        }
        if (this.poll) {
            clearInterval(this.poll);
        }
    };

    handlePoll = (initial = false) => {
        API.post(
            `/staff/my/view`,
            {
                product: (this.props.match && this.props.match.params && this.props.match.params.id) || this.props.id,
                type: "Update",
            },
            {
                props: {
                    ignoreErrors: true,
                    noLoading: true,
                },
            }
        ).then((res) => {
            if (res?.data) {
                if (initial) {
                    if (!_.isEmpty(res.data) && _.some(res.data, (el) => el.u === 1)) {
                        this.props.deploySnackBar("error", "Sorry, another user is currently modifying this product");
                        this.props.history.push(`/products/${(this.props.match && this.props.match.params && this.props.match.params.id) || this.props.id}`);
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                    }
                }
            }
        });
    };

    loadComponentData = () => {
        Promise.all([
            API.get("/accounts/nominalCodes/byCategory"),
            API.get("/eCommerce/categories/all", { params: { withFilters: true }}),
            API.get("/products/categories"),
            API.get('/settings/productDiscountExclusions', { params: { forProductForm: true } }),
            API.get("/suppliers/all", {
                params: {
                    withBrands: true,
                    withRanges: true,
                    withCategories: true,
                    withDiscounts: true,
                    active: true,
                },
            }),
            API.get("/warehouse/warehouseBins"),
            API.get("/misc/countries/codes"),
            API.get("/card-orders/formats"),
            API.get("/card-orders/programmers"),
            API.get("/card-orders/types"),
        ]).then(
            ([
                nominalCats,
                eCommerceCategoriesRes,
                productCategoriesRes,
                productDiscountExRes,
                allSuppliersRes,
                warehouseBinsRes,
                countryCodes,
                cardFormats,
                cardProgrammers,
                cardTypes,
            ]) => {
                let eCommerceCategoriesList = [],
                    eCommerceCategoryFilters = [],
                    allCategories = [],
                    discountExclusions = productDiscountExRes?.data?.exclusion ?? this.state.discountExclusions,
                    cooList = [],
                    nominalCodesList = [],
                    suppliersList = [],
                    warehouseBinsList = [],
                    formats = [],
                    programmers = [],
                    types = [],
                    allSuppliers = allSuppliersRes?.data ?? [],
                    id = (this.props.match && this.props.match.params && this.props.match.params.id) || this.props.id,
                    isLoading = id ? true : false;

                nominalCodesList = _.map(nominalCats.data, (el) => {
                    return _.assign({
                        label: el.nom_cat_name,
                        options: _.map(el.nominal_codes, (_el) => {
                            return _.assign({
                                value: _el.nominal_id,
                                label: `${_el.nominal_code} - ${_el.nominal_name}`,
                            });
                        }),
                    });
                });

                formats = _.map(cardFormats.data, (el) => {
                    return _.assign({
                        value: el.format_id,
                        label: el.format_name,
                    });
                });

                programmers = _.map(cardProgrammers.data, (el) => {
                    return _.assign({
                        value: el.programmer_id,
                        label: el.programmer_name,
                    });
                });

                types = _.map(cardTypes.data, (el) => {
                    return _.assign({
                        value: el.type_id,
                        label: el.type_name,
                    });
                });

                cooList = _.map(countryCodes.data, (el) => {
                    return _.assign({
                        value: el.v,
                        label: el.l,
                    });
                });

                eCommerceCategoriesList = _.map(eCommerceCategoriesRes.data, (el) => {
                    return _.assign({
                        label: el.category_name,
                        options: _.map(el.children, (_el) => {
                            return _.assign({
                                value: _el.category_id,
                                label: _el.category_name
                            });
                        }),
                    });
                });

                _.each(eCommerceCategoriesRes.data, (el) => {
                    _.each(el.children, (_el) => {
                        eCommerceCategoryFilters.push({
                            id: _el.category_id,
                            filters: _el.filters ? _.sortBy(_el.filters, el => el.ecf_name) : []
                        });
                    });
                });

                allCategories = _.map(productCategoriesRes.data, (el) => {
                    return _.assign({
                        label: el.category_name,
                        value: el.category_id,
                        options: _.map(el.children, (_el) => {
                            return _.assign({
                                value: _el.category_id,
                                label: _el.category_name,
                            });
                        }),
                    });
                });

                suppliersList = _.map(allSuppliersRes.data, (el) => {
                    return _.assign({
                        value: el.i,
                        label: el.an,
                    });
                });

                suppliersList.unshift({
                    value: -1,
                    label: "Advanced Access Internal",
                });

                warehouseBinsList = _.map(warehouseBinsRes.data, (el) => {
                    return _.assign({
                        label: `${el.wh_bin_location} - ${el.wh_bin_size} (${el.products_count} products already use this bin)`,
                        value: el.wh_bin_id,
                    });
                });

                this.setState(
                    {
                        allCategories,
                        allSuppliers,
                        cardDb: {
                            formats,
                            programmers,
                            types,
                        },
                        cooList,
                        discountExclusions,
                        eCommerceCategoryFilters,
                        eCommerceCategoriesList,
                        id,
                        isLoading,
                        nominalCodesList,
                        suppliersList,
                        warehouseBinsList,
                    },
                    () => {
                        if (id) {
                            this.getUpdateData(id);
                        } else {
                            this.props.pageTitle([1, "Products", "New Product"]);
                        }
                    }
                );
            }
        );
    };

    getUpdateData = (id) => {
        API.get(`/products/${id}`).then((result) => {
            if (result?.data) {
                const product = result?.data,
                    productCodes = _.map(product.codes, (code) => _.assign({ code: code.code_value, type: code.code_type, defaultFlag: code.code_default === 1 ? true : false })),
                    keyFeatures = _.map(product.key_features, (kf) => _.assign({ kf })),
                    productRanges = product.product_range_id > 0 ? product.product_range_id : '',
                    supplier = this.state.allSuppliers.find((s) => s.i === product.product_supplier_id),
                    images = [],
                    description = htmlToDraft(product.product_full_description),
                    descriptionState = ContentState.createFromBlockArray(description.contentBlocks);

                let defaultImage = 0,
                    supplierDiscount = product.product_supp_disc_id,
                    productBrandsList = [],
                    productCategoriesList = [],
                    productRangesList = [],
                    discountOptions = [],
                    ecommCat = [];

                if (!_.isEmpty(product?.ecommerce_categories)) {
                    ecommCat = _.map(product.ecommerce_categories, (el) => el.category_id);
                }

                if (!_.isEmpty(product.images)) {
                    product.images.forEach((image) => {
                        if (product.default_image && product.default_image.img_id === image.img_id) {
                            defaultImage = image.img_id;
                        }
                        images.push({
                            ...image,
                            deleteFlag: false,
                        });
                    });
                }

                if (!_.isEmpty(supplier?.brands)) {
                    productBrandsList = _.map(supplier.brands, (el) =>
                        _.assign({
                            value: el.brand_id,
                            label: el.brand_name,
                        })
                    );
                }

                if(!_.isEmpty(supplier?.cat)) {
                    _.forEach(_.filter(this.state.allCategories, _c => supplier.cat.includes(_c.value)), c => {
                        let o = _.filter(c.options, _o => supplier.cat.includes(_o.value));
                        if (o.length > 0) {
                            productCategoriesList.push({
                                label: c.label,
                                options: o,
                            })
                        }
                    })
                }

                if (!_.isEmpty(supplier?.ranges)) {
                    productRangesList = _.map(supplier.ranges, (el) =>
                        _.assign({
                            value: el.range_id,
                            label: el.range_name,
                        })
                    );
                }

                if (!_.isEmpty(supplier?.discounts)) {
                    discountOptions = _.map(supplier.discounts, (el) =>
                        _.assign({
                            value: el.supp_disc_id,
                            label: `${el.supp_disc_name} - ${el.supp_disc_percentage}%`,
                        })
                    );
                }

                if (product.product_supp_disc_id === 0 && product.default_rate?.rate_discount !== 0) {
                    discountOptions.push({
                        label: `${parseFloat(product.default_rate.rate_discount).toFixed(2)}%`,
                        value: product.default_rate.rate_discount,
                    });
                    supplierDiscount = product.default_rate.rate_discount;
                }

                let filters = {}
                _.each(product.filters, _f => {
                    filters = {
                        ...filters,
                        [_f.id]: _f.optId ?? ''
                    }
                })

                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            supplier: product.product_supplier_id,
                            productName: product.product_name,
                            productRanges,
                            productCodes,
                            productBrand: product.product_brand_id > 0 ? product.product_brand_id : '',
                            productCategory: product.product_cat_id > 0 ? product.product_cat_id : '',
                            productDept: product.product_dept_id,
                            shortDescription: product.product_description,
                            fullDescription: product.product_full_description,
                            rrp: (product.rates && product.default_rate && product.default_rate.rate_rrp) || 0,
                            supplierDiscount,
                            discount: (product.rates && product.default_rate && product.default_rate.rate_discount) || 0,
                            cost: (product.rates && product.default_rate && product.default_rate.rate_cost) || 0,
                            margin: (product.rates && product.default_rate && product.default_rate.rate_margin) || 0,
                            price: (product.rates && product.default_rate && product.default_rate.rate_price) || 0,
                            markup: (product.rates && product.default_rate && product.default_rate.rate_markup) || 0,
                            openingStockQty: "0",
                            minStockLevel: product.product_min_stock_level,
                            maxStockLevel: product.product_max_stock_level,
                            consignmentStockItem: product.product_consignment_stock,
                            stockItem: product.product_stock_item,
                            warehouseBin: product.product_wh_bin_id,
                            productWeight: product.product_weight,
                            checkPrice: product.product_check_price,
                            checkWeight: product.product_check_weight,
                            checkWeightQtyAllowed: product.product_check_weight_qty_allowed,
                            minOrderQty: product.product_min_order_qty,
                            orderMultiplesOf: product.product_order_multiples_of,
                            packSize: product.product_pack_size,
                            excludeWeb: product.product_exclude_web,
                            noDiscount: product.product_no_discount,
                            noDiscountOverride: product.product_no_discount_override,
                            ignDiscEx: product.product_ign_disc_ex,
                            paxHw: product.product_pax_hw,
                            productStatus: product.product_status,
                            replacement: product.product_rep_product_id,
                            ecomm: product.product_ecommerce,
                            valueVault: product.product_value_vault,
                            configNew: product.product_new !== null ? 1 : 0,
                            isNew: product.product_new !== null ? product.product_new : 0,
                            ecommCat,
                            ecommDefCat: product.product_e_cat_id,
                            ecommSupp: product.product_e_supplier_id,
                            dimL: product.product_length === 0 ? '' : product.product_length,
                            dimW: product.product_width === 0 ? '' : product.product_width,
                            dimH: product.product_height === 0 ? '' : product.product_height,
                            ccImport: product.product_cc_import,
                            ccExport: product.product_cc_export,
                            coo: product.product_cc_country_id,
                            nc: product.product_nominal_code_id,
                            cardDb: product.product_carddb,
                            progId: product.product_prog_id,
                            formatId: product.product_format_id,
                            typeId: product.product_type_id,
                            prog: product.product_programmable,
                            reqTech: product.product_req_tech,
                            service: product.product_service,
                            customer: product.customer?.cust_id ?? "",
                            customerName: product.customer?.cust_company_name ?? "",
                            keyFeatures,
                            imgUploads: "",
                            images,
                            defaultImage,
                            filters
                        },
                        initialFormData: {
                            ...this.state.formData,
                            supplier: product.product_supplier_id,
                            productName: product.product_name,
                            productRanges,
                            productCodes,
                            productBrand: product.product_brand_id > 0 ? product.product_brand_id : '',
                            productCategory: product.product_cat_id > 0 ? product.product_cat_id : '',
                            productDept: product.product_dept_id,
                            shortDescription: product.product_description,
                            fullDescription: product.product_full_description,
                            rrp: (product.rates && product.default_rate && product.default_rate.rate_rrp) || 0,
                            supplierDiscount,
                            discount: (product.rates && product.default_rate && product.default_rate.rate_discount) || 0,
                            cost: (product.rates && product.default_rate && product.default_rate.rate_cost) || 0,
                            margin: (product.rates && product.default_rate && product.default_rate.rate_margin) || 0,
                            price: (product.rates && product.default_rate && product.default_rate.rate_price) || 0,
                            markup: (product.rates && product.default_rate && product.default_rate.rate_markup) || 0,
                            openingStockQty: "0",
                            minStockLevel: product.product_min_stock_level,
                            maxStockLevel: product.product_max_stock_level,
                            consignmentStockItem: product.product_consignment_stock,
                            stockItem: product.product_stock_item,
                            warehouseBin: product.product_wh_bin_id,
                            productWeight: product.product_weight,
                            checkPrice: product.product_check_price,
                            checkWeight: product.product_check_weight,
                            checkWeightQtyAllowed: product.product_check_weight_qty_allowed,
                            minOrderQty: product.product_min_order_qty,
                            orderMultiplesOf: product.product_order_multiples_of,
                            packSize: product.product_pack_size,
                            excludeWeb: product.product_exclude_web,
                            noDiscount: product.product_no_discount,
                            noDiscountOverride: product.product_no_discount_override,
                            ignDiscEx: product.product_ign_disc_ex,
                            paxHw: product.product_pax_hw,
                            productStatus: product.product_status,
                            replacement: product.product_rep_product_id,
                            ecomm: product.product_ecommerce,
                            valueVault: product.product_value_vault,
                            configNew: product.product_new !== null ? 1 : 0,
                            isNew: product.product_new !== null ? product.product_new : 0,
                            ecommCat,
                            ecommDefCat: product.product_e_cat_id,
                            ecommSupp: product.product_e_supplier_id,
                            dimL: product.product_length === 0 ? '' : product.product_length,
                            dimW: product.product_width === 0 ? '' : product.product_width,
                            dimH: product.product_height === 0 ? '' : product.product_height,
                            ccImport: product.product_cc_import,
                            ccExport: product.product_cc_export,
                            coo: product.product_cc_country_id,
                            nc: product.product_nominal_code_id,
                            cardDb: product.product_carddb,
                            progId: product.product_prog_id,
                            formatId: product.product_format_id,
                            typeId: product.product_type_id,
                            prog: product.product_programmable,
                            reqTech: product.product_req_tech,
                            service: product.product_service,
                            customer: product.customer?.cust_id ?? "",
                            customerName: product.customer?.cust_company_name ?? "",
                            keyFeatures,
                            imgUploads: "",
                            images,
                            defaultImage,
                            filters
                        },
                        product,
                        productDescriptionEditor: EditorState.createWithContent(descriptionState),
                        productBrandsList,
                        productCategoriesList,
                        productRangesList,
                        discountOptions,
                    },
                    () => {
                        this.handlePoll(true);

                        let code = _.find(product.codes, (el) => el.code_default === 1);
                        if (code) {
                            code = `${code.code_value} - ${product.product_name}`;
                        } else {
                            code = product.product_name;
                        }

                        this.props.pageTitle([1, "Products", code, "Update"]);
                        this.poll = setInterval(this.handlePoll, 3000);
                    }
                );
            }
        });
    };

    handleCustomerDialog = () => {
        this.props.deployDialog(
            <Box pt={2}>
                <CustomerSearchSelector label={"Select Customer *"} noFrequent noPaper handleAction={this.handleCustomerChange} />
                <div className="buttonRow">
                    <Button color="default" variant="text" onClick={this.props.closeDialog}>
                        <FAIcon icon="times" button size={15} />
                        Cancel
                    </Button>
                    {!_.isEmpty(this.state.formData.customerName) && (
                        <Button color="default" variant="text" onClick={() => this.handleCustomerChange("")}>
                            <FAIcon icon="exclamation-triangle" button size={15} />
                            Unassign Product
                        </Button>
                    )}
                </div>
            </Box>,
            false,
            "Customer Part",
            "standard",
            "xs"
        );
    };

    handleCustomerChange = (customer, customerName) => {
        this.props.closeDialog();
        this.setState({
            formData: {
                ...this.state.formData,
                customer,
                customerName,
            },
        });
    };

    handleCommodityChange = (fieldName, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: value,
            },
        });
    };

    handleDescriptionChange = (productDescriptionEditor) => {
        this.setState({
            ...this.state,
            productDescriptionEditor,
            formData: {
                ...this.state.formData,
                fullDescription: draftToHtml(convertToRaw(productDescriptionEditor.getCurrentContent())),
            },
        });
    };

    handleFilterChange = (id, value) => {   
        this.setState({
            formData: {
                ...this.state.formData,
                filters: {
                    ...this.state.formData.filters,
                    [id]: value
                }
            }
        })
    }

    handleFieldFormat = (e) => {
        let { rrp, discount, cost, margin, price, markup, productWeight, dimL, dimH, dimW, minStockLevel, maxStockLevel, supplierDiscount} = this.state.formData;
        let { discountOptions } = this.state;
        switch ((e && e.target && e.target.name) || e) {
            case "rrp":
                rrp = !rrp || isNaN(rrp) ? "0.00" : parseFloat(rrp).toFixed(2);
                this.setState(
                    {
                        ...this.state,
                        formData: {
                            ...this.state.formData,
                            rrp,
                            cost: (rrp - (rrp / 100) * discount).toFixed(2),
                        },
                    },
                    () => {
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                price: ((100 / (100 - margin)) * this.state.formData.cost).toFixed(2),
                            },
                        });
                    }
                );
                break;
            case "cost":
                cost = !cost || isNaN(cost) ? "0.00" : parseFloat(cost).toFixed(2);
                price = ((100 / (100 - margin)) * cost).toFixed(2);
                let newDiscount =  rrp !== cost ?  ((rrp - cost) / rrp * 100).toFixed(2) : 0;
                if(newDiscount !== 0) {
                    discount = newDiscount;
                    let newIdx = discountOptions.findIndex(el => parseFloat(el.value) === parseFloat(newDiscount));
                    if(newIdx === -1) {
                        discountOptions.push({
                        label: `${parseFloat(newDiscount).toFixed(2)}%`,
                            value: newDiscount,
                        });
                        supplierDiscount = newDiscount;
                    } else {
                        supplierDiscount = newDiscount;
                    }
                } else {
                    discount = 0;
                    supplierDiscount = '';
                }
                this.setState({
                    ...this.state,
                    formData: {
                        ...this.state.formData,
                        cost,
                        price,
                        discount,
                        supplierDiscount,
                        margin: price > 0.0 ? (((price - cost) / price) * 100).toFixed(4) : parseFloat(0).toFixed(4),
                        markup: price > 0.0 ? (((price - cost) / cost) * 100).toFixed(4) : parseFloat(0).toFixed(4),
                    },
                    discountOptions
                });
                break;
            case "discount":
                discount = !discount || isNaN(discount) ? "0.00" : parseFloat(discount).toFixed(2);
                this.setState(
                    {
                        ...this.state,
                        formData: {
                            ...this.state.formData,
                            discount,
                            cost: (rrp - (rrp / 100) * discount).toFixed(2),
                        },
                    },
                    () => {
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                price: ((100 / (100 - margin)) * this.state.formData.cost).toFixed(2),
                            },
                        });
                    }
                );
                break;
            case "margin":
                margin = !margin || isNaN(margin) ? "0.00" : parseFloat(margin).toFixed(4);
                this.setState({
                    ...this.state,
                    formData: {
                        ...this.state.formData,
                        margin,
                        price: ((100 / (100 - margin)) * cost).toFixed(2),
                        markup: ((margin / 100 / (1 - margin / 100)) * 100).toFixed(4),
                    },
                });
                break;
            case "price":
                price = (!price || isNaN(price) ? "0.00" : parseFloat(price)).toFixed(2);
                this.setState({
                    ...this.state,
                    formData: {
                        ...this.state.formData,
                        price,
                        margin: price > 0.0 ? (((price - cost) / price) * 100).toFixed(4) : parseFloat(0).toFixed(4),
                        markup: price > 0.0 ? (((price - cost) / cost) * 100).toFixed(4) : parseFloat(0).toFixed(4),
                    },
                });
                break;
            case "markup":
                markup = !markup || isNaN(markup) ? "0.00" : parseFloat(markup).toFixed(4);
                this.setState({
                    ...this.state,
                    formData: {
                        ...this.state.formData,
                        markup,
                        price: ((100 / (100 - (markup / 100 / (1 + markup / 100)) * 100)) * cost).toFixed(2),
                        margin: ((markup / 100 / (1 + markup / 100)) * 100).toFixed(4),
                    },
                });
                break;
            case "dimL":
                this.setState({
                    ...this.state,
                    formData: {
                        ...this.state.formData,
                        dimL: !dimL || isNaN(dimL) ? "0.00" : parseFloat(dimL).toFixed(2),
                    },
                });
                break;
            case "dimW":
                this.setState({
                    ...this.state,
                    formData: {
                        ...this.state.formData,
                        dimW: !dimW || isNaN(dimW) ? "0.00" : parseFloat(dimW).toFixed(2),
                    },
                });
                break;
            case "dimH":
                this.setState({
                    ...this.state,
                    formData: {
                        ...this.state.formData,
                        dimH: !dimH || isNaN(dimH) ? "0.00" : parseFloat(dimH).toFixed(2),
                    },
                });
                break;
            case "productWeight":
                this.setState({
                    ...this.state,
                    formData: {
                        ...this.state.formData,
                        productWeight: !productWeight || isNaN(productWeight) ? "0.00" : parseFloat(productWeight).toFixed(5),
                    },
                });
                break;
            case "minStockLevel":
                this.setState({
                    ...this.state,
                    formData: {
                        ...this.state.formData,
                        minStockLevel: !minStockLevel || isNaN(minStockLevel) ? "0" : parseInt(minStockLevel),
                    },
                });
                break;
            case "maxStockLevel":
                this.setState({
                    ...this.state,
                    formData: {
                        ...this.state.formData,
                        maxStockLevel: !maxStockLevel || isNaN(maxStockLevel) ? "0" : parseInt(maxStockLevel),
                    },
                });
                break;
            default:
        }
    };

    handleImageDefault = (defaultImage) => {
        this.setState({
            formData: {
                ...this.state.formData,
                defaultImage,
            },
        });
    };

    handleImageDelete = (idx) => {
        let images = [...this.state.formData.images];
        images[idx].deleteFlag = !images[idx].deleteFlag;
        this.setState({
            formData: {
                ...this.state.formData,
                images,
            },
        });
    };

    handleImageUploads = (drop, name, files) => {
        let imgUploads = Array.prototype.slice.call(this.state.formData?.imgUploads ?? []).concat(Array.prototype.slice.call(files)),
            key = uuidv4();

        this.setState({
            formData: {
                ...this.state.formData,
                imgUploads,
                key,
            },
        });
    };

    handleImageUploadsDelete = (idx, fn) => {
        let imgUploads = Array.from(this.state.formData?.imgUploads ?? []),
            key = uuidv4();

        imgUploads.splice(idx, 1);

        let { formErrors } = this.state;

        if (formErrors?.[`imageFormat|${fn}`]) {
            delete formErrors?.[`imageFormat|${fn}`];
        }

        this.setState({
            formData: {
                ...this.state.formData,
                key,
                imgUploads,
            },
            formErrors,
        });
    };

    handleProductCodeAdd = () => {
        let productCodes = this.state.formData.productCodes;
        productCodes.push({ code: "", type: "Internal" });
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                productCodes,
            },
        });
    };

    handleProductCodeChange = (idx, e) => {
        let productCodes = this.state.formData.productCodes;
        productCodes[idx] = {
            ...productCodes[idx],
            code: e.target.value,
        };
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                productCodes,
            },
        });
    };

    handleProductCodeCheck = (idx) => {
        API.get("/smartSearch/products", {
            props: {
                cancellation: true,
                noLoading: true,
            },
            params: {
                codeOnly: true,
                exclude: this.state.id,
                searchString: this.state.formData.productCodes?.[idx]?.code,
            },
        }).then((res) => {
            if (res?.data) {

                let codeExists = {...this.state.codeExists};

                if (res.data.length > 0) {
                    codeExists[idx] = res.data;
                } else {
                    delete codeExists[idx];
                }

                this.setState({
                    codeExists,
                });
            }
        });
    };

    handleProductCodeDefault = (idx) => {
        let productCodes = [...this.state.formData.productCodes];
        _.each(productCodes, (code, idx) => {
            productCodes[idx].defaultFlag = false;
        });
        productCodes[idx].defaultFlag = true;
        this.setState({
            formData: {
                ...this.state.formData,
                productCodes,
            },
        });
    };

    handleProductCodeTypeChange = (idx, selectedOption) => {
        let productCodes = this.state.formData.productCodes;
        productCodes[idx] = {
            ...productCodes[idx],
            type: selectedOption && selectedOption.value,
        };
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                productCodes,
            },
        });
    };

    handleProductCodeRemove = (idx) => {

        let productCodes = this.state.formData.productCodes;
        productCodes.splice(idx, 1);
        
        let codeExists = {};

        this.setState({
            codeExists,
            formData: {
                ...this.state.formData,
                productCodes,
            },
        }, () => {
            _.each(productCodes, (code, idx) => {
                this.handleProductCodeCheck(idx);
            })
        });
    };

    handleKeyFeatureAdd = () => {
        let keyFeatures = this.state.formData.keyFeatures;
        keyFeatures.push({ kf: "" });
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                keyFeatures,
            },
        });
    };

    handleKeyFeatureChange = (idx, e) => {
        let keyFeatures = this.state.formData.keyFeatures;
        keyFeatures[idx] = {
            ...keyFeatures[idx],
            kf: e.target.value,
        };
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                keyFeatures,
            },
        });
    };

    handleKeyFeatureRemove = (idx) => {
        let keyFeatures = this.state.formData.keyFeatures;
        keyFeatures.splice(idx, 1);
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                keyFeatures,
            },
        });
    };

    handleNoSalesDiscountToggle = e => {
        const {
            target: {
                checked
            }
        } = e;
        this.setState({
            formData: {
                ...this.state.formData,
                ignDiscEx: checked ? 1 : 0,
                noDiscount: checked ? 1 : 0,
                noDiscountOverride: !checked ? 0 : this.state.formData.noDiscountOverride
            },
        }, () => {
            if(!checked) {
                this.handleDiscountExclusionCheck()
            }
        });
    }

    handleAutomatedDiscountExclusionToggle = e => {
        const {
            target: {
                checked
            }
        } = e;
        this.setState({
            formData: {
                ...this.state.formData,
                ignDiscEx: checked ? 1 : 0,
                noDiscount: checked ? 0 : this.state.formData.noDiscount,
                noDiscountOverride: checked ? 0 : this.state.formData.noDiscountOverride
            },
        }, () => {
            if(!checked) {
                this.handleDiscountExclusionCheck()
            }
        });
    }

    handleDiscountExclusionCheck = () => {
        
        const {
            allCategories,
            discountExclusions,
            formData: {
                productBrand,
                productCategory,
                productRanges,
                supplier
            }
        } = this.state;

        let {
            formData: {
                noDiscount,
                noDiscountOverride,
            }
        } = this.state;

        let excluded = false;

        let b = productBrand,
            c = productCategory,
            r = productRanges,
            s = supplier;

        if(c !== '' && !_.isEmpty(discountExclusions.category)) {
            if(discountExclusions?.category?.includes(c)) {
                excluded = true;
            }
            if(!excluded) {
                const p = _.find(allCategories, el => _.find(el.options, o => o.value === c));
                if(p) {
                    if(discountExclusions?.category?.includes(p.value)) {
                        excluded = true;
                    }
                }
            }
        }

        if(!excluded) {

            if(s !== '' && discountExclusions?.supplier?.[s]) {

                excluded = discountExclusions.supplier[s].some(el => {
                    
                    // Supplier exclusion check
                    if(_.isEmpty(el.brands) && _.isEmpty(el.categories) && _.isEmpty(el.ranges)) {
                        return true;
                    }

                    // If no values for brand, category or range, then no exclusion right now
                    if(c === '' && b === '' && r === '') {
                        return false;
                    }
                    
                    // Category exclusion check
                    if(!_.isEmpty(el.categories) && _.isEmpty(el.brands) && _.isEmpty(el.ranges)) {
                        if(c === '') {
                            return false;
                        }
                        if(el.categories.includes(c)) {
                            return true;
                        }
                        const p = _.find(allCategories, el => _.find(el.options, o => o.value === c));
                        if(p) {
                            if(el.categories.includes(p.value)) {
                                return true;
                            }
                        }
                        return false;
                    }
                    
                    // Brand exclusion check
                    if(!_.isEmpty(el.brands) && _.isEmpty(el.categories) && _.isEmpty(el.ranges)) {
                        if(b === '') {
                            return false;
                        }
                        if(el.brands.includes(b)) {
                            return true;
                        }
                        return false;
                    }
                    
                    // Range exclusion check
                    if(!_.isEmpty(el.ranges) && _.isEmpty(el.categories) && _.isEmpty(el.brands)) {
                        if(r === '') {
                            return false;
                        }
                        if(el.ranges.includes(r)) {
                            return true;
                        }
                        return false;
                    }

                    // Combination exclusion check
                    let isExcluded = true;

                    if(!_.isEmpty(el.categories)) {
                        if(c === '') {
                            isExcluded = false;
                        } else {
                            const p = _.find(allCategories, el => _.find(el.options, o => o.value === c));
                            if(!el.categories.includes(c) && (!p || !el.categories.includes(p.value))) {
                                isExcluded = false;
                            }
                        }
                    }

                    if(isExcluded) {
                        if(!_.isEmpty(el.brands)) {
                            if(b === '') {
                                isExcluded = false;
                            } else {
                                if(!el.brands.includes(b)) {
                                    isExcluded = false;
                                }
                            }
                        }
                    }

                    if(isExcluded) {
                        if(!_.isEmpty(el.ranges)) {
                            if(r === '') {
                                isExcluded = false;
                            } else {
                                if(!el.ranges.includes(r)) {
                                    isExcluded = false;
                                }
                            }
                        }
                    }

                    return isExcluded;

                });
            }
        }

        if(excluded) {
            noDiscount = 1;
            noDiscountOverride = 1;
        }

        this.setState({
            formData: {
                ...this.state.formData,
                noDiscount,
                noDiscountOverride,
            }
        });

    }

    handleSelectChange = (fieldName) => (selectedOption) => {

        const val = selectedOption && selectedOption.value;
        const service = fieldName === "supplier" ? 0 : this.state.formData.service;
     
        const checkDiscountExclusions = this.state.formData.ignDiscEx === 0 && 
            ['supplier', 'productCategory', 'productBrand', 'productRanges'].includes(fieldName);

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [fieldName]: val,
                    service,
                },
            },
            () => {

                if (fieldName === "supplier") {

                    let productRangesList = [],
                        productBrandsList = [],
                        productCategoriesList = [],
                        discountOptions = [];

                    if (val > 0) {
                        const supplier = this.state.allSuppliers.find((s) => s.i === val);
                        productBrandsList = _.map(supplier.brands, (el) => {
                            return _.assign({
                                value: el.brand_id,
                                label: el.brand_name,
                            });
                        });
                        productRangesList = _.map(supplier.ranges, (el) => {
                            return _.assign({
                                value: el.range_id,
                                label: el.range_name,
                            });
                        });
                        discountOptions = _.map(supplier.discounts, (el) => {
                            return _.assign({
                                value: el.supp_disc_id,
                                label: `${el.supp_disc_name} - ${el.supp_disc_percentage}%`,
                            });
                        });
                        _.forEach(_.filter(this.state.allCategories, _c => supplier.cat.includes(_c.value)), c => {
                            let o = _.filter(c.options, _o => supplier.cat.includes(_o.value));
                            if (o.length > 0) {
                                productCategoriesList.push({
                                    label: c.label,
                                    options: o,
                                })
                            }
                        })
                    }

                    this.setState({ 
                        productRangesList, 
                        productBrandsList, 
                        productCategoriesList,
                        discountOptions, 
                        formData: {
                            ...this.state.formData,
                            productBrand: '', 
                            productRanges: '',
                            productCategory: '',
                            discount: 0,
                            supplierDiscount: '',
                        } 
                    }, () => {
                        if(checkDiscountExclusions) {
                            this.handleDiscountExclusionCheck();
                        }
                    });

                } else if (fieldName === "supplierDiscount") {

                    const suppId = this.state.formData.supplier;
                    const supplier = this.state.allSuppliers.find((s) => s.i === suppId);
                    let discount = !_.isEmpty(supplier?.discounts) ? supplier.discounts.find((s) => s.supp_disc_id === val) : null;
                    let supplierDiscount = discount ? discount.supp_disc_id : "";
                    
                    if (!discount) {
                        if (!_.isEmpty(val)) {
                            if (isNumeric(val)) {
                                discount = parseFloat(val).toFixed(6);
                                supplierDiscount = discount;
                            } else {
                                this.props.deploySnackBar("error", "Please enter a valid numeric value");
                            }
                        } else {
                            discount = 0;
                            supplierDiscount = "";
                        }
                    } else {
                        discount = discount?.supp_disc_percentage ?? 0;
                    }

                    this.setState(
                        {
                            formData: {
                                ...this.state.formData,
                                discount,
                                supplierDiscount,
                            },
                        },
                        () => this.handleFieldFormat("discount")
                    );
                    
                } else {
                    if(checkDiscountExclusions) {
                        this.handleDiscountExclusionCheck();
                    }
                }
            }
        );
    };

    handleStockedItemChange = (e) => {
        let stockItem = this.state.formData.stockItem === 1 ? 0 : 1;
        this.setState({
            formData: {
                ...this.state.formData,
                stockItem,
                consignmentStockItem: !stockItem ? 0 : this.state.formData.consignmentStockItem,
                warehouseBin: !stockItem ? "" : this.state.formData.warehouseBin,
            },
        });
    };

    handleSubmit = () => {
        const id = (this.props.match && this.props.match.params && this.props.match.params.id) || this.props.id,
            route = id ? `/products/${id}` : `/products`;

        let discount = this.state.formData.discount,
            supplierDiscount = this.state.formData.supplierDiscount;

        if (discount === supplierDiscount) {
            supplierDiscount = 0;
        }

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    supplierDiscount,
                },
                isLoading: true,
            },
            () => {
                API.post(route, this.getFormData()).then((result) => {
                    if (result.data?.errors && result.data?.errors?.length > 0) {
                        this.setState({
                            formErrors: formatValidationErrors(result.data.errors),
                            isLoading: false,
                        });
                    } else {
                        this.props.deploySnackBar(`success`, `You have successfully ${id ? "updated the" : "created a new"} product`);
                        this.props.history.push(`/products/${id ? id : result.data.id}`);
                    }
                });
            }
        );
    };

    render() {
        const { classes } = this.props;
        const {
            cardDb,
            codeExists,
            cooList,
            currentTab,
            discountOptions,
            eCommerceCategoryFilters,
            eCommerceCategoriesList,
            formData,
            formErrors,
            id,
            isLoading,
            key,
            nominalCodesList,
            product,
            productDescriptionEditor,
            productBrandsList,
            productCategoriesList,
            productRangesList,
            suppliersList,
            warehouseBinsList,
            codeTypesList,
        } = this.state;

        return (
            <Grid container className={classes.container}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Prompt 
                            when={JSON.stringify(formData) !== JSON.stringify(this.state.initialFormData)} 
                            message="You have unsaved changes, are you sure you want to leave?" 
                        />
                        <Grid item ref={this.mainContentArea} className={classes.main}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TabPanel value={currentTab} index={0}>
                                        <Grid container item spacing={3}>
                                            <Grid item xs={12} lg={6}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Box pb={0.4}>
                                                                <Grid container spacing={1} alignItems="center">
                                                                    <Grid item xs>
                                                                        <Typography variant="h6" gutterBottom>
                                                                            Product Codes
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Button onClick={this.handleProductCodeAdd} variant="text" color="primary">
                                                                            <FAIcon type="light" icon="plus-circle" button size={15} />
                                                                            Additional Code
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                            <form noValidate autoComplete="off">
                                                                {formData.productCodes.map((productCode, idx) => {
                                                                    return (
                                                                        <Grid container spacing={1} alignItems="center" key={`code-${idx}`}>
                                                                            <Grid item xs={6}>
                                                                                <TextField
                                                                                    id={`productCode${idx}`}
                                                                                    name={`productCode${idx}`}
                                                                                    label="Product Code *"
                                                                                    InputProps={{
                                                                                        startAdornment: productCode.defaultFlag && (
                                                                                            <InputAdornment position="end">
                                                                                                <FAIcon type="light" icon="star" style={{ color: "#FFA000" }} />
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                    inputProps={{
                                                                                        maxLength: 60,
                                                                                    }}
                                                                                    value={productCode.code}
                                                                                    className={classes.textField}
                                                                                    error={formErrors?.["productCode|" + idx] ? true : false}
                                                                                    helperText={formErrors?.["productCode|" + idx]}
                                                                                    onChange={(e) => {
                                                                                        this.handleProductCodeChange(idx, e);
                                                                                    }}
                                                                                    onBlur={() => this.handleProductCodeCheck(idx)}
                                                                                    margin="none"
                                                                                    fullWidth
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <AutoCompleteSelect
                                                                                    options={codeTypesList}
                                                                                    label="Type *"
                                                                                    value={productCode.type}
                                                                                    disabled={productCode.defaultFlag}
                                                                                    onChange={(e) => {
                                                                                        this.handleProductCodeTypeChange(idx, e);
                                                                                    }}
                                                                                    noClear
                                                                                    margin="none"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={2}>
                                                                                {!productCode.defaultFlag && (
                                                                                    <>
                                                                                        <Tooltip title="Set as default">
                                                                                            <IconButton onClick={() => this.handleProductCodeDefault(idx)}>
                                                                                                <FAIcon type="light" button noMargin icon="check" size={15} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                        <Tooltip title="Remove product code">
                                                                                            <IconButton onClick={() => this.handleProductCodeRemove(idx)}>
                                                                                                <FAIcon type="light" button noMargin icon="trash-alt" size={15} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </>
                                                                                )}
                                                                            </Grid>
                                                                            {!_.isEmpty(codeExists?.[idx]) && (
                                                                                <>
                                                                                    <Grid item xs={12}>
                                                                                        <Box mb={2}>
                                                                                            <Alert severity="error">
                                                                                                <Typography variant="body2" className="fw-400">
                                                                                                    Duplicate Product Warning
                                                                                                </Typography>
                                                                                                <Typography variant="caption" component="div" gutterBottom>
                                                                                                    The following products match this product code -
                                                                                                </Typography>      
                                                                                                {_.map(codeExists[idx], (prd, pidx) => (
                                                                                                    <Typography variant="caption" component="div" key={pidx}>
                                                                                                        <Tooltip title="View Product (In New Tab)">
                                                                                                            <Link to={`/products/${prd.product_id}`} className="textDefault" target="_blank">
                                                                                                                &bull; {prd.product_name}
                                                                                                            </Link>
                                                                                                        </Tooltip>
                                                                                                    </Typography>
                                                                                                ))}
                                                                                            </Alert>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </>
                                                                            )}
                                                                        </Grid>
                                                                    );
                                                                })}
                                                            </form>
                                                        </PaddedPaper>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant="h6" paragraph>
                                                                Product Details
                                                            </Typography>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        id="productName"
                                                                        name="productName"
                                                                        label="Product Description *"
                                                                        value={this.state.formData.productName}
                                                                        className={classes.textField}
                                                                        error={formErrors && formErrors["productName"] && true}
                                                                        helperText={formErrors && formErrors["productName"]}
                                                                        onChange={this.handleChange}
                                                                        margin="none"
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}>
                                                                    <AutoCompleteSelect
                                                                        options={suppliersList}
                                                                        label="Supplier *"
                                                                        value={formData.supplier}
                                                                        onChange={this.handleSelectChange("supplier")}
                                                                        error={formErrors && formErrors["supplier"] && true}
                                                                        errorText={formErrors && formErrors["supplier"]}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}>
                                                                    <AutoCompleteSelect
                                                                        isGrouped
                                                                        options={productCategoriesList}
                                                                        label='Category *'
                                                                        value={formData.productCategory}
                                                                        onChange={this.handleSelectChange('productCategory')}
                                                                        error={formErrors && formErrors['productCategory'] && true}
                                                                        errorText={formErrors && formErrors['productCategory']}
                                                                        noOptionsMessage={() =>
                                                                            !formData.supplier
                                                                                ? "Please select a supplier to view available product categories..."
                                                                                : "The selected supplier does not have any product categories available"
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}>
                                                                    <AutoCompleteSelect
                                                                        options={productBrandsList}
                                                                        label="Brand"
                                                                        value={formData.productBrand}
                                                                        onChange={this.handleSelectChange("productBrand")}
                                                                        error={formErrors && formErrors["productBrand"] && true}
                                                                        errorText={formErrors && formErrors["productBrand"]}
                                                                        noOptionsMessage={() =>
                                                                            !formData.supplier
                                                                                ? "Please select a supplier to view available product brands..."
                                                                                : "The selected supplier does not have any product brands available"
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}>
                                                                    <AutoCompleteSelect
                                                                        options={productRangesList}
                                                                        label="Range"
                                                                        value={formData.productRanges}
                                                                        onChange={this.handleSelectChange("productRanges")}
                                                                        error={formErrors && formErrors["productRanges"] && true}
                                                                        errorText={formErrors && formErrors["productRanges"]}
                                                                        noOptionsMessage={() =>
                                                                            !formData.supplier
                                                                                ? "Please select a supplier to view available product ranges..."
                                                                                : "The selected supplier does not have any product ranges available"
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </PaddedPaper>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Box pb={1.75}>
                                                                <Typography variant="h6">Product Settings</Typography>
                                                            </Box>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} lg={6}>
                                                                    <TextField
                                                                        id="minOrderQty"
                                                                        name="minOrderQty"
                                                                        label="Minimum Order Quantity *"
                                                                        value={this.state.formData.minOrderQty}
                                                                        className={classes.textField}
                                                                        error={formErrors && formErrors["minOrderQty"] && true}
                                                                        helperText={formErrors && formErrors["minOrderQty"]}
                                                                        onChange={this.handleChange}
                                                                        margin="none"
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}>
                                                                    <TextField
                                                                        id="orderMultiplesOf"
                                                                        name="orderMultiplesOf"
                                                                        label="Order In Multiples Of *"
                                                                        value={this.state.formData.orderMultiplesOf}
                                                                        className={classes.textField}
                                                                        error={formErrors && formErrors["orderMultiplesOf"] && true}
                                                                        helperText={formErrors && formErrors["orderMultiplesOf"]}
                                                                        onChange={this.handleChange}
                                                                        margin="none"
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}>
                                                                    <TextField
                                                                        id="packSize"
                                                                        name="packSize"
                                                                        label="Pack Size *"
                                                                        value={this.state.formData.packSize}
                                                                        className={classes.textField}
                                                                        error={formErrors && formErrors["packSize"] && true}
                                                                        helperText={formErrors && formErrors["packSize"]}
                                                                        onChange={this.handleChange}
                                                                        margin="none"
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} lg={6}>
                                                                    <AutoCompleteSelect
                                                                        isGrouped
                                                                        options={nominalCodesList}
                                                                        label="Nominal Code"
                                                                        value={formData.nc}
                                                                        onChange={this.handleSelectChange("nc")}
                                                                        error={formErrors && formErrors["nc"] && true}
                                                                        errorText={formErrors && formErrors["nc"]}
                                                                        noClear
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </PaddedPaper>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={6}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Box pb={1.75}>
                                                                        <Typography variant="h6">Product Import / Export</Typography>
                                                                    </Box>
                                                                    <form noValidate autoComplete="off">
                                                                        <CommodityCodeInput
                                                                            error={formErrors && formErrors["ccImport"] && true}
                                                                            handleChange={(v) => this.handleCommodityChange("ccImport", v)}
                                                                            label="Import Commodity Code"
                                                                            value={formData.ccImport}
                                                                        />
                                                                        <CommodityCodeInput
                                                                            forExport
                                                                            error={formErrors && formErrors["ccExport"] && true}
                                                                            handleChange={(v) => this.handleCommodityChange("ccExport", v)}
                                                                            label="Export Commodity Code"
                                                                            value={formData.ccExport}
                                                                        />
                                                                        <AutoCompleteSelect
                                                                            options={cooList}
                                                                            label="Country of Origin"
                                                                            value={formData.coo}
                                                                            onChange={this.handleSelectChange("coo")}
                                                                            error={formErrors && formErrors["coo"] && true}
                                                                            errorText={formErrors && formErrors["coo"]}
                                                                            noDefaultSort
                                                                        />
                                                                    </form>
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Box pb={1.75}>
                                                                        <Typography variant="h6">Product Dimensions</Typography>
                                                                    </Box>
                                                                    <form noValidate autoComplete="off">
                                                                        <TextField
                                                                            name="dimL"
                                                                            label="Length (mm)"
                                                                            value={this.state.formData.dimL}
                                                                            className={classes.textField}
                                                                            error={formErrors && formErrors["dimL"] && true}
                                                                            helperText={formErrors && formErrors["dimL"]}
                                                                            onChange={this.handleNumberChange}
                                                                            onBlur={this.handleFieldFormat}
                                                                            margin="none"
                                                                            fullWidth
                                                                            type="number"
                                                                            inputProps={{
                                                                                size: 4,
                                                                                pattern: "[0-9]*",
                                                                                maxLength: 4,
                                                                            }}
                                                                        />
                                                                        <TextField
                                                                            name="dimW"
                                                                            label="Width (mm)"
                                                                            value={this.state.formData.dimW}
                                                                            className={classes.textField}
                                                                            error={formErrors && formErrors["dimW"] && true}
                                                                            helperText={formErrors && formErrors["dimW"]}
                                                                            onChange={this.handleNumberChange}
                                                                            onBlur={this.handleFieldFormat}
                                                                            margin="none"
                                                                            fullWidth
                                                                            type="number"
                                                                            inputProps={{
                                                                                size: 4,
                                                                                pattern: "[0-9]*",
                                                                                maxLength: 4,
                                                                            }}
                                                                        />
                                                                        <TextField
                                                                            name="dimH"
                                                                            label="Height (mm)"
                                                                            value={this.state.formData.dimH}
                                                                            className={classes.textField}
                                                                            error={formErrors && formErrors["dimH"] && true}
                                                                            helperText={formErrors && formErrors["dimH"]}
                                                                            onChange={this.handleNumberChange}
                                                                            onBlur={this.handleFieldFormat}
                                                                            margin="none"
                                                                            fullWidth
                                                                            type="number"
                                                                            inputProps={{
                                                                                size: 4,
                                                                                pattern: "[0-9]*",
                                                                                maxLength: 4,
                                                                            }}
                                                                        />
                                                                        <TextField
                                                                            name="productWeight"
                                                                            label="Weight (kg)"
                                                                            value={this.state.formData.productWeight}
                                                                            className={classes.textField}
                                                                            error={formErrors && formErrors["productWeight"] && true}
                                                                            helperText={formErrors && formErrors["productWeight"]}
                                                                            onChange={this.handleNumberChange}
                                                                            onBlur={this.handleFieldFormat}
                                                                            margin="none"
                                                                            fullWidth
                                                                        />
                                                                    </form>
                                                                </PaddedPaper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Typography variant="h6" gutterBottom>
                                                                        Bespoke Part
                                                                    </Typography>
                                                                    <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                                                        <Grid item xs>
                                                                            <Grid container alignItems="center" wrap="nowrap">
                                                                                <Grid item>
                                                                                    <FAIcon icon={_.isEmpty(formData.customerName) ? "times" : "check"} size={15} className="mr-1" />
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Typography variant="body2" className="fw-400">
                                                                                        {!_.isEmpty(formData.customerName) ? formData.customerName : "No"}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Tooltip title="Modify">
                                                                                <IconButton onClick={this.handleCustomerDialog}>
                                                                                    <FAIcon icon="pencil" size={15} noMargin button />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid>
                                                            {formData.supplier === -1 && (
                                                                <Grid item xs={12}>
                                                                    <PaddedPaper>
                                                                        <Typography variant="h6" gutterBottom>
                                                                            Attributes
                                                                        </Typography>
                                                                        <Box pt={0.6} pb={0.6}>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Switch color="primary" checked={formData.service === 1} onChange={this.handleCheckedChange} value="1" name="service" />
                                                                                }
                                                                                label={<span className="fw-400">Service product (no despatch)</span>}
                                                                            />
                                                                        </Box>
                                                                    </PaddedPaper>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Typography variant="h6" gutterBottom>
                                                                        Exclusions
                                                                    </Typography>
                                                                    <Box pt={0.6} pb={0.6}>
                                                                        <FormControlLabel
                                                                            disabled={formData.ecomm === 1}
                                                                            control={<Switch color="primary" checked={formData.excludeWeb === 1} onChange={this.handleCheckedChange} value="1" name="excludeWeb" />}
                                                                            label={<span className="fw-400">Exclude from website</span>}
                                                                        />
                                                                    </Box>
                                                                    <Box pt={0.6} pb={0.6}>
                                                                        <FormControlLabel
                                                                            control={<Switch color="primary" checked={formData.paxHw === 1} onChange={this.handleCheckedChange} value="1" name="paxHw" />}
                                                                            label={<span className="fw-400">This product is Paxton hardware</span>}
                                                                        />
                                                                    </Box>
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Typography variant="h6" gutterBottom>
                                                                        Technical
                                                                    </Typography>
                                                                    <Box pt={0.6} pb={0.6}>
                                                                        <FormControlLabel
                                                                            control={<Switch color="primary" checked={formData.reqTech === 1} onChange={this.handleCheckedChange} value="1" name="reqTech" />}
                                                                            label={<span className="fw-400">Requires technical before despatch</span>}
                                                                        />
                                                                    </Box>
                                                                    <Box pt={0.6} pb={0.6}>
                                                                        <FormControlLabel
                                                                            control={<Switch color="primary" checked={formData.cardDb === 1} onChange={this.handleCheckedChange} value="1" name="cardDb" />}
                                                                            label={<span className="fw-400">Requires card order authorisation</span>}
                                                                        />
                                                                    </Box>
                                                                    <Box pt={0.6} pb={0.6}>
                                                                        <FormControlLabel
                                                                            control={<Switch color="primary" checked={formData.prog === 1} onChange={this.handleCheckedChange} value="1" name="prog" />}
                                                                            label={<span className="fw-400">Requires in-house programming</span>}
                                                                        />
                                                                    </Box>
                                                                    {(formData.cardDb === 1 || formData.prog === 1) && (
                                                                        <>
                                                                            <AutoCompleteSelect
                                                                                label="Card Format"
                                                                                options={cardDb.formats}
                                                                                value={formData.formatId}
                                                                                onChange={this.handleSelectChange("formatId")}
                                                                                error={formErrors && formErrors["formatId"] && true}
                                                                                errorText={formErrors && formErrors["formatId"]}
                                                                            />
                                                                            {/* <AutoCompleteSelect
                                                                                label="Card Type"
                                                                                options={cardDb.types}
                                                                                value={formData.typeId}
                                                                                onChange={this.handleSelectChange("typeId")}
                                                                                error={formErrors && formErrors["typeId"] && true}
                                                                                errorText={formErrors && formErrors["typeId"]}
                                                                            /> */}
                                                                            <AutoCompleteSelect
                                                                                label="Programmer Type"
                                                                                options={cardDb.programmers}
                                                                                value={formData.progId}
                                                                                onChange={this.handleSelectChange("progId")}
                                                                                error={formErrors && formErrors["progId"] && true}
                                                                                errorText={formErrors && formErrors["progId"]}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </PaddedPaper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={3}>
                                        <Grid container spacing={3}>
                                            {formData.productStatus !== "Active" && formData.ecomm === 1 && (
                                                <Grid item xs={12}>
                                                    <Alert severity="error">This product is not active and will not show on the website</Alert>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} lg={8}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant="h6">Product Description</Typography>
                                                            <TextField
                                                                margin="none"
                                                                id="shortDescription"
                                                                name="shortDescription"
                                                                value={formData.shortDescription}
                                                                error={formErrors && formErrors["shortDescription"]}
                                                                onChange={this.handleChange}
                                                                fullWidth
                                                            />
                                                        </PaddedPaper>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant="h6">Product Information</Typography>
                                                            <WYSIWYG editorState={productDescriptionEditor} onChange={this.handleDescriptionChange} margin="none" height={300} />
                                                        </PaddedPaper>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Box pb={1}>
                                                                <Grid container spacing={1} alignItems="center">
                                                                    <Grid item xs>
                                                                        <Typography variant="h6" gutterBottom>
                                                                            Product Key Features
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Button onClick={this.handleKeyFeatureAdd} variant="text" color="primary">
                                                                            <FAIcon type="light" icon="plus-circle" button size={15} />
                                                                            Additional Key Feature
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                            {(!_.isEmpty(formData.keyFeatures) && (
                                                                <form noValidate autoComplete="off">
                                                                    {formData.keyFeatures.map((kf, idx) => {
                                                                        return (
                                                                            <TextField
                                                                                name={`kf${idx}`}
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                        <InputAdornment position="end">
                                                                                            <FAIcon type="light" icon="check" />
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                    endAdornment: (
                                                                                        <Tooltip title="Delete">
                                                                                            <IconButton onClick={() => this.handleKeyFeatureRemove(idx)}>
                                                                                                <FAIcon type="light" button noMargin icon="trash-alt" size={15} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    ),
                                                                                }}
                                                                                value={kf.kf}
                                                                                className={classes.textField}
                                                                                onChange={(e) => {
                                                                                    this.handleKeyFeatureChange(idx, e);
                                                                                }}
                                                                                margin="none"
                                                                                fullWidth
                                                                            />
                                                                        );
                                                                    })}
                                                                </form>
                                                            )) || (
                                                                <Alert severity="info" variant="outlined">
                                                                    This product has no key features listed
                                                                </Alert>
                                                            )}
                                                        </PaddedPaper>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant="h6" paragraph>
                                                                E-Commerce Settings
                                                            </Typography>
                                                            <Box pt={0.6} pb={0.6}>
                                                                <FormControlLabel
                                                                    disabled={formData.excludeWeb === 1 && formData.ecomm !== 1}
                                                                    control={<Switch color="primary" checked={formData.ecomm === 1} onChange={this.handleCheckedChange} value="1" name="ecomm" />}
                                                                    label={(
                                                                        <>
                                                                            <div className="fw-400">Show on Website</div>
                                                                            <Typography variant="caption">
                                                                                Adds the product to the website, subject to the product status being <em>Active</em> in the <em>Availability</em> tab. Bespoke products will only show for applicable logged-in Portal Users.
                                                                            </Typography>
                                                                        </>
                                                                    )}
                                                                />
                                                            </Box>
                                                            <Box pt={0.6} pb={0.6}>
                                                                <FormControlLabel
                                                                    disabled={formData.ecomm !== 1}
                                                                    control={<Switch color="primary" checked={formData.valueVault === 1} onChange={this.handleCheckedChange} value="1" name="valueVault" />}
                                                                    label={(
                                                                        <>
                                                                            <div className="fw-400">Show on Value Vault</div>
                                                                            <Typography variant="caption">
                                                                                Show this product as part of the Value Vault promotional page
                                                                            </Typography>
                                                                        </>
                                                                    )}
                                                                />
                                                            </Box>
                                                            <Box pt={0.6} pb={0.6}>
                                                                <FormControlLabel
                                                                    disabled={formData.excludeWeb === 1 && formData.ecomm !== 1}
                                                                    control={<Switch color="primary" checked={formData.configNew === 1} onChange={this.handleCheckedChange} value="1" name="configNew" />}
                                                                    label={(
                                                                        <>
                                                                            <div className="fw-400">Override System New Status</div>
                                                                            <Typography variant="caption">
                                                                                By default, products created within the last 3 months will display a <em>New</em> badge, this setting allows you to manually override this.
                                                                            </Typography>
                                                                        </>
                                                                    )}
                                                                />
                                                            </Box>
                                                            <Box pt={0.6} pb={0.6}>
                                                                <FormControlLabel
                                                                    disabled={!formData.configNew}
                                                                    control={<Switch color="primary" checked={formData.configNew === 1 && formData.isNew === 1} onChange={this.handleCheckedChange} value="1" name="isNew" />}
                                                                    label={(
                                                                        <>
                                                                            <div className="fw-400">Show New Badge</div>
                                                                            <Typography variant="caption">
                                                                                This product should display the <em>New</em> badge on the website.
                                                                            </Typography>
                                                                        </>
                                                                    )}
                                                                />
                                                            </Box>
                                                        </PaddedPaper>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant="h6">E-Commerce Categories</Typography>
                                                            <form noValidate autoComplete="off">
                                                                <Box className="pt-2">
                                                                    <AutoCompleteSelect
                                                                        isGrouped
                                                                        options={eCommerceCategoriesList}
                                                                        label={`Primary Category ${formData.ecomm ? '*' : ''}`}
                                                                        value={formData.ecommDefCat}
                                                                        onChange={this.handleSelectChange("ecommDefCat")}
                                                                        error={formErrors && formErrors["ecommDefCat"] && true}
                                                                        errorText={formErrors && formErrors["ecommDefCat"]}
                                                                    />
                                                                </Box>
                                                                <AutoCompleteMultiSelect
                                                                    isGrouped
                                                                    options={eCommerceCategoriesList}
                                                                    label="Additional Categories"
                                                                    value={formData.ecommCat}
                                                                    onChange={(options) => this.handleMultiSelectChange("ecommCat", options)}
                                                                    error={formErrors && formErrors["ecommCat"] && true}
                                                                    errorText={formErrors && formErrors["ecommCat"]}
                                                                />
                                                            </form>
                                                        </PaddedPaper>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant="h6">E-Commerce Filters</Typography>
                                                            {formData.ecommDefCat ? (
                                                                <form noValidate autoComplete="off">
                                                                    <Grid container spacing={0} className="pt-1">
                                                                        {_.find?.(eCommerceCategoryFilters, { id: formData.ecommDefCat })?.filters?.map?.((filter, idx) => (
                                                                            <Grid item xs={12} key={`cfo-${idx}`}>
                                                                                <AutoCompleteSelect
                                                                                    creatable
                                                                                    creatableMessage={(userInput) => `New value: ${userInput}`}
                                                                                    options={_.map(filter.options, o => ({ value: o.ecfo_id, label: o.ecfo_val }))}
                                                                                    label={filter.ecf_name}
                                                                                    value={formData?.filters?.[filter.ecf_id] ?? ''}
                                                                                    onChange={v => this.handleFilterChange(filter.ecf_id, v?.value ?? '')}
                                                                                />
                                                                            </Grid>
                                                                        )) ?? (
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="body2" className="textError">
                                                                                    No filters found for this category!
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </form>
                                                            ) : (
                                                                <Typography variant="body2" className="textError pt-1">
                                                                    Please select a primary e-commerce category!
                                                                </Typography>
                                                            )}
                                                        </PaddedPaper>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant="h6">E-Commerce Supplier</Typography>
                                                            <form noValidate autoComplete="off">
                                                                <AutoCompleteSelect
                                                                    options={suppliersList}
                                                                    value={formData.ecommSupp}
                                                                    onChange={this.handleSelectChange("ecommSupp")}
                                                                    error={formErrors && formErrors["ecommSupp"] && true}
                                                                    errorText={formErrors && formErrors["ecommSupp"]}
                                                                    noClear
                                                                />
                                                            </form>
                                                        </PaddedPaper>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={4}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Typography variant="h6" gutterBottom>
                                                        Product Images
                                                    </Typography>
                                                    <Grid container spacing={3} className="mt-1 mb-1">
                                                        {(this.props.match?.params?.id || this.props.id) &&
                                                            !_.isEmpty(formData.images) &&
                                                            _.map(formData.images, (image, idx) => {
                                                                const defaultImage = image.deleteFlag ? false : formData.defaultImage === image.img_id ? true : false;
                                                                return (
                                                                    <Grid item xs={6} lg={3} key={idx}>
                                                                        <Card style={{ position: "relative" }}>
                                                                            {defaultImage && (
                                                                                <div className={classes.overlay}>
                                                                                    <FAIcon type="light" icon="star" noMargin size={18} style={{ color: "#FFA000" }} />
                                                                                    <Typography variant="body2" component="span" className="fw-400 pl-1">
                                                                                        Default
                                                                                    </Typography>
                                                                                </div>
                                                                            )}
                                                                            <CardContent style={{ textAlign: "center", paddingTop: 48, paddingBottom: 0 }}>
                                                                                <ImageWithError
                                                                                    src={`${CLOUDFRONT_URL}${image.img_filekey}`}
                                                                                    style={{ height: 200, filter: image.deleteFlag ? "grayscale(100%)" : "initial" }}
                                                                                    alt={image.img_filename}
                                                                                />
                                                                            </CardContent>
                                                                            <CardContent style={{ textAlign: "center", paddingTop: 0, paddingBottom: 28, position: "relative" }}>
                                                                                {image.deleteFlag && (
                                                                                    <div
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            bottom: 0,
                                                                                            left: 0,
                                                                                            paddingTop: 6,
                                                                                            textAlign: "center",
                                                                                            width: "100%",
                                                                                            zIndex: 9999,
                                                                                        }}
                                                                                    >
                                                                                        <div style={{ background: "rgba(0,0,0,0.7)" }}>
                                                                                            <Typography variant="caption" style={{ color: "#fff" }}>
                                                                                                This image will be deleted!
                                                                                            </Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                {(!image.deleteFlag && (
                                                                                    <>
                                                                                        <Tooltip title="Download">
                                                                                            <IconButton onClick={() => getPresignedUrl(image.img_filekey)}>
                                                                                                <FAIcon type="light" icon="arrow-to-bottom" noMargin fixedHeight button />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                        {!defaultImage && (
                                                                                            <>
                                                                                                <Tooltip title="Set Default Image">
                                                                                                    <IconButton onClick={() => this.handleImageDefault(image.img_id)}>
                                                                                                        <FAIcon type="light" icon="check" noMargin fixedHeight button />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </>
                                                                                        )}
                                                                                        <Tooltip title="Delete Image">
                                                                                            <IconButton onClick={() => this.handleImageDelete(idx)}>
                                                                                                <FAIcon type="light" icon="trash-alt" noMargin fixedHeight button />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </>
                                                                                )) || (
                                                                                    <Tooltip title="Recover Image" position="right">
                                                                                        <IconButton onClick={() => this.handleImageDelete(idx)}>
                                                                                            <FAIcon type="light" icon="trash-undo-alt" noMargin fixedHeight button />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                )}
                                                                            </CardContent>
                                                                        </Card>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        {_.map(formData.imgUploads, (image, idx) => {
                                                            return (
                                                                <Grid item xs={6} lg={3} key={idx}>
                                                                    <Card style={{ position: "relative", border: formErrors?.[`imgUploads|${image.name}`] ? "1px solid #ef3340" : undefined }}>
                                                                        <CardContent style={{ textAlign: "center", paddingTop: 48, paddingBottom: 0 }}>
                                                                            <ImageWithError src={URL.createObjectURL(image)} style={{ width: "auto", maxWidth: "75%", height: 200 }} alt={image.name} />
                                                                        </CardContent>
                                                                        <CardContent style={{ textAlign: "center", paddingTop: 0, paddingBottom: 28, position: "relative" }}>
                                                                            {formErrors?.[`imgUploads|${image.name}`] && (
                                                                                <div
                                                                                    style={{
                                                                                        position: "absolute",
                                                                                        bottom: 0,
                                                                                        left: 0,
                                                                                        paddingTop: 6,
                                                                                        textAlign: "center",
                                                                                        width: "100%",
                                                                                        zIndex: 9999,
                                                                                    }}
                                                                                >
                                                                                    <div style={{ background: "#ef3340" }}>
                                                                                        <Typography variant="caption" style={{ color: "#fff" }}>
                                                                                            {formErrors && formErrors[`imgUploads|${image?.name}`]}
                                                                                        </Typography>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            <Tooltip title="Delete Image">
                                                                                <IconButton onClick={() => this.handleImageUploadsDelete(idx, image.name)}>
                                                                                    <FAIcon type="light" icon="trash-alt" noMargin fixedHeight button />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                    <DragFileInput
                                                        id="productImages"
                                                        key={key}
                                                        name="productImages[]"
                                                        label={`Upload ${this.props.match?.params?.id || this.props.id ? "Additional " : ""}Product Images (.png, .jpg, .jpeg, .bmp, .gif) *`}
                                                        file={formData.imgUploads}
                                                        errorText={formErrors?.["imgUploads"]}
                                                        onChange={this.handleImageUploads}
                                                        multiple
                                                    />
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={2}>
                                        <Grid container item spacing={3}>
                                            <Grid item xs={12} lg={6}>
                                                <PaddedPaper>
                                                    <Typography variant="h6" gutterBottom>Product Pricing</Typography>
                                                    {formData.rrp !== '' && formData.cost !== '' && parseInt(formData.rrp) < parseInt(formData.cost) ? (
                                                        <Box pb={2}>
                                                            <Alert severity="warning">
                                                                <Typography variant="body2" className="fw-400" gutterBottom>
                                                                    Buy Price is greater than List Price
                                                                </Typography>
                                                                <Typography variant="body2">
                                                                    Is the list price correct for this product?
                                                                </Typography>
                                                            </Alert>
                                                        </Box>
                                                    ) : null}
                                                    <TextField
                                                        id="rrp"
                                                        name="rrp"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <FAIcon type="thin" icon="pound-sign" style={{ color: formErrors && formErrors["rrp"] && "red" }} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        label={"List Price *"}
                                                        value={formData.rrp}
                                                        className={classes.textField}
                                                        error={formErrors && formErrors["rrp"] && true}
                                                        helperText={formErrors && formErrors["rrp"]}
                                                        onChange={this.handleNumberChange}
                                                        onBlur={this.handleFieldFormat}
                                                        margin="none"
                                                        fullWidth
                                                        type="number"
                                                    />
                                                    <AutoCompleteSelect
                                                        creatable
                                                        creatableMessage={(userInput) => `Discount Percent: ${userInput}%`}
                                                        options={discountOptions}
                                                        label="Supplier Discount"
                                                        value={formData.supplierDiscount}
                                                        onChange={this.handleSelectChange("supplierDiscount")}
                                                        error={formErrors && formErrors["supplierDiscount"] && true}
                                                        errorText={formErrors && formErrors["supplierDiscount"]}
                                                    />
                                                    <TextField
                                                        id="cost"
                                                        name="cost"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <FAIcon type="thin" icon="pound-sign" style={{ color: formErrors && formErrors["cost"] && "red" }} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        label={"Buy Price *"}
                                                        value={formData.cost}
                                                        className={classes.textField}
                                                        error={formErrors && formErrors["cost"] && true}
                                                        helperText={formErrors && formErrors["cost"]}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleFieldFormat}
                                                        margin="none"
                                                        fullWidth
                                                        type="number"
                                                    />
                                                    <TextField
                                                        id="margin"
                                                        name="margin"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <FAIcon type="thin" icon="percent" style={{ color: formErrors && formErrors["margin"] && "red" }} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        label={"Margin *"}
                                                        value={formData.margin}
                                                        className={classes.textField}
                                                        error={formErrors && formErrors["margin"] && true}
                                                        helperText={formErrors && formErrors["margin"]}
                                                        onChange={this.handleNumberChange}
                                                        onBlur={this.handleFieldFormat}
                                                        margin="none"
                                                        fullWidth
                                                        type="number"
                                                    />
                                                    {/* <TextField
                                                        id="markup"
                                                        name="markup"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <FAIcon type="thin" icon='percent' style={{color: (formErrors && formErrors['markup']) && 'red'}} />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        label={'Markup *'}
                                                        value={formData.markup}
                                                        className={classes.textField}
                                                        error={formErrors && formErrors['markup'] && true}
                                                        helperText={formErrors && formErrors['markup']}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleFieldFormat}
                                                        margin="none"
                                                        fullWidth
                                                    /> */}
                                                    <TextField
                                                        id="price"
                                                        name="price"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <FAIcon type="thin" icon="pound-sign" style={{ color: formErrors && formErrors["price"] && "red" }} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        label={"Trade Price *"}
                                                        value={formData.price}
                                                        className={classes.textField}
                                                        error={formErrors && formErrors["price"] && true}
                                                        helperText={formErrors && formErrors["price"]}
                                                        onChange={this.handleNumberChange}
                                                        onBlur={this.handleFieldFormat}
                                                        margin="none"
                                                        fullWidth
                                                        type="number"
                                                    />
                                                </PaddedPaper>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant="h6" paragraph>Pricing Options</Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <FormControlLabel
                                                                        control={<Switch color="primary" checked={formData.checkPrice === 1} onChange={this.handleCheckedChange} value="1" name="checkPrice" />}
                                                                        label={(
                                                                            <>
                                                                                <div className="fw-400 textDefault">Check Price and Availability</div>
                                                                                <Typography className="textDefault" variant="caption">
                                                                                    Adds the Check Price & Availability chip on the product.
                                                                                </Typography>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </PaddedPaper>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant="h6" paragraph>Discount Options</Typography>
                                                            <Grid container spacing={2}>
                                                                {formData.noDiscount === 1 && formData.ignDiscEx === 0 && (
                                                                    <Grid item xs={12}>
                                                                        <Alert severity="warning">
                                                                            <Typography variant="body2" className="fw-400" gutterBottom>
                                                                                Product Discount Exclusion Rule Match
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                This product should be excluded from sales discount based on the supplier, category, brand and range selection(s) matching a pre-defined rule set. You can manually override this using the <em>Ignore Automated Discount Exclusion Rules</em> setting.
                                                                            </Typography>
                                                                        </Alert>
                                                                    </Grid>
                                                                )}
                                                                <Grid item xs={12}>
                                                                    <FormControlLabel
                                                                        control={<Switch color="primary" checked={formData.noDiscount === 1} onChange={this.handleNoSalesDiscountToggle} value="1" name="noDiscount" />}
                                                                        disabled={formData.noDiscount === 1 && formData.ignDiscEx === 0}
                                                                        label={(
                                                                            <>
                                                                                <div className="fw-400 textDefault">No Sales Discount</div>
                                                                                <Typography className="textDefault" variant="caption">
                                                                                    Prevents applicable category, supplier, brand, and range customer discounts from being applied to this product automatically. Any applicable customer specific product discounts will still be applied regardless of this setting.
                                                                                </Typography>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <FormControlLabel
                                                                        control={<Switch color="primary" checked={formData.noDiscountOverride === 1} onChange={this.handleCheckedChange} value="1" name="noDiscountOverride" />}
                                                                        label={(
                                                                            <>
                                                                                <div className="fw-400 textDefault">No Sales Discount Manual Override</div>
                                                                                <Typography className="textDefault" variant="caption">
                                                                                    Allows the user to overtype the price on the Quote / Sales Order form if No Sales Discount is enabled and they have access to "Quote / Order - Manual Discounts - Up to 20%". Users with "Quote / Order - Manual Discounts - Any" can overtype the price regardless of this setting.
                                                                                </Typography>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <FormControlLabel
                                                                        control={<Switch color="primary" checked={formData.ignDiscEx === 1} onChange={this.handleAutomatedDiscountExclusionToggle} value="1" name="ignDiscEx" />}
                                                                        disabled={formData.noDiscount === 1 && formData.ignDiscEx === 1}
                                                                        label={(
                                                                            <>
                                                                                <div className="fw-400">Ignore Automated Discount Exclusion Rules</div>
                                                                                <Typography variant="caption">
                                                                                    Prevents automated discount exclusion rules being applied to this product, this should be enabled if manually enabling <em>No Sales Discount</em> for this product.
                                                                                </Typography>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </PaddedPaper>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={1}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} lg={6}>
                                                <Grid container item spacing={3}>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant="h6" paragraph>Availability Options</Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <FormControlLabel
                                                                        control={<Switch color="primary" checked={formData.stockItem === 1} onChange={this.handleCheckedChange} value="1" name="stockItem" />}
                                                                        disabled={formData.consignmentStockItem === 1}
                                                                        label={(
                                                                            <>
                                                                                <div className="fw-400">Stocked Item</div>
                                                                                <Typography variant="caption">
                                                                                    This product is regularly stocked in the warehouse for same-day despatch.
                                                                                </Typography>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <FormControlLabel
                                                                        disabled={formData.stockItem === 0}
                                                                        control={<Switch color="primary" checked={formData.consignmentStockItem === 1} onChange={this.handleCheckedChange} value="1" name="consignmentStockItem" />}
                                                                        label={(
                                                                            <>
                                                                                <div className="fw-400">Consignment Stock</div>
                                                                                <Typography variant="caption">
                                                                                    This product is stocked on a consignment-basis.
                                                                                </Typography>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </PaddedPaper>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant="h6" gutterBottom>Product Availability</Typography>
                                                            <AutoCompleteSelect
                                                                options={[
                                                                    {
                                                                        label: "Active",
                                                                        value: "Active",
                                                                    },
                                                                    {
                                                                        label: "Discontinued",
                                                                        value: "Discontinued",
                                                                    },
                                                                    {
                                                                        label: "Replaced",
                                                                        value: "Replaced",
                                                                    },
                                                                ]}
                                                                label="Product Status *"
                                                                value={formData.productStatus}
                                                                onChange={this.handleSelectChange("productStatus")}
                                                                error={formErrors && formErrors["productStatus"] && true}
                                                                errorText={formErrors && formErrors["productStatus"]}
                                                                noClear
                                                            />
                                                            {((formData.stockItem === 1 || formData.consignmentStockItem === 1) && (
                                                                <>
                                                                    {!id && (
                                                                        <TextField
                                                                            id="openingStockQty"
                                                                            name="openingStockQty"
                                                                            label="Opening Quantity *"
                                                                            value={formData.openingStockQty}
                                                                            className={classes.textField}
                                                                            error={formErrors && formErrors["openingStockQty"] && true}
                                                                            helperText={formErrors && formErrors["openingStockQty"]}
                                                                            onChange={this.handleNumberChange}
                                                                            margin="none"
                                                                            fullWidth
                                                                        />
                                                                    )}
                                                                    <TextField
                                                                        id="minStockLevel"
                                                                        name="minStockLevel"
                                                                        label="Minimum Stock Level"
                                                                        value={formData.minStockLevel}
                                                                        className={classes.textField}
                                                                        error={formErrors && formErrors["minStockLevel"] && true}
                                                                        helperText={formErrors && formErrors["minStockLevel"]}
                                                                        onChange={this.handleNumberChange}
                                                                        onBlur={this.handleFieldFormat}
                                                                        margin="none"
                                                                        fullWidth
                                                                    />
                                                                    <TextField
                                                                        id="maxStockLevel"
                                                                        name="maxStockLevel"
                                                                        label="Maximum Stock Level"
                                                                        value={formData.maxStockLevel}
                                                                        className={classes.textField}
                                                                        error={formErrors && formErrors["maxStockLevel"] && true}
                                                                        helperText={formErrors && formErrors["maxStockLevel"]}
                                                                        onChange={this.handleNumberChange}
                                                                        onBlur={this.handleFieldFormat}
                                                                        margin="none"
                                                                        fullWidth
                                                                    />
                                                                    <AutoCompleteSelect
                                                                        showAll
                                                                        options={warehouseBinsList}
                                                                        label="Warehouse Bin *"
                                                                        value={formData.warehouseBin}
                                                                        onChange={this.handleSelectChange("warehouseBin")}
                                                                        error={formErrors && formErrors["warehouseBin"] && true}
                                                                        errorText={formErrors && formErrors["warehouseBin"]}
                                                                    />
                                                                </>
                                                            )) || <React.Fragment />}
                                                        </PaddedPaper>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant="h6">
                                                                {id
                                                                    ? formData.productStatus === "Replaced"
                                                                        ? "Replacement Product"
                                                                        : formData.productStatus === "Discontinued"
                                                                        ? "Alternative Product"
                                                                        : "Temporary Replacement"
                                                                    : "Replace Product"}
                                                            </Typography>
                                                            <Box mb={3} mt={1}>
                                                                <Typography variant="body2">
                                                                    {id
                                                                        ? `Select a product ${
                                                                              formData.productStatus === "Replaced"
                                                                                  ? "that replaces"
                                                                                  : formData.productStatus === "Discontinued"
                                                                                  ? "as an alternative to"
                                                                                  : "as a temporary replacement to"
                                                                          } this product:`
                                                                        : "Select a product that this product replaces:"}
                                                                </Typography>
                                                            </Box>
                                                            <Box style={{ marginTop: 36, marginBottom: 17 }}>
                                                                <ProductSearchSelector
                                                                    hideStock
                                                                    defaultOption={
                                                                        !product.replacement
                                                                            ? undefined
                                                                            : [
                                                                                  {
                                                                                      img: product.replacement?.default_image?.img_filekey ?? "",
                                                                                      label: product.replacement?.default_code?.code_value ?? "-",
                                                                                      value: product.replacement?.product_id ?? 0,
                                                                                      subtitle1: product.replacement?.product_name ?? "-",
                                                                                  },
                                                                              ]
                                                                    }
                                                                    exclude={this.state.product?.product_id ?? undefined}
                                                                    label="Product"
                                                                    handleAction={this.handleSelectChange("replacement")}
                                                                    value={formData.replacement}
                                                                />
                                                            </Box>
                                                        </PaddedPaper>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <PaddedPaper>
                                                            <Typography variant="h6" paragraph>Freight Restrictions</Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <FormControlLabel
                                                                        control={<Switch color="primary" checked={formData.checkWeight === 1} onChange={this.handleCheckedChange} value="1" name="checkWeight" />}
                                                                        label={(
                                                                            <>
                                                                                <div className="fw-400">Check Weight</div>
                                                                                <Typography variant="caption">
                                                                                    Web orders containing this product should be reviewed for additional freight charges.
                                                                                </Typography>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                {formData.checkWeight === 1 && (
                                                                    <Grid item xs={12}>
                                                                        <Grid container>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <TextField
                                                                                    id="checkWeightQtyAllowed"
                                                                                    name="checkWeightQtyAllowed"
                                                                                    label="Qty Allowed Without Review *"
                                                                                    value={formData.checkWeightQtyAllowed}
                                                                                    className={classes.textField}
                                                                                    error={formErrors && formErrors["checkWeightQtyAllowed"] && true}
                                                                                    helperText={formErrors && formErrors["checkWeightQtyAllowed"]}
                                                                                    onChange={this.handleNumberChange}
                                                                                    margin="none"
                                                                                    fullWidth
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </PaddedPaper>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={2} className={`${classes.side} h-100`}>
                            <Grid container className={`${classes.relative} h-100`}>
                                <Grid item xs={12}>
                                    <Tabs
                                        color="primary"
                                        orientation="vertical"
                                        TabIndicatorProps={{
                                            style: {
                                                display: "none",
                                            },
                                        }}
                                        value={currentTab}
                                        onChange={this.handleTabChange}
                                    >
                                        <Tab classes={{ root: classes.tabs, selected: classes.tabSelected }} value={0} label="Details" />
                                        <Tab classes={{ root: classes.tabs, selected: classes.tabSelected }} value={1} label="Availability" />
                                        <Tab classes={{ root: classes.tabs, selected: classes.tabSelected }} value={2} label="Pricing" />
                                        <Tab classes={{ root: classes.tabs, selected: classes.tabSelected }} value={3} label="E-Commerce" disabled={formData.service === 1} />
                                        <Tab classes={{ root: classes.tabs, selected: classes.tabSelected }} value={4} label="Images" />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={12} className={`${classes.absoluteBottom} ${id ? classes.updateProductTotals : classes.newProductTotals} w-100`}>
                                    <Box mb={2}>
                                        <Grid container spacing={3} justify="center">
                                            <Grid item>
                                                <Button color="primary" disabled={isLoading || currentTab === 0} onClick={() => this.handleTabChange(currentTab - 1)} variant="text">
                                                    <FAIcon icon="arrow-left" disabled={isLoading} size={15} button />
                                                    Back
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button color="primary" onClick={() => this.handleTabChange(currentTab + 1)} disabled={isLoading || currentTab === 4} variant="text">
                                                    <FAIcon icon="arrow-right" size={15} button />
                                                    Next
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} className="pt-0">
                                                <Grid container spacing={3} justify="center">
                                                    <Grid item>
                                                        <Button
                                                            color="primary"
                                                            onClick={() => this.props.deployConfirmation((
                                                                <>
                                                                    {!_.isEmpty(codeExists) && (
                                                                        <>
                                                                            <Box mb={3} m={-3}>
                                                                                <Alert severity="error">
                                                                                    <Typography variant="body2" className="fw-400">
                                                                                        Duplicate Product Warning
                                                                                    </Typography>
                                                                                </Alert>
                                                                            </Box>
                                                                            <Box mb={2}>
                                                                                <Typography variant="body2" className="fw-400">
                                                                                    Other product(s) already use some of the product codes specified.<br />
                                                                                    Please ensure this part does not exist before proceeding!
                                                                                </Typography>
                                                                                {_.map(formData.productCodes, (pc, index) => (
                                                                                        (codeExists[index] && (
                                                                                            <Box mt={2} key={index}>
                                                                                                <Typography variant="body2" component="div" className="textDefault">
                                                                                                    {pc.code}
                                                                                                </Typography>
                                                                                                {_.map(codeExists[index], (prd, pidx) => (
                                                                                                    <Typography variant="caption" component="div" key={pidx}>
                                                                                                        <Tooltip title="View Product (Opens in a new tab)">
                                                                                                            <Link to={`/products/${prd.product_id}`} target="_blank">
                                                                                                                &bull; {prd.product_name}
                                                                                                            </Link>
                                                                                                        </Tooltip>
                                                                                                    </Typography>
                                                                                                ))}
                                                                                            </Box>
                                                                                        )) || (
                                                                                            null
                                                                                        )
                                                                                    ))}
                                                                            </Box>
                                                                            <Box mb={2}>
                                                                                <Divider />
                                                                            </Box>
                                                                        </>
                                                                    )}
                                                                    <Typography variant="body2">
                                                                        Are you sure you want to {id ? "update" : "create"} this product?
                                                                    </Typography>
                                                                </>
                                                            ), this.handleSubmit)}
                                                            variant="contained"
                                                        >
                                                            <FAIcon icon="check" size={15} buttonPrimary />
                                                            {id ? `Update Product` : `Create Product`}
                                                        </Button>
                                                        {id && (
                                                            <Box mt={1.25} align="center">
                                                                <Button
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        this.props.deployConfirmation(`Are you sure you want to cancel changes?`, () => this.props.history.push(`/products/${id}`))
                                                                    }
                                                                    variant="text"
                                                                >
                                                                    <FAIcon icon="times" size={15} button />
                                                                    Cancel
                                                                </Button>
                                                            </Box>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = "", variant = "standard", size = "md", fullscreen = false) =>
            dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen)),
    };
};

export default connect(null, mapDispatchToProps)(withStyles(Form)(ProductForm));
