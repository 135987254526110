import React, { useEffect, useState }from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import {
    Card,
    CardContent, 
    Grid,
    IconButton,
    Tooltip,
    Typography
} from '@material-ui/core';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ProductHistory from "Components/Reporting/Products/ProductHistory/ProductHistory";
import { deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { CLOUDFRONT_URL } from 'Constants';
import { currencyFormat } from 'Functions/MiscFunctions'; 
import CopyToClipboardDatagrid from 'Components/Common/CopyToClipboard/CopyToClipboardDatagrid';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const initialState = () => ({
    dateRangeMonths: 0,
    isLoading: false,
    results: []
})

const MostPurchased = ({customerId, customerName}) => {

    const dispatch = useDispatch(); 

    const [state, setState] = useState(initialState());

    const {
        dateRangeMonths,
        isLoading, 
        results
    } = state;

    const handleDeployMostPurchasedOrders = (productId, productCode, productDesc) => {
        dispatch(deployDialog(
            <ProductHistory inline customerId={customerId} productId={productId} />,
            false,
            `${customerName} - Sales History:  ${productCode} - ${productDesc}`,
            "standard",
            "md",
            false,
        ));
    }

    const handleLoadData = () => {

        setState(state => ({
            ...state,
            isLoading: true,
        }))

        API.get(`/customers/${customerId}/mostPurchased`, {
            params: {
                dateRangeMonths
            }
        })
        .then(res => {
            if(res?.data) {
                setState(state => ({
                    ...state,
                    isLoading: false,
                    results: res.data
                }))
            }
        })

    }

    const handleChange = (n, v) => {
        setState(state => ({
            ...state,
            [n]: v
        }))
    }

    useEffect(() => {
        handleLoadData();
    }, [dateRangeMonths]); /* eslint-disable-line */
    
    return (
        (isLoading && (
            <LoadingCircle />
        )) || (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <AutoCompleteSelect
                                adornment="calendar-alt"
                                onChange={v => handleChange("dateRangeMonths", v?.value ?? 0)}
                                options={[
                                    {
                                        label: 'Last 3 Months',
                                        value: 3
                                    },
                                    {
                                        label: 'Last 6 Months',
                                        value: 6
                                    },
                                    {
                                        label: 'Last 9 Months',
                                        value: 9
                                    },
                                    {
                                        label: 'Last 12 Months',
                                        value: 12
                                    },
                                    {
                                        label: '--------------------',
                                        disabled: true
                                    },
                                    {
                                        label: 'All Time',
                                        value: 0
                                    }
                                ]}
                                noClear
                                noDefaultSort
                                placeholder='Within:'
                                value={dateRangeMonths} 
                                variant="filled"
                                fullWidth
                            /> 
                        </Grid>
                    </Grid>
                </Grid>
                {(!_.isEmpty(results) && (
                    _.map(results, (product, idx) => (
                        <Grid item xs={6} lg={2} key={idx}>
                            <Card>
                                <CardContent style={{ textAlign: "center" }}>
                                    <ImageWithError
                                        product
                                        src={`${CLOUDFRONT_URL}${product.i}`}
                                        height={100}
                                        width={100}
                                        alt={product.d}
                                    />
                                </CardContent>
                                <CardContent
                                    style={{ textAlign: "center", padding: 10 }}
                                >
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        {product.d}
                                    </Typography>
                                    <Typography variant="caption" component="div">
                                        {product.c}
                                    </Typography>
                                    <Typography variant="caption" component="div">
                                        {currencyFormat.format(product.p)}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        component="div"
                                    >
                                        Qty {product.q}
                                    </Typography>
                                    <Grid container spacing={1} justify='center'>
                                        <Grid item>
                                            <CopyToClipboardDatagrid text={product.c} size={17.5} />
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title={'View Sales History'} placement="bottom">
                                                <span>
                                                    <IconButton onClick={() => handleDeployMostPurchasedOrders(product.id, product.c, product.d)}>
                                                        <FAIcon type="light" icon="clock-rotate-left" size={17.5} button noMargin />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                )) || (
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ textAlign: "center" }}>
                            No purchases have been made within the selected date range
                        </Typography>
                    </Grid>
                )}
            </Grid>
        )
    )
}

export default MostPurchased;