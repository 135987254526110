import React from 'react';
import uuidv4 from "uuid/v4";
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import WildixCallCentre from 'Components/Voip/WildixVoip/WildixCallCentre';
import WildixCallDetails from 'Components/Voip/WildixVoip/WildixCallDetails';
import WildixCallDynamicIsland from 'Components/Voip/WildixVoip/WildixCallDynamicIsland';
import WildixCalls from 'Components/Voip/WildixVoip/WildixCalls';
import WildixCallDtmf from 'Components/Voip/WildixVoip/WildixCallDtmf';
import WildixCallTransfer from 'Components/Voip/WildixVoip/WildixCallTransfer';
import WildixDialler from 'Components/Voip/WildixVoip/WildixDialler';
import WildixPhoneBook from 'Components/Voip/WildixVoip/WildixPhoneBook';
import WildixRecentCalls from 'Components/Voip/WildixVoip/WildixRecentCalls';
import WildixQueueDetails from 'Components/Voip/WildixVoip/WildixQueueDetails';
import WildixSetDevice from 'Components/Voip/WildixVoip/WildixSetDevice';
import WildixSetStatus from 'Components/Voip/WildixVoip/WildixSetStatus';
import { isMobile } from 'Functions/MiscFunctions';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { VOIP_DEBUG, VOIP_DEBUG_NUMBER, VOIP_APP_ID, VOIP_REDIRECT_URL, VOIP_PBX_URL, VOIP_PRIORITY_QUEUE, VOIP_ENABLE_RECORDING } from 'Constants';

import WildixContext from "Context/WildixContext";
import WildixMenu from './WildixMenu';
import WildixCallNotes from './WildixCallNotes';
import { START_RECORDING, STOP_RECORDING } from 'Helpers/WildixHelper';

const styles = () => ({
    paper: {
        marginTop: 'calc(env(safe-area-inset-top) + 50px)',
        width: '100%',
        maxWidth: isMobile() ? '100%' : 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        boxShadow: '-4px 0 4px -2px rgba(0,0,0,0.2)'
    },
    smallHeaderPaper: {
        marginTop: 'calc(env(safe-area-inset-top) + 0px)!important',
        width: '100%',
        maxWidth: isMobile() ? '100%' : 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        boxShadow: '-4px 0 4px -2px rgba(0,0,0,0.2)'
    },
    callsPaper: {
        marginTop: 'calc(env(safe-area-inset-top) + 50px)',
        width: '100%',
        maxWidth: isMobile() ? '100%' : 'calc(75% - 400px)',
        marginRight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        boxShadow: '-4px 0 4px -2px rgba(0,0,0,0.2)',
        zIndex: 999999
    },
    callsSmallHeaderPaper: {
        marginTop: 'calc(env(safe-area-inset-top) + 0px)!important',
        width: '100%',
        maxWidth: isMobile() ? '100%' : 'calc(75% - 400px)',
        marginRight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        boxShadow: '-4px 0 4px -2px rgba(0,0,0,0.2)',
        zIndex: 999999
    }
})
 
const initialState = () => {
    
    let callLogs = [];

    let x = localStorage.getItem('callLogs');
    if(x) {
        callLogs = JSON.parse(x);
    }

    return ({
        activeCall: null,
        activeCalls: [],
        authorized: false,
        callCentre: null,
        callDtmf: null,
        callRecord: null,
        callTransfer: null,
        colleagues: null,
        connected: false,
        connecting: false,
        debug: VOIP_DEBUG,
        debugNumber: VOIP_DEBUG_NUMBER,
        device: false,
        drawer: false,
        enableNotifications: false,
        excludeRecentInternal: 0,
        error: false,
        errorLog: [],
        callData: [],
        callLogs, 
        calls: [],
        devices: [],
        lastDialled: null,
        keys: {
            calls: uuidv4()
        },
        presence: {
            available: false,
            code: null,
            address: null,
            icon: null,
            class: null,
            color: null,
            message: null,
            status: null,
            until: null,
        },
        profile: {
            name: null,
            extension: null,
            jid: null,
            license: null
        },
        queues: [],
        recent: [],
        roster: [],
        rosterPresence: {},
        selectedDevice: null,
        showMenu: false,
        wildix: {
            applicationId: VOIP_APP_ID,
            redirectUri: VOIP_REDIRECT_URL,
            pbxUrl: VOIP_PBX_URL
        },
    })
}

class WildixVoip extends React.Component {
    static contextType = WildixContext;
    constructor(props) {
        super(props);
        this.state = initialState();
        this.callDataTimeout = null;
        this.callLogTimeout = null;
        this.callLogActionTimeout = null;
        this.callHistoryTimeout = null;
        this.callRecordTimeout = null;
        this.callUpdateTimeout = null;
        this.errorTimeout = null;
        this.interval = null;
        this.notification = null;
        this.timeout = null;
        this.wtapi = null;
        
        this.broadcast = new BroadcastChannel('WildixVoip');

        this.broadcast.onmessage = event => {
            if(this.state.debug) {
                console.log('Wildix - Broadcast Message received', event)
            }
            if(!event.isTrusted) {
                return;
            }
            if(event.origin !== window.location.origin) {
                return;
            }
            let x = localStorage.getItem('callLogs');
            if(x) {
                x = JSON.parse(x);
                let idx = x.findIndex(c => c.id === event.data);
                let data = x[idx];
                if(idx !== -1) {
                    let callLogs = [...this.state.callLogs];
                    idx = callLogs.findIndex(c => c.id === event.data);
                    if(idx !== -1) {
                        callLogs[idx] = data;
                        this.setState({ callLogs });
                    }
                }
            }
            if (this.state.activeCall) {
                this.handleRefreshCallData(this.state.activeCall);
            }
        }    

    }

    componentDidMount = () => {
        window.oauth2Callback = (params) => {
            if(this.state.debug) {
                console.log('Wildix - OAuth Callback', params);
            }
            if (!this.wtapi) {
                this.handleResetComponent(true);
            } else {
                this.wtapi?.handleCallbackData?.(params);
            }
        } 
        this.initialize();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(!this.interval && !_.isEmpty(this.state.calls)) {
            this.handleActiveCallsInterval(true);
        } else if(this.interval && _.isEmpty(this.state.calls)) {
            this.handleActiveCallsInterval(false);
        }
        if(this.context.dial !== false && this.context.dial !== null) {
            if(this.context.dial === START_RECORDING) {
                this.context.setDial(null);
                if(VOIP_ENABLE_RECORDING) {
                    if(this.state.connected && this.state.activeCall) {
                        this.onCallStartRecord(this.state.activeCall);
                    }
                }
            } else if(this.context.dial === STOP_RECORDING) {
                this.context.setDial(null);
                if(VOIP_ENABLE_RECORDING) {
                    if(this.state.connected && this.state.activeCall) {
                        this.onCallStopRecord(this.state.activeCall);
                    }
                }
            } else {
                const phoneNumber = this.state.debug ? this.state.debugNumber : this.context.dial;
                if(this.state.debug) {
                    console.log(`Wildix - Context Call - ${this.context.dial} - Diverted to ${this.state.debugNumber}`);
                }
                this.context.setDial(null);
                if(this.state.connected) {
                    this.onDial(phoneNumber);
                }
            }
        }
        if(!this.notification && this.state.activeCall?.isRinging?.() && this.state.activeCall?.getDirection() === 'incoming') {
            const data = this.state.callData?.find?.(c => c.ch === this.state.activeCall.getChannel())?.dt ?? null;
            if (this.state.enableNotifications) {
                if(this.notification) {
                    this.handleCloseNotification();
                }
                if((this.props.config?.voip?.disableDesktop ?? 0) === 0) {
                    this.notification = new Notification((data?.fn || data?.ln) ? `${data.fn} ${data.ln}\n${data.cn}\n${this.state.activeCall.getCalleeNumber()}` : data?.cn ? `${data.cn}\n${this.state.activeCall.getCalleeNumber()}` : this.state.activeCall.getCalleeNumber() === 'anonymous' ? 'Anonymous' : this.state.activeCall.getCalleeName(), {
                        icon: '/images/phone.svg',
                        body: 'Incoming Call',
                        requireInteraction: true,
                        renotify: true,
                        tag: `wildix-voip-${this.state.activeCall.getChannel()}-${this.state.activeCall.getCalleeNumber()}}`,
                    });
                    this.notification.onclick = (event) => {
                        window.focus();
                        this.notification?.close?.();
                    }
                }
            }
        }
        if(this.notification && (!this.state.activeCall || !this.state.activeCall?.isRinging?.())) {
            this.handleCloseNotification();
        }
        if(!_.isEqual(prevState.callLogs, this.state.callLogs)) {
            localStorage.setItem('callLogs', JSON.stringify(this.state.callLogs));
        }
        if(this.props.config?.voip?.disableDesktop === 1 && this.notification) {
            this.handleCloseNotification();
        }
    }

    componentWillUnmount = () => {
        if(this.state.connected) {
            this.disconnect();
        }
        if(this.callDataTimeout) {
            clearTimeout(this.callDataTimeout);
        }
        if(this.callHistoryTimeout) {
            clearTimeout(this.callHistoryTimeout);
        }
        if (this.callLogTimeout) {
            clearTimeout(this.callLogTimeout);
        }
        if (this.callLogActionTimeout) {
            clearTimeout(this.callLogActionTimeout);
        }
        if(this.callRecordTimeout) {
            clearTimeout(this.callRecordTimeout);
        }
        if (this.callUpdateTimeout) {
            clearTimeout(this.callUpdateTimeout);
        }
        if(this.errorTimeout) {
            clearTimeout(this.errorTimeout);
        }
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
        if(this.interval) {
            clearInterval(this.interval);
        }
        this.handleCloseNotification();
    }

    
    /* 
     *   Wildix API Authentication, Connection, Configuration and Initialization
     */
    initialize = (initOnly = false) => {

        if(typeof window.WTAPI !== 'undefined') {

            if(this.state.debug) {
                console.log('Wildix - Initializing WTAPI')
            }

            this.wtapi = new window.WTAPI();

            this.wtapi?.on("connected", this.onConnected);
            this.wtapi?.on("disconnected", this.onDisconnected);
        
            this.wtapi?.on("oauthAuthorizationError", this.onOauthAuthorizationError);
            this.wtapi?.on("oauthAuthorizationSuccess", this.onOauthAuthorizationSuccess);
            this.wtapi?.on("restoreCredentials", this.onRestoreCredentials);

            this.wtapi?.presence.on("presence_changed", this.onPresenceChanged);
            this.wtapi?.presence.on("personal_presence_changed", this.onPersonalPresenceChanged);
        
            this.wtapi?.telephony.on("devices_changed", this.onDevicesChanged);
            this.wtapi?.telephony.on("call_added", this.onCallAdded);
            this.wtapi?.telephony.on("call_terminated", this.onCallTerminated);
            this.wtapi?.telephony.on("call_updated", this.onCallUpdated);
        
            this.wtapi?.queues.on("queue_added", this.onQueueAdded);
            this.wtapi?.queues.on("queue_removed", this.onQueueRemoved);
            this.wtapi?.queues.on("user_join", this.onQueueUserJoin);
            this.wtapi?.queues.on("user_left", this.onQueueUserLeft);
            // this.wtapi?.queues.on("call_added", this.onQueueCallAdded);
            // this.wtapi?.queues.on("call_removed", this.onQueueCallRemoved);
            // this.wtapi?.queues.on("member_added", this.onQueueMemberAdded);
            // this.wtapi?.queues.on("member_updated", this.onQueueMemberUpdated);
            // this.wtapi?.queues.on("member_removed", this.onQueueMemberRemoved);

            this.wtapi?.setOptions({
                applicationId: this.state.wildix.applicationId,
                redirectUri: this.state.wildix.redirectUri,
                pbxUrl: this.state.wildix.pbxUrl,
            });

            if(!initOnly) {
            
                if(this.state.debug) {
                    console.log('Wildix - Attempt restore credentials')
                }
    
                let hash = window.location.hash;
                if(hash && hash.includes('wtapiAuth')) {
                    this.wtapi?.handleAuthorizationResponse();
                } else {
                    let auth = localStorage.getItem("wtapi");
                    if (auth) {
                        auth = JSON.parse(auth);
                        if (auth?.expires) {
                            const endOfDay = new Date().setHours(23, 59, 59, 999);
                            if (auth.expires < endOfDay) {
                                this.wtapi.deleteSavedCredentials();
                            } else {
                                this.wtapi.restoreSavedCredentials();
                            }
                        }
                    }
                }
    
            }
    
        } 

    }
    
    connect = () => {    

        if(this.state.debug) {
            console.log("Wildix - Connecting...");
        }  

        if(this.timeout) {
            clearTimeout(this.timeout);
        }

        if(!this.wtapi) {
            this.initialize();            
        }

        this.setState({
            connecting: true
        }, () => {
            this.wtapi?.connect?.();
            this.timeout = setTimeout(() => {
                this.setState({
                    connecting: false
                })
            }, 20000);
        })

    }

    disconnect = (deleteStoredCredentials = false) => {

        if(this.state.debug) {
            console.log("Wildix - Disconnecting...");
        }

        if(deleteStoredCredentials) {
            this.wtapi?.deleteSavedCredentials?.();
        }

        this.setState({
            authorized: false,
            connected: false,
            drawer: !!this.state.error
        }, () => {
            this.wtapi?.disconnect?.();
            this.context.setDial(false);
        });

    }

    onOauthAuthorizationSuccess = data => {

        if(this.state.debug) {
            console.log("Wildix - OAuth Success", data);
        }

        if(this.timeout) {
            clearTimeout(this.timeout);
        }

        this.handleToggleDrawer();
        window.open(VOIP_PBX_URL, '_blank', 'noopener');
    }

    onOauthAuthorizationError = data => {
        
        const error = `Wildix - OAuth Error: ${data.error}`;

        if(this.state.debug) {
            console.error(error, data);
        }
        
        this.onAuthorizationFailure(error);
        
    }
    
    onAuthorizationFailure = error => {

        this.setState({
            authorized: false,
            connected: false,
            connecting: false
        }, () => {

            this.wtapi?.disconnect?.();
            this.wtapi = null;

            this.onError(error);

            if(this.timeout) {
                clearTimeout(this.timeout);
            }

        });

    }
    
    onRestoreCredentials = data => {
        if (this.state.debug && data) {
            console.log("Wildix - Restoring Session - Credentials", data);
        }
    }
    
    onConnected = () => {

        if(this.state.debug) {
            console.log('Wildix - Connected');
        }

        if(this.timeout) {
            clearTimeout(this.timeout);
        }

        this.wtapi?.roster.getRoster?.(this.handleSetRoster);

        this.wtapi?.queues?.subscribe?.();

        if (!("Notification" in window)) {
            if(this.state.debug) {
                console.log("Wildix - This browser does not support desktop notification");
            }
        } else {
            Notification.requestPermission(result => {
                this.setState({
                    enableNotifications: result === "granted"
                })
            });
        }

        this.setState({
            authorized: true,
            connected: true,
            connecting: false,
            profile: {
                ...this.state.profile,
                extension: this.wtapi?.getExtension?.(),
                jid: this.wtapi?.getJid?.(),
                license: this.wtapi?.getLicenseType?.()
            },
            showMenu: false
        }, () => {
            if(!this.errorTimeout) {
                this.errorTimeout = setTimeout(() => {
                    this.setState({
                        errorLog: []
                    })
                }, 2500)
            }  
        })
    }
    
    onDisconnected = error => {

        if(this.state.debug) {
            console.log('Wildix - Disconnected');
        }
        
        if(this.timeout) {
            clearTimeout(this.timeout);
        }

        this.handleCloseNotification();

        if (!this.state.authorized) {
            this.onAuthorizationFailure(error);
        } else {
            this.setState({
                connected: false
            })
        }

    }

    onError = error => {

        if(this.timeout) {
            clearTimeout(this.timeout);
        }

        if (this.wtapi && this.wtapi?.isConnected?.()) {
            this.setState({
                connected:  true,
                connecting: false
            })
        }
    
        if (!error || error === null) {
            return;
        }
        
        if(this.state.debug) {
            console.error('Wildix - Error', error);
        }

        if(this.state.errorLog.length >= 5) {
            if(this.errorTimeout) {
                clearTimeout(this.errorTimeout);
            }
            this.setState({
                drawer: true,
                error: true,
                errorLog: []
            })
        } else if(!this.state.error) {
            this.disconnect();
            let errorLog = [...this.state.errorLog, error];
            this.setState({
                errorLog
            }, () => {
                if(this.errorTimeout) {
                    clearTimeout(this.errorTimeout);
                }
                let timeoutQty = this.state.errorLog.length;
                let timeoutDelay = timeoutQty < 2 ? 750 : 1500;
                this.errorTimeout = setTimeout(() => this.handleResetComponent(false), timeoutDelay);
            })
        } else if(this.state.error && !this.state.drawer) {
            this.setState({
                drawer: true
            })
        }
    
    }

    /* 
     *   Wildix Attendant Console
     */
    onCallAdded = call => {

        if(this.state.debug) {
            console.log('Wildix - Call Added', call, call?.getDirection());
        }

        if(this.timeout) {
            clearTimeout(this.timeout);
        }

        let callLog = _.find(this.state.callLogs, { ch: call.getChannel() });

        let callId = callLog?.id ?? null;

        API.get(`/smartSearch/callee`, {
            params: {
                searchString: call.getCalleeNumber(),
                name: call.getCalleeName(),
                channel: call.getChannel(),
                direction: call.getDirection(),
                state: call.getState(),
                callId: callId,
                action: 'callAdded'
            }
        })
        .then(res => {
    
            let {
                calls,
                callData
            } = this.state;
    
            let data = res?.data ?? null;

            calls.push(call);

            callData.push({
                id: data.id ?? 0,
                ch: call.getChannel(),
                dr: call.getDirection(),
                pn: call.getCalleeNumber(),
                dt: data,
            })
    
            this.setState({
                callData,
                calls,
                keys: {
                    ...this.state.keys,
                    calls: uuidv4()
                }
            }, () => {
                this.handleRefreshActiveCalls()
            })

        })

    }

    onCallUpdated = (call) => {
        if (this.callUpdateTimeout) {
            clearTimeout(this.callUpdateTimeout);
        }
        if (!call.getCalleeNumber().startsWith("0")) {
            this.callUpdateTimeout = setTimeout(() => {
                this.handleCallUpdate(call);
            }, 200);
        }
    };

    handleCallUpdate = async (call) => {
        let { calls, callLogs, debug } = this.state;

        if (debug) {
            console.log("Wildix - Call Updated", call, call?.getDirection());
        }

        let idx = calls.findIndex((c) => c.getChannel() === call.getChannel());
        if (idx > -1) {
            calls[idx] = call;
        }

        idx = callLogs.findIndex((c) => c.ch === call.getChannel());
        let answeredId = null;
        if (idx > -1) {
            if (!callLogs[idx].an && call.getState() === "up") {
                answeredId = callLogs[idx].id;
                callLogs[idx].an = true;
            }
        }

        let callLog = _.find(this.state.callLogs, { ch: call.getChannel() });
        let callLogUpdate = false;
        let lock = false;
        if (!callLog) {
            if (this.callLogTimeout) {
                clearTimeout(this.callLogTimeout);
            }
            lock = await API.put(`/misc/mutexLock/set`, { key: `callLog-${call.getChannel()}-${call.getCalleeNumber()}`, time: 20 });
            if (lock?.data?.success) {
                this.callLogActionTimeout = setTimeout(() => {
                    this.logCall(call);
                }, 500);
            } else {
                this.callLogTimeout = setTimeout(() => {
                    let savedLogs = localStorage.getItem("callLogs");
                    if (savedLogs) {
                        savedLogs = JSON.parse(savedLogs);
                        if (savedLogs.length > 0) {
                            let idx = savedLogs.findIndex((c) => c.ch === call.getChannel());
                            if (idx > -1) {
                                callLog = savedLogs[idx];
                                callLogs.push(callLog);
                                this.setState({
                                    callLogs,
                                });
                            }
                        }
                    }
                }, 1250);
            }
        } else {
            if (callLog.pn !== call.getCalleeNumber()) {
                lock = await API.put(`/misc/mutexLock/set`, { key: `callLog-${call.getChannel()}-${call.getCalleeNumber()}`, time: 20 });
                callLogUpdate = true;
            }
        }

        this.setState(
            {
                calls,
                callLogs,
                keys: {
                    ...this.state.keys,
                    calls: uuidv4(),
                },
            },
            () => {
                this.handleRefreshActiveCalls();
                if (callLogUpdate || !_.find(this.state.callData, { ch: call.getChannel() })) {
                    if (this.callDataTimeout) {
                        clearTimeout(this.callDataTimeout);
                    }
                    this.callDataTimeout = setTimeout(() => {
                        this.handleRefreshCallData(call, lock?.data?.success ?? false);
                    }, 750);
                }
                if (answeredId) {
                    this.logCallAnswered(answeredId);
                }
            }
        );
    };

    onCallTerminated = call => {

        if(this.state.debug) {
            console.log('Wildix - Call Terminated', call, call?.getDirection());
        }

        if (this.callRecordTimeout) {
            clearTimeout(this.callRecordTimeout);
        }

        if (this.callUpdateTimeout) {
            clearTimeout(this.callUpdateTimeout);
        }

        let {
            callData,
            callDtmf,
            callLogs,
            callTransfer,
            calls,
        } = this.state;

        let idx = calls.findIndex(c => c.getChannel() === call.getChannel());
        if(idx > -1) {
            calls.splice(idx, 1);
        }

        idx = callData.findIndex(c => c.ch === call.getChannel());
        if(idx > -1) {
            callData.splice(idx, 1);
        }

        idx = callLogs.findIndex(c => c.ch === call.getChannel());
        if(idx > -1) {
            this.logCallTerminated(callLogs[idx].id, call.getCalleeNumber(), call?.getHangupCauseText?.());
            callLogs.splice(idx, 1);
        }

        if(callDtmf) {
            if(call.getChannel() === callDtmf?.getChannel()) {
                callDtmf = null;
            }
        }

        if(callTransfer) {
            if(call.getChannel() === callTransfer?.getChannel()) {
                callTransfer = null;
            }
        }

        this.setState({
            callData,
            callDtmf,
            callTransfer,
            calls,
            keys: {
                ...this.state.keys,
                calls: uuidv4()
            }
        }, () => {
            this.handleRefreshActiveCalls()
            if(this.state.drawer) {
                this.handleRefreshRecentCalls(true);
            }
        })

    }

    /* 
     *   Wildix Attendant Console
     */
    onDevicesChanged = dev => {
    
        let {
            debug,
            selectedDevice
        } = this.state;

        if(debug) {
            console.log('Wildix - Devices Changed', dev);
        }

        const devices = [];
        _.each(dev, d => {
            let nm = d.getName();
            if(nm === "Zero Distance") {
                nm = "Web Browser"
            }
            nm = nm.replace("Wildix", "")
            devices.push({
                nm,
                id: d.getId(),
                ua: d.getUserAgent(),
                dv: d
            })
        });

        if(selectedDevice) {
            const defaultId = selectedDevice?.getId?.() ?? -1;
            const device = _.find(devices, { id: defaultId });
            if(device) {
                selectedDevice = device.dv;
            } else {
                if(!_.isEmpty(devices)) {
                    selectedDevice = devices[0].dv;
                    let nm = devices[0]?.nm;
                    nm = nm?.includes("iOS") ? "iPhone" : nm?.includes("Android") ? "Android" : nm?.split("/")[0];
                    this.props.deploySnackBar(`warning`, `Wildix VOIP Device set to ${nm} (Existing Device Disconnected)`);
                } else {
                    selectedDevice = null;
                    if(this.state.connected) {
                        this.props.deploySnackBar(`warning`, `Wildix VOIP Device disconnected - no other devices available`);
                    }
                    this.handleCloseNotification();
                }
            }
        } else {
            if(!_.isEmpty(devices)) {
                selectedDevice = devices[0].dv;
                let nm = devices[0]?.nm;
                nm = nm?.includes("iOS") ? "iPhone" : nm?.includes("Android") ? "Android" : nm?.split("/")[0];
                this.props.deploySnackBar(`success`, `Wildix VOIP Device connected and set to ${nm}`);
            } else {
                selectedDevice = null;
            }
        }

        const device = selectedDevice ? true :  false;  

        this.context.setDial(device ? null : false);

        this.setState({
            device,
            devices,
            selectedDevice
        })
        
    }

    onSelectedDeviceChange = selectedDevice => {
        this.setState({
            device: true,
            selectedDevice
        })
    }

    /*
     * Wildix Call Telephony
     */
    onCallAnswer = call => {

        const {
            debug,
            device,
            selectedDevice
        } = this.state;

        if(!device) {
            return;
        }

        if(debug) {
            console.log('Wildix - Answer Call', call);
        }

        this.wtapi?.telephony?.answer?.(selectedDevice, call, this.onError);

        if (this.state.drawer && call?.getDirection?.() === "incoming") {
            this.setState({
                drawer: false
            })
        }

    }

    onCallDecline = call => {

        const {
            debug
        } = this.state;
        
        if(debug) {
            console.log('Wildix - Decline Call', call);
        }

        this.wtapi?.telephony.hangup(call, this.onError);

    }

    onCallHangup = call => {

        const {
            debug
        } = this.state;
        
        if(debug) {
            console.log('Wildix - Hang Up Call', call);
        }

        this.wtapi?.telephony.hangup(call, this.onError);

    }

    onCallHold = call => {

        const {
            debug
        } = this.state;
        
        if(debug) {
            console.log('Wildix - Hold Call', call);
        }

        this.wtapi?.telephony.hold(call, this.onError);

    }

    onCallResume = call => {

        const {
            debug
        } = this.state;
        
        if(debug) {
            console.log('Wildix - Resume Call', call);
        }

        this.wtapi?.telephony.resume(call, this.onError);

    }

    onCallAttendantTransfer = (call, callTo) => {

        const {
            debug
        } = this.state;

        const {
            deploySnackBar
        } = this.props;
        
        if(debug) {
            console.log('Wildix - Attendant Transfer', call, callTo);
        }

        this.wtapi?.telephony.bridge(call, callTo, err => !err && deploySnackBar(`success`, `Call successfully transferred`));

    }

    onCallStartRecord = call => {

        const {
            debug
        } = this.state;
        
        if(debug) {
            console.log('Wildix - Start Recording Call', call);
        }

        if (!call.isRecorded()) {
            this.wtapi?.telephony?.startRecord?.(call, this.onError);
        }

    }

    onCallStopRecord = call => {

        const {
            debug
        } = this.state;
        
        if(debug) {
            console.log('Wildix - Stop Recording Call', call);
        }

        if (call.isRecorded()) {
            this.wtapi?.telephony?.stopRecord?.(call, this.onError);
        }

    }

    /* 
     *   Wildix Dialler
     */
    onDial = to => {

        const {
            device,
            selectedDevice
        } = this.state;

        if(!device) {
            return;
        }

        this.setState({
            drawer: false,
            lastDialled: to   
        }, () => {
            this.wtapi?.telephony.call(selectedDevice, to, this.onError);
        })

    }
    
    /* 
     *   Wildix Presence
     */
    onChangePresence = ({code, message, until}) => {

        let builder = new window.WTAPI.Presence.Builder();

        switch(code) {
            case 1:
                builder.setAway();
                break;
            case 2:
                builder.setDND();
                break;
            default:
                /* No action require - online */
        }

        if(message.length > 0) {
            builder.setStatusMessage(message);
        }

        if(until) {
            builder.setStatusUntil(new Date(until));
        }

        this.wtapi?.presence.changePersonalPresence(builder.build(), this.onChangePresenceCallback);

    }

    onChangePresenceCallback = presence => {

        const {
            profile: {
                extension
            }
        } = this.state;

        if(presence) {
            
            const   status = presence.isAway() ? "Away" : presence.isDND() ? "Do Not Disturb" : "Available",
                    until = presence.isStatusUntilAvailable() ? this.handleFormatDateUntil(presence.getStatusUntil()) : null;

            this.props.deploySnackBar("success", `Extension ${extension} - Set to ${status}${until ? ` until ${until}` : ``}`);

        }

    }

    onPresenceChanged = (user, presence) => {
            
        const {
            debug,
            profile: {
                name,
                extension
            }
        } = this.state;

        if(user?.getExtension() === extension) {

            if(!name) {

                let newName = user?.getName() ?? (this.props.staff?.name !== '' ? this.props.staff?.name : null);

                if(debug && newName) {
                    console.log('Wildix - Profile Name Detected', newName);
                }

                if(newName) {
                    this.setState({
                        profile: {
                            ...this.state.profile,
                            name: newName
                        }
                    })
                }

            }

        } else {

            let {
                rosterPresence
            } = this.state;

            rosterPresence[user?.getExtension()] = presence;
            
            this.setState({
                rosterPresence
            }, () => {

                if(debug) {
                    console.log('Wildix - Presence Changed', user?.getExtension(), presence);
                }

            })

        }

    }

    onPersonalPresenceChanged = presence => {

        const location      = presence.isLocationAvailable() ? presence.getLocation() : null;
        const address       = presence.isLocationAvailable() ? location?.getAddress?.()?.split?.(',')?.slice?.(2) ?? null : null;

        this.setState({
            presence: {
                address,
                available: presence._show === 0,
                code: this.handleGetPresenceCode(presence),
                icon: this.handleGetPresenceIcon(presence),
                class: this.handleGetPresenceClassName(presence),
                color: this.handleGetPresenceColor(presence),
                status: this.handleGetPresenceText(presence),
                message: presence.isStatusMessageAvailable() ? presence.getStatusMessage() : null,
                until: presence.isStatusUntilAvailable() ? this.handleFormatDateUntil(presence.getStatusUntil()) : null,
            },
        }, () => {
            if(this.state.debug) {
                console.log("Wildix - Personal Presence Changed", presence);
            }
        })
        
    }

    /*
     * Wildix Queues
     */
    onQueueAdded = queue => {

        if(this.state.debug) {
            console.log('Wildix - Queue Added', queue);
        }

        let {
            queues
        } = this.state;

        queues.push({
            calls: [],
            users: [],
            queue,
        });

        this.setState({
            queues
        })

    }
    
    onQueueRemoved = queue => {

        if(this.state.debug) {
            console.log('Wildix - Queue Removed', queue);
        }

        let {
            queues,
        } = this.state;

        let idx = queues.findIndex(q => q.queue?.getId?.() === queue.getId());
        if(idx > -1) {
            queues.splice(idx, 1);
        }

        this.setState({
            queues
        })

    }

    onQueueUserJoin = (queue, user) => {

        if(this.state.debug) {
            console.log('Wildix - Queue User Joined', queue, user);
        }

        let {
            queues
        } = this.state;

        let idx = queues.findIndex(q => q.queue?.getId?.() === queue.getId());
        if(idx > -1) {
            queues[idx]?.users.push(user);
        }

        this.setState({
            queues
        })

    }
    
    onQueueUserLeft = (queue, user) => {

        if(this.state.debug) {
            console.log('Wildix - Queue User Left', queue, user);
        }

        let {
            queues
        } = this.state;

        let idx = queues.findIndex(q => q.queue?.getId?.() === queue.getId());
        if(idx > -1) {
            let userIdx = queues[idx].users.findIndex(u => u.getChannel?.() === user.getChannel());
            if(userIdx > -1) {
                queues[idx]?.users.splice(userIdx);
            }
        }

        this.setState({
            queues
        })

    }

    /*
     * Wildix WMS API Integration
     */
    wmsApiSendDtmfCode = async (channel, dtmfCode) => {
        let auth = localStorage.getItem("wtapi");
        if (auth) {
            auth = JSON.parse(auth);
            
            const send = async (_channel, _code) => {
                await fetch(`${auth.pbxUrl}/api/v1/Calls/${encodeURIComponent(_channel)}/Dtmf`, {
                    method: "post",
                    headers: new Headers({
                        Authorization: `Bearer ${auth.accessToken}`,
                        "Content-Type": "application/x-www-form-urlencoded",
                    }),
                    body: new URLSearchParams({
                        dtmf: _code,
                    }),
                });
            }

            if(Array.isArray(dtmfCode)) {
                for(let code of dtmfCode) {
                    await send(channel, code);
                    await new Promise((resolve) => setTimeout(resolve, 500));
                }
            }
            else {
                await send(channel, dtmfCode);
            }

        }
    };

    handleCallContinuity = () => {
        this.wmsApiSendDtmfCode(this.state.activeCall.getChannel(), ['*','5']);
    }
    
    handleStartAutoRecording = (call) => {
        if (!this.handleCheckIsCallInternal() && call && !call.isRecorded()) {
            if (this.callRecordTimeout) {
                clearTimeout(this.callRecordTimeout);
            }
            this.handleStartAutoRecordingCheck(call, 1);
        }
    };

    handleStartAutoRecordingCheck = (call, idx) => {
        if (this.callRecordTimeout) {
            clearTimeout(this.callRecordTimeout);
        }

        // let startRecording = true;

        // if (call.isRinging()) {
        //     startRecording = false;
        // }

        // if (call.getState() !== "up") {
        //     startRecording = false;
        // }

        // if (startRecording) {
        if (!call.isRecorded()) {
            // if (call.isIncoming()) {
            //     this.callRecordTimeout = setTimeout(() => {
            //         this.onCallStartRecord(call);
            //     }, 500);
            // } else {
            this.onCallStartRecord(call);
            // }
        }
        // } else {
        //     this.callRecordTimeout = setTimeout(() => {
        //         this.handleStartAutoRecordingCheck(call, idx + 1);
        //     }, 500);
        // }
    };

    // handleStartAutoRecording = (call) => {
    //     if(VOIP_ENABLE_RECORDING) {
    //         if (!this.handleCheckIsCallInternal() && call && !call.isRecorded()) {
    //             if (this.callRecordTimeout) {
    //                 clearTimeout(this.callRecordTimeout);
    //             }
    //             this.callRecordTimeout = setTimeout(() => {
    //                 this.handleStartAutoRecordingCheck(call, 1);
    //             }, 500);
    //         }
    //     }
    // };

    // handleStartAutoRecordingCheck = (call, idx) => {
    //     if (this.callRecordTimeout) {
    //         clearTimeout(this.callRecordTimeout);
    //     }

    //     let startRecording = true;

    //     if (call.isRinging()) {
    //         startRecording = false;
    //     }

    //     if (call.getState() !== "up") {
    //         startRecording = false;
    //     }

    //     if (startRecording) {
    //         if (!call.isRecorded()) {
    //             if (call.isIncoming()) {
    //                 this.callRecordTimeout = setTimeout(() => {
    //                     this.onCallStartRecord(call);
    //                 }, 500);
    //             } else {
    //                 this.onCallStartRecord(call);
    //             }
    //         }
    //     } else {
    //         this.callRecordTimeout = setTimeout(() => {
    //             this.handleStartAutoRecordingCheck(call, idx + 1);
    //         }, 500);
    //     }
    // };

    handleCheckIsCallInternal = () => {
        if(this.state.activeCall) {
            const callee = this.state.activeCall.getCalleeNumber();
            if(callee) {
                return callee.startsWith('2') && callee.length === 3;
            }
        }
    }

    handleToggleStatus = () => {
        this.onChangePresence({code: this.state.presence?.code === 2 ? 0 : 2, message: "", until: null});
    }

    /*
     * Component functionality
     */
    handleActiveCallsInterval = (hasCalls) => {
        if(hasCalls && !this.interval) {
            this.interval = setInterval(() => {
                this.setState({
                    keys: {
                        ...this.state.keys,
                        calls: uuidv4()
                    }
                }, this.handleRefreshActiveCalls)
            }, 1000);
        } else if(!hasCalls) {
            this.timeout = setTimeout(() => {
                if(this.interval) {
                    clearInterval(this.interval);
                    this.interval = null;
                }
                this.setState({
                    activeCall: initialState().activeCall,
                    activeCalls: initialState().activeCalls,
                    callData: initialState().callData
                })
            }, 1000)
        }
    }

    handleDeployStatusChange = () => {
        const {
            presence: {
                code,
                message,
                until
            }
        } = this.state;
        this.props.deployDialog(
            <WildixSetStatus
                currCode={code}
                currMessage={message}
                currUntil={until}
                changeStatus={this.onChangePresence}
            />, false, 'Set Phone Status', 'standard', 'xs', false, true
        )
    }

    handleFormatDateUntil = date => {
        return date.getFullYear() + '-' + (date.getMonth() < 10 ? '0' : '') + (date.getMonth() + 1) + '-' + (date.getDate() < 10 ? '0' : '') + date.getDate() + ' ' + (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    }

    getCallActions = (call, customerId = null, isCustomerAccount = 0) => {

        let ca = {
            answer: false,
            decline: false,
            hangup: false,
            hold: false,
            resume: false,
            startRecord: false,
            stopRecord: false,
            transfer: false,
            conference: false,
            sendDtmf: false,
            updateNotes: false
        };

        let callLogId = null,
            callLogIdx = null;

        const isInternal = this.handleCheckIsCallInternal();

        switch (call.getState()) {

            case "ring":

                if(call.isIncoming()) {
                    ca.answer = true;
                    ca.decline = true;
                } else {
                    ca.hangup = true;
                }

            break;

            default:

                ca.hangup = true;

                ca.transfer = true;

                if(call.isOnHold()) {
                    ca.resume = true;
                } else {
                    ca.hold = true;
                    ca.sendDtmf = true;
                }

                if (VOIP_ENABLE_RECORDING) {
                    if (!isInternal) {
                        if (call.isRecorded()) {
                            ca.stopRecord = true;
                        } else {
                            ca.startRecord = true;
                        }
                    }
                }

                let idx = _.findIndex(this.state.callLogs, {ch: call.getChannel()});
                if(idx > -1) {
                    let data = _.find(this.state.callData, {ch: call.getChannel()});
                    if(data?.dt?.ci || data?.dt?.si) {
                        ca.updateNotes = true;
                        callLogId = this.state.callLogs[idx].id;
                        callLogIdx = idx;
                    }
                }

            break;

        }

        const actions = [];
        
        if(ca.answer) {
            actions.push(
                {
                    name: 'Answer Call',
                    icon: 'circle-phone',
                    onClick: () => this.onCallAnswer(call),
                    className: 'textSuccess', 
                    type: 'solid'
                }
            )
        }

        if(ca.decline) {
            actions.push(
                {
                    name: 'Decline Call',
                    icon: 'circle-phone-hangup',
                    onClick: () => this.onCallDecline(call),
                    className: 'textError',
                    type: 'solid'
                }
            )
        }

        if(ca.hangup) {
            actions.push(
                {
                    name: 'Terminate Call',
                    icon: 'circle-phone-hangup',
                    onClick: () => this.onCallHangup(call),
                    className: 'textError',
                    type: 'solid'
                }
            )
        }

        if(ca.hold) {
            actions.push(
                {
                    name: 'Hold Call',
                    icon: 'circle-pause',
                    onClick: () => this.onCallHold(call),
                }
            )
        }

        if(ca.resume) {
            actions.push(
                {
                    name: 'Resume Call',
                    icon: 'circle-play',
                    onClick: () => this.onCallResume(call),
                }
            )
        }

        if(ca.startRecord) {
            actions.push(
                {
                    name: 'Start Recording',
                    icon: 'microphone',
                    onClick: () => this.onCallStartRecord(call),
                }
            )
        }

        if(ca.stopRecord) {
            actions.push(
                {
                    name: 'Stop Recording',
                    icon: 'microphone',
                    className: 'textError',
                    onClick: () => this.onCallStopRecord(call),
                }
            )
        }

        if(ca.transfer) {
            actions.push(
                {
                    name: 'Transfer Call',
                    icon: 'shuffle',
                    onClick: () => this.handleCallTransfer(call),
                }
            )
        }

        if(ca.sendDtmf) {
            actions.push(
                {
                    name: 'Keypad',
                    icon: 'grid',
                    onClick: () => this.handleCallDtmfCode(call),
                }
            )
            if(_.size(this.state.devices) > 1) {
                actions.push(
                    {
                        name: "Switch Device",
                        icon: "sync",
                        onClick: () => this.handleCallContinuity(),
                    }
                )
            }
        }

        if(ca.updateNotes && callLogId) {
            actions.push(
                {
                    name: 'Update Notes',
                    icon: 'comments-alt',
                    onClick: () => this.handleUpdateNotes(callLogId, callLogIdx),
                }
            )
        }

        if(customerId) {
            actions.push(
                {
                    name: 'View Customer',
                    icon: 'circle-user',
                    onClick: () => this.props.history.push(`/customers/${customerId}`),
                }
            )
            actions.push(
                {
                    name: 'New Quote',
                    icon: 'circle-q',
                    onClick: () => this.props.history.push(`/quotes/new/${customerId}`),
                }
            )
            if(isCustomerAccount) {
                actions.push(
                    {
                        name: 'New Order',
                        icon: 'circle-o',
                        onClick: () => this.props.history.push(`/sales/new/${customerId}`),
                    }
                )
            }
        }

        return actions;

    }

    getCallStatusClass = call => {
        if(call.isOnHold()) {
            return 'textError fw-400';
        } else if(call.isRinging()) {
            return 'textDefault fw-400';
        } else {
            return 'textSuccess fw-400';
        }
    }

    handleCallDtmfCode = (call = null) => {
        this.setState({
            callDtmf: call
        })
    }

    handleCallTransfer = (call = null) => {
        this.setState({
            callTransfer: call
        }, () => {
            if(call) {
                this.onCallHold(call)
            }
        })
    }

    handleCloseNotification = () => {
        this.notification?.close?.();
        this.notification = null;
    }

    handleGetPresenceCode = presence => {
        return presence._show;
    }

    handleGetWildixIcon = (presence) => {
        let icon = presence?.getStatusIcon();
        if (!presence?.isOnline() && presence?.isRegisteredDevice()) {
            icon = "online";
        }
        return icon;
    };

    handleGetPresenceClassName = (presence, personal = true) => {

        if(!personal && (
            presence?.isConnectedCallAvailable?.()
            || presence?.isRinging?.()
            || presence?.isTalking?.()
            || presence?.isTalkingAndRinging?.()
        )) {
            return 'textError';
        }

        switch (this.handleGetWildixIcon(presence)) {

            case "away":
                return "textWarning";
                
            case "away-offline":
            case "dnd":
            case "dnd-offline":
            case "offline":
                return "textError";

            default:
                return "textSuccess";

        }

    }

    handleGetPresencePriority = (presence) => {

        if(!presence) {
            return 8;
        }

        if(presence?.isConnectedCallAvailable()) {
            return 2;
        }

        switch (this.handleGetWildixIcon(presence)) {

            case "offline":
                return 7;

            case "away-offline":
                return 6;

            case "dnd-offline":
                return 5;

            case "away":
                return 4;

            case "dnd":
                return 3;

            default:
                return 1;

        }

    }

    handleGetPresenceColor = (presence, personal = true) => {

        if(!personal && (
            presence?.isConnectedCallAvailable?.()
            || presence?.isRinging?.()
            || presence?.isTalking?.()
            || presence?.isTalkingAndRinging?.()
        )) {
            return '#d32f2f';
        }

        switch (this.handleGetWildixIcon(presence)) {

            case "away":
                return "#ff9a00";
                
            case "away-offline":
            case "dnd-offline":
            case "dnd":
            case "offline":
                return "#d32f2f";

            default:
                return "#4CAF50";

        }

    }

    handleGetPresenceIcon = (presence, personal = true) => {

        if(!personal && (
            presence?.isConnectedCallAvailable?.()
            || presence?.isRinging?.()
            || presence?.isTalking?.()
            || presence?.isTalkingAndRinging?.()
        )) {
            return 'phone-volume';
        }

        switch (this.handleGetWildixIcon(presence)) {

            case "away":
            case "away-offline":
            case "dnd":
            case "dnd-offline":
            case "offline":
                return "phone-slash";

            default:
                return "phone";

        }

    }

    handleGetPresenceText = (presence, personal = true) => {

        if(!personal && (
            presence?.isConnectedCallAvailable?.()
            || presence?.isRinging?.()
            || presence?.isTalking?.()
            || presence?.isTalkingAndRinging?.()
        )) {
            return 'Busy';
        }

        switch (this.handleGetWildixIcon(presence)) {

            case "away":
                return "Away";

            case "away-offline":
                return "Away (Offline)";

            case "dnd-offline":
                return "Do Not Disturb (Offline)";

            case "offline":
                return "Offline";

            case "dnd":
                return "Do Not Disturb";
                
            default:
                return "Available";

        }

    }
    
    handleRefreshActiveCalls = () => {

        let activeCall = null;

        const activeCalls = this.wtapi?.telephony.getActiveCalls();

        const connected = _.find(activeCalls, c => c.getState() === 'up');

        if(connected) {
            activeCall = connected;
        } else {
            const ringing = _.filter(activeCalls, (c) => c.getState() === "ring");
            if (!_.isEmpty(ringing)) {
                if(ringing.length > 1) {
                    let internalCall = _.find(ringing, (c) => c.getCalleeNumber().startsWith('2') && c.getCalleeNumber().length === 3);
                    if(internalCall) {
                        activeCall = internalCall;
                    } else {
                        activeCall = ringing[0];
                    }
                } else {
                    activeCall = ringing[0];
                }
            } else {
                const onHold = _.filter(activeCalls, (c) => c.getState() === "hold");
                if (!_.isEmpty(onHold)) {
                    activeCall = onHold[onHold.length - 1];
                }
            }
        }

        if(!activeCall && !_.isEmpty(activeCalls)) {
            activeCall = activeCalls[0];
        }

        this.setState({
            activeCall,
            activeCalls
        }, () => {
            if(_.isEmpty(this.state.activeCalls)) {
                if(localStorage.getItem('callLogs')) {
                    localStorage.removeItem('callLogs');
                }
            }
        })

    }

    handleRefreshCallData = (call, forceUpdate = true) => {

        let callData = [...this.state.callData];
        let idx = _.findIndex(callData, { ch: call.getChannel() });

        let callLog = _.find(this.state.callLogs, { ch: call.getChannel() });

        API.get(`/smartSearch/callee`, {
            params: {
                searchString: call.getCalleeNumber(),
                name: call.getCalleeName(),
                channel: call.getChannel(),
                direction: call.getDirection(),
                state: call.getState(),
                callId: callLog?.id ?? null,
                action: 'callUpdated'
            }
        })
        .then(res => {

            if(this.state.debug) {
                console.log('Wildix - Call Data Refreshed', call);
            }

            let data = res?.data ?? null;

            if(idx === -1) {
                callData.push({
                    id: data.id,
                    ch: call.getChannel(),
                    dr: call.getDirection(),
                    pn: call.getCalleeNumber(),
                    dt: data,
                })
            } else {
                callData[idx] = {
                    id: data.id,
                    ch: call.getChannel(),
                    dr: call.getDirection(),
                    pn: call.getCalleeNumber(),
                    dt: data,
                }
            }

            this.setState({
                callData
            }, () => {
                if (forceUpdate) {
                    let callLog = _.find(this.state.callLogs, { ch: call.getChannel() });
                    if (callLog?.id) {
                        this.logCallUpdate(callLog, call);
                    }
                }
            })

        })

    }

    handleRefreshRecentCalls = (timeout = false) => {

        const getRecent = () => API.get(`/misc/calls/recent`, {
                                    params: {
                                        excludeInternal: this.state.excludeRecentInternal
                                    }
                                })
                                .then(res => {
                                    this.setState({recent: res?.data ?? []})
                                });

        if(timeout) {
            this.callHistoryTimeout = setTimeout(getRecent, 1000);
        } else {
            getRecent();
        }

    }

    handleResetComponent = (initOnly = false, clearError = false) => {

        const { 
            debug,
            error,
            errorLog,
        } = this.state;

        if(debug) {
            console.log('Wildix - Reset Component');
        }

        this.setState({
            ...initialState(),
            error: clearError ? null : error,
            errorLog,
        }, () => this.initialize(initOnly))

    }

    handleSetRoster = roster => {

        if(this.state.debug) {
            console.log('Wildix - Roster Set', roster);
        }

        this.setState({
            roster
        })
    }

    handleToggleCallCentre = () => {
        this.setState({
            callCentre: !this.state.callCentre
        })
    }

    handleToggleDrawer = () => {
        this.setState({
            drawer: !this.state.drawer,
            showMenu: false
        }, () => {
            if(this.state.drawer) {
                this.handleRefreshRecentCalls();
            }
        })
    }

    handleToggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        })
    }

    handleTogglePhoneBook = () => {
        this.setState({
            colleagues: !this.state.colleagues
        })
    }

    handleQuickTogglePhoneBook = (e) => {
        if(e && e.stopPropagation) {
            e.stopPropagation();
        }
        this.handleTogglePhoneBook();
    }
    
    handleToggleRecentInternalCalls = () => {
        this.setState({
            excludeRecentInternal: this.state.excludeRecentInternal === 1 ? 0 : 1
        }, this.handleRefreshRecentCalls)
    }

    /*
     * Call Logging
     */
    logCall = async (call) => {
        
        this.handleStartAutoRecording(call);
        
        let data = _.find(this.state.callData, { ch: call.getChannel() });

        API.post(`/misc/calls`, {
            automated: 1,
            callContactId: data?.dt?.id ?? -1,
            callRel: data?.dt?.si ? 'supplier' : 'customer',
            callRelId: data?.dt?.si ?? data?.dt?.ci ?? 0,
            callChannel: call.getChannel(),
            callDestChannel: call.getDestinationChannel(),
            callDestination: this.state.profile?.extension ?? 0,
            callNote: '',
            callStatus: call.isIncoming() ? 'Answered' : 'No Answer',
            callType: call.isIncoming() ? 'Incoming' : 'Outgoing',
            calleeName: data?.dt?.fn || data?.dt?.ln ? `${data.dt.fn} ${data.dt.ln}` : (data?.dt?.cn ? data.dt.cn : call.getCalleeName()),
            calleeCompany: data?.dt?.cn ?? '',
            calleeNumber: call.getCalleeNumber(),
        })
        .then(res => {
            if(res?.data?.id) {
                if(this.state.debug) {
                    console.log('Wildix - Call Logged', res.data.id);
                }
                if(res.data.id > 0) {
                    let callLogs = [...this.state.callLogs];
                    callLogs.push({
                        id: res.data.id ?? 0,
                        ch: call.getChannel(),
                        pn: call.getCalleeNumber(),
                        an: call.isIncoming(),
                        nt: ''
                    })
                    this.setState({
                        callLogs
                    })
                }
            }
        })
    }

    logCallUpdate = (callLog, call) => {

        let data = _.find(this.state.callData, { ch: call.getChannel() });

        API.post(`/misc/calls/${callLog.id}`, {
            callContactId: data?.dt?.id ?? -1,
            callRel: data?.dt?.si ? 'supplier' : 'customer',
            callRelId: data?.dt?.si ?? data?.dt?.ci ?? 0,
            callChannel: call.getChannel(),
            callDestChannel: call.getDestinationChannel(),
            callDestination: this.state.profile?.extension ?? 0,
            callNote: callLog?.nt ?? '',
            callStatus: call.isIncoming() ? 'Answered' : 'No Answer',
            callType: call.isIncoming() ? 'Incoming' : 'Outgoing',
            calleeName: this.handleCheckIsCallInternal() ? call.getCalleeName() : data?.dt?.fn || data?.dt?.ln ? `${data.dt.fn} ${data.dt.ln}` : (data?.dt?.cn ? data.dt.cn : call.getCalleeName()),
            calleeCompany: data?.dt?.cn ?? '',
            calleeNumber: call.getCalleeNumber(),
        })
        .then(res => {
            if(res?.data?.success) {
                if(this.state.debug) {
                    console.log('Wildix - Call Log Updated', callLog.id);
                }
                let callLogs = [...this.state.callLogs];
                let idx = _.findIndex(callLogs, { id: callLog.id });
                if (idx > -1) {
                    let startCallRecording = false;
                    if (callLogs[idx]?.pn !== call.getCalleeNumber()) {
                        startCallRecording = true;
                    }
                    callLogs[idx] = {
                        ...callLogs[idx],
                        ch: call.getChannel(),
                        pn: call.getCalleeNumber(),
                        an: call.isIncoming(),
                    };
                    this.setState(
                        {
                            callLogs,
                        },
                        () => {
                            if (startCallRecording) {
                                this.handleStartAutoRecording(call);
                            }
                        }
                    );
                }

            }
        })
    }

    logCallAnswered = id => {
        API.put(`/misc/calls/${id}/answered`)
    }

    logCallTerminated = (id, number, hangUpCause) => {
        API.put(`/misc/calls/${id}/terminate`, { hangUpCause })
        this.props.deploySnackBar(`success`, `Call to ${number} terminated - ${hangUpCause}`, 1500);
    }

    handleUpdateNotes = (id, idx) => {
        const handleUpdatedNotes = (notes) => {
            let callLogs = [...this.state.callLogs];
            callLogs[idx] = {
                ...callLogs[idx],
                nt: notes
            }
            this.setState({
                callLogs
            }, () => {
                this.broadcast.postMessage(id);
                if (this.state.drawer) {
                    this.handleRefreshRecentCalls();
                }
            });
        }
        this.props.deployDialog(
            <WildixCallNotes callback={handleUpdatedNotes} id={id} />,
            false,
            "Update Call Notes",
            "standard",
            "xs",
            false,
            true
        )
    }

    /* 
     * Render
     */
    render = () => {

        const { 
            activeCall,
            activeCalls,
            authorized,
            callCentre,
            callData,
            callDtmf,
            callLogs,
            callTransfer,
            calls,
            colleagues,
            connected, 
            connecting,
            device,
            devices,
            drawer,
            error,
            excludeRecentInternal,
            presence,
            profile,
            queues,
            recent,
            roster,
            rosterPresence,
            selectedDevice,
            showMenu,
        } = this.state;

        const { 
            classes, 
            ui 
        } = this.props;

        const priorityQueue = !VOIP_PRIORITY_QUEUE ? null : activeCall?.getState?.() === "up" ? _.find(queues, el => el.queue?.getName?.() === VOIP_PRIORITY_QUEUE) : null;

        return (
            <>
                {((!authorized || !connected) && (
                    <Chip
                        avatar={<FAIcon icon="phone-xmark" type="solid" color={'#d32f2f'} size={15} />}
                        clickable
                        color="default" 
                        label={(
                            <Box minWidth={175} pl={0.5}>
                                <Grid container align="left" alignItems="center" spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" component="div" className="fw-400">
                                        {connecting ? `Connecting to Wildix...` : `Connect to Wildix`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        onClick={this.connect}
                        style={{background: '#fff', border: '1px solid #ddd', height: ui?.device?.isTablet ? 32.5 : 34, paddingLeft: 12}}
                        variant="outlined"
                    />
                )) || (profile?.extension && (
                    (device && !_.isEmpty(activeCalls) && (
                        <WildixCallDynamicIsland 
                            activeCalls={activeCalls}
                            call={activeCall}
                            callData={callData}
                            drawer={drawer}
                            getCallActions={this.getCallActions}
                            getCallStatusClass={this.getCallStatusClass}
                            handleToggleDrawer={this.handleToggleDrawer}
                            priorityQueue={priorityQueue}
                        />
                    )) || (
                        <Chip
                            avatar={<FAIcon icon={device ? (presence?.icon ?? 'phone') : 'phone-xmark'} type="solid" color={device ? (presence?.color ?? '#fff') : '#d32f2f'} size={15} />}
                            clickable
                            color="default" 
                            label={(
                                <Box minWidth={190}>
                                    <Grid container align="left" alignItems="center" spacing={0}>
                                        <Grid item xs>
                                            <Typography variant="body2" component="div" className="fw-400">
                                                {profile?.extension} | {device ? presence?.status : 'Unavailable'}
                                            </Typography>
                                        </Grid>
                                            <Grid item>
                                                <Tooltip title="Colleagues">
                                                    <IconButton onClick={this.handleQuickTogglePhoneBook} size="small">
                                                        <FAIcon icon="address-book" button noMargin size={15} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        <Grid item>
                                            <IconButton onClick={this.handleToggleDrawer} size="small">
                                                <FAIcon icon="chevron-down" size={12.5} noMargin button />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                            onClick={this.handleToggleDrawer}
                            style={{background: '#fff', border: '1px solid #ddd', height: ui?.device?.isTablet ? 32.5 : 34, paddingLeft: 12}}
                            variant="outlined"
                        />
                    )
                ))}
                <Drawer
                    anchor="right"
                    classes={{
                        paper: ui?.device?.isMobile ? classes.smallHeaderPaper : classes.paper,
                    }}
                    open={drawer}
                    transitionDuration={0}
                    variant='persistent'
                >
                    <Box p={3}>
                        {(error && (
                            <Grid container spacing={3}>
                                <Grid item xs={12} align="left">
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <FAIcon icon="exclamation-triangle" className="textError" size={15} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="body2" className="fw-500">
                                                Something went wrong.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            Please try again or refresh your browser window<br />
                                            if problems persist.
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container spacing={1} align="left">
                                    <Grid item xs>
                                        <Button 
                                            onClick={() => this.handleResetComponent(false, true)}
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                        >
                                            <FAIcon icon="redo" size={15} buttonPrimary />
                                            Retry
                                        </Button>
                                    </Grid>
                                    <Grid item xs>
                                        <Button 
                                            onClick={() => window.location.reload()}
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                        >
                                            <FAIcon icon="globe" size={15} buttonPrimary />
                                            Refresh
                                        </Button>
                                    </Grid>
                                </Grid>                        
                            </Grid>
                        )) || (
                            <Grid container spacing={3}>                                        
                                <Grid item xs={12}>
                                    <Box position="absolute" right={6} top={8}>
                                        <Grid container spacing={1}>
                                            {!showMenu && (
                                                <Grid item>
                                                    <Tooltip title="Preferences">
                                                        <IconButton onClick={this.handleToggleMenu}>
                                                            <FAIcon icon="cog" noMargin button />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Tooltip title={showMenu ? `Back` : `Close`}>
                                                    <IconButton onClick={showMenu ? this.handleToggleMenu : this.handleToggleDrawer}>
                                                        <FAIcon icon={showMenu ? `arrow-left` : `times`} noMargin button />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item xs={12} align="center">
                                        <Tooltip title={presence?.code === 0 ? 'Switch to DND' : 'Switch to Available'}>
                                                <IconButton onClick={() => this.handleToggleStatus()}>
                                                    <FAIcon
                                                        icon={device ? presence?.icon ?? "phone" : "phone-xmark"}
                                                        type="thin"
                                                        color={device ? presence?.color ?? "#4CAF50" : "#D61F26"}
                                                        size={40}
                                                        noMargin
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <Typography variant="h6">
                                                {profile?.name ?? 'Loading'}{profile?.extension ? ` (${profile?.extension})` : ``}
                                            </Typography>
                                            {device && presence?.address && (
                                                <Typography component="div" variant="body2">
                                                    {presence.address}
                                                </Typography>
                                            )}
                                            {device && (
                                                <Typography component="div" variant="body2">
                                                    {presence?.status}{presence?.until ? ` until ${presence.until}` : ``}
                                                </Typography>
                                            )}
                                            {device && presence?.message?.length > 0 && (
                                                <Typography component="div" variant="body2" dangerouslySetInnerHTML={{__html: presence?.message}} />
                                            )}
                                            {!device && (
                                                <Typography component="div" variant="body2">
                                                    Unavailable
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                {(showMenu && (
                                    <Grid item xs={12}>
                                        <WildixMenu 
                                            handleClose={() => this.handleToggleMenu()}
                                            handleDisconnect={this.disconnect}
                                            handleDeployStatusChange={this.handleDeployStatusChange}
                                            handleToggleCallCentre={this.handleToggleCallCentre}
                                        />
                                    </Grid>
                                )) || (
                                    <>
                                        <Grid item xs={12}>
                                            <WildixSetDevice 
                                                devices={devices} 
                                                selectedDevice={selectedDevice} 
                                                handleSubmit={this.onSelectedDeviceChange} 
                                            />
                                        </Grid>
                                        {device && (
                                            <>
                                                <Grid item xs={12}>
                                                    <WildixDialler 
                                                        device={device} 
                                                        handleSubmit={this.onDial} 
                                                        handleTogglePhoneBook={this.handleTogglePhoneBook}
                                                    />
                                                </Grid>
                                                {(!_.isEmpty(activeCalls) && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <WildixCallDetails
                                                                call={activeCall}
                                                                callData={callData}
                                                                callLogs={callLogs}
                                                                getCallActions={this.getCallActions}
                                                                getCallStatusClass={this.getCallStatusClass}
                                                            />
                                                        </Grid>
                                                        {priorityQueue && (
                                                            <Grid item xs={12}>
                                                                <WildixQueueDetails
                                                                    queue={priorityQueue}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </>
                                                )) || (
                                                    <Grid item xs={12} style={{height: 'calc(100vh - 430px)'}}>
                                                        <WildixRecentCalls
                                                            calls={recent}
                                                            excludeRecentInternal={excludeRecentInternal}
                                                            onDial={this.onDial}
                                                            refresh={this.handleRefreshRecentCalls}
                                                            toggleExcludeInternal={this.handleToggleRecentInternalCalls}
                                                        />
                                                    </Grid>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                        )}
                    </Box>
                </Drawer>
                {drawer && _.size(activeCalls) > 1 && !callTransfer && (
                    <WildixCalls
                        callData={callData}
                        calls={calls}
                        classes={classes}
                        device={device}
                        open
                        getCallActions={this.getCallActions}
                        getCallStatusClass={this.getCallStatusClass}
                    />
                )}
                {callDtmf && (
                    <WildixCallDtmf
                        call={callDtmf}
                        callData={callData}
                        getCallStatusClass={this.getCallStatusClass}
                        handleClose={() => this.handleCallDtmfCode()}
                        handleSubmit={this.wmsApiSendDtmfCode}
                    />
                )}
                {callTransfer && (
                    <WildixCallTransfer
                        activeCalls={activeCalls}
                        call={callTransfer}
                        callData={callData}
                        extension={profile.extension}
                        getCallStatusClass={this.getCallStatusClass}
                        handleClose={() => this.handleCallTransfer()}
                        handleGetPresenceClassName={this.handleGetPresenceClassName}
                        handleGetPresenceColor={this.handleGetPresenceColor}
                        handleGetPresenceIcon={this.handleGetPresenceIcon}
                        handleGetPresencePriority={this.handleGetPresencePriority}
                        handleGetPresenceText={this.handleGetPresenceText}
                        onCallAttendantTransfer={this.onCallAttendantTransfer}
                        onCallHangup={this.onCallHangup}
                        onDial={this.onDial}
                        presence={rosterPresence}
                        roster={roster}
                    />
                )}
                {colleagues && !callTransfer && (
                    <WildixPhoneBook
                        extension={profile.extension}
                        handleGetPresenceColor={this.handleGetPresenceColor}
                        handleGetPresenceClassName={this.handleGetPresenceClassName}
                        handleGetPresenceIcon={this.handleGetPresenceIcon}
                        handleGetPresenceText={this.handleGetPresenceText}
                        handleGetPresencePriority={this.handleGetPresencePriority}
                        handleClose={this.handleTogglePhoneBook}
                        onDial={this.onDial}
                        presence={rosterPresence}
                        roster={roster}
                    />
                )}
                {callCentre && !colleagues && !callTransfer && (
                    <WildixCallCentre
                        handleClose={this.handleToggleCallCentre}
                        queues={queues}
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    config: state.notifications.config,
    staff: state.staffAuth.staff,
    ui: state.ui,
})

const mapDispatchToProps = (dispatch) => ({
    deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
    closeDialog: () => dispatch(closeDialog()),
    deployDialog: (content, disableDialogContent = false, title = "", variant = "standard", size = "md", fullscreen = false, disabled = false) =>
        dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disabled)),
})
 
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(WildixVoip)));