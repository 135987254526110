import _ from "lodash";
import moment from "moment";
import React from "react";

import { Box, FormControl, Grid, Paper, TextField, Typography } from "@material-ui/core";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import API from "API";
import ViewNotes from "Components/Common/Activity/ViewNotes";
import Alert from "Components/Common/Alert/Alert";
import AppButton from "Components/Common/Buttons/AppButton";
import DatePicker from "Components/Common/DatePickers/DatePicker";
import PrintJobDialog from "Components/Common/Dialogs/PrintJobDialog";
import Textarea from "Components/Common/Inputs/Textarea";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import DeliverySummary from "Components/Sales/Misc/DeliverySummary";

import { DPD_CUT_OFF, DHL_CUT_OFF } from "Constants";

const initialState = (isDhl) => ({
    despatchDate: moment().isBefore(moment(isDhl ? DHL_CUT_OFF : DPD_CUT_OFF, "hh:mm"))
        ? moment().format("YYYY-MM-DD")
        : moment().add(1, "day").format("YYYY-MM-DD"),
    despatchLabelQty: "1",
    despatchLabelProduct: null,
    isCutOff: moment().isAfter(moment(isDhl ? DHL_CUT_OFF : DPD_CUT_OFF, "hh:mm")),
    cutOffTime: isDhl ? DHL_CUT_OFF : DPD_CUT_OFF,
    isLoading: false,
    printing: false,
    notes: {
        despatch: "",
    }
});

class Despatch extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(this.props.od?.carriage?.courier?.courier_api_ref === 'dhl');
        this.timeout = false;
    }

    componentWillUnmount = () => {
        if (this.timeout) clearTimeout(this.timeout);
    };

    handleDespatchDate = (date) => this.setState({ despatchDate: moment(date).format("YYYY-MM-DD") });

    handleDespatchLabelProduct = (e, despatchLabelProduct) =>
        this.setState({
            despatchLabelProduct,
            despatchLabelQty: despatchLabelProduct !== "parcel_dpd" && despatchLabelProduct !== "parcel_dhl" ? (despatchLabelProduct === "consolidation" ? 0 : 1) : "",
        });

    handleDespatchLabelQty = (despatchLabelQty) => this.setState({ despatchLabelQty });

    handleNotes = (type, value) => {
        this.setState({
            notes: {
                ...this.state.notes,
                [type]: value,
            },
        });
    };

    handleSubmit = () => {
        const { deployConfirmation, deployDialog, deploySnackBar, id, od } = this.props;

        this.setState(
            {
                isLoading: true,
            },
            () => {
                const { notes, despatchDate, despatchLabelQty, despatchLabelProduct } = this.state;

                API.put(`/warehouse/delivery/${id}/despatch`, {
                    despatchNotes: notes?.despatch ?? "",
                    despatchDate,
                    despatchLabelQty,
                    despatchLabelProduct,
                }).then((res) => {
                    if (res?.data) {
                        if (res.data?.despatched) {
                            this.handleErrorRedirect("despatched by another user");
                        } else if (res.data?.cancelled) {
                            this.handleErrorRedirect("cancelled");
                        } else if (res.data?.hold) {
                            this.handleErrorRedirect("placed on hold");
                        } else if (res.data?.notAwaitingDespatch) {
                            this.handleErrorRedirect("modified and is no longer awaiting despatch");
                        } else if (res.data?.errors) {
                            this.setState(
                                {
                                    isLoading: false,
                                },
                                () => {
                                    deploySnackBar(
                                        `error`,
                                        `There was an issue despatching this delivery - please check the delivery address, order email and mobile number is valid`
                                    );
                                }
                            );
                        } else {
                            if (res.data?.labelError === 1) {
                                deployConfirmation(
                                    <>
                                        <Typography variant="body2" className="textError fw-400" gutterBottom>
                                            Error Fetching Label
                                        </Typography>
                                        <Typography variant="body2">
                                            Please re-print the label from the{" "}
                                            {od?.carriage?.courier?.courier_name ?? "courier's"} portal
                                        </Typography>
                                    </>,
                                    this.handleDespatchCompleted,
                                    false,
                                    "Ok",
                                    null,
                                    true
                                );
                            } else {
                                if (res.data?.printJob) {
                                    this.setState(
                                        {
                                            printing: true,
                                        },
                                        () => {
                                            deployDialog(
                                                <PrintJobDialog
                                                    printJob={res.data.printJob}
                                                    docTitle={`Shipping Label`}
                                                    docSubtitle={`Delivery #${this.props.od.od_reference}/${this.props.od.od_idx}`}
                                                    onClose={this.handleDespatchCompleted}
                                                />,
                                                true,
                                                "",
                                                "standard",
                                                "sm",
                                                false,
                                                true
                                            );
                                        }
                                    );
                                } else {
                                    this.handleDespatchCompleted();
                                }
                            }
                        }
                    }
                });
            }
        );
    };

    handleDespatchCompleted = () => {
        const {
            deploySnackBar,
            history,
            processingComplete,
            // warehouseMode
        } = this.props;

        processingComplete();
        deploySnackBar(`success`, `Delivery successfully despatched`);
        // let redirect =  warehouseMode ? `/dashboard` : `/warehouse/outgoing/awaiting-despatch`;
        this.timeout = setTimeout(() => history.push("/warehouse/outgoing/awaiting-despatch"), 750);
    };

    handleErrorRedirect = (reason) => {
        const { deploySnackBar, history, warehouseMode } = this.props;

        deploySnackBar(`error`, `This delivery has been ${reason}, contact Sales Admin for further advice`);
        if (warehouseMode) {
            history.push(`/dashboard`);
        } else {
            history.push(`/warehouse/outgoing/awaiting-despatch`);
        }
    };

    render = () => {
        const { isCutOff, cutOffTime, despatchDate, despatchLabelProduct, despatchLabelQty, isLoading, printing, notes } =
            this.state;
        const { currentTab, deployConfirmation, history, order, od } = this.props;

        const international = order.order_delivery_address_country !== '' && order.order_delivery_address_country !== 'United Kingdom';
        return (
            (isLoading && ((!printing && <LoadingCircle />) || <React.Fragment />)) ||
            (currentTab === 0 && (
                <Paper>
                    <Box p={3}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <Typography variant="h6">Notes</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt={1}>
                                    <ViewNotes inline notes={order.notes} hideDeleteButton />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            )) ||
            (currentTab === 1 && (
                <DeliverySummary
                    od={od}
                    order={order}
                    idx={_.findIndex(order.deliveries, (el) => el.od_id === od.od_id) + 1}
                />
            )) || (
                <Grid container spacing={3}>
                    {international && (
                        <Grid item xs={12}>
                            <Alert severity="warning">
                                <Typography variant="body1" className="fw-400" gutterBottom>
                                    International Shipment
                                </Typography>
                                <Typography variant="body1">
                                    It looks like this shipment is destined outside of the United Kingdom and may require a commercial invoice / manual despatch - please check delivery address.
                                </Typography>
                            </Alert>
                        </Grid>
                    )}
                    <Grid item xs={12} align="center">
                        <Typography variant="h5" component="div" className="fw-500">
                            {(od.carriage?.courier_conf_collection === 1 && "Collection Details") || "Despatch Details"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography variant="body1" align="center" className="fw-400" paragraph>
                            {od.carriage?.courier_conf_collection === 1 ? `Collection` : `Delivery`} Method
                        </Typography>
                        <Typography variant="body1" align="center">
                            {od.carriage?.courier?.courier_name} - {od.carriage?.courier_conf_service}
                        </Typography>
                    </Grid>
                    {od.carriage?.courier_conf_collection === 0 && (
                        <>
                            <Grid item xs={12} align="center">
                                <Typography variant="body1" align="center" className="fw-400" paragraph>
                                    Delivery Address
                                </Typography>
                                {_.map(
                                    [
                                        order.order_delivery_contact_name,
                                        order.order_delivery_company,
                                        order.order_delivery_address_line_one,
                                        order.order_delivery_address_line_two,
                                        order.order_delivery_address_line_three,
                                        order.order_delivery_address_town,
                                        order.order_delivery_address_county,
                                        order.order_delivery_address_postcode,
                                        order.order_delivery_address_country,
                                    ],
                                    (line, idx) => {
                                        if (line?.length > 0) {
                                            return (
                                                <Typography variant="body1" key={idx} component="div">
                                                    {line}
                                                </Typography>
                                            );
                                        }
                                    }
                                )}
                            </Grid>
                        </>
                    )}
                    {!_.isEmpty(od.od_delivery_address_notes) && (
                        <Grid item xs={12} align="center">
                            <Typography variant="body1" align="center" className="fw-400" paragraph>
                                {od.carriage?.courier_conf_collection === 0 ? `Delivery` : `Collection`} Instructions
                            </Typography>
                            <Typography variant="body1" align="center">
                                {od.od_delivery_address_notes}
                            </Typography>
                        </Grid>
                    )}
                    {od.carriage?.courier_conf_collection === 0 && (
                        <Grid item xs={12} align="center">
                            <Typography variant="body1" align="center" className="fw-400" paragraph>
                                Despatch Date
                            </Typography>
                            <FormControl fullWidth style={{ width: 271 }}>
                                <Box border="1px solid #ddd">
                                    <Paper elevation={0} className="p-1">
                                        <DatePicker
                                            type="date"
                                            value={despatchDate}
                                            onChange={this.handleDespatchDate}
                                            autoOk
                                            disablePast

                                        />
                                    </Paper>
                                </Box>
                            </FormControl>
                            {isCutOff && (
                                <Box mt={2} align="center" className="content-light-warning" p={2} maxWidth={271}>
                                    <Typography variant="body2">
                                        If you try to despatch for today and an error shows please select the next working date as the cut off time has passed ({cutOffTime})
                                    </Typography>
                                </Box>
                            )}
                        </Grid>
                    )}
                    {(od?.carriage?.api && (
                        <>
                            <Grid item xs={12} align="center">
                                <Typography variant="body1" align="center" className="fw-400" paragraph>
                                    Service Product
                                </Typography>
                                {od?.carriage?.courier?.courier_api_ref === 'dhl' ? (
                                    <ToggleButtonGroup
                                        value={despatchLabelProduct}
                                        exclusive
                                        onChange={this.handleDespatchLabelProduct}
                                        style={{ flexDirection: "column", backgroundColor: "#fafafa" }}
                                    >
                                        <ToggleButton
                                            style={{ border: "1px solid #ddd", borderRadius: 0, backgroundColor: "#fff" }}
                                            value={"bagit_sm"}
                                            className={`fw-400`}
                                        >
                                            DHL BagIt Small
                                            <br /> Up to 1kg (235mm x 335mm)
                                        </ToggleButton>
                                        <ToggleButton
                                            style={{ border: "1px solid #ddd", borderRadius: 0, backgroundColor: "#fff" }}
                                            value={"bagit_md"}
                                            className={`mt-3 fw-400`}
                                        >
                                            DHL BagIt Medium
                                            <br /> Up to 2kg (305mm x 420mm)
                                        </ToggleButton>
                                        <ToggleButton
                                            style={{ border: "1px solid #ddd", borderRadius: 0, backgroundColor: "#fff" }}
                                            value={"bagit_lg"}
                                            className={`mt-3 fw-400`}
                                        >
                                            DHL BagIt Large
                                            <br /> Up to 5kg (395mm x 480mm)
                                        </ToggleButton>
                                        <ToggleButton
                                            style={{ border: "1px solid #ddd", borderRadius: 0, backgroundColor: "#fff" }}
                                            value={"parcel_dhl"}
                                            className={`mt-3 fw-400`}
                                        >
                                            DHL Parcel
                                            <br /> Max weight 30kg per box
                                        </ToggleButton>
                                        <ToggleButton
                                            style={{ border: "1px solid #ddd", borderRadius: 0, backgroundColor: "#fff" }}
                                            value={international ? "manual" : "consolidation"}
                                            className={`mt-3 fw-400`}
                                        >
                                            {international ? 'Manual Despatch' : 'Consolidation'}
                                            <br />{international ? `This will not generate a shipping label` : `Shipping with another order (No despatch)`}
                                        </ToggleButton>
                                    </ToggleButtonGroup>   
                                ): (
                                    <ToggleButtonGroup
                                        value={despatchLabelProduct}
                                        exclusive
                                        onChange={this.handleDespatchLabelProduct}
                                        style={{ flexDirection: "column", backgroundColor: "#fafafa" }}
                                    >
                                        <ToggleButton
                                            style={{ border: "1px solid #ddd", borderRadius: 0, backgroundColor: "#fff" }}
                                            value={"expressPak1"}
                                            className={`fw-400`}
                                        >
                                            DPD Expresspak 1
                                            <br /> Up to 1kg
                                        </ToggleButton>
                                        <ToggleButton
                                            style={{ border: "1px solid #ddd", borderRadius: 0, backgroundColor: "#fff" }}
                                            value={"expressPak5"}
                                            className={`mt-3 fw-400`}
                                        >
                                            DPD Expresspak 5
                                            <br /> Up to 5kg
                                        </ToggleButton>
                                        <ToggleButton
                                            style={{ border: "1px solid #ddd", borderRadius: 0, backgroundColor: "#fff" }}
                                            value={"parcel_dpd"}
                                            className={`mt-3 fw-400`}
                                        >
                                            DPD Parcel
                                            <br /> Max weight 30kg per box (Up to 1 metre)
                                        </ToggleButton>
                                        <ToggleButton
                                            style={{ border: "1px solid #ddd", borderRadius: 0, backgroundColor: "#fff" }}
                                            value={international ? "manual" : "consolidation"}
                                            className={`mt-3 fw-400`}
                                        >
                                            {international ? 'Manual Despatch' : 'Consolidation'}
                                            <br />{international ? `This will not generate a shipping label` : `Shipping with another order (No despatch)`}
                                        </ToggleButton>
                                    </ToggleButtonGroup>     
                                )}                  
                            </Grid>
                            {(despatchLabelProduct === "parcel_dpd" || despatchLabelProduct === "parcel_dhl") && (
                                <Grid item xs={12} align="center">
                                    <Typography variant="body1" align="center" className="fw-400" paragraph>
                                        Number of Parcels
                                    </Typography>
                                    <TextField
                                        margin="none"
                                        onChange={(e) => this.handleDespatchLabelQty(e?.target?.value ?? "")}
                                        value={despatchLabelQty}
                                        inputProps={{
                                            size: 4,
                                            pattern: "[0-9]*",
                                            style: {
                                                width: 61,
                                                height: 56,
                                                textAlign: "center",
                                            },
                                            inputMode: "none",
                                        }}
                                        InputProps={{
                                            style: {
                                                width: 85,
                                                height: 80,
                                            },
                                        }}
                                        variant="filled"
                                    />
                                </Grid>
                            )}
                        </>
                    )) ||
                        (od.carriage?.courier_conf_collection === 1 && (
                            <Grid item xs={12}>
                                <Alert severity="warning">
                                    <strong>Customer collection</strong>
                                    <br />
                                    Please place on the collection shelf
                                </Alert>
                            </Grid>
                        )) || (
                            <Grid item xs={12}>
                                <Alert severity="warning">
                                    <strong>Manual shipping required</strong>
                                    <br />
                                    Please liaise with Sales Admin to book on this delivery
                                </Alert>
                            </Grid>
                        )}
                    <Grid item xs={12} align="center">
                        <Typography variant="body1" align="center" className="fw-400" paragraph>
                            Despatch Notes
                        </Typography>
                        <Paper elevation={0}>
                            <Textarea
                                margin="none"
                                onChange={(e) => this.handleNotes("despatch", e?.target?.value ?? "")}
                                value={notes.despatch}
                                variant="outlined"
                                rows={1}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <AppButton
                            disabled={despatchLabelQty === "" || (od?.carriage?.api && !despatchLabelProduct)}
                            className="btn btn-success"
                            icon="check"
                            onClick={() => deployConfirmation(`Are you sure you want to continue?`, this.handleSubmit)}
                            primary
                            text={
                                od.carriage?.courier_conf_collection === 1
                                    ? "Mark as Ready for Collection"
                                    : od.carriage?.api
                                    ? "Request Shipping Labels"
                                    : "Mark as Despatched"
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AppButton
                            back
                            className="btn btn-error"
                            onClick={() =>
                                deployConfirmation(`Are you sure you want to cancel this despatch?`, () =>
                                    history.push("/dashboard")
                                )
                            }
                            text="Cancel"
                            icon="times"
                        />
                    </Grid>
                </Grid>
            )
        );
    };
}

export default Despatch;
