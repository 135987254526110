import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { handleChange, handleFloat, handleSelectChange } from 'Functions/FormFunctions';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const initialState = {
    couriers: [],
    formData: {
        deliveryCourierId: '',
        deliveryApiRef: '',
        deliveryType: '',
        deliveryService: '',
        deliveryText: '',
        deliveryCustomCharge: false,
        deliveryCharge: '',
        deliveryMinOrderValue: '',
        deliveryActive: true,
        deliveryNotes: '',
        deliveryAuthRequired: false,
        deliveryCutOffKnown: false,
        deliveryCutOffVaries: false,
        deliveryCutOffData: {
            mon: '',
            tue: '',
            wed: '',
            thu: '',
            fri: ''
        },
        deliveryApiDpdIlink: '',
        deliveryApiDpdExPak1Code: '',
        deliveryApiDpdExPak5Code: '',
        deliveryApiDhlBagitSm: '',
        deliveryApiDhlBagitMd: '',
        deliveryApiDhlBagitLg: '',
        deliveryApiDhlParcel: ''
    },
    formErrors: [],
    isLoading: true,
}

class AddCourierConfiguration extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;        
        this.handleChange = handleChange.bind(this);
        this.handleFloat = handleFloat.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData();  
    }

    loadComponentData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/couriers')
            .then(result => {
                this.setState({
                    ...this.state,
                    couriers: result.data,
                    isLoading: false,                          
                    formData: {
                        ...this.state.formData,
                        deliveryCourierId: this.props.id ? parseInt(this.props.id) : ''
                    }
                })
            });
        });
    }

    handleDOCheckedChange = e => {  
        const { name, checked } = e.target;
        let resetData = {}
        if(name === 'deliveryCutOffKnown') {
            if(checked === false) {
                resetData = {                    
                    deliveryCutOffVaries: false,
                    deliveryCutOffData: {
                        ...initialState.formData.deliveryCutOffData
                    }
                }        
            }
        }
        if(name === 'deliveryCutOffVaries') {
            resetData = {                   
                deliveryCutOffData: {
                    ...initialState.formData.deliveryCutOffData
                }
            }     
        }
        if(name === 'deliveryCustomCharge') {
            resetData = {                   
                deliveryCharge: initialState.formData.deliveryCharge,
            }     
        }
        this.setState({
            formData: {
                ...this.state.formData,
                ...resetData,
                [name]: checked
            }
        });
    }

    handleDOCutOffChange = e => {
        const { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                deliveryCutOffData: {
                    ...this.state.formData.deliveryCutOffData,
                    [name]: value
                }
            }
        });
    }

    handleDOCutOffAllChange = e => {
        const { value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                deliveryCutOffData: {
                    mon: value,
                    tue: value,
                    wed: value,
                    thu: value,
                    fri: value,
                }
            }
        });
    }

    submit = () => {       
        const { couriers, formData } = this.state;
        let formChecks = {}
        if(formData.deliveryCustomCharge === true) {
            formChecks = {
                ...formChecks,
                deliveryCharge: '0.00',
            }
        }
        if(formData.deliveryCutOffKnown === false) {
            formChecks = {
                ...formChecks,
                deliveryCutOffVaries:  initialState.formData.deliveryCutOffVaries,
                deliveryCutOffData: initialState.formData.deliveryCutOffData
            }
        }
        formChecks = {
            ...formChecks,
            deliveryApiRef: formData.deliveryCourierId ? (couriers[_.findIndex(couriers, (el) => { return el.courier_id === formData.deliveryCourierId})].courier_api_ref ? couriers[_.findIndex(couriers, (el) => { return el.courier_id === formData.deliveryCourierId})].courier_api_ref : false) : false
        }        
        this.setState({
            formData: {
                ...this.state.formData,
                ...formChecks
            }
        }, 
        () => {
            API.post('/couriers/configuration', this.state.formData)
            .then(result => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                    });
                }
                else {
                    this.props.deploySnackBar("success", "The delivery method has been successfully added")
                    if(this.props.toggleDialog)
                        this.props.toggleDialog()
                }
            })
        });
    }

    render() {
        const { couriers, formData, formErrors, isLoading } = this.state;
        const dpdApiOption = formData.deliveryCourierId ? (couriers[_.findIndex(couriers, (el) => { return el.courier_id === formData.deliveryCourierId})].courier_api_ref === 'dpd' ? true : false) : false;
        const dhlApiOption = formData.deliveryCourierId ? (couriers[_.findIndex(couriers, (el) => { return el.courier_id === formData.deliveryCourierId})].courier_api_ref === 'dhl' ? true : false) : false;
        return (
            <Grid container spacing={3}>
                {(isLoading && (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                )) || (
                    <>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={3} justify="flex-end">          
                                <Grid item xs={12}>  
                                    <PaddedPaper>
                                        <Typography variant="h6" gutterBottom>
                                            Delivery Partner
                                        </Typography>   
                                        <Grid container>
                                            <Grid item xs={12} lg={6}>                                
                                                <AutoCompleteSelect                                
                                                    options={_.map(couriers, el => {
                                                        return _.assign({
                                                            value: el.courier_id,
                                                            label: el.courier_name,
                                                        })
                                                    })}
                                                    label='Delivery Partner *'
                                                    onChange={o => this.handleSelectChange('deliveryCourierId', o)}
                                                    error={formErrors && formErrors['deliveryCourierId'] && true}
                                                    errorText={formErrors && formErrors['deliveryCourierId']}
                                                    value={formData.deliveryCourierId}
                                                />         
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>                
                                <Grid item xs={12} lg={6}>                                    
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Delivery Configuration
                                        </Typography>     
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    checked={formData.deliveryActive} 
                                                    control={<Checkbox name="deliveryActive" value="1" color="primary" onChange={this.handleDOCheckedChange}/>}
                                                    label="Enabled"  
                                                    style={{marginRight: 24}}     
                                                />
                                                <FormControlLabel 
                                                    checked={formData.deliveryAuthRequired} 
                                                    control={<Checkbox name="deliveryAuthRequired" value="1" color="primary" onChange={this.handleDOCheckedChange}/>}
                                                    label="Requires Approval"                                
                                                />
                                            </Grid>
                                            <Grid item xs={12}>                
                                                <TextField
                                                    name="deliveryService"
                                                    label="Service *"
                                                    value={formData.deliveryService}
                                                    error={formErrors && formErrors['deliveryService'] && true}
                                                    helperText={formErrors && formErrors['deliveryService']}
                                                    onChange={this.handleChange}
                                                    margin="none"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>                
                                                <TextField
                                                    name="deliveryText"
                                                    label="Service PDF Description"
                                                    value={formData.deliveryText}
                                                    error={formErrors && formErrors['deliveryText'] && true}
                                                    helperText={formErrors && formErrors['deliveryText']}
                                                    onChange={this.handleChange}
                                                    margin="none"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>                       
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Delivery Charge
                                        </Typography>       
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>                                                    
                                                <FormControlLabel
                                                    checked={formData.deliveryCustomCharge} 
                                                    control={<Checkbox name="deliveryCustomCharge" value="1" color="primary" onChange={this.handleDOCheckedChange}/>}
                                                    label="Delivery Charge Varies By Order"                                
                                                />
                                            </Grid>  
                                            <Grid item xs={12}>    
                                                <TextField
                                                    name="deliveryCharge"
                                                    label="Delivery Charge *"
                                                    value={formData.deliveryCharge}
                                                    error={formErrors && formErrors['deliveryCharge'] && true}
                                                    helperText={formErrors && formErrors['deliveryCharge']}
                                                    onChange={this.handleChange}
                                                    onBlur={this.handleFloat}
                                                    margin="none"
                                                    disabled={formData.deliveryCustomCharge ? true : false}
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">£</InputAdornment>
                                                    }}
                                                    type="number"
                                                    inputProps={{
                                                        min: 0
                                                    }}
                                                /> 
                                            </Grid>          
                                        </Grid>          
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Order Cut Off Time
                                        </Typography>        
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>                           
                                                <FormControlLabel 
                                                    checked={formData.deliveryCutOffKnown} 
                                                    control={<Checkbox name="deliveryCutOffKnown" value="1" color="primary" onChange={this.handleDOCheckedChange}/>}
                                                    label="Configure Cut Off Times"     
                                                    style={{marginRight: 24}}                           
                                                />
                                                {formData.deliveryCutOffKnown && (
                                                    <FormControlLabel
                                                        disabled={formData.deliveryCutOffKnown ? false : true}
                                                        checked={formData.deliveryCutOffVaries} 
                                                        control={<Checkbox name="deliveryCutOffVaries" value="1" color="primary" onChange={this.handleDOCheckedChange}/>}
                                                        label="Cut Off Times Vary By Day"                    
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {formData.deliveryCutOffKnown && (
                                                    (!formData.deliveryCutOffVaries && (
                                                        <Box width={200}>
                                                            <TextField
                                                                type="time"
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                name="mon"
                                                                label="Order Cut Off Time *"
                                                                value={formData.deliveryCutOffData.mon}
                                                                error={formErrors && formErrors['deliveryCutOffData|mon'] && true}
                                                                helperText={formErrors && formErrors['deliveryCutOffData|mon']}
                                                                onChange={this.handleDOCutOffAllChange}
                                                                fullWidth
                                                            />
                                                        </Box>
                                                    )) || (
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1} alignItems='center'>
                                                                <Grid item style={{width: '20%'}}>
                                                                    <TextField
                                                                        type="time"
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        name="mon"
                                                                        label="Monday *"
                                                                        value={formData.deliveryCutOffData.mon}
                                                                        error={formErrors && formErrors['deliveryCutOffData|mon'] && true}
                                                                        helperText={formErrors && formErrors['deliveryCutOffData|mon']}
                                                                        onChange={this.handleDOCutOffChange}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item style={{width: '20%'}}>
                                                                    <TextField
                                                                        type="time"
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        name="tue"
                                                                        label="Tuesday *"
                                                                        value={formData.deliveryCutOffData.tue}
                                                                        error={formErrors && formErrors['deliveryCutOffData|tue'] && true}
                                                                        helperText={formErrors && formErrors['deliveryCutOffData|tue']}
                                                                        onChange={this.handleDOCutOffChange}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item style={{width: '20%'}}>
                                                                    <TextField
                                                                        type="time"
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        name="wed"
                                                                        label="Wednesday *"
                                                                        value={formData.deliveryCutOffData.wed}
                                                                        error={formErrors && formErrors['deliveryCutOffData|wed'] && true}
                                                                        helperText={formErrors && formErrors['deliveryCutOffData|wed']}
                                                                        onChange={this.handleDOCutOffChange}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item style={{width: '20%'}}>
                                                                    <TextField
                                                                        type="time"
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        name="thu"
                                                                        label="Thursday *"
                                                                        value={formData.deliveryCutOffData.thu}
                                                                        error={formErrors && formErrors['deliveryCutOffData|thu'] && true}
                                                                        helperText={formErrors && formErrors['deliveryCutOffData|thu']}
                                                                        onChange={this.handleDOCutOffChange}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item style={{width: '20%'}}>
                                                                    <TextField
                                                                        type="time"
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        name="fri"
                                                                        label="Friday *"
                                                                        value={formData.deliveryCutOffData.fri}
                                                                        error={formErrors && formErrors['deliveryCutOffData|fri'] && true}
                                                                        helperText={formErrors && formErrors['deliveryCutOffData|fri']}
                                                                        onChange={this.handleDOCutOffChange}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>  
                                                    )
                                                )}
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>      
                                {dpdApiOption && (
                                    <Grid item xs={12}>                                    
                                        <PaddedPaper>
                                            <Typography variant="h6" gutterBottom>
                                                DPD API Configuration
                                            </Typography>     
                                            <Typography variant="body2" className="fw-400" paragraph>
                                                <FAIcon type="solid" icon="exclamation-triangle" className="textError mr-1" size={14} />
                                                For DPD International Shipments please enter 0 for the API Codes
                                            </Typography>
                                            <Grid container spacing={1}>
                                                <Grid item>   
                                                    <TextField
                                                        name="deliveryApiDpdIlink"
                                                        label="Parcel Code *"
                                                        value={formData.deliveryApiDpdIlink}
                                                        error={formErrors && formErrors['deliveryApiDpdIlink'] && true}
                                                        helperText={formErrors && formErrors['deliveryApiDpdIlink']}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        type="number"
                                                        inputProps={{
                                                            min: 0
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>   
                                                    <TextField
                                                        name="deliveryApiDpdExPak1Code"
                                                        label="ExpressPak 1 Code *"
                                                        value={formData.deliveryApiDpdExPak1Code}
                                                        error={formErrors && formErrors['deliveryApiDpdExPak1Code'] && true}
                                                        helperText={formErrors && formErrors['deliveryApiDpdExPak1Code']}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        type="number"
                                                        inputProps={{
                                                            min: 0
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>   
                                                    <TextField
                                                        name="deliveryApiDpdExPak5Code"
                                                        label="ExpressPak 5 Code *"
                                                        value={formData.deliveryApiDpdExPak5Code}
                                                        error={formErrors && formErrors['deliveryApiDpdExPak5Code'] && true}
                                                        helperText={formErrors && formErrors['deliveryApiDpdExPak5Code']}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        type="number"
                                                        inputProps={{
                                                            min: 0
                                                        }}
                                                    />                 
                                                </Grid>
                                            </Grid>                       
                                        </PaddedPaper>
                                    </Grid>
                                )}    
                                {dhlApiOption && (
                                    <Grid item xs={12}>                                    
                                        <PaddedPaper>
                                            <Typography variant="h6" gutterBottom>
                                                DHL UK API Configuration
                                            </Typography>        
                                            <Typography variant="body2" className="fw-400" paragraph>
                                                <FAIcon type="solid" icon="exclamation-triangle" className="textError mr-1" size={14} />
                                                For DHL UK International Shipments please enter 0 for the API Codes
                                            </Typography> 
                                            <Grid container spacing={1}>
                                                <Grid item>   
                                                    <TextField
                                                        name="deliveryApiDhlParcel"
                                                        label="DHL Parcel Code *"
                                                        value={formData.deliveryApiDhlParcel}
                                                        error={formErrors && formErrors['deliveryApiDhlParcel'] && true}
                                                        helperText={formErrors && formErrors['deliveryApiDhlParcel']}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        type="number"
                                                        inputProps={{
                                                            min: 0
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>   
                                                    <TextField
                                                        name="deliveryApiDhlBagitSm"
                                                        label="DHL Bagit (Small) *"
                                                        value={formData.deliveryApiDhlBagitSm}
                                                        error={formErrors && formErrors['deliveryApiDhlBagitSm'] && true}
                                                        helperText={formErrors && formErrors['deliveryApiDhlBagitSm']}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        type="number"
                                                        inputProps={{
                                                            min: 0
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>   
                                                    <TextField
                                                        name="deliveryApiDhlBagitMd"
                                                        label="DHL Bagit (Medium) *"
                                                        value={formData.deliveryApiDhlBagitMd}
                                                        error={formErrors && formErrors['deliveryApiDhlBagitMd'] && true}
                                                        helperText={formErrors && formErrors['deliveryApiDhlBagitMd']}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        type="number"
                                                        inputProps={{
                                                            min: 0
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>   
                                                    <TextField
                                                        name="deliveryApiDhlBagitLg"
                                                        label="DHL Bagit (Large) *"
                                                        value={formData.deliveryApiDhlBagitLg}
                                                        error={formErrors && formErrors['deliveryApiDhlBagitLg'] && true}
                                                        helperText={formErrors && formErrors['deliveryApiDhlBagitLg']}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                        type="number"
                                                        inputProps={{
                                                            min: 0
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>                       
                                        </PaddedPaper>
                                    </Grid>
                                )}   
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Internal Notes
                                        </Typography>                       
                                        <Textarea
                                            name="deliveryNotes"
                                            value={formData.deliveryNotes}
                                            error={formErrors && formErrors['deliveryNotes'] && true}
                                            helperText={formErrors && formErrors['deliveryNotes']}
                                            onChange={this.handleChange}
                                            margin="none"
                                            variant="outlined"
                                            rows={2}
                                            style={{marginTop: 12}}
                                            multiline
                                            fullWidth
                                        /> 
                                    </PaddedPaper>
                                </Grid>       
                                <Grid item align="right">
                                    <Button 
                                        type='submit'
                                        onClick={() => this.props.deployConfirmation("Are you sure you want to add this delivery method?", this.submit)}
                                        variant="contained" 
                                        color="primary"
                                        style={{margin: 0}} 
                                    >
                                        <FAIcon icon="check" size={15} buttonPrimary />
                                        Create
                                    </Button>
                                </Grid>                   
                            </Grid>
                        </Grid>  
                    </>         
                )}
            </Grid>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message))
    };
}

export default connect(null, mapDispatchToProps)(AddCourierConfiguration);