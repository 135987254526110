import React, { useState } from 'react';

import { 
    connect, 
    useDispatch 
} from 'react-redux';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import EmailDialog from 'Components/Communication/Misc/EmailDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import QuoteSummary from 'Components/Quotes/Misc/QuoteSummary';
import StaffChip from 'Components/Common/Chips/StaffChip';
import StatusChip from 'Components/Common/Chips/StatusChip';
import ThresholdChip from 'Components/Common/Chips/ThresholdChip';

import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { getPresignedUrl } from 'Functions/S3Functions';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

const getLegacyQuotationPDF = (quoteId) => {
    API.get('/quotes/' + quoteId + '/legacy/pdf')
    .then(result => {
        if(result.data) {
            pdfFromBase64(result.data.pdf, result.data.pdfName);
        }
    })
}

const handleInProgressUser = (callback, dispatch, quoteId, reviewId) => {

    dispatch(deployConfirmation(`Are you sure you want to ${reviewId > 0 ? `remove the review flag from this quote?` : `mark this quote as under review?`}`, () => {
            
        let route = `/quotes/${quoteId}/approval/user/${reviewId > 0 ? `clear` : `set`}`;

        API.put(route)
        .then(res => {
            if(res?.data?.success) {
                callback?.();
                dispatch(deploySnackBar(`success`, `You have successfully ${reviewId > 0 ? `cleared the review flag for this quote` : `marked this quote as under review`}`))
            }
        })

    }))

}

const deployPreview = (dispatch, id) => {
    API.get(`/quotes/${id}`)
    .then(res => {
        if (res?.data) {
            const quote = res.data;
            dispatch(
                deployDialog(
                    <QuoteSummary
                        inDialog
                        quote={quote} 
                    />
                , 
                true, 
                ``, 
                "standard", 
                "xl",
                )
            );
        }
    });
};

const getColumns = (handleCheckQuote, checked, isCustomer, staff, status, basic, dispatch, callback) => {

    let cols;

    if(isCustomer) {
        cols = [   
            {
                heading: 'Email',
                field: rowData => (
                    <Checkbox 
                        color="primary"
                        checked={checked.indexOf(rowData.id) !== -1}
                        onChange={() => handleCheckQuote(rowData.id)}
                    />
                ),
                dataRef: 'ref',
                main: true,
                sizeToContent: true
            },  
            {
                heading: 'Ref #',
                field: rowData => rowData.ref,
                dataRef: 'ref',
                main: true,
                sizeToContent: true
            },   
            {
                heading: 'Date',
                field: rowData => rowData.created,
                fieldFormat: 'date',
                dataRef: 'created',
                sizeToContent: true
            },  
            {
                heading: 'Customer Reference',
                field: rowData => rowData.crf,
                dataRef: 'crf',
                truncate: true,
                main: true,
            }, 
            {
                heading: 'Net Total',
                field: rowData => rowData.net,
                fieldFormat: 'currency',
                dataRef: 'net',
                alignment: 'right',
                sizeToContent: true
            },
            {
                heading: 'Hot',
                field: rowData => rowData.h,
                fieldFormat: 'boolean',
                dataRef: 'h',
                sizeToContent: true,
                alignment: 'center'
            },   
            {
                heading: 'Sent',
                field: rowData => rowData.em,
                alignment: 'center',
                dataRef: 'em',
                fieldFormat: 'boolean',
                sizeToContent: true,
            }, 
            {
                heading: 'Quoted By',
                field: rowData => (staff && staff[rowData.staff] && <StaffChip staff={staff[rowData.staff]} />) || '-',
                dataRef: 'staff',
                sizeToContent: true,
                fieldFormat: 'staff',
            },  
            {
                heading: 'Salesperson',
                field: rowData => (staff && staff[rowData.sp] && <StaffChip staff={staff[rowData.sp]} />) || '-',
                dataRef: 'sp',
                sizeToContent: true,
                fieldFormat: 'staff',
            },  
            {
                heading: 'Threshold',
                field: rowData => (rowData.qt_color && <ThresholdChip threshold={{ qt_color: rowData.qt_color, qt_label: rowData.qt_label }} />) || '-',
                dataRef: 'qt_label',
                sortRef: 'qt_min',
                sizeToContent: true
            },
            {
                heading: 'Status',
                field: rowData => <StatusChip status={rowData.status} />,
                dataRef: 'status',
                sizeToContent: true
            },   
            {
                actions: rowData => {
                    return [
                        {name: 'View Quote', icon: 'binoculars',  link: `/quotes/${rowData.id}`, doubleClick: true},
                        {name: "Preview Quote", icon: "eye", onClick: () => deployPreview(dispatch, rowData.id)},
                        {name: 'Download Quote PDF', icon: 'file-pdf', color: 'error',  onClick: () => rowData.legacy ? getLegacyQuotationPDF(rowData.id) : getPresignedUrl(rowData.pdf)},
                    ]
                }
            }
        ]

    } else if(basic) {
        cols = [   
            {
                heading: 'Ref #',
                field: rowData => rowData.ref,
                dataRef: 'ref',
                main: true,
                sizeToContent: true
            },  
            {
                heading: 'Date',
                field: rowData => rowData.created,
                fieldFormat: 'date',
                dataRef: 'created',
                sizeToContent: true
            },  
            {
                heading: 'Customer Reference',
                field: rowData => rowData.crf,
                dataRef: 'crf',
                truncate: true,
                main: true,
            }, 
            {
                heading: 'Net Total',
                field: rowData => rowData.net,
                fieldFormat: 'currency',
                dataRef: 'net',
                alignment: 'right',
                sizeToContent: true
            },
            {
                heading: 'Hot',
                field: rowData => rowData.h,
                fieldFormat: 'boolean',
                dataRef: 'h',
                sizeToContent: true,
                alignment: 'center'
            },   
            {
                heading: 'Sent',
                field: rowData => rowData.em,
                alignment: 'center',
                dataRef: 'em',
                fieldFormat: 'boolean',
                sizeToContent: true,
            }, 
            {
                heading: 'Quoted By',
                field: rowData => (staff && staff[rowData.staff] && <StaffChip staff={staff[rowData.staff]} />) || '-',
                dataRef: 'staff',
                sizeToContent: true,
                fieldFormat: 'staff',
            },  
            {
                heading: 'Salesperson',
                field: rowData => (staff && staff[rowData.sp] && <StaffChip staff={staff[rowData.sp]} />) || '-',
                dataRef: 'sp',
                sizeToContent: true,
                fieldFormat: 'staff',
            },  
            {
                heading: 'Threshold',
                field: rowData => (rowData.qt_color && <ThresholdChip threshold={{ qt_color: rowData.qt_color, qt_label: rowData.qt_label }} />) || '-',
                dataRef: 'qt_label',
                sortRef: 'qt_min',
                sizeToContent: true
            },
            {
                heading: 'Status',
                field: rowData => <StatusChip status={rowData.status} />,
                dataRef: 'status',
                sizeToContent: true
            },   
            {
                actions: rowData => {
                    return [
                        {name: 'View Quote', icon: 'binoculars',  link: `/quotes/${rowData.id}`, doubleClick: true},
                        {name: "Preview Quote", icon: "eye", onClick: () => deployPreview(dispatch, rowData.id)},
                        {name: 'Download Quote PDF', icon: 'file-pdf', color: 'error',  onClick: () => rowData.legacy ? getLegacyQuotationPDF(rowData.id) : getPresignedUrl(rowData.pdf)},
                    ]
                }
            }
        ]

    } else {

        switch(status) {

            case "Compact":
                cols = [  
                    {
                        heading: 'Quote',
                        field: rowData => rowData.ref,
                        dataRef: 'ref',
                        main: true,
                        sizeToContent: true
                    },   
                    {
                        heading: 'Date',
                        field: rowData => rowData.created,
                        fieldFormat: 'date',
                        dataRef: 'created',
                        sizeToContent: true
                    },     
                    {
                        heading: 'Customer',
                        field: rowData => (
                            <>
                                {rowData.customer}
                                <Typography variant="caption" component="div">{rowData.sc} | {rowData.acc}</Typography>
                            </>
                        ),
                        dataRef: 'customer',
                        main: true,
                        truncate: true
                    },
                    {
                        heading: 'Net Total',
                        field: rowData => rowData.net,
                        fieldFormat: 'currency',
                        dataRef: 'net',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        heading: 'Threshold',
                        field: rowData => (rowData.qt_color && <ThresholdChip threshold={{ qt_color: rowData.qt_color, qt_label: rowData.qt_label }} />) || '-',
                        dataRef: 'qt_label',
                        sortRef: 'qt_min',
                        sizeToContent: true
                    }, 
                    {
                        heading: 'Hot',
                        field: rowData => rowData.h,
                        fieldFormat: 'boolean',
                        dataRef: 'h',
                        alignment: 'center',
                        sizeToContent: true
                    },   
                    {
                        actions: rowData => {
                            return [
                                {name: 'View Quote', icon: 'binoculars',  link: `/quotes/${rowData.id}`, doubleClick: true},
                                {name: "Preview Quote", icon: "eye", onClick: () => deployPreview(dispatch, rowData.id)},
                            ]
                        }
                    }
                ]
            break;
            case "Awaiting Approval":
                cols = [  
                    {
                        heading: 'Ref #',
                        field: rowData => rowData.ref,
                        dataRef: 'ref',
                        main: true,
                        sizeToContent: true
                    },   
                    {
                        heading: 'Date',
                        field: rowData => rowData.created,
                        fieldFormat: 'date',
                        dataRef: 'created',
                        sizeToContent: true
                    },   
                    {
                        heading: 'Customer',
                        field: rowData => (
                            <>
                                {rowData.customer}
                                <Typography variant="caption" component="div">{rowData.sc} | {rowData.acc}</Typography>
                            </>
                        ),
                        dataRef: 'customer',
                        main: true,
                        truncate: true,
                        sizeToContent: true
                    },  
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.acc,
                        dataRef: 'acc',
                        truncate: true,
                        hidden: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Status Code',
                        field: rowData => rowData.sc,
                        dataRef: 'sc',
                        truncate: true,
                        hidden: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Customer Reference',
                        field: rowData => rowData.crf,
                        dataRef: 'crf',
                        truncate: true,
                        main: true,
                        sizeToContent: true
                    }, 
                    {
                        heading: 'Net Total',
                        field: rowData => rowData.net,
                        fieldFormat: 'currency',
                        dataRef: 'net',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        heading: 'Hot',
                        field: rowData => rowData.h,
                        fieldFormat: 'boolean',
                        dataRef: 'h',
                        alignment: 'center',
                        sizeToContent: true
                    },   
                    {
                        heading: 'Quoted By',
                        field: rowData => (staff && staff[rowData.staff] && <StaffChip staff={staff[rowData.staff]} />) || '-',
                        dataRef: 'staff',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },  
                    {
                        heading: 'Salesperson',
                        field: rowData => (staff && staff[rowData.sp] && <StaffChip staff={staff[rowData.sp]} />) || '-',
                        dataRef: 'sp',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },  
                    {
                        heading: 'Threshold',
                        field: rowData => (rowData.qt_color && <ThresholdChip threshold={{ qt_color: rowData.qt_color, qt_label: rowData.qt_label }} />) || '-',
                        dataRef: 'qt_label',
                        sortRef: 'qt_min',
                        sizeToContent: true
                    }, 
                    {
                        actions: rowData => {
                            return [
                                {name: 'View Quote', icon: 'binoculars',  link: `/quotes/${rowData.id}`, doubleClick: true},
                                {name: "Preview Quote", icon: "eye", onClick: () => deployPreview(dispatch, rowData.id)},
                                {name: rowData.reviewId > 0 ? 'Remove Review Flag' : 'Mark as Under Review', icon: rowData.reviewId > 0 ? 'user-slash' : 'user', onClick: rowData => handleInProgressUser(callback, dispatch, rowData.id, rowData.reviewId)},
                                {name: 'Download Quote PDF', icon: 'file-pdf', color: 'error',  onClick: () => rowData.legacy ? getLegacyQuotationPDF(rowData.id) : getPresignedUrl(rowData.pdf)},
                            ]
                        }
                    }
                ]
            break;
            case "Open":
                cols = [  
                    {
                        heading: 'Ref #',
                        field: rowData => rowData.ref,
                        dataRef: 'ref',
                        main: true,
                        sizeToContent: true
                    },   
                    {
                        heading: 'Date',
                        field: rowData => rowData.created,
                        fieldFormat: 'date',
                        dataRef: 'created',
                        sizeToContent: true
                    },    
                    {
                        heading: 'Customer',
                        field: rowData => (
                            <>
                                {rowData.customer}
                                <Typography variant="caption" component="div">{rowData.sc} | {rowData.acc}</Typography>
                            </>
                        ),
                        dataRef: 'customer',
                        main: true,
                        truncate: true,
                        sizeToContent: true
                    },  
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.acc,
                        dataRef: 'acc',
                        truncate: true,
                        hidden: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Status Code',
                        field: rowData => rowData.sc,
                        dataRef: 'sc',
                        truncate: true,
                        hidden: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Customer Reference',
                        field: rowData => rowData.crf,
                        dataRef: 'crf',
                        truncate: true,
                        main: true,
                    }, 
                    {
                        heading: 'Net Total',
                        field: rowData => rowData.net,
                        fieldFormat: 'currency',
                        dataRef: 'net',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        heading: 'Hot',
                        field: rowData => rowData.h,
                        fieldFormat: 'boolean',
                        dataRef: 'h',
                        alignment: 'center',
                        sizeToContent: true
                    },   
                    {
                        heading: 'Sent',
                        field: rowData => rowData.em,
                        alignment: 'center',
                        dataRef: 'em',
                        fieldFormat: 'boolean',
                        sizeToContent: true,
                    }, 
                    {
                        heading: 'Quoted By',
                        field: rowData => (staff && staff[rowData.staff] && <StaffChip staff={staff[rowData.staff]} />) || '-',
                        dataRef: 'staff',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },  
                    {
                        heading: 'Salesperson',
                        field: rowData => (staff && staff[rowData.sp] && <StaffChip staff={staff[rowData.sp]} />) || '-',
                        dataRef: 'sp',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },  
                    {
                        heading: 'Threshold',
                        field: rowData => (rowData.qt_color && <ThresholdChip threshold={{ qt_color: rowData.qt_color, qt_label: rowData.qt_label }} />) || '-',
                        dataRef: 'qt_label',
                        sortRef: 'qt_min',
                        sizeToContent: true
                    },
                    {
                        actions: rowData => {
                            return [
                                {name: 'View Quote', icon: 'binoculars',  link: `/quotes/${rowData.id}`, doubleClick: true},
                                {name: "Preview Quote", icon: "eye", onClick: () => deployPreview(dispatch, rowData.id)},
                                {name: 'Download Quote PDF', icon: 'file-pdf', color: 'error',  onClick: () => rowData.legacy ? getLegacyQuotationPDF(rowData.id) : getPresignedUrl(rowData.pdf)},
                            ]
                        }
                    }
                ]
            break;
            case "Ordered":
                cols = [  
                    {
                        heading: 'Ref #',
                        field: rowData => rowData.ref,
                        dataRef: 'ref',
                        main: true,
                        sizeToContent: true
                    },   
                    {
                        heading: 'Date',
                        field: rowData => rowData.created,
                        fieldFormat: 'date',
                        dataRef: 'created',
                        sizeToContent: true
                    },    
                    {
                        heading: 'Customer',
                        field: rowData => (
                            <>
                                {rowData.customer}
                                <Typography variant="caption" component="div">{rowData.sc} | {rowData.acc}</Typography>
                            </>
                        ),
                        dataRef: 'customer',
                        main: true,
                        truncate: true,
                        sizeToContent: true
                    },  
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.acc,
                        dataRef: 'acc',
                        truncate: true,
                        hidden: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Status Code',
                        field: rowData => rowData.sc,
                        dataRef: 'sc',
                        truncate: true,
                        hidden: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Customer Reference',
                        field: rowData => rowData.crf,
                        dataRef: 'crf',
                        truncate: true,
                        main: true,
                    }, 
                    {
                        heading: 'Net Total',
                        field: rowData => rowData.net,
                        fieldFormat: 'currency',
                        dataRef: 'net',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        heading: 'Hot',
                        field: rowData => rowData.h,
                        fieldFormat: 'boolean',
                        dataRef: 'h',
                        alignment: 'center',
                        sizeToContent: true
                    },   
                    {
                        heading: 'Sent',
                        field: rowData => rowData.em,
                        alignment: 'center',
                        dataRef: 'em',
                        fieldFormat: 'boolean',
                        sizeToContent: true,
                    }, 
                    {
                        heading: 'Quoted By',
                        field: rowData => (staff && staff[rowData.staff] && <StaffChip staff={staff[rowData.staff]} />) || '-',
                        dataRef: 'staff',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },  
                    {
                        heading: 'Salesperson',
                        field: rowData => (staff && staff[rowData.sp] && <StaffChip staff={staff[rowData.sp]} />) || '-',
                        dataRef: 'sp',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },  
                    {
                        heading: 'Threshold',
                        field: rowData => (rowData.qt_color && <ThresholdChip threshold={{ qt_color: rowData.qt_color, qt_label: rowData.qt_label }} />) || '-',
                        dataRef: 'qt_label',
                        sortRef: 'qt_min',
                        sizeToContent: true
                    },
                    {
                        actions: rowData => {
                            return [
                                {name: 'View Quote', icon: 'binoculars',  link: `/quotes/${rowData.id}`, doubleClick: true},
                                {name: "Preview Quote", icon: "eye", onClick: () => deployPreview(dispatch, rowData.id)},
                                {name: 'Download Quote PDF', icon: 'file-pdf', color: 'error',  onClick: () => rowData.legacy ? getLegacyQuotationPDF(rowData.id) : getPresignedUrl(rowData.pdf)},
                            ]
                        }
                    }
                ]
            break;
            case "Draft":
                cols = [  
                    {
                        heading: 'Ref #',
                        field: rowData => rowData.ref,
                        dataRef: 'ref',
                        main: true,
                        sizeToContent: true
                    },   
                    {
                        heading: 'Date',
                        field: rowData => rowData.created,
                        fieldFormat: 'date',
                        dataRef: 'created',
                        sizeToContent: true
                    },  
                    {
                        heading: 'Customer',
                        field: rowData => (
                            <>
                                {rowData.customer}
                                <Typography variant="caption" component="div">{rowData.sc} | {rowData.acc}</Typography>
                            </>
                        ),
                        dataRef: 'customer',
                        main: true,
                        truncate: true,
                        sizeToContent: true
                    },  
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.acc,
                        dataRef: 'acc',
                        truncate: true,
                        hidden: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Status Code',
                        field: rowData => rowData.sc,
                        dataRef: 'sc',
                        truncate: true,
                        hidden: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Customer Reference',
                        field: rowData => rowData.crf,
                        dataRef: 'crf',
                        truncate: true,
                        main: true,
                        sizeToContent: true
                    }, 
                    {
                        heading: 'Net Total',
                        field: rowData => rowData.net,
                        fieldFormat: 'currency',
                        dataRef: 'net',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        heading: 'Hot',
                        field: rowData => rowData.h,
                        fieldFormat: 'boolean',
                        dataRef: 'h',
                        alignment: 'center',
                        sizeToContent: true
                    },   
                    {
                        heading: 'Quoted By',
                        field: rowData => (staff && staff[rowData.staff] && <StaffChip staff={staff[rowData.staff]} />) || '-',
                        dataRef: 'staff',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },  
                    {
                        heading: 'Salesperson',
                        field: rowData => (staff && staff[rowData.sp] && <StaffChip staff={staff[rowData.sp]} />) || '-',
                        dataRef: 'sp',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },  
                    {
                        heading: 'Threshold',
                        field: rowData => (rowData.qt_color && <ThresholdChip threshold={{ qt_color: rowData.qt_color, qt_label: rowData.qt_label }} />) || '-',
                        dataRef: 'qt_label',
                        sortRef: 'qt_min',
                        sizeToContent: true
                    }, 
                    {
                        actions: rowData => {
                            return [
                                {name: 'View Quote', icon: 'binoculars',  link: `/quotes/${rowData.id}`, doubleClick: true},
                                {name: "Preview Quote", icon: "eye", onClick: () => deployPreview(dispatch, rowData.id)},
                                {name: 'Download Quote PDF', icon: 'file-pdf', color: 'error',  onClick: () => rowData.legacy ? getLegacyQuotationPDF(rowData.id) : getPresignedUrl(rowData.pdf)},
                            ]
                        }
                    }
                ]
            break;
            case "Lost":
                cols = [  
                    {
                        heading: 'Ref #',
                        field: rowData => rowData.ref,
                        dataRef: 'ref',
                        main: true,
                        sizeToContent: true
                    },   
                    {
                        heading: 'Date',
                        field: rowData => rowData.created,
                        fieldFormat: 'date',
                        dataRef: 'created',
                        sizeToContent: true
                    },      
                    {
                        heading: 'Customer',
                        field: rowData => (
                            <>
                                {rowData.customer}
                                <Typography variant="caption" component="div">{rowData.sc} | {rowData.acc}</Typography>
                            </>
                        ),
                        dataRef: 'customer',
                        main: true,
                        truncate: true,
                        sizeToContent: true
                    },  
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.acc,
                        dataRef: 'acc',
                        truncate: true,
                        hidden: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Status Code',
                        field: rowData => rowData.sc,
                        dataRef: 'sc',
                        truncate: true,
                        hidden: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Customer Reference',
                        field: rowData => rowData.crf,
                        dataRef: 'crf',
                        main: true,
                    }, 
                    {
                        heading: 'Net Total',
                        field: rowData => rowData.net,
                        fieldFormat: 'currency',
                        dataRef: 'net',
                        alignment: 'right',
                        sizeToContent: true
                    },   
                    {
                        heading: 'Quoted By',
                        field: rowData => (staff && staff[rowData.staff] && <StaffChip staff={staff[rowData.staff]} />) || '-',
                        dataRef: 'staff',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },  
                    {
                        heading: 'Salesperson',
                        field: rowData => (staff && staff[rowData.sp] && <StaffChip staff={staff[rowData.sp]} />) || '-',
                        dataRef: 'sp',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },  
                    {
                        heading: 'Threshold',
                        field: rowData => (rowData.qt_color && <ThresholdChip threshold={{ qt_color: rowData.qt_color, qt_label: rowData.qt_label }} />) || '-',
                        dataRef: 'qt_label',
                        sortRef: 'qt_min',
                        sizeToContent: true
                    },
                    {
                        heading: 'Reason Lost',
                        field: rowData => rowData.rsn,
                        dataRef: 'rsn',
                        sizeToContent: true
                    }, 
                    {
                        actions: rowData => {
                            return [
                                {name: 'View Quote', icon: 'binoculars',  link: `/quotes/${rowData.id}`, doubleClick: true},
                                {name: "Preview Quote", icon: "eye", onClick: () => deployPreview(dispatch, rowData.id)},
                                {name: 'Download Quote PDF', icon: 'file-pdf', color: 'error',  onClick: () => rowData.legacy ? getLegacyQuotationPDF(rowData.id) : getPresignedUrl(rowData.pdf)},
                            ]
                        }
                    }
                ]
            break;
            case "Declined":
                cols = [  
                    {
                        heading: 'Ref #',
                        field: rowData => rowData.ref,
                        dataRef: 'ref',
                        main: true,
                        sizeToContent: true
                    },   
                    {
                        heading: 'Date',
                        field: rowData => rowData.created,
                        fieldFormat: 'date',
                        dataRef: 'created',
                        sizeToContent: true
                    },  
                    {
                        heading: 'Customer',
                        field: rowData => (
                            <>
                                {rowData.customer}
                                <Typography variant="caption" component="div">{rowData.sc} | {rowData.acc}</Typography>
                            </>
                        ),
                        dataRef: 'customer',
                        main: true,
                        truncate: true,
                        sizeToContent: true
                    },  
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.acc,
                        dataRef: 'acc',
                        truncate: true,
                        hidden: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Status Code',
                        field: rowData => rowData.sc,
                        dataRef: 'sc',
                        truncate: true,
                        hidden: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Customer Reference',
                        field: rowData => rowData.crf,
                        dataRef: 'crf',
                        main: true,
                    }, 
                    {
                        heading: 'Net Total',
                        field: rowData => rowData.net,
                        fieldFormat: 'currency',
                        dataRef: 'net',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        heading: 'Quoted By',
                        field: rowData => (staff && staff[rowData.staff] && <StaffChip staff={staff[rowData.staff]} />) || '-',
                        dataRef: 'staff',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },  
                    {
                        heading: 'Salesperson',
                        field: rowData => (staff && staff[rowData.sp] && <StaffChip staff={staff[rowData.sp]} />) || '-',
                        dataRef: 'sp',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },  
                    {
                        heading: 'Threshold',
                        field: rowData => (rowData.qt_color && <ThresholdChip threshold={{ qt_color: rowData.qt_color, qt_label: rowData.qt_label }} />) || '-',
                        dataRef: 'qt_label',
                        sortRef: 'qt_min',
                        sizeToContent: true
                    },
                    {
                        heading: 'Reason Declined',
                        field: rowData => '',
                        dataRef: 'rsn',
                        sizeToContent: true
                    }, 
                    {
                        actions: rowData => {
                            return [
                                {name: 'View Quote', icon: 'binoculars',  link: `/quotes/${rowData.id}`, doubleClick: true},
                                {name: "Preview Quote", icon: "eye", onClick: () => deployPreview(dispatch, rowData.id)},
                                {name: 'Download Quote PDF', icon: 'file-pdf', color: 'error',  onClick: () => rowData.legacy ? getLegacyQuotationPDF(rowData.id) : getPresignedUrl(rowData.pdf)},
                            ]
                        }
                    }
                ]
            break;

            default: 

                cols = [  
                    {
                        heading: 'Ref #',
                        field: rowData => rowData.ref,
                        dataRef: 'ref',
                        main: true,
                        sizeToContent: true
                    },   
                    {
                        heading: 'Date',
                        field: rowData => rowData.created,
                        fieldFormat: 'date',
                        dataRef: 'created',
                        sizeToContent: true
                    },   
                    {
                        heading: 'Customer',
                        field: rowData => (
                            <>
                                {rowData.customer}
                                <Typography variant="caption" component="div">{rowData.sc} | {rowData.acc}</Typography>
                            </>
                        ),
                        dataRef: 'customer',
                        main: true,
                        truncate: true,
                        sizeToContent: true
                    },  
                    {
                        heading: 'Account Code',
                        field: rowData => rowData.acc,
                        dataRef: 'acc',
                        truncate: true,
                        hidden: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Status Code',
                        field: rowData => rowData.sc,
                        dataRef: 'sc',
                        truncate: true,
                        hidden: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Customer Reference',
                        field: rowData => rowData.crf,
                        dataRef: 'crf',
                        truncate: true,
                        main: true,
                        sizeToContent: true
                    }, 
                    {
                        heading: 'Net Total',
                        field: rowData => rowData.net,
                        fieldFormat: 'currency',
                        dataRef: 'net',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        heading: 'Hot',
                        field: rowData => rowData.h,
                        fieldFormat: 'boolean',
                        dataRef: 'h',
                        alignment: 'center',
                        sizeToContent: true
                    },   
                    {
                        heading: 'Sent',
                        field: rowData => rowData.em,
                        alignment: 'center',
                        dataRef: 'em',
                        fieldFormat: 'boolean',
                        sizeToContent: true,
                    }, 
                    {
                        heading: 'Quoted By',
                        field: rowData => (staff && staff[rowData.staff] && <StaffChip staff={staff[rowData.staff]} />) || '-',
                        dataRef: 'staff',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },  
                    {
                        heading: 'Salesperson',
                        field: rowData => (staff && staff[rowData.sp] && <StaffChip staff={staff[rowData.sp]} />) || '-',
                        dataRef: 'sp',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },  
                    {
                        heading: 'Threshold',
                        field: rowData => (rowData.qt_color && <ThresholdChip threshold={{ qt_color: rowData.qt_color, qt_label: rowData.qt_label }} />) || '-',
                        dataRef: 'qt_label',
                        sortRef: 'qt_min',
                        sizeToContent: true
                    },
                    {
                        heading: 'Status',
                        field: rowData => <StatusChip status={rowData.status} />,
                        dataRef: 'status',
                        sizeToContent: true
                    },   
                    {
                        actions: rowData => {
                            return [
                                {name: 'View Quote', icon: 'binoculars',  link: `/quotes/${rowData.id}`, doubleClick: true},
                                {name: "Preview Quote", icon: "eye", onClick: () => deployPreview(dispatch, rowData.id)},
                                {name: 'Download Quote PDF', icon: 'file-pdf', color: 'error',  onClick: () => rowData.legacy ? getLegacyQuotationPDF(rowData.id) : getPresignedUrl(rowData.pdf)},
                            ]
                        }
                    }
                ]

            break;
        }
    }

    return cols;

}

const SearchQuotesTable = ({basic, callback, customerId, dataLoading, inline, isCustomer, persistenceId, resetSearch, rows, staff, showOpenOnly, status}) => {
    
    const initialState = () => ([]);

    const dispatch = useDispatch();
    
    const [ checked, setChecked ] = useState(initialState());
    
    const handleCheckQuote = (quoteId) => {
        const index = checked.indexOf(quoteId);
        if (index === -1) {
            if(checked.length < 10) {
                setChecked([...checked, quoteId]);
            } else {
                dispatch(deploySnackBar(`error`, `You can only select up to 10 quotes at a time.`));
            }
        } else {
            setChecked(checked.filter(id => id !== quoteId));
        }
    }

    const handleSuccess = () => {
        setChecked(initialState());
        callback?.();
    }

    const handleSendQuotes = () => {

        let defaultFormData = {
            emailSubject: `Advanced Access | Quotes`,
        }

        dispatch(deployDialog(
            <EmailDialog
                callback={handleSuccess}
                id={checked}
                type='quote'
                success='The quotes have been successfully sent'
                defaultFormData={defaultFormData}
            />, false, "Send Quotes", "standard","md", false, true
        ))
    }

    return (
        <DataTable  
            config={{
                alternatingRowColours: true,
                inline: inline ? true : false,
                isLoading: dataLoading,
                key: 'id',
                pagination: true,
                persistenceId: persistenceId ?? null,
                plainPagination: inline ? true : false,
                rowsPerPage: showOpenOnly ? 50 : undefined,
                options: {
                    headingInput: (
                        isCustomer && customerId && checked.length > 0 && (
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={checked.length === 0}
                                onClick={handleSendQuotes}
                            >
                                <FAIcon icon="envelope" size={15} disabled={checked.length === 0} buttonPrimary />
                                E-mail {checked.length} Quote{checked.length > 1 ? 's' : ''}
                            </Button>
                        )
                    ),
                    dataRef: true,
                    reset: resetSearch,
                    export: {
                        title: `Quotes - ` + (status || 'All'),
                        name: `Quotes`,
                        excel: true,
                        pdf: true,
                        print: true
                    }
                }
            }}
            columns={getColumns(handleCheckQuote, checked, isCustomer, staff, showOpenOnly ? 'Compact' : (status ?? 'All'), basic, dispatch, callback)}
            rows={rows}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        staff: state.notifications.status
    };
}

export default connect(mapStateToProps)(SearchQuotesTable);