import React from 'react';
import { useDispatch, useSelector } from 'react-redux';


import API from 'API';
import DataTable from 'Components/Common/DataTables/DataTable';
import { pdfFromBase64 } from 'Helpers/PDFHelper';
import { getPresignedUrl } from 'Functions/S3Functions';
import StatusChip from 'Components/Common/Chips/StatusChip';
import StaffChip from 'Components/Common/Chips/StaffChip';
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';

const getLegacyReturnPDF = id => {
    if(id) {
        API.get(`/rma/${id}/legacy/pdf`)
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        })
    }
}

const getColumns = ({status, staff, access, basic, callback, dispatch}) => {
    
    const handleInProgressUser = (id, ps) => {
        dispatch(
            deployConfirmation(`Are you sure you want to ${ps > 0 ? `remove the review flag from this RMA?` : `mark this RMA as under review?`}`, () => {
                API.put(`/rma/${id}/review`).then((res) => {
                    if (res?.data?.success) {
                        dispatch(deploySnackBar(`success`, `You have successfully ${ps > 0 ? `cleared the review flag for this RMA` : `marked this RMA as under review`}`));
                        callback?.();
                    }
                });
            })
        );
    };

    if(basic) {
        return [  
            {
                heading: 'RMA Number',
                field: rowData => rowData.ref,
                dataRef: 'ref',
                main: true,
                sizeToContent: true
            },   
            {
                heading: 'Raised On',
                field: rowData => rowData.created,
                dataRef: 'created',
                sizeToContent: true
            },     
            {
                heading: 'Customer',
                field: rowData => rowData.cn,
                dataRef: 'cn',
                main: true,
                sizeToContent: true
            },
            {
                heading: 'Account Number',
                field: rowData => rowData.ac,
                dataRef: 'ac',
                main: true,
                sizeToContent: true
            },
            {
                heading: 'Net Credit',
                field: rowData => rowData.net,
                fieldFormat: 'currency',
                dataRef: 'net',
                alignment: 'right',
                sizeToContent: true
            },
            {
                heading: 'Raised By',
                field: rowData => (staff && staff[rowData.staff] && <StaffChip staff={staff[rowData.staff]} />) || '-',
                dataRef: 'staff',
                sizeToContent: true,
                fieldFormat: 'staff',
            },                      
            {
                heading: 'Status',
                field: rowData => <StatusChip status={rowData.status} />,
                dataRef: 'status',
                sizeToContent: true
            },   
            {
                actions: rowData => ([
                    { name: 'View RMA', icon: 'binoculars',  link: `/rma/${rowData.id}`, disabled: !access.viewRMA, doubleClick: true },
                    { name: 'Download Return Form', icon: 'file-pdf', color: 'error',  onClick: () => rowData.legacy ? getLegacyReturnPDF(rowData.id) : getPresignedUrl(rowData.pdf)}
                ])
            }
        ];
    } else {
        switch(status) {
            case "Awaiting Approval":
                return [  
                    {
                        heading: 'RMA Number',
                        field: rowData => rowData.ref,
                        dataRef: 'ref',
                        main: true,
                        sizeToContent: true
                    },   
                    {
                        heading: 'Raised On',
                        field: rowData => rowData.created,
                        dataRef: 'created',
                        sizeToContent: true
                    },     
                    {
                        heading: 'Customer',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        main: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.ac,
                        dataRef: 'ac',
                        main: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Net Credit',
                        field: rowData => rowData.net,
                        fieldFormat: 'currency',
                        dataRef: 'net',
                        alignment: 'right',
                        sizeToContent: true
                    },              
                    {
                        heading: 'Status',
                        field: rowData => <StatusChip status={rowData.status} />,
                        dataRef: 'status',
                        sizeToContent: true
                    },   
                    {
                        heading: 'Under Review By',
                        field: rowData => rowData.ps === 0 ? '-' : <StaffChip staff={staff[rowData.ps]} />,
                        fieldFormat: 'staff',
                        sizeToContent: true
                    },
                    {
                        actions: rowData => ([
                            { name: rowData.ps > 0 ? "Remove Review Flag" : "Mark as Under Review", icon: rowData.ps > 0 ? "user-slash" : "user", onClick: (rowData) => handleInProgressUser(rowData.id, rowData.ps)},
                            { name: 'View RMA', icon: 'binoculars',  link: `/rma/${rowData.id}`, disabled: !access.viewRMA, doubleClick: !access.approveRMA },
                            { name: 'Approve / Decline RMA', icon: 'check',  link: `/rma/approve/${rowData.id}`, disabled: !access.approveRMA, doubleClick: true },
                        ])
                    }
                ];
            case "Open":
                return [  
                    {
                        heading: 'RMA Number',
                        field: rowData => rowData.ref,
                        dataRef: 'ref',
                        main: true,
                        sizeToContent: true
                    },   
                    {
                        heading: 'Raised On',
                        field: rowData => rowData.created,
                        dataRef: 'created',
                        sizeToContent: true
                    },     
                    {
                        heading: 'Opened',
                        field: rowData => rowData.op,
                        dataRef: 'op',
                        sizeToContent: true
                    },     
                    {
                        heading: 'Received',
                        field: rowData => rowData.p,
                        fieldFormat: 'boolean',
                        dataRef: 'p',
                        sizeToContent: true,
                        alignment: 'center'
                    }, 
                    {
                        heading: 'Customer',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        main: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.ac,
                        dataRef: 'ac',
                        main: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Net Credit',
                        field: rowData => rowData.net,
                        fieldFormat: 'currency',
                        dataRef: 'net',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        heading: 'Raised By',
                        field: rowData => (staff && staff[rowData.staff] && <StaffChip staff={staff[rowData.staff]} />) || '-',
                        dataRef: 'staff',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },                      
                    {
                        heading: 'Status',
                        field: rowData => <StatusChip status={rowData.status} />,
                        dataRef: 'status',
                        sizeToContent: true
                    },   
                    {
                        actions: rowData => ([
                            { name: 'View RMA', icon: 'binoculars',  link: `/rma/${rowData.id}`, disabled: !access.viewRMA, doubleClick: true },
                            { name: 'Download Return Form', icon: 'file-pdf', color: 'error',  onClick: () => rowData.legacy ? getLegacyReturnPDF(rowData.id) : getPresignedUrl(rowData.pdf)}
                        ])
                    }
                ];
            default:
                return [  
                    {
                        heading: 'RMA Number',
                        field: rowData => rowData.ref,
                        dataRef: 'ref',
                        main: true,
                        sizeToContent: true
                    },   
                    {
                        heading: 'Raised On',
                        field: rowData => rowData.created,
                        dataRef: 'created',
                        sizeToContent: true
                    },     
                    {
                        heading: 'Customer',
                        field: rowData => rowData.cn,
                        dataRef: 'cn',
                        main: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Account Number',
                        field: rowData => rowData.ac,
                        dataRef: 'ac',
                        main: true,
                        sizeToContent: true
                    },
                    {
                        heading: 'Net Credit',
                        field: rowData => rowData.net,
                        fieldFormat: 'currency',
                        dataRef: 'net',
                        alignment: 'right',
                        sizeToContent: true
                    },
                    {
                        heading: 'Raised By',
                        field: rowData => (staff && staff[rowData.staff] && <StaffChip staff={staff[rowData.staff]} />) || '-',
                        dataRef: 'staff',
                        sizeToContent: true,
                        fieldFormat: 'staff',
                    },                      
                    {
                        heading: 'Status',
                        field: rowData => <StatusChip status={rowData.status} />,
                        dataRef: 'status',
                        sizeToContent: true
                    },   
                    {
                        actions: rowData => ([
                            { name: 'View RMA', icon: 'binoculars',  link: `/rma/${rowData.id}`, disabled: !access.viewRMA, doubleClick: true },
                            { name: 'Download Return Form', icon: 'file-pdf', color: 'error',  onClick: () => rowData.legacy ? getLegacyReturnPDF(rowData.id) : getPresignedUrl(rowData.pdf)}
                        ])
                    }
                ];
        }
    }
}

const SearchRMATable = ({access, basic, callback, dataLoading, inline, persistenceId, status, resetSearch, rows, rowsPerPage = 10}) => {
    const staff = useSelector(state => state.notifications.status)
    const dispatch = useDispatch();
    return (
        <DataTable  
            config={{
                key: 'id',
                pagination: true,
                persistenceId: persistenceId ?? null,
                alternatingRowColours: true,
                isLoading: dataLoading,
                // responsiveImportance: true,
                rowsPerPage: rowsPerPage,
                inline: inline,
                options: {
                    dataRef: true,
                    reset: resetSearch || false,
                    export: {
                        title: 'RMA - ' + (status || 'All'),
                        name: `RMA`,
                        excel: true,
                        pdf: true,
                        print: true
                    }
                }
            }}
            columns={getColumns({status: status ? status : 'all', staff, access, basic, callback, dispatch})}
            rows={rows}
        />
    )
}

export default SearchRMATable;