import API from "API";
import { API_URL } from "Constants";
import axios from "axios";
import fileDownload from "js-file-download";

export const getPresignedUrl = (url, fileName = null) => {
    let path = url.split("."),
        ext = path?.[path.length - 1] ?? null;

    if (ext) {
        let newWindow = null;
        ext = ext.toLowerCase();
        if (ext === "pdf" || ext === "png" || ext === "gif" || ext === "jpg" || ext === "jpeg" || ext === "bmp") {
            newWindow = window.open();
            newWindow.focus();
            newWindow.onblur = function () {
                newWindow.close();
            };
        }
        API.post("/cloud/presignedUrl", {
            download: true,
            request: btoa(url),
        }).then((result) => {
            if (result?.data?.url) {
                if (newWindow && fileName) {
                    let filePath = fileName.split(".");
                    filePath = filePath.slice(0, filePath.length - 1).join(".");
                    newWindow.location = `${API_URL}/proxy/${encodeURIComponent(filePath)}?req=${encodeURIComponent(result.data.url)}&nm=${fileName}`
                } else if(newWindow) {
                    newWindow.location = atob(result.data.url);
                } else {
                    axios.get(atob(result.data.url), {
                        responseType: 'blob'
                    })
                    .then((res) => {
                        if(res?.data) {
                            fileDownload(res.data, fileName ?? result?.data?.fn ?? 'file')
                        }
                    })
                }
            }
        });
    }
};
