import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { connect, useDispatch } from "react-redux";
import uuidv4 from "uuid/v4";

import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import API from "API";
import logo from "Assets/Images/no-img.jpg";
import SearchCreditNotes from "Components/Accounts/SearchCreditNotes/SearchCreditNotes";
import SearchInvoices from "Components/Accounts/SearchInvoices/SearchInvoices";
import CardDbSiteForm from "Components/CardDB/Misc/CardDbSiteForm";
import ActionMenu from "Components/Common/Activity/ActionMenu";
import ActivityLog from "Components/Common/Activity/ActivityLog";
import AddEmailLogForm from "Components/Common/Activity/AddEmailLogForm";
import AddCallLogForm from "Components/Common/Activity/AddCallLogForm";
import AddNoteForm from "Components/Common/Activity/AddNoteForm";
import SendSMSForm from "Components/Common/Activity/SendSMSForm";
import TaskForm from "Components/Common/Activity/TaskForm";
import ViewActions from "Components/Common/Activity/ViewActions";
import ViewAddresses from "Components/Common/Activity/ViewAddresses";
import ViewCallLogs from "Components/Common/Activity/ViewCallLogs";
import ViewContacts from "Components/Common/Activity/ViewContacts";
import ViewDocuments from "Components/Common/Activity/ViewDocuments";
import ViewNotes from "Components/Common/Activity/ViewNotes";
import Alert from "Components/Common/Alert/Alert";
import ActionChip from "Components/Common/Chips/ActionChip";
import InsightChip from "Components/Common/Chips/InsightChip";
import ProductStockChip from "Components/Common/Chips/ProductStockChip";
import DataTable from "Components/Common/DataTables/DataTable";
import AddressForm from "Components/Common/Forms/AddressForm";
import ContactForm from "Components/Common/Forms/ContactForm";
import DocumentForm from "Components/Common/Forms/DocumentForm";
import Heading from "Components/Common/Heading/Heading";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import Textarea from "Components/Common/Inputs/Textarea";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import ViewPage from "Components/Common/Styles/ViewPage";
import Tile from "Components/Common/Tiles/Tile";
import EmailLog from "Components/Communication/Communication/EmailLog";
import SmsLog from "Components/Communication/Communication/SmsLog";
import EmailDialog from "Components/Communication/Misc/EmailDialog";
import AccountsOverview from "Components/Customers/Misc/AccountsOverview";
import ActionCreditReport from "Components/Customers/Misc/ActionCreditReport";
import CreditOverview from "Components/Customers/Misc/CreditOverview";
import CustomerPriceList from "Components/Customers/Misc/CustomerPriceList";
import CustomerTrackedPriceList from "Components/Customers/Misc/CustomerTrackedPriceList";
import CustomerVisits from "Components/Customers/Misc/CustomerVisits";
import TradeZoneUserForm from "Components/Customers/Misc/TradeZoneUserForm";
import VisitForm from 'Components/Customers/Misc/VisitForm';
import ConvertProspect from "Components/NewBusiness/Misc/ConvertProspect";
import SearchQuotes from "Components/Quotes/SearchQuotes/SearchQuotes";
import SearchRMA from "Components/RMA/SearchRMA/SearchRMA";
import SearchOrders from "Components/Sales/SearchOrders/SearchOrders";
import { CLOUDFRONT_URL, VIEW_PAGE_APP_BAR_HEIGHT, VIEW_PAGE_OFFSET } from "Constants";
import WildixContext from "Context/WildixContext";
import {
    handleChange,
    handleCheckedChange, handleConfirmationClose, handleConfirmationOpen, handleConfirmationSuccess
} from "Functions/FormFunctions";
import {
    accountPaymentDueFrom, accountStatus, accountStatusList, currencyFormat,
    handleTabChange, momentFormatDateTime, TabPanel,
    toggleDialog
} from "Functions/MiscFunctions";
import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { closeDialog, deployDialog } from "Redux/Actions/Dialog/Dialog";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";
// import NotificationDialog from 'Components/Common/Dialogs/NotificationDialog';
import { CardHeader, DialogTitle, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Hidden, InputAdornment, TextField } from "@material-ui/core";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import StaffTile from "Components/Common/Tiles/StaffTile";
import { setPersistence } from "Redux/Actions/StatePersistence/StatePersistence";

import MostPurchased from "Components/Customers/Misc/MostPurchased";
import ProductHistory from "Components/Reporting/Products/ProductHistory/ProductHistory";
import { getInitialState, hasPageState, savePageState } from "Functions/StatePersistence/StatePersistenceFunctions";

const ManageCredit = ({ callback, closeDialog, customer, deployConfirmation, deploySnackBar, loadComponentData }) => {
    const initialState = () => ({
        creditLimit: "",
        crReason: "",
        eulerCover: "",
        isLoading: false,
        reason: "",
        paymentTerm: "",
        paymentTerms: [],
        emailCredit: 1,
    });

    const hasCredit = customer.has_credit === 1,
        [action, setAction] = useState(null),
        [creditLimit, setCreditLimit] = useState(hasCredit ? customer.credit_limit : initialState().creditLimit),
        [eulerCover, setEulerCover] = useState(hasCredit ? customer.euler : initialState().eulerCover),
        [reason, setReason] = useState(initialState().creditMemo),
        [crReason, setCrReason] = useState(initialState().crReason),
        [isLoading, setLoading] = useState(initialState().isLoading),
        [sageCode, setSageCode] = useState(customer?.sage_status ?? ""),
        [paymentTerm, setPaymentTerm] = useState(
            hasCredit ? customer.payment_terms?.pt_id ?? "" : initialState().paymentTerm
        ),
        [paymentTerms, setPaymentTerms] = useState(initialState().paymentTerms),
        [emailCredit, setEmailCredit] = useState(initialState().emailCredit),
        [emailCreditName, setEmailCreditName] = useState(customer.company_name),
        [emailCreditAddress, setEmailCreditAddress] = useState(customer.email);
        
    const manageCreditAccount = () => {
        const route = hasCredit ? "changeLimit" : "open";
        setLoading(true);
        API.put(`/customers/${customer.id}/sage/credit/${route}`, {
            creditLimit,
            eulerCover,
            reason,
            paymentTerm,
            emailCredit,
            emailCreditName,
            emailCreditAddress
        }).then((res) => {
            if (res?.data) {
                loadComponentData(true, { handleDeployCreditAccount: true });
                closeDialog();
                if (hasCredit) {
                    deploySnackBar(
                        `success`,
                        `You have successfully changed the customer's credit terms - current credit limit: ${currencyFormat.format(
                            creditLimit
                        )}`
                    );
                } else {
                    deploySnackBar(
                        `success`,
                        `You have successfully opened a credit facility for this customer with a credit limit of ${currencyFormat.format(
                            creditLimit
                        )}`
                    );
                }
            }
        });
    };

    const toggleCreditHold = () => {
        let onHold = customer?.on_hold === 1 ? 0 : 1;
        setLoading(true);
        API.put(`/customers/${customer.id}/sage/credit/hold`, { onHold, crReason, sageCode }).then((res) => {
            if (res?.data) {
                closeDialog();
                callback({ handleDeployCreditAccount: true });
                if (onHold === 1) {
                    deploySnackBar(`success`, `You have successfully placed the account on-hold`);
                } else {
                    deploySnackBar(`success`, `You have successfully removed the hold from this account`);
                }
            }
        });
    };

    const closeCreditAccount = () => {
        setLoading(true);
        API.put(`/customers/${customer.id}/sage/credit/close`, { crReason, sageCode }).then((res) => {
            if (res?.data?.errors) {
                setLoading(false);
                deploySnackBar(
                    `error`,
                    `Something went wrong, please try again!`
                );
            } else if(res?.data?.liveOrders) {
                setLoading(false);
                deploySnackBar(
                    `error`,
                    `Unable to close account currently as there are outstanding sales orders on credit terms`
                );
            } else {
                closeDialog();
                loadComponentData(true, { handleDeployCreditAccount: false });
                deploySnackBar(
                    `success`,
                    `You have successfully closed the customer's credit facility - any future orders will be on prepay terms.`
                );
            }
        });
    };

    useEffect(() => {
        API.get("/accounts/paymentTerms", { params: { forCredit: true } }).then((res) => {
            if (res?.data) {
                let paymentTerms = _.map(res.data, (el) =>
                    _.assign({
                        value: el.pt_id,
                        label: el.pt_name,
                    })
                );
                setPaymentTerms(paymentTerms);
            }
        });
    }, []);

    return (
        <Box className="content-light-grey">
            <DialogTitle disableTypography style={{ paddingBottom: 0 }}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h6">Credit Facility Management</Typography>
                        <Grid container alignItems="center" className="pb-2">
                            <Grid item xs>
                                <Typography variant="body2" className="fw-400">
                                    {customer.company_name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item align="right">
                        <IconButton aria-label="Close" onClick={closeDialog} disabled={isLoading}>
                            <FAIcon type="light" icon="times" button noMargin />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <Box p={3} pt={0.5}>
                <Box pb={3}>
                    {(customer?.on_hold === 1 && (
                        <Alert severity={"error"} elevation={0}>
                            <strong>Important -</strong> This account is currently on-hold, please see the credit review
                            memo for more detail
                        </Alert>
                    )) || (
                        <Alert severity={hasCredit ? "info" : "warning"} elevation={0}>
                            {(hasCredit && (
                                <>
                                    <strong>Important - </strong>
                                    This customer has a credit facility which{" "}
                                    {!_.isEmpty(customer.request_credit_report)
                                        ? `was last reviewed on ${momentFormatDateTime(
                                              customer.request_credit_report?.[0]?.rc_actioned_datetime
                                          )}`
                                        : `has not been reviewed yet`}
                                </>
                            )) || (
                                <>
                                    <strong>Important - </strong>
                                    Please ensure all documents are checked and you complete the necessary background
                                    checks prior to opening a credit facility
                                </>
                            )}
                        </Alert>
                    )}
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={5}>
                        <Grid container spacing={3}>
                            {(action === "terms" && (
                                <Grid item xs={12}>
                                    {(isLoading && (
                                        <Box p={3} pt={2.5} pb={3} className="content-light-white">
                                            <Typography variant="h6" gutterBottom>
                                                {hasCredit ? "Change Credit Limit / Due Days" : "Open Credit Facility"}
                                            </Typography>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <LoadingCircle size={40} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )) || (
                                        <Box p={3} pt={2.5} pb={3} className="content-light-white">
                                            <Typography variant="h6" gutterBottom>
                                                {hasCredit ? "Change Credit Limit / Due Days" : "Open Credit Facility"}
                                            </Typography>
                                            <Grid container>
                                                <Grid item xs={12} md={6} className="pr-2">
                                                    <TextField
                                                        label="Credit Limit *"
                                                        value={creditLimit}
                                                        onChange={(e) => setCreditLimit(e?.target?.value ?? "")}
                                                        onBlur={(e) => setCreditLimit(e?.target?.value?.length > 0 ? parseFloat(e?.target?.value).toFixed(2) : '')}
                                                        type="number"
                                                        margin="none"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <FAIcon type="thin" icon="pound-sign" size="small" />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} className="pl-1">
                                                    <TextField
                                                        label="Euler Cover *"
                                                        value={eulerCover}
                                                        onChange={(e) => setEulerCover(e?.target?.value ?? "")}
                                                        onBlur={(e) => setEulerCover(e?.target?.value?.length > 0 ? parseFloat(e?.target?.value).toFixed(2) : '')}
                                                        type="number"
                                                        margin="none"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <FAIcon type="thin" icon="pound-sign" size="small" />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                {(creditLimit === "0.00" && (
                                                    <Grid item xs={12}>
                                                        <Alert severity="error">
                                                            <Typography variant="body2">
                                                                <strong>Are you trying to close the credit facility?</strong><br />
                                                                To change this account to prepay, please click the back button below, then select the "Close Credit Facility / Place On Hold" option.
                                                            </Typography>
                                                        </Alert>
                                                    </Grid>
                                                )) || (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <AutoCompleteSelect
                                                                options={paymentTerms}
                                                                label="Payment Terms *"
                                                                value={paymentTerm}
                                                                onChange={(v) => setPaymentTerm(v?.value ?? "")}
                                                                noClear
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} className="pt-1">
                                                            <Textarea
                                                                label="Memo Comments:"
                                                                onChange={(e) => setReason(e?.target?.value ?? "")}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                rows={2}
                                                                value={reason}
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        {!hasCredit && (
                                                            <Grid item xs={12} className="pt-3">
                                                                <FormControlLabel
                                                                    className="pb-1"
                                                                    control={
                                                                        <Switch color="primary" checked={emailCredit === 1} onChange={() => setEmailCredit(emailCredit === 1 ? 0 : 1)} value="1" name="emailCredit" />
                                                                    }
                                                                    label={<><Typography className="fw-400 pb-1" variant="body2">Send Credit Application Successful E-mail</Typography>
                                                                    <TextField
                                                                        placeholder="Contact Name"
                                                                        name="emailCreditName"
                                                                        value={emailCreditName}
                                                                        onChange={(e) => setEmailCreditName(e.target.value)}
                                                                        variant="outlined"
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <FAIcon icon="user" size={15} type="thin" noMargin />
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                        size="small"
                                                                        margin="none"
                                                                        fullWidth
                                                                        required
                                                                        disabled={emailCredit === 0}
                                                                    />
                                                                    <TextField
                                                                        className="pt-1"
                                                                        placeholder="Email Address"
                                                                        name="emailCreditAddress"
                                                                        value={emailCreditAddress}
                                                                        onChange={(e) => setEmailCreditAddress(e.target.value)}
                                                                        variant="outlined"
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <FAIcon icon="at" size={15} type="thin" noMargin />
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                        size="small"
                                                                        margin="none"
                                                                        fullWidth
                                                                        required
                                                                        disabled={emailCredit === 0}
                                                                    /></>}
                                                                />
                                                            </Grid>
                                                        )}
                                                        <Grid item xs={12} className="pt-3">
                                                            <Button
                                                                className={
                                                                    paymentTerm === "" || creditLimit === "" || eulerCover === "" || creditLimit === "0.00" || (!hasCredit && emailCredit === 1 && (emailCreditName === '' || emailCreditAddress === ''))
                                                                        ? "btn btn-disabled"
                                                                        : "btn btn-success"
                                                                }
                                                                color="primary"
                                                                fullWidth
                                                                disabled={
                                                                    paymentTerm === "" || creditLimit === "" || eulerCover === "" || creditLimit === "0.00" || (!hasCredit && emailCredit === 1 && (emailCreditName === '' || emailCreditAddress === ''))
                                                                }
                                                                onClick={() =>
                                                                    deployConfirmation(
                                                                        `Are you sure you want to ${
                                                                            hasCredit
                                                                                ? "change the credit terms for this account?"
                                                                                : "open a credit facility for this customer?"
                                                                        }`,
                                                                        manageCreditAccount
                                                                    )
                                                                }
                                                            >
                                                                <FAIcon icon="check" size={15} button />
                                                                Confirm
                                                            </Button>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </Box>
                                    )}
                                </Grid>
                            )) || (action === "close" && (
                                <Grid item xs={12}>
                                    {(isLoading && (
                                        <Box p={3} pt={2.5} pb={3} className="content-light-white">
                                            <Typography variant="h6" gutterBottom>
                                                Close Credit Facility / Place On Hold
                                            </Typography>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <LoadingCircle size={40} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )) || (
                                        <Box p={3} pt={3} pb={3} className="content-light-white">
                                            <Typography variant="h6" gutterBottom>
                                                Close Credit Facility / Place On Hold
                                            </Typography>
                                            <Grid container spacing={3} className="pt-1">
                                                <Grid item xs={12}>
                                                    <AutoCompleteSelect
                                                        options={accountStatusList()}
                                                        label="Proposed Sage Account Status *"
                                                        value={sageCode}
                                                        onChange={(v) => setSageCode(v?.value ?? "")}
                                                        noClear
                                                        noDefaultSort
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className="pt-0">
                                                    <Textarea
                                                        label="Memo Comments *"
                                                        onChange={(e) => setCrReason(e?.target?.value ?? "")}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        rows={2}
                                                        value={crReason}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        className={
                                                            crReason !== "" ? "btn btn-warning" : "btn btn-disabled"
                                                        }
                                                        color="primary"
                                                        fullWidth
                                                        disabled={crReason === ""}
                                                        onClick={() =>
                                                            deployConfirmation(
                                                                `Are you sure you want to ${
                                                                    customer?.on_hold === 1
                                                                        ? "remove the hold from this account?"
                                                                        : "place this account on hold?"
                                                                }`,
                                                                toggleCreditHold
                                                            )
                                                        }
                                                    >
                                                        <FAIcon icon="exclamation-triangle" size={15} button />
                                                        {customer?.on_hold === 1 ? "Remove Hold" : "Place On Hold"}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {(customer.liveSO > 0 && (
                                                        <Alert severity="warning">
                                                            <Typography variant="caption">
                                                                There are {customer.liveSO} sales order(s) outstanding on credit terms, these must be completed or payment terms modified before you can close the credit facility. You can still place the account on hold by entering comments above.
                                                            </Typography>
                                                        </Alert>
                                                    )) || (
                                                        <Button
                                                            className={
                                                                crReason !== "" ? "btn btn-error" : "btn btn-disabled"
                                                            }
                                                            color="primary"
                                                            fullWidth
                                                            disabled={crReason === ""}
                                                            onClick={() =>
                                                                deployConfirmation(
                                                                    customer.credit_balance > 0 ? (
                                                                        <>
                                                                            <Typography variant="body2" className="fw-400">
                                                                                This customer has a balance of{" "}
                                                                                {currencyFormat.format(
                                                                                    customer.credit_balance
                                                                                )}
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                Are you sure you want to close their credit
                                                                                account?
                                                                            </Typography>
                                                                        </>
                                                                    ) : (
                                                                        `Are you sure you want to close the credit facility?`
                                                                    ),
                                                                    () => closeCreditAccount()
                                                                )
                                                            }
                                                        >
                                                            <FAIcon icon="times" size={15} button />
                                                            Close Credit Facility
                                                        </Button>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                </Grid>
                            )) || (
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} align="center">
                                            <Box p={3} className="content-light-white content-light-white-clickable" onClick={() => setAction("terms")}>
                                                <Box pb={1}>
                                                    <FAIcon icon={hasCredit ? 'cog' : 'check-circle'} size={50} noMargin button />
                                                </Box>
                                                <Typography variant="h6" gutterBottom>
                                                    {hasCredit ? "Change Credit Limit / Due Days" : "Open Credit Facility"}
                                                </Typography>
                                                <Typography variant="body2">
                                                    <FAIcon icon="info-circle" size={12.5} type="solid" button />
                                                    {hasCredit ? "This will allow you to change the credit limit, payment due days or euler cover limit for this account." : "This will allow you to open a credit facility for this customer and send the success / credit limit e-mail."}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {hasCredit && (
                                            <Grid item xs={12} align="center">
                                                <Box p={3} className="content-light-white content-light-white-clickable" onClick={() => setAction("close")}>
                                                    <Box pb={1}>
                                                        <FAIcon icon="exclamation-circle" size={50} noMargin button />
                                                    </Box>
                                                    <Typography variant="h6" gutterBottom>
                                                        Close Credit Facility / Place On Hold
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <FAIcon icon="info-circle" size={12.5} type="solid" button />
                                                        This will allow you to close the credit facility for this customer or place the account on hold in Sage.
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {action !== null && (
                                <Grid item xs={12}>
                                    <Button
                                        color="primary"
                                        variant="text"
                                        fullWidth
                                        onClick={() => setAction(null)}
                                    >
                                        <FAIcon icon="arrow-left" size={15} button />
                                        Back
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Box p={3} pt={2} pb={1} className="content-light-white">
                                    <Typography variant="h6" paragraph>
                                        Account Overview
                                    </Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <CardHeader
                                                title={
                                                    <Typography
                                                        variant="h3"
                                                        style={{ textAlign: "center", color: "#fff" }}
                                                    >
                                                        {currencyFormat.format(customer.credit_limit)}
                                                    </Typography>
                                                }
                                                subheader={
                                                    <Typography
                                                        variant="subtitle1"
                                                        style={{ textAlign: "center", color: "#fff" }}
                                                    >
                                                        Credit Limit
                                                    </Typography>
                                                }
                                                style={{ backgroundColor: "#ef3340", borderRadius: 8 }}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={
                                                    <FAIcon
                                                        type="thin"
                                                        icon={
                                                            parseFloat(customer.credit_balance) >
                                                            parseFloat(customer.credit_limit)
                                                                ? "exclamation-triangle"
                                                                : "check"
                                                        }
                                                        style={{
                                                            color:
                                                                (customer &&
                                                                    customer.credit_balance &&
                                                                    customer.credit_limit &&
                                                                    parseFloat(customer.credit_balance) >
                                                                        parseFloat(customer.credit_limit) &&
                                                                    "#ef3340") ||
                                                                "#4CAF50",
                                                        }}
                                                        noMargin
                                                        size={35}
                                                    />
                                                }
                                                title={
                                                    <Typography variant="h5">
                                                        {currencyFormat.format(parseFloat(customer.credit_balance))}
                                                    </Typography>
                                                }
                                                subheader={<Typography variant="caption">Current Balance</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon="shield-check" noMargin size={35} />}
                                                title={
                                                    <Typography variant="h5">
                                                        {customer.euler !== "0.00"
                                                            ? currencyFormat.format(customer.euler)
                                                            : "None"}
                                                    </Typography>
                                                }
                                                subheader={<Typography variant="caption">Euler Cover</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={
                                                    <FAIcon
                                                        type="thin"
                                                        icon={
                                                            customer.credit_data &&
                                                            customer.credit_data.paymentDueDays &&
                                                            customer.credit_data.averagePayDays &&
                                                            parseInt(customer.credit_data.averagePayDays) <=
                                                                parseInt(customer.credit_data.paymentDueDays)
                                                                ? "check"
                                                                : "exclamation-triangle"
                                                        }
                                                        style={{
                                                            color:
                                                                (customer.credit_data &&
                                                                    customer.credit_data.paymentDueDays &&
                                                                    customer.credit_data.averagePayDays &&
                                                                    parseInt(customer.credit_data.averagePayDays) <=
                                                                        parseInt(customer.credit_data.paymentDueDays) &&
                                                                    "#4CAF50") ||
                                                                "#ef3340",
                                                        }}
                                                        noMargin
                                                        size={35}
                                                    />
                                                }
                                                title={
                                                    <Typography variant="h5">
                                                        {customer.credit_data &&
                                                            parseFloat(customer.credit_data.averagePayDays).toFixed(0)}
                                                    </Typography>
                                                }
                                                subheader={<Typography variant="caption">Average Pay Days</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={
                                                    <FAIcon
                                                        type="thin"
                                                        icon={
                                                            customer?.credit_data?.termsAgreed &&
                                                            parseInt(customer?.credit_data?.termsAgreed) === 1
                                                                ? "check"
                                                                : "exclamation-triangle"
                                                        }
                                                        style={{
                                                            color:
                                                                customer?.credit_data?.termsAgreed &&
                                                                parseInt(customer?.credit_data?.termsAgreed) === 1
                                                                    ? "#4CAF50"
                                                                    : "#ef3340",
                                                        }}
                                                        noMargin
                                                        size={35}
                                                    />
                                                }
                                                title={
                                                    <Typography variant="h5">
                                                        {customer?.credit_data?.termsAgreed &&
                                                        parseInt(customer?.credit_data?.termsAgreed) === 1
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                }
                                                subheader={<Typography variant="caption">Terms Agreed</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={
                                                    <FAIcon
                                                        type="thin"
                                                        icon={customer.liveSO > 0 ? "exclamation-triangle" : "check"}
                                                        style={{ color: customer.liveSO > 0 ? "#ef3340" : "#4CAF50" }}
                                                        noMargin
                                                        size={35}
                                                    />
                                                }
                                                title={<Typography variant="h5">{customer.liveSO}</Typography>}
                                                subheader={
                                                    <Typography variant="caption">Pending Credit Sales Orders</Typography>
                                                }
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={
                                                    <FAIcon
                                                        type="thin"
                                                        icon={customer.liveRMA > 0 ? "exclamation-triangle" : "check"}
                                                        style={{ color: customer.liveRMA > 0 ? "#ef3340" : "#4CAF50" }}
                                                        noMargin
                                                        size={35}
                                                    />
                                                }
                                                title={<Typography variant="h5">{customer.liveRMA}</Typography>}
                                                subheader={<Typography variant="caption">Open RMA</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={
                                                    <FAIcon type="thin" icon="money-check-edit" noMargin size={35} />
                                                }
                                                title={
                                                    <Typography variant="h5">
                                                        {customer.payment_terms?.pt_name}
                                                    </Typography>
                                                }
                                                subheader={<Typography variant="caption">RED Payment Terms</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon="calendar-alt" noMargin size={35} />}
                                                title={
                                                    <Typography variant="h5">
                                                        {customer.credit_data && customer.credit_data.paymentDueDays}{" "}
                                                        {customer.credit_data &&
                                                            accountPaymentDueFrom(customer.credit_data.paymentDueFrom)}
                                                    </Typography>
                                                }
                                                subheader={<Typography variant="caption">Sage Payment Due</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon="signature" noMargin size={35} />}
                                                title={
                                                    <Typography variant="h5">
                                                        {customer?.credit_data?.terms ?? "-"}
                                                    </Typography>
                                                }
                                                subheader={
                                                    <Typography variant="caption">Sage Account Terms</Typography>
                                                }
                                                disableTypography
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CardHeader
                                                avatar={<FAIcon type="thin" icon={"user-cog"} noMargin size={35} />}
                                                title={
                                                    <Typography variant="h5">
                                                        {customer.credit_data &&
                                                            accountStatus(customer.credit_data.accountStatus)}
                                                    </Typography>
                                                }
                                                subheader={<Typography variant="caption">Sage Account Status</Typography>}
                                                disableTypography
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

const CloseAccount = ({ handleSubmit, isProspect, id }) => {
    const dispatch = useDispatch(),
        [reason, setReason] = useState(''),
        [accCode, setAccCode] = useState(''),
        [sageCode, setSageCode] = useState(2),
        [isLoading, setLoading] = useState(true),
        [accCodes, setAccCodes] = useState([]);

    const submit = () => {
        setLoading(true);
        handleSubmit(id, accCode, sageCode, reason);
    };

    useEffect(() => {
        API.get("/customers/statuses", { params: { closed: true } }).then((res) => {
            if (res?.data) {
                setLoading(false);
                setAccCodes(
                    _.map(res.data, (el) =>
                        _.assign({
                            label: el.status_name,
                            options: _.map(el.children, (_el) => {
                                return _.assign({
                                    value: _el.status_id,
                                    label: `${_el.status_code} - ${_el.status_name}`,
                                });
                            }),
                        })
                    )
                );
                setAccCode(res.data?.[0]?.children?.[0]?.status_id);
            }
        });
    }, []);

    return (
        (isLoading && <LoadingCircle />) || (
            <Box mt={1.5}>
                <AutoCompleteSelect
                    options={accCodes}
                    label="Account Status *"
                    isGrouped={true}
                    value={accCode}
                    onChange={(v) => setAccCode(v?.value ?? "")}
                    noClear
                />
                {!isProspect && (
                    <>
                        <Box pt={1}>
                            <AutoCompleteSelect
                                options={accountStatusList(true)}
                                label="Sage Account Status *"
                                value={sageCode}
                                onChange={(v) => setSageCode(v?.value ?? "")}
                                noClear
                                noDefaultSort
                            />
                        </Box>
                        <Box pt={2}>
                            <Textarea
                                label="Closure Reason:"
                                onChange={(e) => setReason(e?.target?.value ?? "")}
                                rows={2}
                                value={reason}
                                variant="outlined"
                            />
                        </Box>
                    </>
                )}
                <div className="buttonRow">
                    <Button color="primary" onClick={() => dispatch(closeDialog())} variant="text">
                        <FAIcon icon="times" button />
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        disabled={accCode === "" || sageCode === ""}
                        onClick={() =>
                            dispatch(
                                deployConfirmation(`Are you sure you want to close this account?`, () =>
                                    submit(id, accCode, reason)
                                )
                            )
                        }
                        variant="text"
                    >
                        <FAIcon icon="check" button />
                        Close {isProspect ? 'Prospect' : 'Account'}
                    </Button>
                </div>
            </Box>
        )
    );
};

const OpenAccount = ({ handleSubmit, id }) => {
    const dispatch = useDispatch(),
        [reason, setReason] = useState(""),
        [accCode, setAccCode] = useState(""),
        [isLoading, setLoading] = useState(true),
        [accCodes, setAccCodes] = useState([]);

    const submit = () => {
        setLoading(true);
        handleSubmit(id, accCode, reason);
    };

    useEffect(() => {
        API.get("/customers/statuses", { params: { open: true } }).then((res) => {
            if (res?.data) {
                setLoading(false);
                setAccCodes(
                    _.map(res.data, (el) =>
                        _.assign({
                            label: el.status_name,
                            options: _.map(el.children, (_el) => {
                                return _.assign({
                                    value: _el.status_id,
                                    label: `${_el.status_code} - ${_el.status_name}`,
                                });
                            }),
                        })
                    )
                );
            }
        });
    }, []);

    return (
        (isLoading && <LoadingCircle />) || (
            <Box mt={1.5}>
                <AutoCompleteSelect
                    options={accCodes}
                    label="Account Status *"
                    isGrouped={true}
                    value={accCode}
                    onChange={(v) => setAccCode(v?.value ?? "")}
                />
                <Box pt={2}>
                    <Textarea
                        label="Reason:"
                        onChange={(e) => setReason(e?.target?.value ?? "")}
                        rows={2}
                        value={reason}
                        variant="outlined"
                    />
                </Box>
                <div className="buttonRow">
                    <Button color="primary" onClick={() => dispatch(closeDialog())} variant="text">
                        <FAIcon icon="times" button />
                        Abort
                    </Button>
                    <Button
                        color="primary"
                        disabled={accCode === ""}
                        onClick={() =>
                            dispatch(
                                deployConfirmation(`Are you sure you want to re-open this account?`, () =>
                                    submit(id, accCode, reason)
                                )
                            )
                        }
                        variant="text"
                    >
                        <FAIcon icon="check" button />
                        Re-open Account
                    </Button>
                </div>
            </Box>
        )
    );
};

const RequestAccountApplicationForm = ({ handleSubmit, id }) => {
    const dispatch = useDispatch(),
        [notes, setNotes] = useState("");

    return (
        <Box mt={1.5}>
            <Textarea
                label="Notes:"
                onChange={(e) => setNotes(e?.target?.value ?? "")}
                rows={2}
                value={notes}
                variant="outlined"
            />
            <div className="buttonRow">
                <Button color="primary" onClick={() => dispatch(closeDialog())} variant="text">
                    <FAIcon icon="times" button />
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() =>
                        dispatch(
                            deployConfirmation(`Are you sure you want to send this request?`, () =>
                                handleSubmit(id, notes)
                            )
                        )
                    }
                    variant="text"
                >
                    <FAIcon icon="check" button />
                    Send Request
                </Button>
            </div>
        </Box>
    );
};

const RequestAccountOpening = ({ handleSubmit, id }) => {
    const dispatch = useDispatch(),
        [notes, setNotes] = useState(""),
        [type, setType] = useState("");

    return (
        <Box mt={1.5}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <AutoCompleteSelect
                        label="Account Type *"
                        onChange={(o) => setType(o?.value ?? '')}
                        options={[
                            { value: "credit", label: "Credit Account" },
                            { value: "prepay", label: "Prepay Account" },
                        ]}
                        noClear
                        variant="outlined"
                        value={type}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Textarea
                        label="Notes:"
                        onChange={(e) => setNotes(e?.target?.value ?? "")}
                        rows={5}
                        value={notes}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <div className="buttonRow">
                <Button color="primary" onClick={() => dispatch(closeDialog())} variant="text">
                    <FAIcon icon="times" button />
                    Cancel
                </Button>
                <Button
                    color="primary"
                    disabled={!type}
                    onClick={() =>
                        dispatch(
                            deployConfirmation(`Are you sure you want to send this request?`, () =>
                                handleSubmit(id, notes, type)
                            )
                        )
                    }
                    variant="text"
                >
                    <FAIcon icon="check" button />
                    Send Request
                </Button>
            </div>
        </Box>
    );
};

const RequestCatalogue = ({ handleSubmit, id }) => {
    const dispatch = useDispatch(),
        [notes, setNotes] = useState("");

    return (
        <Box mt={1.5}>
            <Textarea
                label="Notes:"
                onChange={(e) => setNotes(e?.target?.value ?? "")}
                rows={2}
                value={notes}
                variant="outlined"
            />
            <div className="buttonRow">
                <Button color="primary" onClick={() => dispatch(closeDialog())} variant="text">
                    <FAIcon icon="times" button />
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() =>
                        dispatch(
                            deployConfirmation(`Are you sure you want to request a catalogue?`, () =>
                                handleSubmit(id, notes)
                            )
                        )
                    }
                    variant="text"
                >
                    <FAIcon icon="check" button />
                    Send Request
                </Button>
            </div>
        </Box>
    );
};

const RequestCreditCheck = ({ handleSubmit, id }) => {
    const dispatch = useDispatch(),
        [comments, setComments] = useState(""),
        [reason, setReason] = useState(null),
        [reasons, setReasons] = useState([]);

    const handleChangeReason = (v) => {
        setReason(v?.value ?? "");
        setComments(v?.text ?? "");
    }

    useEffect(() => {
        API.get("/accounts/creditReports/requests/reasons").then((res) => {
            if (res?.data) {
                setReasons(
                    _.map(res.data, (el) =>
                        _.assign({
                            value: el.crr_id,
                            label: el.crr_reason,
                            text: el.crr_default_text
                        })
                    )
                );
            }
        });
    }, []);

    return (
        <Box mt={1.5}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AutoCompleteSelect
                        options={reasons}
                        label="Reason *"
                        value={reason}
                        onChange={handleChangeReason}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Textarea
                    disabled={!reason}
                        label="Comments:"
                        onChange={(e) => setComments(e?.target?.value ?? "")}
                        rows={2}
                        value={comments}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <div className="buttonRow">
                <Button color="primary" onClick={() => dispatch(closeDialog())} variant="text">
                    <FAIcon icon="times" button />
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() =>
                        dispatch(
                            deployConfirmation(`Are you sure you want to request a credit check?`, () =>
                                handleSubmit(id, comments, reason)
                            )
                        )
                    }
                    variant="text"
                >
                    <FAIcon icon="check" button />
                    Send Request
                </Button>
            </div>
        </Box>
    );
};

const initialState = () => ({
    access: {
        accounts: false,
        addAddress: false,
        addCallLog: false,
        addContact: false,
        addNote: false,
        addPriorityNote: false,
        addSite: false,
        closeAccount: false,
        openAccount: false,
        credit: false,
        deleteAddress: false,
        deleteContact: false,
        modifyAddress: false,
        modifyContact: false,
        modifySite: false,
        newQuote: false,
        newOrder: false,
        newRMA: false,
        convertProspect: false,
        updateProspect: false,
        updateCustomer: false,
        monitorCustomer: false,
        uploadDoc: false,
        modifyDoc: false,
        deleteDoc: false,
        requestAccAppForm: false,
        requestAccOpening: false,
        requestCatalogue: false,
        requestCreditReport: false,
        actionCreditReport: false,
        viewProduct: false,
        tradeZone: false,
        tradeZoneMgt: false,
        priceList: false,
        trackedPriceList: false,
        deleteCustomer: false,
        deleteProspect: false,
        deleteTradeZoneUser: false,
        visits: false,
        addVisit: false
    },
    charts: {
        spend: {},
    },
    key: uuidv4(),
    smsContactList: [],
    currentTab: 0,
    customer: {},
    customerId: 0,
    isLoading: true,
    isProspect: false,
    creditLoading: true,
    primaryAddressIdx: -1,
    primaryContactIdx: -1,
    tradingAddress: [],
    viewing: {
        list: [],
        update: false,
    },
});

class ViewCustomer extends React.Component {
    static contextType = WildixContext;
    constructor(props) {
        super(props);
        this.mainContentArea = React.createRef();
        this.persistenceId = `customer:${this.props.id ?? this.props.match?.params?.id}`;
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState = hasPageState.bind(this);
        this.savePageState = savePageState.bind(this);
        this.state = this.getInitialState(initialState());
        this.toggleDialog = toggleDialog.bind(this);
        this.handleTabChange = handleTabChange.bind(this);
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this);
        this.handleConfirmationClose = handleConfirmationClose.bind(this);
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this);
        this.handleChange = handleChange.bind(this);
        this.handleCheckedChange = handleCheckedChange.bind(this);
        this.poll = false;
    }

    componentDidMount() {
        if (!this.hasPageState()) {
            API.multiAccess([
                "add-quote",
                "add-order",
                "close-account:customer",
                "open-account:customer",
                "update-customer",
                "monitor-customer",
                "update-prospect",
                "convert-prospect",
                "view-customer:add-note",
                "view-customer:add-priority-note",
                "view-customer:add-call",
                "view-customer:send-sms",
                "view-customer:add-address",
                "view-customer:add-contact",
                "view-customer:delete-address",
                "view-customer:delete-contact",
                "view-customer:modify-address",
                "view-customer:modify-contact",
                "view-customer:add-document",
                "view-customer:modify-document",
                "view-customer:delete-document",
                "request-acc-app-form",
                "req-acc-op",
                "request-catalogue",
                "credit-report-requests:create",
                "credit-report-requests:action",
                "view-customer:accounts",
                "view-customer:credit",
                "view-product",
                "view-customer:tradezone",
                "view-customer:tradezone-mgt",
                "carddb:add-site",
                "carddb:modify-site",
                "add-rma",
                "view-customer:price-list",
                "view-customer:trk-price-list",
                "delete-customer",
                "delete-prospect",
                "delete-tz-user",
                "view-customer:visits",
                "view-customer:add-visit"
            ]).then(
                ([
                    newQuote,
                    newOrder,
                    closeAccount,
                    openAccount,
                    updateCustomer,
                    monitorCustomer,
                    updateProspect,
                    convertProspect,
                    addNote,
                    addPriorityNote,
                    addCallLog,
                    sendSms,
                    addAddress,
                    addContact,
                    deleteAddress,
                    deleteContact,
                    modifyAddress,
                    modifyContact,
                    uploadDoc,
                    modifyDoc,
                    deleteDoc,
                    requestAccAppForm,
                    requestAccOpening,
                    requestCatalogue,
                    requestCreditReport,
                    actionCreditReport,
                    accounts,
                    credit,
                    viewProduct,
                    tradeZone,
                    tradeZoneMgt,
                    addSite,
                    modifySite,
                    newRMA,
                    priceList,
                    trackedPriceList,
                    deleteCustomer,
                    deleteProspect,
                    deleteTradeZoneUser,
                    visits,
                    addVisit
                ]) => {
                    let customerId = this.props.id ?? this.props.match?.params?.id,
                        showCreditOnly = this.props.showCreditOnly ? true : false,
                        currentTab = this.props.location?.state?.currentTab ?? 0;

                    if (currentTab > 0) {
                        this.props.history.replace({ state: { currentTab: undefined } });
                    } else if (this.props.location?.hash === "#credit") {
                        currentTab = 16;
                    }

                    this.setState(
                        {
                            access: {
                                ...this.state.access,
                                accounts,
                                addAddress,
                                addContact,
                                closeAccount,
                                openAccount,
                                sendSms,
                                addCallLog,
                                addNote,
                                addPriorityNote,
                                addSite,
                                credit,
                                newQuote,
                                newOrder,
                                newRMA,
                                updateCustomer,
                                monitorCustomer,
                                convertProspect,
                                updateProspect,
                                deleteAddress,
                                deleteContact,
                                modifyAddress,
                                modifyContact,
                                modifySite,
                                uploadDoc,
                                modifyDoc,
                                deleteDoc,
                                requestAccAppForm,
                                requestAccOpening,
                                requestCatalogue,
                                requestCreditReport,
                                actionCreditReport,
                                viewProduct,
                                tradeZone,
                                tradeZoneMgt,
                                priceList,
                                trackedPriceList,
                                deleteCustomer,
                                deleteProspect,
                                deleteTradeZoneUser,
                                visits,
                                addVisit
                            },
                            currentTab,
                            customerId,
                            showCreditOnly,
                        },
                        () => {
                            this.poll = setInterval(this.handlePoll, 5000);
                            this.loadComponentData(true);
                        }
                    );
                }
            );
        } else {
            if (this.props.pageTitle)
                this.props.pageTitle([
                    1,
                    this.state.isProspect ? "New Business" : "Customers",
                    this.state.customer.company_name,
                ]);
            this.loadComponentData(true);
        }
    }

    componentDidUpdate = (prevProps) => {
        if (
            prevProps.match?.params?.id !== this.props.match?.params?.id
        ) {
            let currentTab = this.props.location?.state?.currentTab ?? 0;

            if (currentTab > 0) {
                this.props.history.replace({ state: { currentTab: undefined } });
            } else if (this.props.location?.hash === "#credit") {
                currentTab = 16;
            }

            this.persistenceId = `customer:${this.props.id ?? this.props.match?.params?.id}`;

            if (this.hasPageState()) {
                this.setState(
                    {
                        ...this.getInitialState(initialState()),
                        currentTab,
                    },
                    () => {
                        if (this.props.pageTitle)
                            this.props.pageTitle([
                                1,
                                this.state.isProspect ? "New Business" : "Customers",
                                this.state.customer.company_name,
                            ]);
                    }
                );
            } else {
                this.setState(
                    {
                        currentTab,
                        customerId: this.props.match.params.id,
                        showCreditOnly: this.props.showCreditOnly ? true : false,
                    },
                    () => {
                        this.setState(
                            {
                                isLoading: true,
                            },
                            () => {
                                this.loadComponentData(true);
                            }
                        );
                    }
                );
            }
        } else if(prevProps.location?.pathname !== this.props.location?.pathname) {
            this.setState(
                {
                    currentTab: 0,
                    customerId: this.props.match.params.id,
                },
                () => {
                    this.setState(
                        {
                            isLoading: true,
                        },
                        () => {
                            this.loadComponentData(true);
                        }
                    );
                }
            );
        }
    
    }

    handlePoll = () => {
        if (!document.hidden) {
            API.post(
                `/staff/my/view`,
                {
                    cust: this.state.customerId,
                    type: "View",
                },
                {
                    props: {
                        ignoreErrors: true,
                        noLoading: true,
                    },
                }
            ).then((res) => {
                const { viewing } = this.state;
                if (res?.data) {
                    const list = res.data;
                    if (JSON.stringify(list) !== JSON.stringify(viewing.list)) {
                        const update = _.some(list, (el) => el.u === 1);
                        this.setState(
                            {
                                viewing: {
                                    list,
                                    update,
                                },
                            },
                            () => {
                                if (viewing.update && !update) {
                                    const view = _.find(viewing.list, (el) => el.u === 1);
                                    if (this.props.staff[view.i]) {
                                        this.props.deploySnackBar(
                                            "success",
                                            `${this.props.staff[view.i].name} has finished updating this customer`
                                        );
                                    }
                                    this.loadComponentData();
                                }
                            }
                        );
                    }
                }
            });
        }
    };

    componentWillUnmount = () => {
        if (this.poll) {
            clearInterval(this.poll);
        }
    };

    loadComponentData = (initial = false, config = undefined) => {
        API.get(`/customers/${this.state.customerId}`, { params: { forView: true } }).then((result) => {
            if (result?.data?.errors) {
                this.props.history.push("/customers");
            } else if (result?.data) {
                let smsContactList = [];
                _.each(result.data.contacts, (el) => {
                    // if(el.t) {
                    //     smsContactList.push({
                    //         id: el.i,
                    //         value: el.t + ':tel',
                    //         label: el.fn + ' ' + el.ln + ' (Tel) - ' + el.t
                    //     })
                    // }
                    if (el.m) {
                        smsContactList.push({
                            id: el.i,
                            value: el.m + ":mob",
                            label: el.fn + " " + el.ln + " (Mob) - " + el.m,
                        });
                    }
                });

                let spendData = result.data.spend,
                    spend = {},
                    labels = [],
                    data = [];

                if (spendData) {
                    for (let i = 0, j = 11; i <= j; i++) {
                        data.push(spendData[i].v);
                        labels.push(spendData[i].l);
                    }

                    spend = {
                        labels,
                        datasets: [
                            {
                                lineTension: 0.1,
                                backgroundColor: "transparent",
                                borderColor: "#FFCDD2",
                                borderCapStyle: "butt",
                                borderDash: [],
                                borderDashOffset: 0.0,
                                borderJoinStyle: "miter",
                                pointBorderColor: "#EF3340",
                                pointBackgroundColor: "#fff",
                                pointBorderWidth: 4,
                                pointHoverRadius: 5,
                                pointHoverBackgroundColor: "#fff",
                                pointHoverBorderColor: "#C62828",
                                pointHoverBorderWidth: 2,
                                pointRadius: 2,
                                pointHitRadius: 10,
                                data,
                            },
                        ],
                    };
                }

                let tradingAddress = false,
                    primaryAddressIdx = null,
                    primaryContactIdx = null;

                primaryContactIdx = _.findIndex(result.data?.contacts, (el) => el.p === 1);
                primaryAddressIdx = _.findIndex(result.data?.addresses, (el) => el.t === "Trading");
                if (primaryAddressIdx !== -1) {
                    tradingAddress = [
                        result.data?.company_name,
                        result.data?.addresses[primaryAddressIdx].n,
                        result.data?.addresses[primaryAddressIdx].l1,
                        result.data?.addresses[primaryAddressIdx].l2,
                        result.data?.addresses[primaryAddressIdx].l3,
                        result.data?.addresses[primaryAddressIdx].to,
                        result.data?.addresses[primaryAddressIdx].cc ?? "Unknown",
                        result.data?.addresses[primaryAddressIdx].po,
                        result.data?.addresses[primaryAddressIdx].co ?? "Unknown",
                    ];
                }

                const   dd = JSON.parse(result.data.discount_data),
                        discounts = [];

                if(parseInt(dd?.all?.percent ?? 0) !== 0) {
                    const e = [];
                    _.each(dd?.all?.excludedCategories,  d => e.push(`${d?.label ?? ''} (Category)`));
                    _.each(dd?.all?.excludedSuppliers,   d => e.push(`${d?.label ?? ''} (Supplier)`));
                    _.each(dd?.all?.excludedBrands,      d => e.push(`${d?.label ?? ''} (Brand)`));
                    _.each(dd?.all?.excludedRanges,      d => e.push(`${d?.label ?? ''} (Range)`));
                    _.each(dd?.all?.excludedProducts,    d => e.push(`${d?.label ?? ''} (Product)`));
                    discounts.push({
                        t: 'Across Board',
                        p: dd?.all?.percent,
                        i: 'Across Board',
                        l: 'Exclusions',
                        e
                    });
                }

                _.each(dd?.category, cat => {
                    const e = [];
                    _.each(cat?.excludedCategories,  d => e.push(`${d?.label ?? ''} (Sub-Category)`));
                    _.each(cat?.excludedSuppliers,   d => e.push(`${d?.label ?? ''} (Supplier)`));
                    _.each(cat?.excludedBrands,      d => e.push(`${d?.label ?? ''} (Brand)`));
                    _.each(cat?.excludedRanges,      d => e.push(`${d?.label ?? ''} (Range)`));
                    _.each(cat?.excludedProducts,    d => e.push(`${d?.label ?? ''} (Product)`));
                    discounts.push({
                        t: 'Category',
                        p: cat?.percent,
                        i: cat?.label ?? cat?.category,
                        l: cat?.isInclusion === 1 ? 'Only where' : 'Exclusions',
                        e
                    });
                });

                _.each(dd?.supplier, sup => {
                    const e = [];
                    _.each(sup?.excludedCategories,  d => e.push(`${d?.label ?? ''} (Category)`));
                    _.each(sup?.excludedBrands,      d => e.push(`${d?.label ?? ''} (Brand)`));
                    _.each(sup?.excludedRanges,      d => e.push(`${d?.label ?? ''} (Range)`));
                    _.each(sup?.excludedProducts,    d => e.push(`${d?.label ?? ''} (Product)`));
                    discounts.push({
                        t: 'Supplier',
                        p: sup?.percent,
                        i: sup?.label ?? sup?.supplier,
                        l: sup?.isInclusion === 1 ? 'Only where' : 'Exclusions',
                        e
                    });
                });

                _.each(dd?.brand, brd => {
                    const e = [];
                    _.each(brd?.excludedCategories,  d => e.push(`${d?.label ?? ''} (Category)`));
                    _.each(brd?.excludedRanges,      d => e.push(`${d?.label ?? ''} (Range)`));
                    _.each(brd?.excludedProducts,    d => e.push(`${d?.label ?? ''} (Product)`));
                    discounts.push({
                        t: 'Brand',
                        p: brd?.percent,
                        i: brd?.label ?? brd?.brand,
                        l: brd?.isInclusion === 1 ? 'Only where' : 'Exclusions',
                        e
                    });
                });

                _.each(dd?.range, rng => {
                    const e = [];
                    _.each(rng?.excludedCategories,  d => e.push(`${d?.label ?? ''} (Category)`));
                    _.each(rng?.excludedProducts,    d => e.push(d?.label ?? ''));
                    discounts.push({
                        t: 'Range',
                        p: rng?.percent,
                        i: rng?.label ?? rng?.range,
                        l: rng?.isInclusion === 1 ? 'Only where' : 'Exclusions',
                        e
                    });
                });

                _.each(dd?.product, prd => {
                    const i = [];
                    _.each(prd?.product,  d => i.push(d?.label ?? ''));
                    discounts.push({
                        t: 'Product',
                        p: prd?.percent,
                        q: prd?.minQty,
                        l: 'Selected Products',
                        i,
                    });
                });

                this.setState(
                    {
                        charts: {
                            spend,
                        },
                        customer: {
                            ...result.data,
                            credit_data: JSON.parse(result.data.credit_data),
                            discounts,
                        },
                        isLoading: false,
                        isProspect: _.isEmpty(result?.data?.sage_ref),
                        key: uuidv4(),
                        smsContactList,
                        primaryAddressIdx,
                        primaryContactIdx,
                        tradingAddress,
                    },
                    () => {
                        if (this.props.pageTitle)
                            this.props.pageTitle([
                                1,
                                this.state.isProspect ? "New Business" : "Customers",
                                this.state.customer.company_name,
                            ]);

                        if (initial) {
                            let fromNewForm = this.props.location?.state?.new ?? false;

                            if (fromNewForm) {
                                const { isProspect } = this.props;

                                this.props.history.replace({ state: { new: false } });

                                if(isProspect) {
                                    this.handleDeployAddTask(true)
                                }

                            }

                            if (!this.state.isProspect && !_.isEmpty(this.state.customer.account_number)) {
                                this.creditCheck(config);
                            } else {
                                this.setState({
                                    creditLoading: false,
                                });
                            }
                        }

                        this.savePageState();
                    }
                );
            }
        });
    };

    creditCheck = (config) => {
        this.setState(
            {
                creditLoading: true,
            },
            () => {
                API.get(`/customers/${this.state.customerId}/sage`).then((res) => {
                    if (res.data) {
                        if (res.data.success) {
                            this.setState(
                                {
                                    customer: {
                                        ...this.state.customer,
                                        ...res.data.customer,
                                        credit_data: JSON.parse(res.data.customer.credit_data),
                                    },
                                    creditLoading: false,
                                },
                                () => {
                                    if (config?.handleDeployCreditAccount) {
                                        this.handleDeployCreditAccount();
                                    }
                                }
                            );
                        } else {
                            this.setState(
                                {
                                    creditLoading: false,
                                },
                                () => {
                                    if (config?.handleDeployCreditAccount) {
                                        this.handleDeployCreditAccount();
                                    }
                                    this.savePageState();
                                }
                            );
                        }
                    }
                });
            }
        );
    };

    handleContactSms = (contactId, number) => {
        const { smsContactList, customerId } = this.state;
        this.props.deployDialog(
            <SendSMSForm
                id={customerId}
                contacts={smsContactList}
                relation="customer"
                customNumber={true}
                contactId={contactId}
                number={`${number}:mob`}
                toggleDialog={this.props.closeDialog}
            />,
            true,
            "",
            "standard",
            "xs",
            false,
            true
        );
    };

    handleDeleteAccount = () => {

        const {
            customerId,
            isProspect
        } = this.state;

        API.put(`/customers/${customerId}/delete`)
        .then(res => {
            if (res?.data) {
                this.props.deploySnackBar("success", `The ${isProspect ? 'prospect' : 'account'} has been successfully deleted`);
                this.props.history.push(isProspect ? '/new-business' : '/customers');
            }
        });
        
    }

    handleDeleteTradeZoneUser = (userId) => {

        const {
            customerId,
        } = this.state;

        API.put(`/customers/${customerId}/tradeZone/users/${userId}/delete`)
        .then(res => {
            if (res?.data) {
                this.props.deploySnackBar("success", `The portal user has been successfully deleted`);
                this.loadComponentData();
            }
        });
        
    }

    handleDeployAddCall = () =>
        this.props.deployDialog(
            <AddCallLogForm
                id={this.state.customerId}
                relation="customer"
                toggleDialog={() => {
                    this.loadComponentData();
                    this.props.closeDialog();
                }}
            />,
            true,
            "",
            "standard",
            "xs",
            false,
            true
        );

    handleDeployAddEmail = () =>
        this.props.deployDialog(
            <AddEmailLogForm
                id={this.state.customerId}
                relation="cust"
                toggleDialog={() => {
                    this.loadComponentData();
                    this.props.closeDialog();
                }}
            />,
            true,
            "",
            "standard",
            "xs",
            false,
            true
        );

    handleDeployFileUpload = () =>
        this.props.deployDialog(
            <DocumentForm
                id={this.state.customer.id}
                type="customer"
                category="Customers"
                callback={this.loadComponentData}
                cancel={this.props.closeDialog}
            />,
            false,
            "File Upload",
            "standard",
            "xs",
            false,
            true
        );

    handleDeployAddNote = () =>
        this.props.deployDialog(
            <AddNoteForm
                id={this.state.customerId}
                relation="customer"
                toggleDialog={() => {
                    this.loadComponentData();
                    this.props.closeDialog();
                }}
                standardOnly={!this.state.access.addPriorityNote}
            />,
            true,
            "",
            "standard",
            "sm",
            false,
            true
        );

    handleDeployAddVisit = () =>
        this.props.deployDialog(
            <VisitForm
                customer={this.state.customerId}
                callback={() => {
                    this.loadComponentData();
                    this.props.closeDialog();
                }}
            />,
            true,
            "",
            "standard",
            "sm",
            false,
            true
        );

    handleDeployAddSms = () =>
        this.props.deployDialog(
            <SendSMSForm
                id={this.state.customerId}
                relation="customer"
                contacts={this.state.smsContactList}
                customNumber={true}
                toggleDialog={() => {
                    this.loadComponentData();
                    this.props.closeDialog();
                }}
            />,
            true,
            "",
            "standard",
            "xs",
            false,
            true
        );

    handleDeployAddTask = (fromProspectForm) => {

        const { 
            deployDialog
        } = this.props;

        let Component, 
            callback;
        
        if(fromProspectForm) {
            Component = () => (
                <Grid container  style={{width: '100%', padding: 0, margin: 0, overflow: 'hidden'}}>
                    <Grid item xs={12}>
                        <Alert severity="error">
                            <Typography variant="subtitle1" className="fw-400">
                                Account Review Required
                            </Typography>
                            <Typography variant="caption">
                                You must add an account review now for this prospective account
                            </Typography>
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <TaskForm
                            id={this.state.customerId}
                            relation="customer"
                            defaultOption={{
                                label: this.state.customer.company_name,
                                value: this.state.customer.id,
                                subtitle1: `${this.state.customer.addresses?.[this.state.primaryAddressIdx]?.l1}, ${
                                    this.state.customer.addresses?.[this.state.primaryAddressIdx]?.to
                                }, ${this.state.customer.addresses?.[this.state.primaryAddressIdx]?.po}`,
                                c: 1,
                            }}
                            defaultSearch={this.state.customer.company_name}
                            toggleDialog={() => {
                                this.loadComponentData();
                                this.props.closeDialog();
                            }}
                            callback={callback}
                            defaultType={3} //account review
                            mandatory
                            noElevation
                        />
                    </Grid>
                </Grid>
            )
        } else {
            Component = () => (
                <TaskForm
                    id={this.state.customerId}
                    relation="customer"
                    defaultOption={{
                        label: this.state.customer.company_name,
                        value: this.state.customer.id,
                        subtitle1: `${this.state.customer.addresses?.[this.state.primaryAddressIdx]?.l1}, ${
                            this.state.customer.addresses?.[this.state.primaryAddressIdx]?.to
                        }, ${this.state.customer.addresses?.[this.state.primaryAddressIdx]?.po}`,
                        c: 1,
                    }}
                    defaultSearch={this.state.customer.company_name}
                    toggleDialog={() => {
                        this.loadComponentData();
                        this.props.closeDialog();
                    }}
                />
            )
        }

        deployDialog(
            <Component />,
            true,
            "",
            "standard",
            "sm",
            false,
            true,
        );

    }

    handleDeployDiscounts = () => {

        const {
            customer
        } = this.state;

        this.props.deployDialog(
            <Grid container>
                <Grid item xs={12}>
                    <Box
                        pb={2}
                        mt={-1}
                        ml={-1}
                        mr={-1}
                    >
                        <Divider />
                    </Box>
                </Grid>
                {_.map(
                    customer.discount_templates,
                    (disc, idx) => (
                        <Grid
                            item
                            xs={12}
                            key={idx}
                            className={
                                idx !==
                                customer.discount_templates
                                    .length -
                                    1 
                                    ? "pb-1"
                                    : undefined
                            }
                        >
                            <InsightChip
                                icon='check'
                                label={`${
                                    disc.nm
                                }`}
                            />
                            <Box pl={2.5}>
                                <ul className="pl-2 mt-0 mb-0 pb-0">
                                    <li>
                                        <Typography
                                            variant="caption"
                                            component="div"
                                        >
                                            Preset Discount Template
                                        </Typography>
                                    </li>
                                </ul>
                            </Box>
                        </Grid>
                    )
                )}
                {_.map(
                    customer.discounts,
                    (disc, idx) => (
                        <Grid
                            item
                            xs={12}
                            key={idx}
                            className={
                                idx !==
                                customer.discounts
                                    .length -
                                    1 && disc.t !==
                                    "Product" && disc.e.length === 0
                                    ? "pb-1"
                                    : undefined
                            }
                        >
                            <InsightChip
                                icon='check'
                                label={`${
                                    disc.p
                                }% Off ${
                                    disc.t ===
                                    "Across Board"
                                        ? "Across Board"
                                        : ""
                                }${
                                    disc.t ===
                                    "Category"
                                        ? `${disc.i} (Category)`
                                        : ``
                                }${
                                    disc.t ===
                                    "Supplier"
                                        ? `${disc.i} (Supplier)`
                                        : ``
                                }${
                                    disc.t ===
                                    "Brand"
                                        ? `${disc.i} (Brand)`
                                        : ``
                                }${
                                    disc.t ===
                                    "Range"
                                        ? `${disc.i} (Range)`
                                        : ``
                                }${
                                    disc.t ===
                                    `Product`
                                        ? `Selected Products`
                                        : ``
                                }${
                                    !_.isEmpty(disc.e) ||
                                    disc.t ===
                                        "Product"
                                        ? ` *`
                                        : ``
                                }`}
                            />
                            <Box pl={2.75}>
                                {(disc.t ===
                                    "Product" && (
                                    <>
                                        <Typography
                                            variant="caption"
                                            className="fw-400"
                                            component="div"
                                        >
                                            {disc.l}
                                        </Typography>
                                        <ul className="pl-2 mt-0 mb-0 pb-0">
                                            {_.map(
                                                disc.i,
                                                (
                                                    prd,
                                                    pIdx
                                                ) => (
                                                    <li
                                                        key={
                                                            pIdx
                                                        }
                                                    >
                                                        <Typography
                                                            variant="caption"
                                                            component="div"
                                                        >
                                                            {
                                                                prd
                                                            }
                                                            {disc.q >
                                                            0
                                                                ? ` (${disc.q}+)`
                                                                : ``}
                                                        </Typography>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </>
                                )) ||
                                    (!_.isEmpty(
                                        disc.e
                                    ) && (
                                        <>
                                            <Typography
                                                variant="caption"
                                                className="fw-400"
                                                component="div"
                                            >
                                                {disc.l}
                                            </Typography>
                                            <ul className="pl-2 mt-0 mb-0">
                                                {_.map(
                                                    disc.e,
                                                    (
                                                        exclusion,
                                                        eIdx
                                                    ) => (
                                                        <li
                                                            key={
                                                                eIdx
                                                            }
                                                        >
                                                            <Typography
                                                                variant="caption"
                                                                component="div"
                                                            >
                                                                {exclusion}
                                                            </Typography>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </>
                                    ))}
                            </Box>
                        </Grid>
                    )
                )}
            </Grid>,
            false,
            'Customer Discounts',
            'standard',
            'sm'
        )

    }

    handleDeployModifyTradingAddress = () => {
        const { customer } = this.state;
        let tradingAddress = _.find(customer.addresses, (el) => el.t === "Trading");
        if (tradingAddress) {
            this.props.deployDialog(
                <AddressForm
                    id={customer.id}
                    type="customer"
                    noRegistered
                    noTrading
                    callback={() => {
                        this.props.closeDialog();
                        this.loadComponentData();
                    }}
                    cancel={this.props.closeDialog}
                    updateId={tradingAddress.i}
                />,
                false,
                "Modify Trading Address",
                "standard",
                "xs",
                false,
                true
            );
        }
    };
    
    handleDeployMostPurchasedOrders = (productId, productCode, productDesc) => {
        const { customer } = this.state;
        this.props.deployDialog(
            <ProductHistory inline customerId={customer.id} productId={productId} />,
            false,
            `${customer.company_name} - Sales History:  ${productCode} - ${productDesc}`,
            "standard",
            "md",
            false,
        );
    }

    handleDeploySendEmail = () => {
        let defaultFormData = {};
        if (!_.isEmpty(this.state.customer?.email)) {
            defaultFormData = {
                ...defaultFormData,
                emailTo: {
                    label: this.state.customer.email,
                    value: this.state.customer.email,
                },
            };
        }
        this.props.deployDialog(
            <EmailDialog
                callback={this.loadComponentData}
                id={this.state.customerId}
                type="customer"
                success="The e-mail has been successfully sent"
                defaultFormData={defaultFormData}
            />,
            false,
            "Compose E-mail",
            "standard",
            "md",
            false,
            true
        );
    };

    handleDeployCreateAccount = () => {

        const { customer, customerId } = this.state;
        const { deployDialog, history } = this.props;

        deployDialog(
            <ConvertProspect
                id={customerId}
                history={history}
            />,
            false,
            `Create Account - ${customer.company_name}`,
            "error",
            "xs",
            false,
            true
        );
        
    };

    handleDeployCloseAccount = () => {
        const { isProspect } = this.state;

        const { deployConfirmation, deployDialog } = this.props;

        const {
            credit_balance,
            id,
            // liveRMA,
            // liveSO
        } = this.state.customer;

        // if(liveSO > 0 || liveRMA > 0) {
        //     deployDialog(
        //         <NotificationDialog
        //             text={`This account has live orders and/or returns, please ensure these are cancelled, completed or invoiced and then try again.`}
        //             onClick={closeDialog}
        //             btnText='Close'
        //             variant="error"
        //         />,
        //         false,
        //         `Error`,
        //         "error",
        //         "sm",
        //         false,
        //         true
        //     )
        // } else if(credit_balance > 0) {
        if (credit_balance > 0) {
            deployConfirmation(
                <>
                    <Typography variant="body2" className="fw-400">
                        This customer has a balance of {currencyFormat.format(credit_balance)}
                    </Typography>
                    <Typography variant="body2">Are you sure you want to close the account?</Typography>
                </>,
                () => {
                    deployDialog(
                        <CloseAccount id={id} isProspect={isProspect} handleSubmit={this.handleSubmitCloseAccount} />,
                        false,
                        "Close Account",
                        "standard",
                        "xs",
                        false,
                        true
                    );
                }
            );
        } else {
            deployDialog(
                <CloseAccount id={id} isProspect={isProspect} handleSubmit={this.handleSubmitCloseAccount} />,
                false,
                `Close ${isProspect ? 'Prospect' : 'Account'}`,
                "standard",
                "xs",
                false,
                true
            );
        }
    };

    handleDeployOpenAccount = () => {
        const { deployDialog } = this.props;

        const { id } = this.state.customer;

        deployDialog(
            <OpenAccount id={id} handleSubmit={this.handleSubmitOpenAccount} />,
            false,
            "Re-open Account",
            "standard",
            "xs",
            false,
            true
        );
    };

    handleDeployCreditAccount = () => {
        const { closeDialog, deployDialog, deployConfirmation, deploySnackBar } = this.props;

        const { customer } = this.state;

        deployDialog(
            <ManageCredit
                callback={this.creditCheck}
                closeDialog={closeDialog}
                customer={customer}
                deployConfirmation={deployConfirmation}
                deploySnackBar={deploySnackBar}
                loadComponentData={this.loadComponentData}
            />,
            true,
            "",
            "standard",
            "md",
            false,
            true
        );
    };

    handleDeployTradeZoneForm = (user) =>
        this.props.deployDialog(
            <TradeZoneUserForm
                customer={this.state.customerId}
                callback={() => {
                    this.props.closeDialog();
                    this.loadComponentData();
                }}
                roles={this.state.customer.roles}
                user={user}
            />,
            true,
            "",
            "standard",
            "xs",
            false,
            true
        );

    handleLoginAsUser = (userId) => {
        API.get(`/customers/${this.state.customerId}/tradeZone/users/${userId}/login`)
        .then(res => {
            if(res?.data?.success) {
                window.open(res.data.auth, '_blank')
                this.props.deploySnackBar(`success`, `Portal master login token successfully generated`)
            } else {
                this.props.deploySnackBar(`error`, `Error logging in as user - please try again`)
            }
        })
    }

    handleDeploySiteForm = (site) =>
        this.props.deployDialog(
            <CardDbSiteForm
                customer={this.state.customerId}
                callback={() => {
                    this.props.closeDialog();
                    this.loadComponentData();
                }}
                site={site}
            />,
            true,
            "",
            "standard",
            "xs",
            false,
            site ? true : false
        );

    handleRequestAccountApplicationForm = () => {
        const id = this.state.customer.id;
        this.props.deployDialog(
            <RequestAccountApplicationForm id={id} handleSubmit={this.handleSubmitRequestAccountApplicationForm} />,
            false,
            "Request Account Application Form",
            "standard",
            "sm",
            false,
            true
        );
    };

    handleRequestAccountOpening = () => {
        const id = this.state.customer.id;
        this.props.deployDialog(
            <RequestAccountOpening id={id} handleSubmit={this.handleSubmitRequestAccountOpening} />,
            false,
            "Request Account Opening",
            "standard",
            "sm",
            false,
            true
        );
    };
    
    handleRequestCatalogue = () => {
        const id = this.state.customer.id;
        this.props.deployDialog(
            <RequestCatalogue id={id} handleSubmit={this.handleSubmitRequestCatalogue} />,
            false,
            "Request Catalogue",
            "standard",
            "sm",
            false,
            true
        );
    };

    handleAddNewCreditReport = () => {
        const id = this.state.customer.id;
        this.props.deployDialog(
            <ActionCreditReport
                id={id}
                callback={this.loadComponentData}
                closeDialog={this.props.closeDialog}
                customer={this.state.customer}
                deployConfirmation={this.props.deployConfirmation}
                deploySnackBar={this.props.deploySnackBar}
            />,
            false,
            "New Credit Check",
            "standard",
            "sm",
            false,
            true
        );
    };

    handleRequestCreditReport = () => {
        const id = this.state.customer.id;
        this.props.deployDialog(
            <RequestCreditCheck id={id} handleSubmit={this.handleSubmitRequestCreditReport} />,
            false,
            "Request Credit Check",
            "standard",
            "sm",
            false,
            true
        );
    };

    handleSubmitCloseAccount = (id, accCode, sageCode, reason) => {
        API.put(`/customers/${id}/close`, { accCode, sageCode, reason }).then((res) => {
            if (res?.data) {
                if (res?.data?.errors) {
                    this.props.deploySnackBar(
                        "error",
                        "You cannot close this account as there are outstanding orders / returns"
                    );
                    this.loadComponentData();
                    this.props.closeDialog();
                } else {
                    this.props.deploySnackBar("success", "You have successfully closed this customer's account");
                    this.loadComponentData(true);
                    this.props.closeDialog();
                }
            }
        });
    };

    handleSubmitOpenAccount = (id, accCode, reason) => {
        API.put(`/customers/${id}/open`, { accCode, reason }).then((res) => {
            if (res?.data) {
                if (res?.data?.errors) {
                    this.props.deploySnackBar("error", "There was an issue re-opening this account, please try again");
                    this.loadComponentData();
                    this.props.closeDialog();
                } else {
                    this.props.deploySnackBar("success", "You have successfully re-opened this customer's account");
                    this.loadComponentData(true);
                    this.props.closeDialog();
                }
            }
        });
    };

    handleSubmitRequestAccountApplicationForm = (id, notes) => {
        API.post(`/accounts/accountApplicationForm/requests`, { id, notes }).then((res) => {
            if (res?.data) {
                if (res?.data?.exists) {
                    this.props.deploySnackBar("error", "An account application form request already exists for this customer");
                    this.loadComponentData();
                    this.props.closeDialog();
                } else {
                    this.props.deploySnackBar(
                        "success",
                        "You have successfully requested an Account Application Form to be sent to this customer"
                    );
                    this.loadComponentData();
                    this.props.closeDialog();
                }
            }
        });
    };

    handleSubmitRequestAccountOpening = (id, notes, type) => {
        API.post(`/accounts/accountOpening/requests`, { id, notes, type }).then((res) => {
            if (res?.data) {
                if (res?.data?.exists) {
                    this.props.deploySnackBar("error", "An account opening request already exists for this customer");
                    this.loadComponentData();
                    this.props.closeDialog();
                } else {
                    this.props.deploySnackBar(
                        "success",
                        `You have successfully requested for a ${type} account to be opened for this prospect`
                    );
                    this.loadComponentData();
                    this.props.closeDialog();
                }
            }
        });
    };

    handleSubmitRequestCatalogue = (id, notes) => {
        API.post(`/marketing/requests/catalogues`, { id, notes }).then((res) => {
            if (res?.data) {
                if (res?.data?.exists) {
                    this.props.deploySnackBar("error", "A catalogue request already exists for this customer");
                    this.loadComponentData();
                    this.props.closeDialog();
                } else {
                    this.props.deploySnackBar(
                        "success",
                        "You have successfully requested a catalogue for this customer"
                    );
                    this.loadComponentData();
                    this.props.closeDialog();
                }
            }
        });
    };

    handleSubmitRequestCreditReport = (id, comments, reason) => {
        API.post(`/accounts/creditReports/requests`, { id, comments, reason }).then((res) => {
            if (res?.data) {
                if (res?.data?.reasonMissing) {
                    this.props.deploySnackBar("error", "Please select a reason for the Credit Report Request");
                } else if (res?.data?.exists) {
                    this.props.deploySnackBar("error", "A credit check is already in progress for this customer");
                    this.loadComponentData();
                    this.props.closeDialog();
                } else {
                    this.props.deploySnackBar(
                        "success",
                        "You have successfully requested a credit check for this customer"
                    );
                    this.loadComponentData();
                    this.props.closeDialog();
                }
            }
        });
    };

    handleToggleMonitor = () => {
        const id = this.state.customer?.id ?? false;
        const company = this.state.customer?.company_name ?? "this customer";
        if (id) {
            API.put(`/customers/${id}/toggleMonitoring`).then((res) => {
                if (res?.data) {
                    this.loadComponentData(true);
                    const message =
                        res.data?.monitored === 1
                            ? `You have added monitoring for ${company}`
                            : `You have removed monitoring for ${company}`;
                    this.props.deploySnackBar("success", message);
                }
            });
        }
    };

    render() {

        const { classes, closeDialog, deployConfirmation, deployDialog, deploySnackBar, staff, ui } =
            this.props;

        const {
            access,
            charts,
            creditLoading,
            currentTab,
            customer,
            isLoading,
            isProspect,
            key,
            viewing,
            primaryContactIdx,
            tradingAddress,
        } = this.state;

        const accClosed = customer?.status?.status_closed === 1;

        const height = window.matchMedia("(display-mode: standalone)").matches
            ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)`
            : ui.device.height;

        const contentHeight = window.matchMedia("(display-mode: standalone)").matches
            ? `calc(env(safe-area-inset-bottom) + ${ui.device.height - VIEW_PAGE_OFFSET}px)`
            : height - VIEW_PAGE_OFFSET;

        const canDial = this.context?.dial === null;

        return (
            <>
                <Grid container style={{ margin: -24, width: "initial" }}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar
                                    style={{
                                        backgroundColor: "#fff",
                                        borderBottom: "1px solid #ddd",
                                        height: VIEW_PAGE_APP_BAR_HEIGHT,
                                    }}
                                    position="static"
                                    elevation={0}
                                >
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems="center">
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item>
                                                        <Typography variant="h4" className="textDefault">
                                                            {customer.company_name}
                                                            {access.monitorCustomer && customer.monitored === 1
                                                                ? ` [ON HOLD]`
                                                                : ``}
                                                        </Typography>
                                                    </Grid>
                                                    {customer.phone.length > 0 && canDial ? (
                                                        <Grid item>
                                                            <Tooltip title="Call Customer">
                                                                <IconButton onClick={() => this.context.useDial(customer.phone)} size="small">
                                                                    <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin size={27.5} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    ) : null}
                                                    {access.monitorCustomer && !isProspect && (
                                                        <Grid item xs>
                                                            <Tooltip
                                                                title={`${
                                                                    customer.monitored === 1 ? "Remove" : "Add"
                                                                } monitoring`}
                                                            >
                                                                <IconButton
                                                                    onClick={() =>
                                                                        this.props.deployConfirmation(
                                                                            `Are you sure you want to ${
                                                                                customer.monitored === 1
                                                                                    ? "remove"
                                                                                    : "add"
                                                                            } monitoring for ${customer.company_name}?`,
                                                                            this.handleToggleMonitor
                                                                        )
                                                                    }
                                                                >
                                                                    <FAIcon
                                                                        icon={
                                                                            customer.monitored === 1
                                                                                ? "eye"
                                                                                : "eye-slash"
                                                                        }
                                                                        type="light"
                                                                        color={
                                                                            customer.monitored === 1
                                                                                ? "primary"
                                                                                : undefined
                                                                        }
                                                                        size={17.5}
                                                                        noMargin
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            {access.newQuote && (
                                                <Grid item>
                                                    <Tooltip title="New Quote" placement="top">
                                                        <IconButton
                                                            disabled={accClosed}
                                                            onClick={() =>
                                                                this.props.history.push(`/quotes/new/${customer.id}`)
                                                            }
                                                        >
                                                            <FAIcon
                                                                type="light"
                                                                icon="quote"
                                                                size={17.5}
                                                                button
                                                                noMargin
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {!isProspect && access.newOrder && (
                                                <Grid item>
                                                    <Tooltip title="New Order" placement="top">
                                                        <IconButton
                                                            disabled={accClosed}
                                                            onClick={() =>
                                                                this.props.history.push(`/sales/new/${customer.id}`)
                                                            }
                                                        >
                                                            <FAIcon
                                                                type="light"
                                                                icon="order"
                                                                size={17.5}
                                                                button
                                                                noMargin
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {!isProspect && access.newRMA && (
                                                <Grid item>
                                                    <Tooltip title="New RMA" placement="top">
                                                        <IconButton
                                                            disabled={accClosed}
                                                            onClick={() =>
                                                                this.props.history.push(`/rma/new/${customer.id}`)
                                                            }
                                                        >
                                                            <FAIcon
                                                                type="light"
                                                                icon="exchange-alt"
                                                                size={17.5}
                                                                button
                                                                noMargin
                                                                disabled={accClosed}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Tooltip title="Add Action" placement="top">
                                                    <IconButton onClick={() => this.handleDeployAddTask()}>
                                                        <FAIcon
                                                            type="light"
                                                            icon="layer-plus"
                                                            size={17.5}
                                                            button
                                                            noMargin
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {access.addNote && (
                                                <Grid item>
                                                    <Tooltip title="Add Note" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddNote()}>
                                                            <FAIcon
                                                                type="light"
                                                                icon="comment-alt-plus"
                                                                size={17.5}
                                                                button
                                                                noMargin
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.addCallLog && (
                                                <Grid item>
                                                    <Tooltip title="Log Call" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddCall()}>
                                                            <FAIcon
                                                                type="light"
                                                                icon="phone-plus"
                                                                size={17.5}
                                                                button
                                                                noMargin
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.uploadDoc && (
                                                <Grid item>
                                                    <Tooltip title="Upload File" placement="top">
                                                        <IconButton onClick={() => this.handleDeployFileUpload()}>
                                                            <FAIcon
                                                                type="light"
                                                                icon="file-plus"
                                                                size={17.5}
                                                                button
                                                                noMargin
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.requestCatalogue && (
                                                <Grid item>
                                                    <Tooltip
                                                        title={
                                                            customer.crc > 0
                                                                ? "Catalogue Requested"
                                                                : "Request Catalogue"
                                                        }
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            disabled={accClosed || customer.crc > 0}
                                                            onClick={() => this.handleRequestCatalogue()}
                                                        >
                                                            <FAIcon
                                                                type="light"
                                                                icon="book-reader"
                                                                size={17.5}
                                                                button
                                                                noMargin
                                                                disabled={accClosed || customer.crc > 0}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {!isProspect &&
                                                access.updateCustomer &&
                                                customer?.status?.status_parent_id !== 4 && (
                                                    <Grid item>
                                                        <Tooltip title="Modify Account" placement="top">
                                                            <IconButton
                                                                disabled={viewing.update}
                                                                onClick={() =>
                                                                    this.props.history.push(
                                                                        `/customers/update/${customer.id}`
                                                                    )
                                                                }
                                                            >
                                                                <FAIcon
                                                                    type="light"
                                                                    icon="pencil"
                                                                    size={17.5}
                                                                    button
                                                                    noMargin
                                                                    disabled={viewing.update}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                )}
                                            {isProspect &&
                                                access.updateProspect &&
                                                customer?.status?.status_parent_id !== 4 && (
                                                    <Grid item>
                                                        <Tooltip title="Modify Prospect" placement="top">
                                                        <IconButton
                                                                disabled={viewing.update}
                                                                onClick={() =>
                                                                    this.props.history.push(
                                                                        `/new-business/update/${customer.id}`
                                                                    )
                                                                }
                                                            >
                                                                <FAIcon
                                                                    type="light"
                                                                    icon="pencil"
                                                                    size={17.5}
                                                                    button
                                                                    noMargin
                                                                    disabled={viewing.update}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                )}
                                            <Grid item>
                                                <ActionMenu
                                                    rows={[
                                                        {
                                                            icon: "layer-plus",
                                                            label: "Add Action",
                                                            onClick: () => this.handleDeployAddTask(),
                                                            display: true,
                                                        },
                                                        {
                                                            icon: "comment-alt-plus",
                                                            label: "Add Note",
                                                            onClick: () => this.handleDeployAddNote(),
                                                            display: access.addNote,
                                                        },
                                                        {
                                                            icon: "car",
                                                            label: "Add Visit",
                                                            onClick: () => this.handleDeployAddVisit(),
                                                            display: access.addVisit,
                                                        },
                                                        {
                                                            icon: "envelope-open-text",
                                                            label: "Compose E-mail",
                                                            onClick: () => this.handleDeploySendEmail(),
                                                            display: true,
                                                            disabled: customer.deleted === 1 || accClosed
                                                        },
                                                        {
                                                            icon: "sms",
                                                            label: "Compose SMS",
                                                            onClick: () => this.handleDeployAddSms(),
                                                            display: access.sendSms,
                                                            disabled: customer.deleted === 1 || accClosed
                                                        },
                                                        {
                                                            icon: "phone-plus",
                                                            label: "Log Call",
                                                            onClick: () => this.handleDeployAddCall(),
                                                            display: access.addCallLog,
                                                        },
                                                        {
                                                            icon: "inbox-in",
                                                            label: "Log E-mail",
                                                            onClick: () => this.handleDeployAddEmail(),
                                                            display: access.addCallLog,
                                                        },
                                                        {
                                                            icon: "file-plus",
                                                            label: "Upload File",
                                                            onClick: () => this.handleDeployFileUpload(),
                                                            display: access.uploadDoc,
                                                            divider: true,
                                                        },
                                                        {
                                                            icon: "quote",
                                                            label: "New Quote",
                                                            onClick: () =>
                                                                this.props.history.push(`/quotes/new/${customer.id}`),
                                                            display: access.newQuote,
                                                            disabled: accClosed,
                                                        },
                                                        {
                                                            icon: "order",
                                                            label: "New Order",
                                                            onClick: () =>
                                                                this.props.history.push(`/sales/new/${customer.id}`),
                                                            display: !isProspect && access.newOrder,
                                                            disabled: accClosed,
                                                        },
                                                        {
                                                            icon: "exchange-alt",
                                                            label: "New RMA",
                                                            onClick: () =>
                                                                this.props.history.push(`/rma/new/${customer.id}`),
                                                            display: !isProspect && access.newRMA,
                                                            disabled: accClosed,
                                                        },
                                                        {
                                                            icon: "user-plus",
                                                            label: "New Portal User",
                                                            onClick: () =>
                                                                this.handleDeployTradeZoneForm(null),
                                                            display: !accClosed,
                                                            disabled: !access.tradeZoneMgt,
                                                            divider: true,
                                                        },
                                                        {
                                                            icon: "file-contract",
                                                            label:
                                                                customer.cra > 0
                                                                    ? "Account Application Form Requested"
                                                                    : "Request Account Application Form",
                                                            onClick: () => this.handleRequestAccountApplicationForm(),
                                                            display: access.requestAccAppForm,
                                                            disabled:
                                                                customer.has_credit === 1 ||
                                                                customer.deleted === 1 ||
                                                                customer.cra > 0 ||
                                                                accClosed
                                                                    ? true
                                                                    : false,
                                                        },
                                                        {
                                                            icon: "file-check",
                                                            label:
                                                                customer.opening 
                                                                    ? "Account Opening Requested"
                                                                    : "Request Account Opening",
                                                            onClick: () => this.handleRequestAccountOpening(),
                                                            display: access.requestAccOpening && customer.prospect,
                                                            disabled:
                                                                customer.has_credit === 1 ||
                                                                customer.deleted === 1 ||
                                                                customer.opening ||
                                                                accClosed
                                                                    ? true
                                                                    : false,
                                                        },
                                                        {
                                                            icon: "book-reader",
                                                            label:
                                                                customer.crc > 0
                                                                    ? "Catalogue Requested"
                                                                    : "Request Catalogue",
                                                            onClick: () => this.handleRequestCatalogue(),
                                                            display: access.requestCatalogue,
                                                            disabled:
                                                                customer.deleted === 1 ||
                                                                customer.crc > 0 ||
                                                                accClosed
                                                                    ? true
                                                                    : false,
                                                        },
                                                        {
                                                            icon: "file-user",
                                                            label:
                                                                customer.ccr > 0
                                                                    ? "Credit Check Requested"
                                                                    : "Request Credit Check",
                                                            onClick: () => this.handleRequestCreditReport(),
                                                            display: access.requestCreditReport,
                                                            disabled:
                                                                customer.ccr > 0 ||
                                                                accClosed
                                                                    ? true
                                                                    : false,
                                                            divider: true,
                                                        },
                                                        {
                                                            icon: "file-search",
                                                            label: "New Credit Check",
                                                            onClick: () => this.handleAddNewCreditReport(),
                                                            display: access.actionCreditReport,
                                                            disabled: accClosed,
                                                            divider: true,
                                                        },
                                                        {
                                                            icon: "building",
                                                            label: "Change Trading Address",
                                                            onClick: this.handleDeployModifyTradingAddress,
                                                            display: access.modifyAddress,
                                                            disabled:
                                                                (!isProspect &&
                                                                access.openAccount &&
                                                                accClosed) || customer.deleted === 1,
                                                        },
                                                        {
                                                            icon: "database",
                                                            label: "Manage Credit Facility",
                                                            onClick: this.handleDeployCreditAccount,
                                                            disabled:
                                                                !this.state.isProspect &&
                                                                access.openAccount &&
                                                                accClosed,
                                                            display: !isProspect && access.accounts,
                                                        },
                                                        {
                                                            icon: "pencil",
                                                            label: "Modify Account",
                                                            onClick: () =>
                                                                this.props.history.push(
                                                                    `/customers/update/${customer.id}`
                                                                ),
                                                            display:
                                                                !isProspect &&
                                                                access.updateCustomer &&
                                                                customer?.status?.status_parent_id !== 4,
                                                            disabled: viewing.update,
                                                            divider: true,
                                                        },
                                                        {
                                                            icon: "pencil",
                                                            label: "Modify Prospect",
                                                            onClick: () => 
                                                                this.props.history.push(
                                                                    `/new-business/update/${customer.id}`
                                                                ),
                                                            display:
                                                                isProspect && access.updateProspect,
                                                            disabled: viewing.update || customer.deleted === 1,
                                                            divider: true,
                                                        },
                                                        {
                                                            icon: "check",
                                                            label: "Create Account",
                                                            onClick: this.handleDeployCreateAccount,
                                                            display:
                                                                isProspect && access.convertProspect,
                                                            disabled: customer.deleted === 1 || accClosed
                                                        },
                                                        {
                                                            icon: "times",
                                                            label: `Close ${isProspect ? 'Prospect' : 'Account'}`,
                                                            onClick: this.handleDeployCloseAccount,
                                                            display:
                                                                access.closeAccount &&
                                                                customer?.status?.status_parent_id !== 4,
                                                            disabled: viewing.update,
                                                        },
                                                        {
                                                            icon: "trash-alt",
                                                            label: `Delete ${isProspect ? 'Prospect' : 'Account'}`,
                                                            onClick: () => this.props.deployConfirmation(`Are you sure you want to delete this ${isProspect ? 'prospect' : 'account'}?`, this.handleDeleteAccount),
                                                            display: accClosed && ((isProspect && access.deleteProspect) || (!isProspect && access.deleteCustomer)),
                                                            disabled: viewing.update || customer.deleted === 1
                                                        },
                                                        {
                                                            icon: "check",
                                                            label: "Re-open Account",
                                                            onClick: this.handleDeployOpenAccount,
                                                            display:
                                                                customer.deleted === 0 && 
                                                                !isProspect &&
                                                                access.openAccount &&
                                                                accClosed,
                                                            disabled: viewing.update,
                                                        },
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justify="space-between" alignItems="center">
                                                <Grid item xs={10} md={8}>
                                                    <Grid container spacing={2}>
                                                        {(!isProspect && (
                                                            <>
                                                                <Grid item>
                                                                    <InsightChip
                                                                        icon="user"
                                                                        label={`A/C: ${customer.account_number}`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip
                                                                        icon="quote"
                                                                        label={`Last Quote: ${
                                                                            customer.lastQuote ?? "Never"
                                                                        }`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip
                                                                        icon="order"
                                                                        label={`Last Order: ${
                                                                            customer.lastOrder ?? "Never"
                                                                        }`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip
                                                                        icon="calendar"
                                                                        label={`Created: ${moment(
                                                                            customer.created_datetime,
                                                                            "YYYY-MM-DD HH:mm"
                                                                        ).format("DD/MM/YYYY")}`}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        )) || (
                                                            <>
                                                                <Grid item>
                                                                    <InsightChip
                                                                        icon="exclamation-circle"
                                                                        label={`Prospective Account`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip
                                                                        icon="quote"
                                                                        label={`Last Quote: ${
                                                                            customer.lastQuote ?? "Never"
                                                                        }`}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <InsightChip
                                                                        icon="calendar"
                                                                        label={`Created: ${moment(
                                                                            customer.created_datetime,
                                                                            "YYYY-MM-DD HH:mm"
                                                                        ).format("DD/MM/YYYY")}`}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        )}
                                                        {!_.isEmpty(customer.trading_name) && !ui?.device?.isTablet && (
                                                            <Grid item>
                                                                <InsightChip
                                                                    icon="info-circle"
                                                                    label={`T/a: ${customer.trading_name}`}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Hidden smDown>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={2} justify="flex-end">
                                                            <Grid item>
                                                                <ActionChip
                                                                    action={{ u: 0, p: 0 }}
                                                                    staff={staff[this.props.loggedInStaff.id]}
                                                                    page="customer"
                                                                />
                                                            </Grid>
                                                            {_.map(viewing.list, (view, idx) => {
                                                                if (staff[view.i]) {
                                                                    return (
                                                                        <Grid item key={idx}>
                                                                            <ActionChip
                                                                                action={view}
                                                                                staff={staff[view.i]}
                                                                                page="customer"
                                                                            />
                                                                        </Grid>
                                                                    );
                                                                }
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                                <Hidden mdUp>
                                                    <Grid item xs={2} align="right">
                                                        <ActionChip
                                                            staff={{ name: `${_.size(viewing.list) + 1} viewing` }}
                                                            page="customer"
                                                        />
                                                    </Grid>
                                                </Hidden>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid>
                            <Grid
                                item
                                ref={this.mainContentArea}
                                className={classes.main}
                                style={{ height: contentHeight - VIEW_PAGE_APP_BAR_HEIGHT }}
                            >
                                <Grid container>
                                    {(customer?.opening?.rao_id && (
                                        <Grid item xs={12}>
                                            <Box borderBottom="1px solid #ddd">
                                                <Alert severity={"warning"} elevation={0}>
                                                    <strong>Account Opening Requested -</strong> A {customer.opening?.rao_prepay ? `prepay` : `credit`} account opening request is waiting to be actioned from {moment(customer.opening.rao_created_datetime).format("DD/MM/YYYY HH:mm")}
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )) || (customer.deleted === 1 && (
                                        <Grid item xs={12}>
                                            <Box borderBottom="1px solid #ddd">
                                                <Alert severity="error" variant="standard" elevation={0}>
                                                    <strong>{isProspect ? 'Prospect' : 'Account'} Deleted</strong> - Please contact Sales Admin to re-instate this {isProspect ? 'prospect' : 'account'}...
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )) ||
                                        (customer?.on_hold === 1 && (
                                            <Grid item xs={12}>
                                                <Box borderBottom="1px solid #ddd">
                                                    <Alert severity="error" variant="standard" elevation={0}>
                                                        <strong>Important -</strong> This account is currently on-hold,
                                                        please speak to Finance for more information
                                                    </Alert>
                                                </Box>
                                            </Grid>
                                        )) ||
                                        (customer.sage_status !== 0 && accountStatus(customer.sage_status) && (
                                            <Grid item xs={12}>
                                                <Box borderBottom="1px solid #ddd">
                                                    <Alert severity="warning" variant="standard" elevation={0}>
                                                        {customer.prospect === 1
                                                            ? "This is a prospective customer, an account must be opened before an order can be placed"
                                                            : `This account is currently ${accountStatus(
                                                                  customer.sage_status
                                                              )}`}
                                                    </Alert>
                                                </Box>
                                            </Grid>
                                        )) ||
                                        (customer?.credit_data?.inactiveFlag === "1" && (
                                            <Grid item xs={12}>
                                                <Box borderBottom="1px solid #ddd">
                                                    <Alert severity="warning" variant="standard" elevation={0}>
                                                        <strong>Warning -</strong> This account is inactive, please
                                                        speak to Finance for more information
                                                    </Alert>
                                                </Box>
                                            </Grid>
                                        ))}
                                    <Grid item xs={12}>
                                        <Box p={3}>
                                            <TabPanel value={currentTab} index={0}>
                                                <Grid container spacing={3}>
                                                    <ViewActions
                                                        key={key}
                                                        callback={this.loadComponentData}
                                                        filter="customer"
                                                        filterId={customer.id}
                                                        staff={staff}
                                                        deployDialog={deployDialog}
                                                        closeDialog={closeDialog}
                                                        outstanding
                                                        dashboard
                                                        noGrid
                                                    />
                                                    <Grid item xs={12} md={6} lg={3}>
                                                        <Grid container spacing={3}>
                                                            {tradingAddress && (
                                                                <Grid item xs={12}>
                                                                    <PaddedPaper>
                                                                        <Box pb={1.5}>
                                                                            <Typography variant="h6">
                                                                                Company Details
                                                                            </Typography>
                                                                        </Box>
                                                                        <Grid container className="pb-1">
                                                                            <Grid item>
                                                                                <FAIcon icon="map-marker" size={12} />
                                                                            </Grid>
                                                                            <Grid item xs>
                                                                                {_.map(tradingAddress, (line, idx) => {
                                                                                    if (line?.length > 0) {
                                                                                        return (
                                                                                            <Typography
                                                                                                variant="body2"
                                                                                                key={idx}
                                                                                                component="div"
                                                                                                gutterBottom
                                                                                            >
                                                                                                {line}
                                                                                            </Typography>
                                                                                        );
                                                                                    }
                                                                                })}
                                                                            </Grid>
                                                                        </Grid>
                                                                        {customer.phone.length > 0 && (
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="div"
                                                                                className="pb-1"
                                                                            >
                                                                                <FAIcon icon="phone" size={12} />
                                                                                {canDial ? (
                                                                                    <Link className="textDefault cursorPointer" onClick={() => this.context.useDial(customer.phone)}>
                                                                                        {customer.phone}
                                                                                        <FAIcon icon="circle-phone" type="solid" className="pl-1 textSuccess" button noMargin size={15} />
                                                                                    </Link>
                                                                                    ) : customer.phone}
                                                                            </Typography>
                                                                        )}
                                                                        {customer.email.length > 0 && (
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="div"
                                                                                className="pb-1"
                                                                            >
                                                                                <FAIcon icon="envelope" size={12} />
                                                                                <Link className="textDefault" href={`mailto:${customer.email}`}>
                                                                                    {customer.email}
                                                                                </Link>
                                                                            </Typography>
                                                                        )}
                                                                        {customer.website.length > 0 && (
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="div"
                                                                                className="pb-1"
                                                                            >
                                                                                <FAIcon icon="globe" size={12} />
                                                                                <Link
                                                                                    className="textDefault" 
                                                                                    href={`${
                                                                                        !customer.website.match(
                                                                                            /^[a-zA-Z]+:\/\//
                                                                                        )
                                                                                            ? "http://"
                                                                                            : ""
                                                                                    }${customer.website}`}
                                                                                    target="_blank"
                                                                                >
                                                                                    {customer.website}
                                                                                </Link>
                                                                            </Typography>
                                                                        )}
                                                                        {customer.facebook &&
                                                                            customer.facebook.length > 0 && (
                                                                                <>
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="textSecondary"
                                                                                        className="pb-1"
                                                                                    >
                                                                                        <FAIcon
                                                                                            type="brand"
                                                                                            icon="facebook"
                                                                                            size="small"
                                                                                        />
                                                                                        <Link
                                                                                            className="textDefault" 
                                                                                            href={`${
                                                                                                !customer.facebook.match(
                                                                                                    /^[a-zA-Z]+:\/\//
                                                                                                )
                                                                                                    ? "http://"
                                                                                                    : ""
                                                                                            }${customer.facebook}`}
                                                                                            target="_blank"
                                                                                        >
                                                                                            {customer.facebook}
                                                                                        </Link>
                                                                                    </Typography>
                                                                                </>
                                                                            )}
                                                                        {customer.twitter &&
                                                                            customer.twitter.length > 0 && (
                                                                                <>
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="textSecondary"
                                                                                        className="pb-1"
                                                                                    >
                                                                                        <FAIcon
                                                                                            type="brand"
                                                                                            icon="twitter"
                                                                                            size="small"
                                                                                        />
                                                                                        <Link
                                                                                            className="textDefault" 
                                                                                            href={`${
                                                                                                !customer.twitter.match(
                                                                                                    /^[a-zA-Z]+:\/\//
                                                                                                )
                                                                                                    ? "http://"
                                                                                                    : ""
                                                                                            }${customer.twitter}`}
                                                                                            target="_blank"
                                                                                        >
                                                                                            {customer.twitter}
                                                                                        </Link>
                                                                                    </Typography>
                                                                                </>
                                                                            )}
                                                                        {customer.instagram &&
                                                                            customer.instagram.length > 0 && (
                                                                                <>
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="textSecondary"
                                                                                        className="pb-1"
                                                                                    >
                                                                                        <FAIcon
                                                                                            type="brand"
                                                                                            icon="instagram"
                                                                                            size="small"
                                                                                        />
                                                                                        <Link
                                                                                            className="textDefault" 
                                                                                            href={`${
                                                                                                !customer.instagram.match(
                                                                                                    /^[a-zA-Z]+:\/\//
                                                                                                )
                                                                                                    ? "http://"
                                                                                                    : ""
                                                                                            }${customer.instagram}`}
                                                                                            target="_blank"
                                                                                        >
                                                                                            {customer.instagram}
                                                                                        </Link>
                                                                                    </Typography>
                                                                                </>
                                                                            )}
                                                                        {customer.linkedin &&
                                                                            customer.linkedin.length > 0 && (
                                                                                <>
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="textSecondary"
                                                                                        className="pb-1"
                                                                                    >
                                                                                        <FAIcon
                                                                                            type="brand"
                                                                                            icon="linkedin"
                                                                                            size="small"
                                                                                        />
                                                                                        <Link
                                                                                            className="textDefault" 
                                                                                            href={`${
                                                                                                !customer.linkedin.match(
                                                                                                    /^[a-zA-Z]+:\/\//
                                                                                                )
                                                                                                    ? "http://"
                                                                                                    : ""
                                                                                            }${customer.linkedin}`}
                                                                                            target="_blank"
                                                                                        >
                                                                                            {customer.linkedin}
                                                                                        </Link>
                                                                                    </Typography>
                                                                                </>
                                                                            )}
                                                                        {customer.youtube &&
                                                                            customer.youtube.length > 0 && (
                                                                                <>
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="textSecondary"
                                                                                    >
                                                                                        <FAIcon
                                                                                            type="brand"
                                                                                            icon="youtube"
                                                                                            size="small"
                                                                                        />
                                                                                        <Link
                                                                                            className="textDefault" 
                                                                                            href={`${
                                                                                                !customer.youtube.match(
                                                                                                    /^[a-zA-Z]+:\/\//
                                                                                                )
                                                                                                    ? "http://"
                                                                                                    : ""
                                                                                            }${customer.youtube}`}
                                                                                            target="_blank"
                                                                                        >
                                                                                            {customer.youtube}
                                                                                        </Link>
                                                                                    </Typography>
                                                                                </>
                                                                            )}
                                                                    </PaddedPaper>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12}>
                                                                <PaddedPaper style={{ height: "100%" }}>
                                                                    <Box pb={1}>
                                                                        <Typography variant="h6">
                                                                            Primary Contact
                                                                        </Typography>
                                                                    </Box>
                                                                    {(!_.isEmpty(customer.contacts) &&
                                                                        primaryContactIdx !== null &&
                                                                        primaryContactIdx !== -1 && (
                                                                            <>
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    className="pb-1"
                                                                                >
                                                                                    <FAIcon icon="user" size={12} />
                                                                                    {
                                                                                        customer.contacts?.[
                                                                                            primaryContactIdx
                                                                                        ]?.fn
                                                                                    }{" "}
                                                                                    {
                                                                                        customer.contacts?.[
                                                                                            primaryContactIdx
                                                                                        ]?.ln
                                                                                    }
                                                                                </Typography>
                                                                                {customer.contacts?.[primaryContactIdx]
                                                                                    ?.m.length > 0 && (
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        component="div"
                                                                                        className="pb-1"
                                                                                    >
                                                                                        <FAIcon
                                                                                            icon="mobile"
                                                                                            size={12}
                                                                                        />
                                                                                            {canDial ? (
                                                                                                <Link className="textDefault cursorPointer" onClick={() => this.context.useDial(customer.contacts?.[primaryContactIdx]?.m)}>
                                                                                                    {customer.contacts?.[primaryContactIdx]?.m}
                                                                                                    <FAIcon icon="circle-phone" type="solid" className="pl-1 textSuccess" button noMargin size={15} />
                                                                                                </Link>
                                                                                            ) : customer.contacts?.[primaryContactIdx]?.m}
                                                                                    </Typography>
                                                                                )}
                                                                                {customer.contacts?.[primaryContactIdx]
                                                                                    ?.t.length > 0 && (
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        component="div"
                                                                                        className="pb-1"
                                                                                    >
                                                                                        <FAIcon
                                                                                            icon="phone"
                                                                                            size={12}
                                                                                        />
                                                                                        {canDial ? (
                                                                                                <Link className="textDefault cursorPointer" onClick={() => this.context.useDial(customer.contacts?.[primaryContactIdx]?.t)}>
                                                                                                    {customer.contacts?.[primaryContactIdx]?.t}
                                                                                                    <FAIcon icon="circle-phone" type="solid" className="pl-1 textSuccess" button noMargin size={15} />
                                                                                                </Link>
                                                                                            ) : customer.contacts?.[primaryContactIdx]?.t}
                                                                                    </Typography>
                                                                                )}
                                                                                {customer.contacts?.[primaryContactIdx]
                                                                                    ?.e.length > 0 && (
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        component="div"
                                                                                    >
                                                                                        <FAIcon
                                                                                            icon="envelope"
                                                                                            size={12}
                                                                                        />
                                                                                        <Link
                                                                                            className="textDefault" 
                                                                                            href={`mailto:${customer.contacts?.[primaryContactIdx]?.e}`}
                                                                                        >
                                                                                            {
                                                                                                customer.contacts?.[
                                                                                                    primaryContactIdx
                                                                                                ]?.e
                                                                                            }
                                                                                        </Link>
                                                                                    </Typography>
                                                                                )}
                                                                            </>
                                                                        )) || (
                                                                        <Typography variant="body2">
                                                                            <em>
                                                                                No primary contact is set for this
                                                                                customer
                                                                            </em>
                                                                        </Typography>
                                                                    )}
                                                                </PaddedPaper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={5}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Account Management" />
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12} lg={7}>
                                                                            <StaffTile
                                                                                noPaper
                                                                                title="Proactive Responsibility"
                                                                                staffId={
                                                                                    customer.account_manager_staff_id ??
                                                                                    null
                                                                                }
                                                                                missingText="Not Set"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={5}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="quote"
                                                                                title="Total Quotes"
                                                                                content={customer.cqu}
                                                                                onClick={() => this.handleTabChange(2)}
                                                                            />
                                                                        </Grid>
                                                                        {/* <Grid item xs={12}>     
                                                                            <Tile
                                                                                noPaper
                                                                                icon='account-review'
                                                                                title="Next Account Review"
                                                                                content={customer?.account_review ? `${momentFormatDateTime(customer.account_review?.task_base_datetime)}` : `Not Set`}
                                                                            />
                                                                        </Grid> */}
                                                                        <Grid item xs={12} lg={7}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="check"
                                                                                title={
                                                                                    customer?.status?.parent
                                                                                        ?.status_name
                                                                                }
                                                                                content={`${customer?.status?.status_code} - ${customer?.status?.status_name}`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={5}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="order"
                                                                                title="Total Orders"
                                                                                content={customer.cor}
                                                                                onClick={() => this.handleTabChange(3)}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Account Details" />
                                                                    {(isProspect && (
                                                                        <Grid container spacing={3}>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="user"
                                                                                    title={`Aliases`}
                                                                                    content={
                                                                                        _.isEmpty(customer.aliases)
                                                                                            ? `None`
                                                                                            : _.map(
                                                                                                  customer.aliases,
                                                                                                  (alias, idx) => (
                                                                                                      <Typography
                                                                                                          variant="body2"
                                                                                                          key={idx}
                                                                                                      >
                                                                                                          {
                                                                                                              alias.alias_name
                                                                                                          }
                                                                                                      </Typography>
                                                                                                  )
                                                                                              )
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="users-class"
                                                                                    title={`Company Type`}
                                                                                    content={customer.type.type_name}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="comments"
                                                                                    title="Interactions"
                                                                                    content={customer.interactions}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="phone"
                                                                                    title="Last Call"
                                                                                    content={customer.lastCall}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="book-reader"
                                                                                    title={`Last Catalogue Sent`}
                                                                                    content={
                                                                                        (customer.latest_catalogue &&
                                                                                            ((customer.latest_catalogue
                                                                                                .rc_actioned === 0 &&
                                                                                                "In Progress") ||
                                                                                                moment(
                                                                                                    customer
                                                                                                        .latest_catalogue
                                                                                                        .rc_actioned_datetime
                                                                                                ).format(
                                                                                                    "DD/MM/YYYY"
                                                                                                ))) ||
                                                                                        "Never"
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="directions"
                                                                                    title={`Referral Source`}
                                                                                    content={
                                                                                        !_.isEmpty(
                                                                                            customer.source?.crs_name
                                                                                        )
                                                                                            ? customer.source?.crs_name
                                                                                            : "N/A"
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    )) || (
                                                                        <Grid container spacing={3}>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="user"
                                                                                    title={`Aliases`}
                                                                                    content={
                                                                                        _.isEmpty(customer.aliases)
                                                                                            ? `None`
                                                                                            : _.map(
                                                                                                  customer.aliases,
                                                                                                  (alias, idx) => (
                                                                                                      <Typography
                                                                                                          variant="body2"
                                                                                                          key={idx}
                                                                                                      >
                                                                                                          {
                                                                                                              alias.alias_name
                                                                                                          }
                                                                                                      </Typography>
                                                                                                  )
                                                                                              )
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="users-class"
                                                                                    title={`Company Type`}
                                                                                    content={customer.type.type_name}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="landmark"
                                                                                    title={`Company Legal Status`}
                                                                                    content={`${customer.legal_status}`}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="hashtag"
                                                                                    title={`Company Number`}
                                                                                    content={`${
                                                                                        customer.reg_number &&
                                                                                        customer.reg_number.length > 0
                                                                                            ? `${customer.reg_number}`
                                                                                            : `N/A`
                                                                                    }`}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="chart-network"
                                                                                    title="Last Activity"
                                                                                    content={customer.lastActivity}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="phone"
                                                                                    title="Last Call"
                                                                                    content={customer.lastCall}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="book-reader"
                                                                                    title={`Last Catalogue Sent`}
                                                                                    content={
                                                                                        (customer.latest_catalogue &&
                                                                                            ((customer.latest_catalogue
                                                                                                .rc_actioned === 0 &&
                                                                                                "In Progress") ||
                                                                                                moment(
                                                                                                    customer
                                                                                                        .latest_catalogue
                                                                                                        .rc_actioned_datetime
                                                                                                ).format(
                                                                                                    "DD/MM/YYYY"
                                                                                                ))) ||
                                                                                        "Never"
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="pound-sign"
                                                                                    title={`Payment Terms`}
                                                                                    content={
                                                                                        customer?.payment_terms
                                                                                            ?.pt_name ?? "-"
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="directions"
                                                                                    title={`Referral Source`}
                                                                                    content={
                                                                                        !_.isEmpty(
                                                                                            customer.source?.crs_name
                                                                                        )
                                                                                            ? customer.source?.crs_name
                                                                                            : "N/A"
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={6}>
                                                                                <Tile
                                                                                    noPaper
                                                                                    icon="receipt"
                                                                                    title={`VAT Number`}
                                                                                    content={
                                                                                        !_.isEmpty(customer.vat_number)
                                                                                            ? customer.vat_number
                                                                                            : "N/A"
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                </PaddedPaper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} lg={4}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Customer Spend" />
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="info-circle"
                                                                                title={"Turnover YTD"}
                                                                                content={currencyFormat.format(
                                                                                    customer.tYtd
                                                                                )}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="info-circle"
                                                                                title={"Turnover YE 2024"}
                                                                                content={currencyFormat.format(
                                                                                    customer.tYtd2023
                                                                                )}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} md={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="check-circle"
                                                                                title={"Available Credit"}
                                                                                content={
                                                                                    (customer.credit_limit !== null &&
                                                                                        currencyFormat.format(
                                                                                            parseFloat(
                                                                                                customer.credit_limit
                                                                                            ) -
                                                                                                parseFloat(
                                                                                                    customer.credit_balance
                                                                                                )
                                                                                        )) ||
                                                                                    "N/A"
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="tachometer-average"
                                                                                title={"Average Order"}
                                                                                content={`${
                                                                                    customer.avgOrder
                                                                                        ? `£${customer.avgOrder}`
                                                                                        : `£0.00`
                                                                                }`}
                                                                            />
                                                                        </Grid>
                                                                        {/* <Grid item xs={12} lg={6}>
                                                                            <Tile
                                                                                noPaper
                                                                                icon="check"
                                                                                title={'Turnover YTD 2019'}
                                                                                content={currencyFormat.format(customer.turnoverYTD)}
                                                                            />
                                                                        </Grid> */}
                                                                        <Grid item xs={12}>
                                                                            <Line
                                                                                data={charts.spend}
                                                                                options={{
                                                                                    legend: {
                                                                                        display: false,
                                                                                    },
                                                                                    scales: {
                                                                                        xAxes: [
                                                                                            {
                                                                                                gridLines: {
                                                                                                    color: "rgba(0,0,0,.03)",
                                                                                                },
                                                                                            },
                                                                                        ],
                                                                                        yAxes: [
                                                                                            {
                                                                                                gridLines: {
                                                                                                    color: "rgba(0,0,0,.03)",
                                                                                                },
                                                                                                ticks: {
                                                                                                    callback: (
                                                                                                        value,
                                                                                                        index,
                                                                                                        values
                                                                                                    ) => {
                                                                                                        return value.toLocaleString(
                                                                                                            "en-GB",
                                                                                                            {
                                                                                                                style: "currency",
                                                                                                                currency:
                                                                                                                    "GBP",
                                                                                                            }
                                                                                                        );
                                                                                                    },
                                                                                                    beginAtZero: true,
                                                                                                    min: 0,
                                                                                                },
                                                                                            },
                                                                                        ],
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>   
                                                                <PaddedPaper>       
                                                                    <Grid container>
                                                                        <Grid item xs>
                                                                            <Typography
                                                                                variant="h6"
                                                                                component="div"
                                                                            >
                                                                                Customer Discounts
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Tooltip title="View Discounts">
                                                                                <IconButton
                                                                                    disabled={_.isEmpty(customer.discounts) && _.isEmpty(customer.discount_templates)}
                                                                                    onClick={this.handleDeployDiscounts}
                                                                                >
                                                                                    <FAIcon
                                                                                        type="light"
                                                                                        icon="binoculars"
                                                                                        size="small"
                                                                                        noMargin
                                                                                        button
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    </Grid>   
                                                                    <Box
                                                                        mr={1}
                                                                        display="inline-block"
                                                                    >
                                                                        <InsightChip
                                                                            icon="percent"
                                                                            label={
                                                                                !_.isEmpty(
                                                                                    customer.discounts
                                                                                ) || !_.isEmpty(customer.discount_templates)
                                                                                    ? `${_.size(
                                                                                            customer.discounts
                                                                                        ) + _.size(customer.discount_templates)} Available`
                                                                                    : "None"
                                                                            }
                                                                        />
                                                                    </Box>
                                                                    {(customer.foc_shipping && (
                                                                        <InsightChip
                                                                            icon="truck-loading"
                                                                            label={`Free Delivery On ${
                                                                                customer.foc_shipping ===
                                                                                "0.00"
                                                                                    ? `All Orders`
                                                                                    : `Orders Over ${currencyFormat.format(
                                                                                            customer.foc_shipping
                                                                                        )}`
                                                                            }`}
                                                                        />
                                                                    )) || (
                                                                        <InsightChip
                                                                            icon="truck-loading"
                                                                            label={`No Free Delivery`}
                                                                        />
                                                                    )}
                                                                    {customer.foc_shipping && customer.freeDelivery && (
                                                                        <InsightChip
                                                                            icon="truck"
                                                                            label={`Free Delivery via ${customer?.freeDelivery?.nm}`}
                                                                        />
                                                                    )}
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Paper>
                                                                    <Box p={3}>
                                                                        <Typography
                                                                            variant="h6"
                                                                            component="div"
                                                                        >
                                                                            Customer Exclusions
                                                                            <Box pt={0.25}>
                                                                                <Box
                                                                                    mr={1}
                                                                                    display="inline-block"
                                                                                >
                                                                                    <InsightChip
                                                                                        icon="arrow-right"
                                                                                        label={
                                                                                            `Paxton Hardware: ${customer.pax_excl_hw === 1 ? "Yes" : "No"}`
                                                                                        }
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                        </Typography>
                                                                    </Box>
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={3}>
                                                <SearchOrders
                                                    key={key}
                                                    dataLoading={isLoading}
                                                    customerId={customer.id}
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                <SearchQuotes
                                                    key={key}
                                                    dataLoading={isLoading}
                                                    customerId={customer.id}
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={5}>
                                                <ActivityLog apiRoute={`/customers/${customer.id}/activity`} fullPage />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={6}>
                                                <ViewCallLogs apiRoute={`/customers/${customer.id}/calls`} hasCallRecordings hasEmailLogs />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={7}>
                                                <ViewNotes notes={customer.notes} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={8}>
                                                <ViewAddresses
                                                    access={access}
                                                    callback={this.loadComponentData}
                                                    addresses={customer.addresses}
                                                    disabled={accClosed}
                                                    name={customer.company_name}
                                                    AddressForm={
                                                        <AddressForm
                                                            id={customer.id}
                                                            type="customer"
                                                            noRegistered
                                                            noTrading
                                                            callback={() => {
                                                                this.props.closeDialog();
                                                                this.loadComponentData();
                                                            }}
                                                            cancel={this.props.closeDialog}
                                                        />
                                                    }
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={9}>
                                                <ViewContacts
                                                    access={access}
                                                    callback={this.loadComponentData}
                                                    contacts={customer.contacts}
                                                    handleContactSms={this.handleContactSms}
                                                    disabled={accClosed}
                                                    name={customer.company_name}
                                                    ContactForm={
                                                        <ContactForm
                                                            id={customer.id}
                                                            type="customer"
                                                            callback={() => {
                                                                this.props.closeDialog();
                                                                this.loadComponentData();
                                                            }}
                                                            cancel={this.props.closeDialog}
                                                            customerForm
                                                        />
                                                    }
                                                />
                                            </TabPanel>
                                            {access.accounts && (
                                                <TabPanel value={currentTab} index={10}>
                                                    <AccountsOverview
                                                        callback={this.creditCheck}
                                                        creditLoading={creditLoading}
                                                        customer={customer}
                                                        accClosed={accClosed}
                                                        handleDeployCreditAccount={this.handleDeployCreditAccount}
                                                    />
                                                </TabPanel>
                                            )}
                                            <TabPanel value={currentTab} index={11}>
                                                <MostPurchased customerId={customer.id} customerName={customer.company_name} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <ViewDocuments
                                                            access={access}
                                                            callback={this.loadComponentData}
                                                            category="Customers"
                                                            disabled={accClosed}
                                                            documents={customer.documents}
                                                            uploadFile={this.handleDeployFileUpload}
                                                            legacyDocBox={`C:/DATA/Advance/Documents/ACCDocs/ACC${customer.legacy_account_number}`}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={13}>
                                                <EmailLog
                                                    key={key}
                                                    filter="customer"
                                                    filterId={customer.id}
                                                    staff={staff}
                                                    deployConfirmation={deployConfirmation}
                                                    deploySnackBar={deploySnackBar}
                                                    deployDialog={deployDialog}
                                                    closeDialog={closeDialog}
                                                    sendEmail={this.handleDeploySendEmail}
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={14}>
                                                <SmsLog
                                                    key={key}
                                                    filter="customer"
                                                    filterId={customer.id}
                                                    staff={staff}
                                                    deployConfirmation={deployConfirmation}
                                                    deploySnackBar={deploySnackBar}
                                                    deployDialog={deployDialog}
                                                    closeDialog={closeDialog}
                                                    sendSms={access.sendSms ? this.handleDeployAddSms : undefined}
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={15}>
                                                <ViewActions
                                                    key={key}
                                                    filter="customer"
                                                    filterId={customer.id}
                                                    scheduled={customer.scheduled_actions}
                                                    staff={staff}
                                                    deployDialog={deployDialog}
                                                    closeDialog={closeDialog}
                                                    callback={this.loadComponentData}
                                                />
                                            </TabPanel>
                                            {access.credit && (
                                                <TabPanel value={currentTab} index={16}>
                                                    <CreditOverview
                                                        access={access}
                                                        callback={this.loadComponentData}
                                                        customer={customer}
                                                        closeDialog={closeDialog}
                                                        creditLoading={creditLoading}
                                                        deployConfirmation={deployConfirmation}
                                                        deployDialog={deployDialog}
                                                        deploySnackBar={deploySnackBar}
                                                    />
                                                </TabPanel>
                                            )}
                                            {access.accounts && (
                                                <TabPanel value={currentTab} index={17}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <ViewDocuments
                                                                access={access}
                                                                documents={_.filter(
                                                                    customer.documents,
                                                                    (el) => el.doc_dc_id === 24
                                                                )}
                                                                legacyDocBox={`C:/DATA/Advance/Documents/ACCDocs/ACC${customer.legacy_account_number}`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TabPanel>
                                            )}
                                            <TabPanel value={currentTab} index={18}>
                                                <Paper>
                                                    <DataTable
                                                        config={{
                                                            key: "i",
                                                            pagination: true,
                                                            alternatingRowColours: true,
                                                            isLoading: false,
                                                            responsiveImportance: true,
                                                            options: {
                                                                dataRef: true,
                                                                export: {
                                                                    title: `${customer.company_name} Special Parts`,
                                                                    name: `SpecialParts`,
                                                                    excel: true,
                                                                    pdf: true,
                                                                    print: true,
                                                                },
                                                            },
                                                        }}
                                                        columns={[
                                                            {
                                                                field: (rowData) => (
                                                                    <Avatar
                                                                        variant="square"
                                                                        alt={rowData.name}
                                                                        src={
                                                                            (rowData.im &&
                                                                                `${CLOUDFRONT_URL}${rowData.im}`) ||
                                                                            logo
                                                                        }
                                                                    />
                                                                ),
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Product Code",
                                                                field: (rowData) => rowData.c,
                                                                important: true,
                                                                dataRef: "c",
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Description",
                                                                field: (rowData) => rowData.d,
                                                                important: true,
                                                                dataRef: "d",
                                                                main: true,
                                                                truncate: true,
                                                            },
                                                            {
                                                                heading: "Buy Price",
                                                                field: (rowData) => rowData.b,
                                                                fieldFormat: "currency",
                                                                dataRef: "b",
                                                                important: true,
                                                                alignment: "right",
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Trade Price",
                                                                field: (rowData) => rowData.p,
                                                                fieldFormat: "currency",
                                                                dataRef: "p",
                                                                important: true,
                                                                sizeToContent: true,
                                                                alignment: "right",
                                                                cellProps: () => ({
                                                                    style: {
                                                                        color: "#388E3C",
                                                                    },
                                                                }),
                                                            },
                                                            {
                                                                heading: "In Stock",
                                                                field: (rowData) => rowData.st,
                                                                important: true,
                                                                dataRef: "st",
                                                                alignment: "right",
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Available",
                                                                field: (rowData) => rowData.av,
                                                                important: true,
                                                                dataRef: "av",
                                                                alignment: "right",
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Allocated",
                                                                field: (rowData) => rowData.al,
                                                                important: true,
                                                                dataRef: "al",
                                                                alignment: "right",
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "On Order",
                                                                field: (rowData) => rowData.oo,
                                                                important: true,
                                                                dataRef: "oo",
                                                                alignment: "right",
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Pre-ordered",
                                                                field: (rowData) => rowData.po,
                                                                important: true,
                                                                dataRef: "po",
                                                                alignment: "right",
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Status",
                                                                field: (rowData) => (
                                                                    <ProductStockChip
                                                                        status={rowData.s}
                                                                        basedOnStatus
                                                                    />
                                                                ),
                                                                dataRef: "s",
                                                                sizeToContent: true,
                                                            },
                                                            // {
                                                            //     heading: 'Customer Rating',
                                                            //     field: rowData => <Rating readOnly max={5} value={rowData.rat} size="small" precision={0.5} />,
                                                            //     important: true,
                                                            //     dataRef: 'rat',
                                                            //     sizeToContent: true
                                                            // },
                                                            {
                                                                actions: (rowData) => {
                                                                    return [
                                                                        {
                                                                            name: "View",
                                                                            icon: "binoculars",
                                                                            link: "/products/" + rowData.i,
                                                                            disabled: !access.viewProduct,
                                                                        },
                                                                    ];
                                                                },
                                                            },
                                                        ]}
                                                        rows={customer.special_parts}
                                                    />
                                                </Paper>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={19}>
                                                {(!_.isEmpty(customer.users) && (
                                                    <>
                                                        <ExpansionPanel defaultExpanded={_.filter(customer.users, (u) => u.l === 0).length}>
                                                            <ExpansionPanelSummary
                                                                expandIcon={<FAIcon icon="chevron-down" noMargin button />}
                                                            >
                                                                <Typography variant="h6">Installer Portal Users ({_.filter(customer.users, (u) => u.l === 0).length})</Typography>
                                                            </ExpansionPanelSummary>
                                                            <ExpansionPanelDetails>
                                                                <DataTable
                                                                    config={{
                                                                        key: "i",
                                                                        alternatingRowColours: true,
                                                                        isLoading: false,
                                                                        responsiveImportance: true,
                                                                        options: {
                                                                            minimalPadding: true
                                                                        }
                                                                    }}
                                                                    columns={[
                                                                        {
                                                                            heading: "Active",
                                                                            field: (rowData) => rowData.s,
                                                                            fieldFormat: "boolean",
                                                                            sizeToContent: true,
                                                                            alignment: "center",
                                                                            important: true,
                                                                        },
                                                                        {
                                                                            heading: "Admin",
                                                                            field: (rowData) => rowData.ga,
                                                                            fieldFormat: "boolean",
                                                                            sizeToContent: true,
                                                                            alignment: "center",
                                                                            important: true,
                                                                        },
                                                                        {
                                                                            heading: "Name",
                                                                            field: (rowData) => (
                                                                                <>
                                                                                    {rowData.fn} {rowData.ln}
                                                                                </>
                                                                            ),
                                                                            dataRef: "fn",
                                                                            important: true,
                                                                            main: true,
                                                                            sizeToContent: true,
                                                                        },
                                                                        {
                                                                            heading: "E-mail Address",
                                                                            field: (rowData) => rowData.em,
                                                                            dataRef: "em",
                                                                            important: true,
                                                                            truncate: true,
                                                                        },
                                                                        {
                                                                            heading: "Mobile Number",
                                                                            field: (rowData) => rowData.ph || '-',
                                                                            dataRef: "ph",
                                                                            important: true,
                                                                            truncate: true,
                                                                        },
                                                                        {
                                                                            heading: "Position",
                                                                            field: (rowData) => rowData.p || '-',
                                                                            dataRef: "p",
                                                                            important: true,
                                                                            truncate: true,
                                                                        },
                                                                        {
                                                                            heading: "Role",
                                                                            field: (rowData) => _.find(customer.roles, el => el.value === rowData.r)?.label ?? 'Unknown',
                                                                            dataRef: "r",
                                                                            important: true,
                                                                        },
                                                                        {
                                                                            heading: "Activated On",
                                                                            field: (rowData) => rowData.c,
                                                                            dataRef: "c",
                                                                            fieldFormat: "date",
                                                                            sizeToContent: true,
                                                                        },
                                                                        {
                                                                            heading: "Last Login",
                                                                            field: (rowData) => rowData.ll,
                                                                            dataRef: "ll",
                                                                            fieldFormat: "datetime",
                                                                            sizeToContent: true,
                                                                        },
                                                                        {
                                                                            actions: (rowData) => {
                                                                                return [
                                                                                    {
                                                                                        name: "Login as User",
                                                                                        icon: "sign-in-alt",
                                                                                        onClick: () =>
                                                                                            this.handleLoginAsUser(
                                                                                                rowData.i
                                                                                            ),
                                                                                        disabled:
                                                                                            accClosed || !access.tradeZoneMgt,
                                                                                    },
                                                                                    {
                                                                                        name: "Modify",
                                                                                        icon: "pencil",
                                                                                        onClick: () =>
                                                                                            this.handleDeployTradeZoneForm(
                                                                                                rowData
                                                                                            ),
                                                                                        disabled:
                                                                                            accClosed || !access.tradeZoneMgt,
                                                                                    },
                                                                                    {
                                                                                        name: "Delete",
                                                                                        icon: "trash-alt",
                                                                                        onClick: () =>
                                                                                            this.props.deployConfirmation(`Are you sure you want to delete this Portal User?`, () => this.handleDeleteTradeZoneUser(rowData.i)),
                                                                                        disabled:
                                                                                            accClosed || !access.deleteTradeZoneUser || rowData.ga,
                                                                                    },
                                                                                ];
                                                                            },
                                                                        },
                                                                    ]}
                                                                    rows={_.filter(customer.users, (u) => u.l === 0)}
                                                                />
                                                            </ExpansionPanelDetails>
                                                        </ExpansionPanel>
                                                        {_.filter(customer.users, (u) => u.l === 1).length > 0 && (
                                                                <ExpansionPanel defaultExpanded={!_.filter(customer.users, (u) => u.l === 0).length}>
                                                                <ExpansionPanelSummary
                                                                    expandIcon={<FAIcon icon="chevron-down" noMargin button />}
                                                                >
                                                                    <Typography variant="h6">Trade Zone (Legacy) Users ({_.filter(customer.users, (u) => u.l === 1).length})</Typography>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails>
                                                                    <DataTable
                                                                        config={{
                                                                            key: "i",
                                                                            alternatingRowColours: true,
                                                                            isLoading: false,
                                                                            responsiveImportance: true,
                                                                            options: {
                                                                                minimalPadding: true
                                                                            }
                                                                        }}
                                                                        columns={[
                                                                            {
                                                                                heading: "Active",
                                                                                field: (rowData) => rowData.s,
                                                                                fieldFormat: "boolean",
                                                                                sizeToContent: true,
                                                                                alignment: "center",
                                                                                important: true,
                                                                            },
                                                                            {
                                                                                heading: "Name",
                                                                                field: (rowData) => (
                                                                                    <>
                                                                                        {rowData.fn} {rowData.ln}
                                                                                    </>
                                                                                ),
                                                                                dataRef: "fn",
                                                                                important: true,
                                                                                main: true,
                                                                                sizeToContent: true,
                                                                            },
                                                                            {
                                                                                heading: "E-mail Address",
                                                                                field: (rowData) => rowData.em,
                                                                                dataRef: "em",
                                                                                important: true,
                                                                                truncate: true,
                                                                            },
                                                                            {
                                                                                heading: "Activated On",
                                                                                field: (rowData) => rowData.c,
                                                                                dataRef: "c",
                                                                                fieldFormat: "date",
                                                                                sizeToContent: true,
                                                                            },
                                                                            {
                                                                                heading: "Last Login",
                                                                                field: (rowData) => rowData.ll,
                                                                                dataRef: "ll",
                                                                                fieldFormat: "datetime",
                                                                                sizeToContent: true,
                                                                            },
                                                                            {
                                                                                actions: (rowData) => {
                                                                                    return [
                                                                                        {
                                                                                            name: "Delete",
                                                                                            icon: "trash-alt",
                                                                                            onClick: () =>
                                                                                                this.props.deployConfirmation(`Are you sure you want to delete this Portal User?`, () => this.handleDeleteTradeZoneUser(rowData.i)),
                                                                                            disabled:
                                                                                                accClosed || !access.deleteTradeZoneUser,
                                                                                        },
                                                                                    ];
                                                                                },
                                                                            },
                                                                        ]}
                                                                        rows={_.filter(customer.users, (u) => u.l === 1)}
                                                                    />
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                        )}
                                                    </>
                                                )) || (
                                                    <Grid container spacing={3} justify="center">
                                                        <Grid item xs={12}>
                                                            <Typography variant="h5" className="fw-400" align="center">
                                                                This account is not registered for the Installer Portal
                                                            </Typography>
                                                        </Grid>
                                                        {!accClosed && access.tradeZoneMgt && (
                                                            <Grid item>
                                                                <Button onClick={() => this.handleDeployTradeZoneForm(null)} variant="contained" color="primary">
                                                                    <FAIcon icon="user-plus" size={15} button />
                                                                    Add Portal User
                                                                </Button>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                )}
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={20}>
                                                <Paper>
                                                    <DataTable
                                                        config={{
                                                            key: "site_id",
                                                            pagination: true,
                                                            alternatingRowColours: true,
                                                            isLoading: false,
                                                            // responsiveImportance: true,
                                                            options: {
                                                                headingInput: access.addSite && (
                                                                    <Button
                                                                        variant="text"
                                                                        color="primary"
                                                                        disabled={accClosed}
                                                                        size="small"
                                                                        onClick={() =>
                                                                            this.handleDeploySiteForm(undefined)
                                                                        }
                                                                    >
                                                                        <FAIcon
                                                                            type="light"
                                                                            icon="plus-circle"
                                                                            size={15}
                                                                            button
                                                                        />
                                                                        Add Site
                                                                    </Button>
                                                                ),
                                                                dataRef: true,
                                                                export: {
                                                                    title: `${customer.company_name} Sites`,
                                                                    name: `${customer.company_name}-Sites`,
                                                                    excel: true,
                                                                    pdf: true,
                                                                    print: true,
                                                                },
                                                            },
                                                        }}
                                                        columns={[
                                                            {
                                                                heading: "Active",
                                                                field: (rowData) => rowData.site_active,
                                                                fieldFormat: "boolean",
                                                                sizeToContent: true,
                                                                alignment: "right",
                                                            },
                                                            {
                                                                heading: "Site",
                                                                field: (rowData) =>
                                                                    `${rowData.site_code} - ${rowData.site_description}`,
                                                                dataRef: "site_code",
                                                            },
                                                            {
                                                                heading: "Orders",
                                                                field: (rowData) => rowData.co,
                                                                dataRef: "co",
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Last Order Date",
                                                                field: (rowData) =>
                                                                    rowData?.latest_order?.co_created_datetime ??
                                                                    "0000-00-00",
                                                                fieldFormat: "date",
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Last Order Ref",
                                                                field: (rowData) =>
                                                                    rowData?.latest_order?.co_ref ?? "-",
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Last Quantity",
                                                                field: (rowData) =>
                                                                    rowData?.latest_order?.co_quantity ?? "-",
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Last Internal Range",
                                                                field: (rowData) =>
                                                                    `${
                                                                        rowData?.latest_order
                                                                            ?.co_internal_range_start ?? "N/A"
                                                                    } ~ ${
                                                                        rowData?.latest_order?.co_internal_range_end ??
                                                                        "N/A"
                                                                    }`,
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Last External Range",
                                                                field: (rowData) =>
                                                                    `${
                                                                        rowData?.latest_order
                                                                            ?.co_external_range_start ?? "N/A"
                                                                    } ~ ${
                                                                        rowData?.latest_order?.co_external_range_end ??
                                                                        "N/A"
                                                                    }`,
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Site Created By",
                                                                field: (rowData) =>
                                                                    staff?.[rowData.site_created_by_staff_id]?.name ??
                                                                    "-",
                                                                dataRef: "site_created_by_staff_id",
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                heading: "Site Created On",
                                                                field: (rowData) => rowData.site_created_datetime,
                                                                dataRef: "site_created_datetime",
                                                                fieldFormat: "datetime",
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                actions: (rowData) => {
                                                                    return [
                                                                        {
                                                                            name: "Modify",
                                                                            icon: "pencil",
                                                                            onClick: () =>
                                                                                this.handleDeploySiteForm(rowData),
                                                                            disabled: accClosed || !access.modifySite,
                                                                        },
                                                                    ];
                                                                },
                                                            },
                                                        ]}
                                                        rows={customer.sites}
                                                    />
                                                </Paper>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={21}>
                                                <SearchRMA key={key} dataLoading={isLoading} customerId={customer.id} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={22}>
                                                <SearchCreditNotes
                                                    key={key}
                                                    dataLoading={isLoading}
                                                    customerId={customer.id}
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={23}>
                                                <SearchInvoices
                                                    key={key}
                                                    dataLoading={isLoading}
                                                    customerId={customer.id}
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={24}>
                                                <CustomerPriceList
                                                    key={key}
                                                    dataLoading={isLoading}
                                                    customer={customer}
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={25}>
                                                <CustomerTrackedPriceList
                                                    key={key}
                                                    dataLoading={isLoading}
                                                    customer={customer}
                                                />
                                            </TabPanel>
                                            {access.visits && (
                                                <TabPanel value={currentTab} index={26}>
                                                    <CustomerVisits 
                                                        key={key}
                                                        callback={this.loadComponentData}
                                                        dataLoading={isLoading}
                                                        customerId={customer.id} 
                                                    />
                                                </TabPanel>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                className={classes.side}
                                style={{ height: contentHeight - VIEW_PAGE_APP_BAR_HEIGHT }}
                            >
                                <Tabs
                                    color="primary"
                                    onChange={this.handleTabChange}
                                    orientation="vertical"
                                    TabIndicatorProps={{
                                        style: {
                                            display: "none",
                                        },
                                    }}
                                    value={currentTab}
                                    variant="scrollable"
                                >
                                    <Tab
                                        classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                        value={0}
                                        label="Overview"
                                    />
                                    <Tab
                                        classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                        value={8}
                                        label="Addresses"
                                    />
                                    <Tab
                                        classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                        value={9}
                                        label="Contacts"
                                    />
                                    <Tab
                                        classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                        value={12}
                                        label={
                                            <Badge
                                                color="primary"
                                                className="badge"
                                                badgeContent={customer.caf ?? customer.cra ?? 0}
                                                showZero={false}
                                            >
                                                Files
                                            </Badge>
                                        }
                                        disabled={_.isEmpty(customer.documents)}
                                    />
                                    <Tab
                                        classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                        value={24}
                                        label="Price List"
                                        disabled={!access.priceList}
                                    />
                                    {customer.track_price_list === 1 && (
                                        <Tab
                                            classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                            value={25}
                                            label="Tracked Price List"
                                            disabled={!access.trackedPriceList}
                                        />
                                    )}
                                    {!isProspect && (
                                        <Tab
                                            classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                            value={20}
                                            disabled={isProspect}
                                            label="Sites"
                                        />
                                    )}
                                    <Tab
                                        classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                        value={18}
                                        label="Special Parts"
                                        disabled={_.isEmpty(customer.special_parts)}
                                    />
                                    {access.tradeZone && (
                                        <Tab
                                            classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                            value={19}
                                            label="Installer Portal"
                                        />
                                    )}
                                    <Divider />
                                    {!isProspect && access.accounts && (
                                        <Tab
                                            classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                            value={10}
                                            label="Finance"
                                        />
                                    )}
                                    {access.credit && (
                                        <Tab
                                            classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                            value={16}
                                            label={`Account Overview`}
                                        />
                                    )}
                                    {!isProspect && access.accounts && (
                                        <Tab
                                            classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                            value={17}
                                            label="Credit Reports"
                                            disabled={_.isEmpty(
                                                _.filter(customer.documents, (el) => el.doc_dc_id === 24)
                                            )}
                                        />
                                    )}
                                    {!isProspect && (
                                        <Tab
                                            classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                            value={22}
                                            label="Credit Notes"
                                        />
                                    )}
                                    {!isProspect && (
                                        <Tab
                                            classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                            value={23}
                                            label="Invoices"
                                        />
                                    )}
                                    {!isProspect && <Divider />}
                                    {!isProspect && (
                                        <Tab
                                            classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                            value={11}
                                            label="Most Purchased"
                                        />
                                    )}
                                    {isProspect && <Divider />}
                                    <Tab
                                        classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                        value={2}
                                        label="Quotes"
                                    />
                                    {isProspect && <Divider />}
                                    {!isProspect && (
                                        <Tab
                                            classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                            value={3}
                                            label="Sales Orders"
                                        />
                                    )}
                                    {!isProspect && (
                                        <Tab
                                            classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                            value={21}
                                            label="RMA"
                                        />
                                    )}
                                    {!isProspect && <Divider />}
                                    <Tab
                                        classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                        value={5}
                                        label="Timeline"
                                    />
                                    <Tab
                                        classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                        value={15}
                                        label="Actions"
                                    />
                                    <Tab
                                        classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                        value={6}
                                        label="Calls"
                                    />
                                    <Tab
                                        classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                        value={7}
                                        label="Notes"
                                    />
                                    {access.visits && (
                                        <Tab
                                            classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                            value={26}
                                            label="Visits"
                                        />
                                    )}
                                    <Divider />
                                    <Tab
                                        classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                        value={13}
                                        label="E-mails"
                                    />
                                    <Tab
                                        classes={{ textColorInherit: classes.tab, selected: classes.tabSelected }}
                                        value={14}
                                        label="SMS"
                                    />
                                </Tabs>
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    staff: state.notifications.status,
    loggedInStaff: state.staffAuth.staff,
    statePersistence: state.statePersistence,
    ui: state.ui,
});

const mapDispatchToProps = (dispatch) => ({
    closeDialog: () => dispatch(closeDialog()),
    deployConfirmation: (message, success) => dispatch(deployConfirmation(message, success)),
    deployDialog: (
        content,
        disableDialogContent = false,
        title = "",
        variant = "standard",
        size = "md",
        fullscreen = false,
        disableExit = false
    ) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disableExit)),
    deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
    setPersistence: (key, state) => dispatch(setPersistence(key, state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(ViewCustomer));